import { Injectable, Injector, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { dateFormatForDatePipe } from './format';
import { GlobalStoreService } from './global-store.service';
import { JobPostWorkbookPageAccessPermission } from 'src/app/job-post/constants';
import { PAGE } from '../_models/page.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnDestroy {

  private _activeUrl = new BehaviorSubject<string>('');
  activeUrl$ = this._activeUrl.asObservable();
  localDateFormat: string = dateFormatForDatePipe;
  localTimeFormat: string = 'HH:mm';
  subscriptions: Subscription[] = [];

  private _clearGlobalSearch = new BehaviorSubject<boolean>(false);
  clearGlobalSearch$ = this._clearGlobalSearch.asObservable();

  constructor(
    private injector: Injector,
    private datePipe: DatePipe,
    private globalStoreService: GlobalStoreService,
    private router: Router,
  ) { }

  scrollToTop(delay: number = 300) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, delay);
  }

  compareTwoDates(date1: string, date2: string) {
    let dateObj1 = new Date(date1);
    let dateObj2 = new Date(date2);
    return ((+dateObj1 - +dateObj2) > 0) // return true if date 1 is future to date 2
  }

  convertIsoToLocalDateAndTime(isoDate: string) {
    const localDate = this.datePipe.transform(isoDate, this.localDateFormat);
    const localTime = this.datePipe.transform(isoDate, this.localTimeFormat);
    return { localDate, localTime };
  }

  hideOrShowTooltip(event: any, object: any, property: string, isEqual: boolean = true) {
    const childElement = event?.target;
    const parentElement = childElement?.parentElement;
    if (parentElement && childElement) {
      if (childElement?.offsetWidth > parentElement?.offsetWidth ||
        (isEqual && childElement?.offsetWidth === parentElement?.offsetWidth)) {
        object[property] = true;
      }
      else {
        object[property] = false;
      }
    }
  }

  hideOrShowTooltipFormTable(event: any, object: any, property: string, isEqual: boolean = true) {
    const childElement = event?.target;
    const parentElement = childElement?.parentElement;
    if (parentElement && childElement) {
      if (childElement?.offsetWidth > parentElement?.offsetWidth ||
        (isEqual && childElement?.offsetWidth === parentElement?.offsetWidth)) {
        object[property]?.setValue(true);
      }
      else {
        object[property]?.setValue(false);
      }
    }
  }

  hideOrShowTooltipVertical(event: any, object: any, property: string, isEqual: boolean = true) {
    const element = event?.target;
    if (element && (element?.offsetHeight < element?.scrollHeight || (isEqual && element?.offsetHeight === element?.scrollHeight))) {
      object[property] = true;
    } else {
      object[property] = false;
    }
  }

  hasVerticalScroll() {
    return window.innerWidth > document.documentElement.clientWidth
  }

  setActiveUrl(url: string) {
    this._activeUrl.next(url);
  }

  customEmailAndUserNameSearch(term: string, item: any, propertyA: string = 'username', propertyB: string = 'email') {
    term = term.toLocaleLowerCase().trim();
    return item[propertyA].toLocaleLowerCase().indexOf(term) > -1 || item[propertyB].toLocaleLowerCase().indexOf(term) > -1;
  }

  dropdownScrollToTop(className: string) {
    setTimeout(() => {
      const dropdownContainer = document.querySelector(className + ' .ng-dropdown-panel .ng-dropdown-panel-items');
      if (dropdownContainer) {
        dropdownContainer.scrollTop = 0;  
      }
    }, 0);
  }

  convertDurationData(arr: any) {
    let temp: any = {};
    arr.forEach((element: any) => {
      temp[element.id] = element.name;
    });
    return temp;
  }

  closeModals() {
    let modals = this.injector.get(NgbModal)
    modals.dismissAll();
  }

  clearSearch() {
    this._clearGlobalSearch.next(true);
  }

  scrollToClassName(className: string) {
    setTimeout(() => {
      const container = document.querySelector(className);
      if (container) {
        container.scrollTop = 0;  
      }
    }, 0);
  }

  checkJobWorkbookPageAccess(page: PAGE): Observable<boolean> {
    return new Observable((observer) => {
      let jobPostWorkbookId = this.globalStoreService.getJobPostWorkbookId();
      if (jobPostWorkbookId) {
        this.subscriptions.push(
          this.globalStoreService.getJobPostData().subscribe((jobData: any) => {
            if(!JobPostWorkbookPageAccessPermission[page].includes(jobData.status)) {
              observer.next(false);
              observer.complete();
              this.router.navigateByUrl(`jobpost/view/${jobData.id}`);
            }
            observer.next(true);
            observer.complete();
          })
        )
      } else {
        observer.next(true);
        observer.complete();
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}



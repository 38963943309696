import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ApiService, CoreModule } from './core';
import { SharedModule } from './shared';
import { AuthModule } from './auth/auth.module';
import { GlobalErrorHandler } from './error-handlers/global-error-handler.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { StorageService } from './shared/_services/storage.service';

function initializeKeycloak(keycloak: KeycloakService, apiService: ApiService, storageService: StorageService) {
  let data: any;
  return () => new Promise<void>((resolve: any) => {
    data = storageService.get('tenant-config') || undefined;
    if (data && Object.keys(data).length) {
      keycloakInit(keycloak, data, resolve);
    } else {
      apiService.get(`/api/login/`).subscribe((res: any) => {
        if (res.status === 200) {
          data = res.data;
          storageService.set('tenant-config', res.data);
          keycloakInit(keycloak, res.data, resolve);
        }
      }, (err: any) => {
        console.log(err)
        resolve();
      })
    }
  })
}

const keycloakInit = (keycloak: KeycloakService, data: any, resolve: any) => {
  let origin = window.location.origin;

  keycloak.init({
    config: {
      url: data.server_url,
      realm: data.realm_name,
      clientId: data.client_id,
    },
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri:
        origin + '/assets/silent-check-sso.html'
    },
  }).then((val: any) => {
    resolve();
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    KeycloakAngularModule,
  ],
  exports: [
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, ApiService, StorageService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export const environment = {
  production: false,
  serverUrl: 'https://api-v2-dev-dot-qrms-ext-staging.uc.r.appspot.com',
  privateKey: 'qbUrst@plmoknIJBuhv@*&^%$0349752',
  oAuth: {
    client_id:
      '185624160312-gn1devvog08kda0r84vfp8ti9n1u54id.apps.googleusercontent.com',
  },
  firebase: {
    apiKey: 'AIzaSyCWiIpMms3oc4IhMXefSpb89SfCV259W18',
    authDomain: 'qrms-ext-dev-211e8.firebaseapp.com',
    projectId: 'qrms-ext-dev-211e8',
    storageBucket: 'qrms-ext-dev-211e8.appspot.com',
    messagingSenderId: '212877794857',
    appId: '1:212877794857:web:d3e4f7ebfd51c597f10638',
    measurementId: 'G-XFQGYCDQE4',
  },
};

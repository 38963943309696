export enum Role {
  HR = 'HR Staff',
  Director = 'Director',
  PM = 'PM',
  Staff = 'Staff',
  HRM = "HR Manager",
  CL = "Core Leader",
  VT = "VT",
  IS = "Has Interview",
  RM = 'Resume Manager',
  OA = 'Organization Admin',
  IN = 'Is Interviewer',
  TEMP_IN = 'Privileged Interviewer'
}

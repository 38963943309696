<div class="offerLetterForm" tabindex="0">
  <form [formGroup]="hiringForm">
    <div class="fluid-container p-4">
      <div class="row mb-4">
        <div class="col-sm-12 popup-header">Offer Letter Pre-requisites</div>
      </div>

      <div class="mb-4">
        <div class="col-sm-12 label-value description-section">
          Please make sure to fill in the required details that you want to add to the offer letter for this candidate.
        </div>
      </div>

      <div class="row item-list-container">
        <div *ngIf="!hiringDetails.employment_type" class="col-md-6 mb-3">
          <div class="row item">
            <label class="col-form-label ">Employment Type</label>
            <div>
              <div class="h-40 d-flex align-items-center">
                <input type="radio" class="radio-large me-1" [value]="'P'" formControlName="employment_type" />
                <span class="radio-text min-w-100">Permanent</span>
                <span class="ms-5 me-5"></span>
                <input type="radio" class="radio-large m-l-20" [value]="'C'" formControlName="employment_type" />
                <span class="radio-text">Contract</span>
              </div>

              <div *ngIf="f.employment_type.touched && f.employment_type.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['employment_type'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].designation" class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Designation</label>
            <div class="">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="designationsFiltered"
                class="remove-border" bindLabel="name" bindValue="id" #designation_ref [handler_input]="designation_ref"
                ngSelectHandler formControlName="designation" [searchable]="true" [clearable]="true"
                placeholder="Select or start typing">
              </ng-select>
              <div *ngIf="f.designation.touched && f.designation.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['designation'].hasError('required')">Required
                </div>
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="hiringDetails.offer[latestOffer].remote == null" class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Fully Remote</label>
            <div>
              <div class="h-40 d-flex align-items-center">
                <input type="radio" class="radio-large me-1" formControlName="remote" [value]="true"
                  (change)="onRemoteChange(true)" />
                <span class="radio-text min-w-100">Yes</span>
                <span class="ms-5 me-5"></span>
                <input type="radio" class="radio-large m-l-20" formControlName="remote" [value]="false"
                  (change)="onRemoteChange(false)" />
                <span class="radio-text">No</span>
              </div>
              <div *ngIf="f.remote.touched && f.remote.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['remote'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].remote_location && f.remote.value " class="col-md-6 mb-3"
          [ngStyle]="{'opacity': !f.remote.value ? .25: 1}">
          <div class="row item">
            <label class=" col-form-label ">Remote Location</label>
            <div class="">
              <input type="text" [readonly]="!f.remote.value" autocomplete="off" class="form-control"
                formControlName="remote_location" />
              <div *ngIf="f.remote_location.touched && f.remote_location.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['remote_location'].hasError('maxlength')">Max character allowed is 50
                </div>
                <div *ngIf="hiringForm.controls['remote_location'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].offered_salary" class="col-md-6 mb-3">
          <div class="row  item">
            <label class=" col-form-label ">Offered Salary per month</label>
            <div class="">
              <div class="form-control d-flex align-items-center">
                <span class="border-right me-1 pe-2 input-prefix c">
                  <ng-select [items]="currencyChoice" class="remove-border" bindLabel="name" bindValue="id"
                    [searchable]="false" [clearable]="false" formControlName="currency">
                  </ng-select>
                </span>
                <input autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none"
                  formControlName="offered_salary">
              </div>
              <div *ngIf="f.offered_salary.touched && f.offered_salary.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['offered_salary'].hasError('pattern') ||
              hiringForm.controls['offered_salary'].hasError('max')">Invalid Amount
                </div>
                <div *ngIf="hiringForm.controls['offered_salary'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="!hiringDetails.offer[latestOffer].joining_bonus && !isFresherCandidate && hiringDetails.is_allow_joining_bonus"
          class="col-md-6 mb-3">
          <div class="row item">
            <label class="col-form-label ">Joining Bonus <span class="optional-feild">(Optional)</span></label>
            <div class="">
              <div class="form-control d-flex align-items-center">
                <span class="border-right me-1 pe-2 input-prefix c">
                  <ng-select [items]="currencyChoice" class="remove-border" bindLabel="name" bindValue="id"
                    [searchable]="false" [clearable]="false" formControlName="joining_bonus_currency"
                    [(ngModel)]="hiringForm.value['joining_bonus_currency']">
                  </ng-select>
                </span>
                <input autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none"
                  formControlName="joining_bonus">
              </div>
              <div *ngIf="f.joining_bonus.touched && f.joining_bonus.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['joining_bonus'].hasError('pattern') ||
              hiringForm.controls['joining_bonus'].hasError('max')">Invalid Amount
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].joining_location" class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Joining Location</label>
            <div class="">
              <ng-select [items]="interviewLocations" bindLabel="name" bindValue="id"
                formControlName="joining_location" [searchable]="false" [clearable]="false">
              </ng-select>
              <div *ngIf="f.joining_location.touched && f.joining_location.invalid" class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['joining_location'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].joining_date" class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Joining Date <span class="optional-feild">(Optional)</span></label>
            <div class="">
              <app-single-date-picker-component [item]="hiringForm.value['joining_date']" [border]="false" [datePickerId]="'OfferJDPicker'">
              </app-single-date-picker-component>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].hiring_approved_by" class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Hiring Approved by</label>
            <div class="">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="spaceUsersNStaff" bindLabel="username"
                bindValue="id" formControlName="hiring_approved_by" [searchable]="true" [clearable]="true" #approved_by_ref
                [handler_input]="approved_by_ref" placeholder="Select or start typing" ngSelectHandler [searchFn]="customSearchFn.bind(this)">
                <ng-template ng-option-tmp let-subItem="item">
                  <span> {{subItem.username}} </span>
                  &nbsp;[<span class="label-value-blue">{{subItem.email}}</span>]
                </ng-template>
              </ng-select>
              <div *ngIf="f.hiring_approved_by.touched && f.hiring_approved_by.invalid"
                class="alert alert-error mb-n21">
                <div *ngIf="hiringForm.controls['hiring_approved_by'].hasError('required')">Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!hiringDetails.offer[latestOffer].onboarding_type" class="col-md-6 mb-3">
          <div class="row item  highlight">
            <label class=" col-form-label ">Onboarding</label>
            <div class="h-40 d-flex align-items-center">
              <input type="radio" class="radio-large me-1" formControlName="onboarding_type" value="ON" />
              <span class="radio-text min-w-100">Online</span>
              <span class="ms-5 me-5"></span>
              <input type="radio" class="radio-large m-l-20" formControlName="onboarding_type" value="OFF" />
              <span class="radio-text">Offline</span>
            </div>
          </div>
          <div *ngIf="f.onboarding_type.touched && f.onboarding_type.invalid" class="alert alert-error mb-n21">
            <div *ngIf="hiringForm.controls['onboarding_type'].hasError('required')">Required
            </div>
          </div>
        </div>
      </div>
      <div class="row item-list-container mt-3">
        <div *ngIf="!hiringDetails.address" class="col-md-12 mb-3">
          <div class="row">
            <label class="col-form-label ">
              Address
            </label>
            <div>
              <textarea class="form-control txtarea" formControlName="address" placeholder="House name  /  Post Office
Place  /  District
State - Pincode"></textarea>
              <div *ngIf="f.address.touched && f.address.invalid" class="mb-n21">

                <div class="alert alert-error" *ngIf="hiringForm.controls['address'].hasError('required')">Required</div>
                <div class="alert alert-error" *ngIf="hiringForm.controls['address'].hasError('actualRow')">Address field is limited to 5 lines
                </div>
                <div class="alert alert-error" *ngIf="hiringForm.controls['address'].hasError('maxlength')">Maximum 500 characters allowed
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-3">
          <div class="row justify-content-center mt-3">
            <button class="col-sm-3 m-1 me-4 btn button-cancel" (click)="cancel()">{{'cancel'|
              uppercase }}</button>
            <button class="col-sm-3 m-1 btn button-save red-btn" (click)="save()">{{'Save & Preview' |
             uppercase}}</button>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
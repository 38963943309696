import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CryptoService } from "./crypto.service";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private cryptoService: CryptoService
  ) { }

  get(key: string) {
    let value = localStorage.getItem(key);
    return (value ? this.cryptoService.get(environment.privateKey, value) : null);
  }

  set(key: string, value: string) {
    var encrypted = this.cryptoService.set(environment.privateKey, value);
    localStorage.setItem(key, encrypted);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
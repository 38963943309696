import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
  selector: '[appDnd]'
})

export class DndDirective {
  @Output() private filesChangeEmiter: EventEmitter<File> = new EventEmitter();
  @Output() private isValidType: EventEmitter<boolean> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#ffff';
  @HostBinding('style.border') private borderStyle = '2px dashed';
  @HostBinding('style.border-color') private borderColor = '#e9edf3';
  @HostBinding('style.border-radius') private borderRadius = '7px';
 

  @HostListener('dragover', ['$event']) public onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#e9edf3';
    this.borderColor = '#e9edf3';
    this.borderStyle = '2px solid #e9edf3';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#e9edf3';
    this.borderColor = '#e9edf3';
    this.borderStyle = '2px dashed #e9edf3';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#ffff';
    this.borderColor = '#e9edf3';
    this.borderStyle = '2px dashed #e9edf3';
    let files = evt.dataTransfer.files;
    let valid_files: File = files;
    this.getType(valid_files) ? this.isValidType.emit(true) : this.isValidType.emit(false);
    this.filesChangeEmiter.emit(valid_files);
  }

  private getType(file: File): boolean {
    if (!(file.type == 'zip' || file.type == 'application/octet-stream' || file.type == 'application/zip' || file.type == 'application/x-zip' || file.type == 'application/x-zip-compressed' || file.type == 'multipart/x-zip'))
      return false;
    else return true;
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../core/http/api.service';
import { AppModel } from '../shared/_models/utils';
import { WorkbookListDatum } from '../shared/_models/workbook';

export const routes = {
  getWorkbooks: `/api​/work_books​`,
  addWorkBook: '/api/work_books/',
  getRequestRatings: `/api/work_books/candidate-request-rating-list`,
  sendRequestRating: `/api/work_books/candidate-request-rating`,
  getCandidates: '/api/workbook-candidates',
  removeWorkbookCandidate: '/api/remove-candidates',
  addWorkbookCandidate: '/api/work_books/add-candidate',
  getWorkbookHistory: '/api/work_books',
  callLetterCandidates: '/api/work_books/send-call-letter-list',
  getScheduleinterviewCandidates: '/api/work_books/get-candidates',
  scheduleInterviewsForWorkbook: '/api/work_books/schedule-interview',
  createCandidate: `create-candidate`,
  candidateDrafts: 'create-candidate-and-schedule-interview-draft',
  removeDraft: 'work_book-candidate-and-schedule-interview',
  interviewerDrivenSchedule: `interviewer-driven-schedule`,
  interviewerDrivenCreate: `interviewer-driven-create-candidate`,
  createCandidateDrafts: 'create-candidate-draft',
  sendOfferLetter:`send-offer-letter/`,
  recruitmentDriveHistory: `/api/recruitment-drives/`,
}

@Injectable({
  providedIn: 'root',
})
export class WorkbookService {
  private workbookId = new BehaviorSubject<any>(null);
  constructor(private apiService: ApiService) {}
  // Save workbook data
  private workbookData = new BehaviorSubject<any>(void 0);

  shouldReload: boolean = false;

  loadWorkbookData(id: any) {
    this.getWorkbookById(id).subscribe((res: AppModel<WorkbookListDatum>) => {
      this.workbookData.next(res.data);
    });
  }

  clearWorkbookData() {
    this.workbookData.next({});
  }

  getWorkbookData(): Observable<any> {
    return this.workbookData.asObservable();
  }

  getWorkbooks(url: string, params: any) {
    return this.apiService.get(url, params);
  }

  getWorkbookById(id: string): Observable<any> {
    return this.apiService.get('/api/work_books/' + id + '/');
  }

  addWorkBook(url: string, data: any) {
    return this.apiService.post(url, data);
  }

  getCandidatesByWBId(id: number) {
    return this.apiService.get(`${routes.getRequestRatings}/${id}/`);
  }

  sendRequestRatings(data: any) {
    return this.apiService.post(`${routes.sendRequestRating}`, data);
  }

  getWorkbookCandidates(id: any) {
    return this.apiService.get(`${routes.getCandidates}/${id}`);
  }

  removeWorkbookCandidate(workbookId: any, candidateId: any) {
    return this.apiService.delete(
      `${routes.removeWorkbookCandidate}/${workbookId}/${candidateId}`
    );
  }

  updateWorkbook(id: any, body: any): Observable<any> {
    return this.apiService.put(`/api/work_books/${id}/`, body);
  }

  addCandidates(data: any) {
    return this.apiService.post(`${routes.addWorkbookCandidate}`, data);
  }
  getWorkBookHistory(workbookId: any, page: any) {
    return this.apiService.get(
      `${routes.getWorkbookHistory}/${workbookId}/history/?page=${page}`
    );
  }

  getCandidateReqSalaryDoc(workbookId: any): Observable<any> {
    return this.apiService.get(
      `/api/work_books/candidate-request-salary-doc/${workbookId}/`
    );
  }

  sendCandidateReqSalaryDoc(body: any): Observable<any> {
    return this.apiService.post(
      `/api/work_books/candidate-request-salary-doc`,
      body
    );
  }

  getCallLetterCandidates(workbookId: any) {
    return this.apiService.get(`${routes.callLetterCandidates}/${workbookId}/`);
  }

  sendCallLetter(workbookId: any, params: any) {
    return this.apiService.post(
      `${routes.callLetterCandidates}/${workbookId}/`,
      params
    );
  }
  listWorkBookCandidates(workBookId: number) {
    return this.apiService.get(
      `${routes.getScheduleinterviewCandidates}/${workBookId}`
    );
  }

  scheduleWorkBookInterviews(data: any) {
    return this.apiService.post(
      `${routes.scheduleInterviewsForWorkbook}`,
      data
    );
  }

  createCandidate(workbookId: any, data: any) {
    return this.apiService.post(
      `/api/work_books/${workbookId}/${routes.createCandidate}`,
      data
    );
  }

  getDrafts(workbookId: any) {
    return this.apiService.get(
      `/api/work_books/${workbookId}/${routes.candidateDrafts}/`
    );
  }

  removeDraft(id: string) {
    return this.apiService.delete(`/api/${routes.removeDraft}/${id}/`);
  }

  setWorkBookId(id: any) {
    // set workbookid
    this.workbookId.next(id);
  }

  getWorkBookId() {
    // get workbookid
    return this.workbookId.asObservable();
  }

  getInterviewerDrivenScheduling(id: any): Observable<any> {
    return this.apiService.get(
      `${routes.addWorkBook}${id}/${routes.interviewerDrivenSchedule}/`
    );
  }

  postInterviewerDrivenScheduling(data: any, id: any): Observable<any> {
    return this.apiService.post(
      `${routes.addWorkBook}${id}/${routes.interviewerDrivenSchedule}/`,
      data
    );
  }

  interviewerDrivenCreateCandidate(data: any, id: any): Observable<any> {
    return this.apiService.post(
      `${routes.addWorkBook}${id}/${routes.interviewerDrivenCreate}/`,
      data
    );
  }

  getCreateCandidateDraftList(workbookId: any) {
    return this.apiService.get(
      `/api/work_books/${workbookId}/${routes.createCandidateDrafts}/`
    );
  }

  getSendOfferLetterList(id: any) {
    return this.apiService.get(
      `${routes.addWorkBook}${id}/${routes.sendOfferLetter}`
    );
  }

  sendOfferLetterList(id: any, data: any) {
    return this.apiService.post(
      `${routes.addWorkBook}${id}/${routes.sendOfferLetter}`,
      data
    );
  }

  getRecruitmentDrivesHistory(id: any, page: any) {
    return this.apiService.get(`${routes.recruitmentDriveHistory}${id}/history/?page=${page}`);
  }
}

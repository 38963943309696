<div class="comment-popup custom-style-ng-select" tabindex="0">
  <form [formGroup]="commentForm">
    <div class="modal-header">
      <h4 class="modal-title">{{popUpContents?.title}}</h4>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="popUpContents?.status || popUpContents?.infoMessage">
        <div class="info-msg mb-1"><span *ngIf="popUpContents?.isApproveJobPost;else status_content"
            [innerHTML]="popUpContents?.infoMessage"></span>
          <ng-template #status_content><span>You are about to update the status 
              <ng-container *ngIf="popUpContents?.candidateName">of the candidate <b class="text-break">{{popUpContents?.candidateName}}</b></ng-container> 
              to <b>{{popUpContents?.status}}</b>. Do you want to continue?</span></ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="popUpContents?.updateHiringStatus">
        <div class="info-msg mb-1">
          <span>You are about to <b>{{popUpContents?.offerStatusName}}</b> the offer<ng-container
              *ngIf="popUpContents?.isApproval">{{' approval'}}</ng-container>. Please enter the reason for
            {{popUpContents?.offerStatusAction}} the offer<ng-container *ngIf="popUpContents?.isApproval">{{' approval'}}
            </ng-container>.</span>
        </div>
      </ng-container>
      <ng-container *ngIf="popUpContents?.isForwardResume">
        <div class="mb-1">
          <div class="row item">
            <label><span class="label">{{popUpContents?.fwdResumeTo}} </span></label>
            <div>
              <ng-select [editableSearchTerm]="true" [items]="toUserList" bindLabel="username" bindValue="email"
                [searchable]="true" #to_ref [clearable]="true" (ngModelChange)="recepientModelChange($event, to_ref)"
                placeholder="Select or start typing" (change)="onRecepientSelection($event, to_ref,true)"
                [searchFn]="customSearchFn.bind(this)" formControlName="to">
                <ng-template ng-option-tmp let-subItem="item"> {{subItem?.username}} &nbsp;[<span
                    class="label-value-blue">{{subItem?.email}}</span>] </ng-template>
              </ng-select>
              <div *ngIf="toList?.length>0" [ngClass]="{'mt-2 email-list':toList.length>0}">
                <div class="d-flex  align-items-center flex-wrap">
                  <span class="multi-select-items d-flex  align-items-center mb-2 me-3"
                    *ngFor="let item of toList">{{item}} <img class="ms-1 remove cursor-pointer"
                      src="./assets/images/close.svg" (click)="onRemove(item,true)"></span>
                </div>
              </div>
              <div *ngIf="f.to.touched && !toList?.length" class="error-msg">
                <div>Required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <div class="row item">
            <label><span class="label">{{popUpContents?.fwdResumeCC}} </span><span
                class="label-italics">(Optional)</span></label>
            <div>
              <ng-select [editableSearchTerm]="true" [items]="ccUserList" bindLabel="username" bindValue="email"
                [searchable]="true" #cc_ref [clearable]="true" (ngModelChange)="recepientModelChange($event, cc_ref)"
                placeholder="Select or start typing" (change)="onRecepientSelection($event, cc_ref,false)"
                [searchFn]="customSearchFn.bind(this)" formControlName="cc">
                <ng-template ng-option-tmp let-subItem="item"> {{subItem?.username}} &nbsp;[<span
                    class="label-value-blue">{{subItem?.email}}</span>] </ng-template>
              </ng-select>
              <div *ngIf="ccList?.length>0" [ngClass]="{'mt-2 email-list':ccList.length>0}">
                <div class="d-flex  align-items-center flex-wrap">
                  <span class="multi-select-items d-flex  align-items-center mb-2 me-3"
                    *ngFor="let item of ccList">{{item}} <img class="ms-1 remove cursor-pointer"
                      src="./assets/images/close.svg" (click)="onRemove(item,false)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="popUpContents?.hasDateField">
        <div class="mb-1">
          <div class="row item">
            <label><span class="label">{{popUpContents?.dateLabel}}</span></label>
            <div class="position-relative">
              <app-single-date-picker-component [item]="popUpContents?.dateItem" [border]="false"
                (ngSelectedDate)="onDateSelected($event)" [datePickerId]="'StatusJDPicker'" >
              </app-single-date-picker-component>
              <span *ngIf="f.joining_date.touched && f.joining_date.errors?.required"
                class="alert alert-error fs-12">Required</span>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <div class="row item">
            <div class="flex-50">
              <label><span class="label">Experience </span><span class="label-italics">(Optional)</span></label>
              <div class="form-control d-flex align-items-center ">
                <input autocomplete="off" name="experience_years" formControlName="experience_years" type="text"
                  class="w-100 border-0 o-none" />
                <span class="ms-1 ps-2 pe-0"><small>yrs</small></span>
              </div>
              <div *ngIf="f.experience_years.touched && f.experience_years.invalid" class="alert alert-error fs-12 pb-0">
                <div *ngIf="f.experience_years.errors?.pattern">Enter a valid year</div>
              </div>
            </div>
            <div class="d-flex flex-50">
              <div>
                <label class="invisible">Experience</label>
                <div class="d-flex align-items-center">
                  <div class="form-control d-flex align-items-center pe-2">
                    <input name="experience_months" autocomplete="off" type="text" class="w-100 border-0 o-none"
                      formControlName="experience_months" />
                    <span class="ms-1 ps-2 pe-0"><small>mnths</small></span>
                  </div>
                  <div class="ms-1"><img class="cursor-pointer" (mouseover)="toggleInfo=true" (mouseout)="toggleInfo=false" src="./assets/images/info-message-icon.svg" alt="info">
                  <div class="position-relative">
                      <div *ngIf="toggleInfo" class="info-box"> Please note the updated experience will be used to calculate referral bonus </div>
                  </div>
                  </div>
               
                </div>
                <div *ngIf="f.experience_months.touched && f.experience_months.invalid" class="alert alert-error fs-12 pb-0">
                  <div *ngIf="f.experience_months.errors?.pattern">Enter month between (0-11)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="popUpContents?.isApproveJobPost">
        <div class="mb-1">
          <div class="row item">
            <label><span class="label">{{popUpContents?.userLabel}} </span><span
                class="label-italics">(Optional)</span></label>
            <div>
              <ng-select [editableSearchTerm]="true" #owner_ref [items]="hrUsers" formControlName="owner"
                bindLabel="hrName" bindValue="id" [searchable]="true" [clearable]="true"
                (ngModelChange)="ownerModelChange($event, owner_ref)" placeholder="Select or start typing" [searchFn]="customSearchFn.bind(this)">
                <ng-template ng-option-tmp let-subItem="item"> {{subItem?.hrName}} &nbsp;[<span
                    class="label-value-blue">{{subItem.email}}</span>] </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="position-relative">
        <label><span class="label">{{commentLabel}} </span><span class="label-italics"
            *ngIf="!isMandatory">(Optional)</span></label>
        <textarea class="form-control txtarea" formControlName="comment" (blur)="formatComment()"></textarea>
        <span *ngIf="f.comment.touched && f.comment.errors?.maxlength" class="alert alert-error fs-12 comment-error">Max
          character allowed is {{commentMaxLength}}</span>
        <span *ngIf="isMandatory  && f.comment.touched && f.comment.errors?.required"
          class="alert alert-error fs-12">Required</span>
      </div>
      <ng-container *ngIf="notifyRequired || popUpContents?.isUpdateCandidateStatus">
        <div class="d-flex"
          [ngClass]="f.comment.touched && (f.comment.errors?.maxlength || isMandatory && f.comment.errors?.required) ? 'mt-4' : 'mt-2'">
          <ng-container *ngIf="notifyRequired">
            <app-checkbox [checked]="f.notify_candidate.value" (toggle)="onNotifyCandidate()"></app-checkbox>
          </ng-container>
          <div>
            <ng-container *ngIf="notifyRequired">
              <div class="ms-2 info-msg">Notify Candidate</div>
            </ng-container>
            <ng-container *ngIf="popUpContents?.isUpdateCandidateStatus">
              <div class="d-flex source-prompt-checkbox px-2 py-2 mt-3" [ngClass]="notifyRequired ? 'me-2' :'' ">
                <app-checkbox [checked]="f.update_candidate_source.value" (toggle)="onUpdateCandidateSource()">
                </app-checkbox>
                <div class="ms-2">Update the source to HR since the candidate's last application was an year ago. 
                    <img class="cursor-pointer" (mouseover)="toggleSourceInfo=true" (mouseout)="toggleSourceInfo=false"
                      src="./assets/images/info-message-icon.svg" alt="info">
                    <div class="position-relative">
                      <div *ngIf="toggleSourceInfo" class="info-box-source"> Applied on date will be set to today's date
                      </div>
                    </div>
                  </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="row justify-content-center action-div">
        <button class="col-sm-5 btn button-cancel" (click)="onCancel()"
          [ngClass]="popUpContents?.isLargeBtnLabel ? 'me-2': 'me-4'">{{'cancel'| uppercase }}</button>
        <button class="col-sm-5 btn red-btn" (click)="onSave()"
          [ngClass]="popUpContents?.isLargeBtnLabel ? 'lg-save-btn': 'button-save'">{{ btnText | uppercase }}</button>
      </div>
    </div>
  </form>
</div>
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { ToastrService } from 'ngx-toastr';
import { ResumeService } from 'src/app/resume/resume.service';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { GlobalStoreService } from 'src/app/shared/_services/global-store.service';
@Component({
  selector: 'app-label-tag',
  templateUrl: './label-tag.component.html',
  styleUrls: ['./label-tag.component.scss']
})
export class LabelTagComponent implements OnInit, OnDestroy {

  selectedLabel: any = {};
  @Input() label: string = "";
  @Input() bgColor: string = "";
  @Input() color: string = "";
  @Input() outline: boolean = false;
  @Input() showDot: boolean = true;
  @Input() themeBasedColouring: boolean = false;
  @Input() editable: boolean = false;
  @Input() dropdownOptions: any[] = [];
  @Input() candidateId !: number;
  @Input() labelType:string="";
  @Input() fetchCandidateData: boolean = false;
  @Input() candidateName: string = '';
  @Input() hasPromptSourceUpdate: boolean = false;
  @Output() reloadCandidateData: EventEmitter<boolean> = new EventEmitter();
  @Output() reloadInterviews: EventEmitter<boolean> = new EventEmitter();
  @Output() switchToOffersTab: EventEmitter<any> = new EventEmitter();
  @ViewChild('updateStatusPopup', { static: true }) updateStatusPopup!: NgbModalRef;
  @ViewChild('commentPopup', { static: true }) commentPopup!: NgbModalRef;
  commentMaxLength:number=200;
  commentLabel:string=''
  statusUpdateForm: any = {} as any;
  loaded: boolean = false;
  isResponseLoading = false;
  candidateData: any = {} as any;
  employmentType: string | null = '';
  isAllowJoiningBonus: boolean = false;
  isFresher: boolean = false;
  subscription: Subscription = new Subscription();
  subscriptions: any = []
  joiningDateItem: any = {
    id: 'joining_date',
    value: undefined,
    minDate: dayjs.Dayjs,
    maxDate: dayjs.Dayjs,
  }
  closeResult: any;
  commentData: any;
  isMandatory:boolean=false;
  themeConfig: any = {};
  constructor(
    private modalService: NgbModal,
    private appService: AppService,
    private toaster: ToastrService,
    private resumeService: ResumeService,
    private globalStore: GlobalStoreService,
  ) { 
    this.themeConfig = this.globalStore.getThemeConfig();
  }

  ngOnInit() {
    if (this.editable) {
      this.subscriptions.push(
        this.resumeService.getCandidateData().subscribe((data) => {
          this.candidateData = data;
          this.employmentType = data.employment_type;
          this.isAllowJoiningBonus = data.is_allow_joining_bonus;
          this.isFresher = data.is_fresher;
        })
      );
    }
  }

  getLabelContainerStyle() {
    const styles = {'background-color':'', 'padding-right': '', 'border' : '', 'borderRadius': ''}
    if(this.themeConfig.labels === 'rounded-style-1' && !this.outline && !this.editable) {
      styles['background-color'] = 'transparent';
      styles['border'] = '1px solid' + this.bgColor;
      styles['borderRadius'] = '52px';
    } else {
      styles['background-color'] = this.outline ? 'transparent' : this.bgColor;
      styles['border'] = !this.themeBasedColouring && this.outline ? '1px solid ' + this.bgColor : '';
    }
    styles['padding-right'] = this.editable ? '0' : '0.625rem';
    return styles;
  }
  
  getFontColour() {
    const fontStyle = {'color': ''};  
    if(this.themeConfig.labels === 'rounded-style-1' && !this.outline && !this.editable) {
      fontStyle['color'] = this.bgColor;
    } else {
      fontStyle['color'] = this.color && !this.themeBasedColouring ? this.color : 'inherit'
    }
    return fontStyle;
  }

  open(label: any) {
    this.selectedLabel = label;
    if (this.labelType === 'candidate-status') {
      this.setCommentConfig(500,false,'Comments');
      if (label?.id === 'S') {
        if (!this.employmentType) {
          this.toaster.error(`The employment type of the candidate should be entered`);
          return;
        }
        if (this.fetchCandidateData) {
          this.candidateData = {}; //  making this empty so that candidate data will be fetched from update status popup
        }
        this.openStatusUpdateForm();
      }
      else {
        label.id === 'J' ? this.updateJoiningStatus() : this.openCommentPopup(false, undefined, '', '', 'Update Candidate Status');
      }
    }
    else if (this.labelType === 'hiring-status') {
      this.changeHiringStatus(label);
    }
  }

  updateJoiningStatus() {
    let joining_date: any = '';
    if (this.candidateData?.joining_date) {
      joining_date = this.candidateData.joining_date;
    }
    let experience = {
      experience_years: this.candidateData?.experience_years,
      experience_months: this.candidateData?.experience_months
    }
    if (joining_date) {
      this.joiningDateItem.value = {
        startDate: dayjs(joining_date),
        endDate: dayjs(joining_date)
      };
    } else {
      this.joiningDateItem.value = undefined;
    }
    this.openCommentPopup(true, { ...this.joiningDateItem }, joining_date, 'Joining Date', 'Update Candidate Status', experience);
  }

  changeHiringStatus(label: any) {
    if (['D', 'OR'].includes(label?.id)) {
      this.selectedLabel.id === 'D' && this.setCommentConfig(200, true, "Reason for declining");
      this.selectedLabel.id === 'OR' && this.setCommentConfig(200, true, "Reason for revoking");
      this.openCommentPopup(false, undefined, '', '', 'Update Hiring Status');
    }
    else 
    {
      this.setCommentConfig(500, false, "Comments");
      this.openCommentPopup(false, undefined, '', '', 'Update Hiring Status');
    }
  }

  setCommentConfig(maxLength:number,isMandatory:boolean,commentLable:string)
  {
    this.commentMaxLength=maxLength;
    this.isMandatory=isMandatory;
    this.commentLabel=commentLable;

  }

  openCommentPopup(hasDateField: boolean = false, joiningDate: any = undefined, joiningDateInitialValue: string = '', dateLabel: string = '', title: string = '', additionalField:any=undefined) {
    this.modalService.dismissAll();
    this.commentData = {
      title: title,
      status: this.selectedLabel?.name,
      hasDateField: hasDateField,
      dateItem: joiningDate,
      dateValue: joiningDateInitialValue,
      dateLabel: dateLabel,
      additionalField: additionalField,
      candidateName: this.candidateName
    }

    if (this.labelType === 'candidate-status' && this.hasPromptSourceUpdate) {
      this.commentData['isUpdateCandidateStatus'] = true;
    }

    this.modalService
      .open(this.commentPopup, {
        size: 'md',
        centered: true,
        windowClass: 'update-label-popup',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = reason;
        }
      );

  }

  cancel() {
    this.modalService.dismissAll();
  }

  save(event: any) {
    if(this.labelType==='candidate-status')
    {
      let requestObject = this.formatData({ ...event });
      this.subscriptions.push(this.appService.updateStatus(this.candidateId, requestObject).subscribe(
        () => {
          this.toaster.success(`Candidate status updated`);
          this.closePopUpOnUpdate();
        },
        () => this.toaster.error(`Could not update the status`)
      ))
    }
    else if(this.labelType==='hiring-status')
    {
      let request = this.formatPayload({ ...event });
      this.updateHiringStatus(request);
    }


  }
 updateHiringStatus(request:any)
 {
    this.subscriptions.push(this.appService.updateHiringStatus(this.candidateId, request).subscribe(
     (res:any) => {
       if(res?.status===200)
       {
          this.toaster.success(`Hiring status updated`);
          this.closePopUpOnUpdate();
       }else{
          this.toaster.error(`Could not update the status`)
        }

      },
      () => this.toaster.error(`Could not update the status`)
    ))
  }
  formatPayload(payload:any)
  {
    let data: any= {
      status: this.selectedLabel.id,
      offer_revoked_reason: this.selectedLabel.id === 'OR' ? payload?.comment?.trim() :null,
      offer_declined_reason: this.selectedLabel.id === 'D' ? payload?.comment?.trim() : null,
      comment: ['OA','S'].includes(this.selectedLabel.id) ? (payload?.comment?.trim() || null) : null
    };

    return data;
  }

  formatData(payload: any) {
    let data: any = {
      status: this.selectedLabel.id,
      comments: payload['comment']?.trim() || null
    };
    if (this.selectedLabel.id == 'J') {
      data['joining_date'] = payload['joining_date'];
      data['experience_years'] = payload['experience_years'] ? Number(payload['experience_years']) : null;
      data['experience_months'] = payload['experience_months'] ? Number(payload['experience_months']) : null;
    }
    if (this.labelType === 'candidate-status') {
      if (payload['notify_candidate'] !== undefined) {
        data['notify_candidate'] = payload['notify_candidate'];
      }

      if (payload['update_candidate_source'] !== undefined) {
        data['source'] = payload['update_candidate_source'] ? 'HR' : null;
      }
    }
    return data;
  }

  openStatusUpdateForm() {
    this.statusUpdateForm['title'] = "Update Status";
    let description = `You are about to update the status ${this.candidateName ? ('of <b>' + this.candidateName + '</b>' ) : ''} to <b>Selected</b>. Please complete the following details before you update the status`;
    this.statusUpdateForm['description'] = description;
    this.statusUpdateForm['statusFormData'] = this.candidateData;
    this.statusUpdateForm['candidateId'] = this.candidateId + '';
    this.statusUpdateForm['hasPromptSourceUpdate'] = this.hasPromptSourceUpdate;
    this.statusUpdateForm['employmentType'] = this.employmentType;
    this.statusUpdateForm['isAllowJoiningBonus'] = this.isAllowJoiningBonus;
    this.statusUpdateForm['isFresher'] = this.isFresher;
    this.openModalBox(this.updateStatusPopup, 'lg', 'statusModal');
  }

  openModalBox(content: any, size: string, windowClass: string, disableCloseOnOverlayClick: boolean = true) {
    let modalObj: any = {
      size: size,
      centered: true,
      windowClass: windowClass,
    };
    if (disableCloseOnOverlayClick) {
      modalObj['backdrop'] = 'static';
      modalObj['keyboard'] = true;
    }
    this.modalService.open(content, modalObj).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = reason;
      }
    );
  }
  onUpdateSelectedStatus(data: any) {
    this.subscriptions.push(this.appService.updateStatus(this.candidateId, data).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.toaster.success(response.message);
          this.closePopUpOnUpdate();
          this.switchToOffersTab.emit();
        } else {
          this.toaster.error(response.message)
        }
      },
      () => this.toaster.error(`Could not update the status`)
    ))
  }
  closePopUpOnUpdate() {
    this.modalService.dismissAll();
    this.reloadCandidateData.emit();
    this.reloadInterviews.emit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptions.forEach((item: any) => item.unsubscribe());
  }
}

<div class="simple-tab-container">
  <div class="row simple-tab-header-container">
      <div class="simple-tab-div">
          <ul class="nav nav-tabs row">
              <ng-container *ngFor="let tab of tabs">
                  <li class="nav-item col" [ngClass]="tab.disabled ? 'disabled-tab' : ''" *userRole="tab.userRole">
                      <span class="nav-link first-item" [ngClass]="activeTab === tab.tab ? 'active' : 'not-active'" aria-current="page" (click)="onTabChange(tab.tab)">
                        <span [innerHTML]="tab.label"></span>
                      </span>
                  </li>
              </ng-container>
          </ul>
      </div>
  </div>
  <ng-content></ng-content>
</div>
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PAGE } from '../../../_models/page.enum';
import { Role } from '../../../_models/role';
import { GlobalStoreService } from '../../../_services/global-store.service';
import { AuthService } from 'src/app/auth/auth.service';
import { NavMenu } from 'src/app/shared/_models/navMenu.model';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-nav-sub-menu',
  templateUrl: './nav-sub-menu.component.html',
  styleUrls: ['./nav-sub-menu.component.scss', '../side-nav.component.scss']
})
export class NavSubMenuComponent {

  userRole: any;
  _subMenuItem: any;
  @Input() toggleSubmenu: boolean = false;
  @Input() menuTitle: any;
  @Input() isNavCollapsed: boolean = false;
  @Output() hoverOutside: EventEmitter<boolean> = new EventEmitter();
  @Input()
  set subMenuItems(data: any) {
    if (data) {
      data.forEach((item: any) => {
        if (item?.subMenu) {
          item.subMenu = this.setSubMenuData(item.subMenu)
        }
      })
      this._subMenuItem = [...data]
    }
  }
  get subMenuItems() {
    return this._subMenuItem;
  }

  submenuHoverItemIndex: any;

  constructor(
    private globalStore: GlobalStoreService,
    private authService: AuthService,
    private router: Router
  ) {
    this.userRole = this.authService.getUser()?.role;
  }

  handleClick(e: any, menu: NavMenu) {
    if (menu.subMenu) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.toggleMenu(menu);
  }

  toggleMenu(menu: NavMenu) {
    this._subMenuItem.forEach((item: any) => {
      if (Object.keys(item).includes('toggleSubmenu')) {
        if (item !== menu) {
          item['toggleSubmenu'] = false;
        }
      }
    })
    if (menu && menu.subMenu?.length) {
      menu.toggleSubmenu = !menu.toggleSubmenu;
    } else {
      this.isRouteChanged(() => {
        if (menu?.page) {
          if (menu.page == PAGE.ACTIONITEMS) {
            //clear filters and page details of default sub-menu
            menu.page = [Role.HR, Role.HRM, Role.Director].includes(this.userRole) ? PAGE.ACTIONITEMSREVIEWRATING : PAGE.AWAITINGRATING;
          }
          this.globalStore.removeFilters(menu.page);
          this.globalStore.removePageDetails(menu.page);
        }

        if (menu?.page1) {
          this.globalStore.removeFilters(menu.page1);
          this.globalStore.removePageDetails(menu.page1)
        }
      })
    }
  }

  isRouteChanged(callback: Function) {
    let s = this.router.events.subscribe((e: any) => {
      if (e instanceof RoutesRecognized) {
        if (this.router.url !== e.urlAfterRedirects) {
          callback();
        }
        s.unsubscribe();
      }
      if (e instanceof NavigationEnd) {
        s?.unsubscribe();
      }
    })
  }

  setSubMenuData(menu: any) {
    let secondaryMenu = menu.filter((menuItem: any) => {
      if (!menuItem.role) {
        return menuItem
      } else {
        return menuItem.role && menuItem.role.includes(this.userRole)
      }
    });
    return secondaryMenu;
  }

  outsideHover() {
    this.hoverOutside.emit(true);
  }
}



import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ResumeService } from 'src/app/resume/resume.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppModel } from 'src/app/shared/_models/utils';
import { CandidateData } from 'src/app/shared/_models/candidate.model';
import { AppService } from 'src/app/app.service';
import { regex } from 'src/app/shared/_services/regex';
import * as dayjs from "dayjs";
import { DatePipe } from '@angular/common';
import { CustomValidators } from 'src/app/shared/_services/custom-validators.service';
import { FileUploadService } from 'src/app/shared/_components/file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { dateFormatForDatePipe } from '../../_services/format';
import { UtilService } from '../../_services/util.service';

@Component({
  selector: 'app-update-status-popup',
  templateUrl: './update-status-popup.component.html',
  styleUrls: ['./update-status-popup.component.scss']
})
export class UpdateStatusPopupComponent implements OnInit, OnDestroy {
  
  departments: any = [];
  streams: any = [];
  streamBackup: any = [];
  designation: any = [];
  designationBackup: any = [];
  currencyList: any = []
  joiningLocation: any = []
  employementType:any = [];
  spaceUsers: any = [];
  subscription: Subscription = new Subscription();
  subscriptions: any = []
  isResponseLoading = false;
  candidateData: any = {} as any;
  statusUpdateForm!: FormGroup;
  hiringApprovedBy: any;
  workAuthorization:any;
  @Input() formContents: any;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() updateData: EventEmitter<any> = new EventEmitter();
  salarySlipName: string = "";
  salarySlipLink: string = "";
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;
  @ViewChild('uploader') InputVar!: ElementRef;
  isFresherCandidate: boolean = false;
  isPaySlipUpdated: boolean = false;
  localDateFormat: string = dateFormatForDatePipe;
  virtualTeams: any;
  toggleInfo: boolean = false;
  indirectContractMode: boolean = false;
  employmentType: any;
  showOtherInfo: boolean = false;
  contractDuration: any;
  employmentTypeValue: any;
  isAllowJoiningBonus: any;
  hybridTypes:any;
  workModes:any;
  hybridType: any;

  constructor(private resumeService: ResumeService, private appService: AppService, private formBuilder: FormBuilder,
    private _datepipe: DatePipe, private customValidatorService: CustomValidators, private fileUploadService: FileUploadService,
    private toaster: ToastrService, private utilService: UtilService,
  ) { this.initialiseForm() }

  ngOnInit(): void {
    this.isFresherCandidate = this.formContents.isFresher;
    this.employmentFormValidation(this.formContents.employmentType);
    this.isAllowJoiningBonus = this.formContents.isAllowJoiningBonus;
    if (Object.keys(this.formContents?.statusFormData).length == 0) {
      this.getCandidateDetails();
    }
    else {
      this.candidateData = this.formContents?.statusFormData;
    }
    this.getDropdownData();
    Object.keys(this.candidateData).length && this.departments.length && this.setStatusFormData({ ...this.candidateData })
  }
  initialiseForm() {
    this.statusUpdateForm = this.formBuilder.group({
      operating_country: [null, Validators.required],
      employment_type: [{value: null, disabled: true}, Validators.required],
      department: [null, Validators.required],
      stream: [null, Validators.required],
      designation: [null, Validators.required],
      fully_remote: [true],
      remote_location: [null, [Validators.maxLength(50)]],
      hiring_approved_by: [null,Validators.required],
      joining_location: [null, Validators.required],
      joining_date: [{
        id: 'joining_date',
        value: undefined,
        minDate: dayjs(),
        maxDate: dayjs.Dayjs,
      },
      ],
      comments: ['', [Validators.maxLength(500)]],
      virtual_team: [null],
      onboarding: [null, Validators.required],
      is_fresher: [false],
      work_mode:[null],
      no_of_hybrid_days:[null],
      hybrid_type:['week'],
      work_authorization: [null]
    });
    
    
  }

  addAdditionalFormControl() {
    if(this.indirectContractMode) {
      this.statusUpdateForm.addControl('monthly_rate_card_currency', new FormControl('INR'));
      this.statusUpdateForm.addControl('monthly_rate_card', new FormControl(null,
        [Validators.required, Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));
      this.statusUpdateForm.addControl('contract_duration',new FormControl(null));
    }
    else {
      this.statusUpdateForm.addControl('offered_salary_currency', new FormControl('INR'));
      this.statusUpdateForm.addControl('offered_salary', new FormControl(null,
        [Validators.required, Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));
      this.statusUpdateForm.addControl('address', new FormControl(null,
        [Validators.required, Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]));
      this.statusUpdateForm.addControl('joining_bonus_currency', new FormControl('INR'));
      this.statusUpdateForm.addControl('joining_bonus', new FormControl(null,
        [Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));
    }
    this.setFresherCandidateValidation();
  }

  get f() { return this.statusUpdateForm.controls; }

  setStatusFormData(data: any) {
    let streamInfo = this.streamBackup.find(
      (item: any) => item.id === data.stream?.id
    );
    this.statusUpdateForm.controls['stream'].setValue(
      streamInfo ? data.stream?.id : null
    );
    this.statusUpdateForm.controls['department'].setValue(
      data.stream?.department?.id
    );
    if (this.statusUpdateForm.controls['designation'].value || this.statusUpdateForm.controls['department'].value) {
      this.setDesignation(this.statusUpdateForm.controls['department'].value);
      const dept_id = this.statusUpdateForm.controls['department'].value;
      this.setStreams(dept_id);
    }
    data?.designation && this.setDesignationValue(data?.designation);
    this.statusUpdateForm.controls['address']?.setValue(data?.address);
    this.statusUpdateForm.controls['operating_country'].setValue(data?.operating_country);
    this.statusUpdateForm.controls['employment_type'].setValue(data?.employment_type);
    this.statusUpdateForm.controls['fully_remote'].setValue(data?.remote);
    this.statusUpdateForm.controls['work_mode'].setValue(data.work_mode);
    data.no_of_hybrid_days? 
    this.statusUpdateForm.controls['hybrid_type'].setValue(data.hybrid_type): this.statusUpdateForm.controls['hybrid_type'].setValue('week');
    this.statusUpdateForm.controls['no_of_hybrid_days'].setValue(data.no_of_hybrid_days);
    this.statusUpdateForm.controls['remote_location'].setValue(data.remote_location);
    if(this.statusUpdateForm.controls['work_mode']?.value) this.setWorkModeValidation(this.statusUpdateForm.controls['work_mode']?.value);
    this.statusUpdateForm.controls['virtual_team'].setValue(data?.virtual_team?.id)
    this.statusUpdateForm.controls['is_fresher'].setValue(data?.is_fresher || false);
    this.statusUpdateForm.controls['work_authorization'].setValue(data?.work_authorization?.id || null);
    this.isFresherCandidate = data?.is_fresher || false;
    this.hiringApprovedBy = { ...data?.hiring_approved_by }
    this.getSpaceUsers();
    !this.isFresherCandidate && !this.indirectContractMode && this.updateFormValidity();
    !this.isFresherCandidate && !this.indirectContractMode && this.fetchFileLink(data?.id);
    this.setFresherCandidateValidation();
    if (this.formContents?.hasPromptSourceUpdate) {
      this.statusUpdateForm.addControl('update_candidate_source', new FormControl(false));
    }
  }

  setFresherCandidateValidation() {
    if (this.isFresherCandidate) {
      this.statusUpdateForm.controls['address']?.setValidators([Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]);
      this.statusUpdateForm.addControl('stipend', new FormControl(null,
        [Validators.pattern(regex.withoutLeadingZero), Validators.max(99999)]));
      this.statusUpdateForm.addControl('stipend_currency', new FormControl('INR'));
      if (!this.indirectContractMode) {
        this.removeSalarySlip();
        this.statusUpdateForm.removeControl('payslip_id');
        this.statusUpdateForm.removeControl('existing_qrms_payslip_key');
        this.statusUpdateForm.removeControl('joining_bonus');
        this.statusUpdateForm.removeControl('joining_bonus_currency');
      }
    } else {
      this.statusUpdateForm.controls['address']?.setValidators([Validators.required, Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]);
      this.statusUpdateForm.removeControl('stipend');
      this.statusUpdateForm.removeControl('stipend_currency');
      if (!this.indirectContractMode) {
        this.statusUpdateForm.addControl('payslip_id', new FormControl(null, Validators.required));
        this.statusUpdateForm.addControl('existing_qrms_payslip_key', new FormControl(null));
        this.statusUpdateForm.addControl('joining_bonus_currency', new FormControl('INR'));
        this.statusUpdateForm.addControl('joining_bonus', new FormControl(null,
          [Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));
        this.updateFormValidity();
      }
    }
    this.statusUpdateForm.controls['address']?.updateValueAndValidity();
  }

  updateFormValidity() {
    this.statusUpdateForm.controls['payslip_id'].setValidators(Validators.required);
    this.statusUpdateForm.controls['payslip_id'].updateValueAndValidity();
  }

  employmentFormValidation(employmentType:any) {
    this.employmentTypeValue = employmentType;
    this.checkEmploymentType(employmentType);
    this.addAdditionalFormControl();
  }

  setStreams(dept_id: string) {
    this.streams = this.streamBackup.filter((stream: any) => {
      return (dept_id === stream.department_id)
    });
  }

  setDesignationValue(value: any) {
    //remove the previously added temporary designation
    let last: any = this.designationBackup[this.designationBackup.length - 1];
    if (last.is_temporary) {
      this.designationBackup.pop();
    }
    const designationId = this.designationBackup.find(
      (item: any) => item?.id === value?.id
    )?.id;
    this.statusUpdateForm.controls['designation'].setValue(value?.id || null);
    //add current temporary designation to the dropdown if not already present
    if (!designationId) {
      let tempDesignation = {...value, is_temporary: true};
      this.designationBackup = [...this.designationBackup, tempDesignation];
    }
    this.setDesignation(this.statusUpdateForm.controls['department'].value);
  }
  changeBonusCurrency(code: any) {
    if (code.id) {
      this.statusUpdateForm.controls['joining_bonus_currency']?.setValue(code.id);
    }
  }

  onDepartmentChange(dept_id: string) {
    this.statusUpdateForm.controls['designation'].setValue(null);
    this.setDesignation(dept_id);
    if (!dept_id) {
      this.statusUpdateForm.controls['stream'].setValue(null);
      this.streams = [...this.streamBackup];
    } else {
      this.statusUpdateForm.controls['stream'].setValue(null);
      this.streams = [];
      if (dept_id) {
        this.streamBackup.forEach((s: any) => {
          if (dept_id == s.department_id) {
            this.streams = [...this.streams, s];
            //automatically set stream for non development departments
            if (dept_id != '2') {
              this.statusUpdateForm.controls['stream'].setValue(s.id);
            }
          }
        });
      }
    }

  }

  onStreamChange(stream_id: string) {
    this.streams.forEach((stream: any) => {
      if (stream.id == stream_id) {
        let currentDeptId = this.statusUpdateForm.controls['department'].value;
        if (currentDeptId && stream.department_id !== currentDeptId) {
          this.statusUpdateForm.controls['designation'].setValue(null);
        }
        this.setDesignation(stream.department_id);
        this.statusUpdateForm.controls['department'].setValue(
          stream.department_id
        );
      }
    });
  }

  onHiringApprovedChange(user: any) {
    if(user?.id){
      this.resumeService.getSpaceUserVirtualTeam(user?.id).subscribe((res: any) => {
         this.statusUpdateForm.controls['virtual_team'].setValue(res?.data.virtual_team?.id || null);
      }, (error: any) => {
        console.log(error)
        this.statusUpdateForm.controls['virtual_team'].setValue(null);
      })
    }else{
      this.statusUpdateForm.controls['virtual_team'].setValue(null);
    }
  }

  setDesignation(dept_id: string) {
    if(this.employmentTypeValue === 'P') {
      this.designation = this.designationBackup.filter((item: any) => (item.department_id === dept_id
        || this.f.designation?.value == item.id || item.is_temporary || item.id ===this.statusUpdateForm.controls['designation'].value) && !['DC', 'QAC'].includes(item.code));
    } else {
      this.designation = this.designationBackup.filter((item: any) => item.department_id === dept_id
        || this.f.designation?.value == item.id || item.is_temporary || item.id ===this.statusUpdateForm.controls['designation'].value);
    }
  }


  onVirtualTeamModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.virtualTeams)
  }

  onDepartmentModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.departments)
  }

  onStreamModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.streams)
  }

  onDesignationModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.designation)
  }

  onModelChange(event: any, ref: NgSelectComponent, data: any) {
    if (event) {
      let filterValue = data.find((str: any) => str.id === event);
      ref.searchTerm = filterValue ? filterValue.name : '';
    } else {
      ref.searchTerm = '';
    }
  }

  getCandidateDetails() {
    try {
      this.subscription = this.resumeService
        .getResumeById(this.formContents?.candidateId + '')
        .subscribe((res: AppModel<CandidateData>) => {
          this.candidateData = res.data;
          this.checkEmploymentType(this.candidateData.employment_type);
          this.employmentTypeValue = this.candidateData.employment_type;
          this.addAdditionalFormControl();
          this.setStatusFormData({ ...this.candidateData })
        });
    } catch (e: any) {
      this.isResponseLoading = true;
      e.console.error();
    }

  }
  getDropdownData() {
    this.subscriptions.push(
      this.appService.generalDropdowns.subscribe((data: any) => {
        const {
          Stream = [],
          Department = [],
          Designation = [],
          Currency_choice = [],
          Joining_location = [],
          Employment_type = [],
          virtual_teams = [],
          Contract_duration = [],
          work_mode = [],
          hybrid_type = [],
          work_authorizations = [],
        } = data;
        this.setDropDownList(Stream, Department, Designation, Currency_choice, Joining_location,Employment_type, virtual_teams, Contract_duration,work_mode,hybrid_type, work_authorizations);
      }, error => {
        console.log(error)
      }));

  }

  setDropDownList(stream: any, department: any, designation: any, currencyList: any, jobLocation: any,Employment_type:any, virtual_team: any, Contract_duration:any,work_mode:any,hybrid_type:any, work_authorizations:any) {
    this.streams = stream;
    this.streamBackup = [...stream];
    this.departments = department;
    this.designationBackup = [...designation];
    this.setDesignation(this.statusUpdateForm.controls['department'].value);
    this.currencyList = currencyList;
    this.joiningLocation = jobLocation;
    this.employmentType = Employment_type;
    this.virtualTeams = virtual_team;
    this.contractDuration = Contract_duration;
    this.workModes = work_mode;
    this.hybridTypes = hybrid_type;
    this.workAuthorization = work_authorizations;
  }
  getSpaceUsers() {
    this.subscriptions.push(this.appService.spaceUsers
      .subscribe((response: any) => {
        if (response) {
          this.spaceUsers = response;
          this.statusUpdateForm.controls['hiring_approved_by'].patchValue(this.hiringApprovedBy?.id)
        }
      },
        (error) => {console.log(error); }))
  }

  onCancel() {
    this.closePopup.emit();
  }
  updateStatus() {
    if(this.isFresherCandidate){
      this.statusUpdateForm.controls['joining_bonus']?.setValue(null);
    }
    this.statusUpdateForm.markAllAsTouched();

    if (this.statusUpdateForm.invalid) {
      this.scrollToError();
      return;
    }
    let result: any = this.transformRequestPayload();
    if (this.formContents?.hasPromptSourceUpdate && this.statusUpdateForm.controls['update_candidate_source'].value) {
      result['source'] = this.statusUpdateForm.controls['update_candidate_source'].value ? 'HR' : null;
    }
    this.updateData.emit(result);
  }
  transformRequestPayload() {
    this.checkWorkAuthorization();
    let statusFormData = JSON.parse(JSON.stringify(this.statusUpdateForm.value));
    let commonPayload:any = {
      "status": 'S',
      "comments": statusFormData['comments']?.trim() || null,
      "employment_type": this.employmentTypeValue,
      "department": statusFormData['department'],
      "stream_id": statusFormData['stream'],
      "designation": statusFormData['designation'],
      "operating_country": statusFormData['operating_country'],
      "joining_location": statusFormData['joining_location'],
      "joining_date": this.convertDateFormat(statusFormData['joining_date']?.value),
      "remote": statusFormData['fully_remote'],
      "remote_location": statusFormData['remote_location']?statusFormData['remote_location']:null,
      "hiring_approved_by": statusFormData['hiring_approved_by'],
      'virtual_team': statusFormData['virtual_team'] || null,
      "onboarding_type": statusFormData['onboarding']?statusFormData['onboarding']:null,
      "is_fresher": statusFormData['is_fresher'],
      "work_mode":statusFormData['work_mode']??null,
      "hybrid_type":statusFormData['hybrid_type']??null,
      "no_of_hybrid_days":statusFormData['no_of_hybrid_days']?Number(statusFormData['no_of_hybrid_days']):null,
      "work_authorization": statusFormData['work_authorization'],
    }
    let finalPayload:any;

    if(this.employmentTypeValue === 'IC') {
      let indirectPayload:any = {
        "monthly_rate_card_currency": statusFormData['monthly_rate_card_currency'],
        "monthly_rate_card": statusFormData['monthly_rate_card']?Number(statusFormData['monthly_rate_card']):null,
        'contract_duration': statusFormData['contract_duration'] || null,
      }
      finalPayload = { ...commonPayload, ...indirectPayload } 
    }
    else {
      let otherPayload:any = {
        "address": statusFormData['address']?statusFormData['address']:null,
        "offered_salary": statusFormData['offered_salary']?Number(statusFormData['offered_salary']):null,
        "currency": statusFormData['offered_salary_currency']?statusFormData['offered_salary_currency']:null,
        "existing_qrms_payslip_key" : !this.isFresherCandidate ? (statusFormData['existing_qrms_payslip_key'] || null) : undefined,
        "payslip_id" : !this.isFresherCandidate ? (statusFormData['payslip_id'] || null) : undefined,
        "joining_bonus": statusFormData['joining_bonus'] ? Number(statusFormData['joining_bonus']):null,
        "joining_bonus_currency": statusFormData['joining_bonus_currency']?statusFormData['joining_bonus_currency']:null,
      }

      if (this.isFresherCandidate) {
        commonPayload = {
          ...commonPayload,
          "stipend" : statusFormData['stipend'] ? Number(statusFormData['stipend']) : null,
          "stipend_currency" : statusFormData['stipend_currency'] || null,
        }
      }
      finalPayload = { ...commonPayload, ...otherPayload } 
    }
    return finalPayload;
  }

  convertDateFormat(date: any): string | null {
    return this._datepipe.transform(date?.startDate, this.localDateFormat);
  }
  scrollToError() {
    const firstElementWithError = document.querySelector(
      '.alert.alert-error,input.ng-invalid, ng-select.ng-invalid'
    );
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  //Reset Salary Slip
  removeSalarySlip() {
    if (this.InputVar?.nativeElement) {
      this.InputVar.nativeElement.value = '';
    }
    this.salarySlipName = "";
    this.salarySlipLink = "";
    this.fileSizeError = false;
    this.fileTypeError = false;
    this.statusUpdateForm.controls['payslip_id']?.setValue(null);
    this.statusUpdateForm.controls['payslip_id']?.markAsTouched();
    this.statusUpdateForm.controls['existing_qrms_payslip_key']?.setValue(null);
  }

  onChange(event: any) {
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.isPaySlipUpdated = true;
    let file = event.target.files[0];
    this.uploadFile(file)
  }

  uploadFile(file: any) {
    if (!this.checkFileExtension(file)) {
      this.removeSalarySlip();
      this.fileTypeError = true;
      return;
    }

    if ((file?.size / 1048576) > 2.5) {
      this.removeSalarySlip();
      this.fileSizeError = true;
      return;
    }

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("document", file, file.name);
    formData.append("file_type", 'PS');


    // invoke upload API
    this.subscriptions.push(this.fileUploadService.upload(formData)
      .subscribe(
        (data: any) => {
          //remove previous salary slip if it's present
          if ( this.statusUpdateForm.controls['payslip_id'].value) {
            this.removeSalarySlip();
          }
          this.statusUpdateForm.controls['existing_qrms_payslip_key'].setValue(data.data.existing_qrms_file_key);
          this.statusUpdateForm.controls['payslip_id'].setValue(data.data.id);
          this.salarySlipName = data.data?.name || "";
          this.truncateFileName();
          this.salarySlipLink = data.data?.link || "";
          this.statusUpdateForm.controls['payslip_id'].markAsTouched();
        },
        () => {
          this.toaster.error(`Failed to upload file`);
        }
      )
    );
  }

  checkFileExtension(file: File) {
    var extension = this.getFileExtension(file.name);
    var allowedExtensions = ['png', 'jpeg', 'jpg', 'pdf'];
    if (file.name.length > 0) {
      if (allowedExtensions.indexOf(extension) == -1) {
        return false;
      }
      else {
        return true
      }
    } else return false;
  }

  truncateFileName() {
    if (this.salarySlipName?.length > 16) {
      this.salarySlipName = this.salarySlipName.slice(0, 4) + '...' + this.salarySlipName.slice(this.salarySlipName.length - 8, this.salarySlipName.length);
    }
  }

  getFileExtension(name: string) {
    return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
  }

  download() {
    window.open(this.salarySlipLink,(this.isPaySlipUpdated ? '_blank' : '_self'));
  }

  fetchFileLink(id: any) {
    this.subscriptions.push(this.fileUploadService.downloadFile(id, 'PS').subscribe((res: any) => {
      this.salarySlipLink = res.link;
      this.salarySlipName = res.name;
      this.truncateFileName();
      this.statusUpdateForm.controls['existing_qrms_payslip_key'].setValue(res.existing_qrms_file_key);
      this.statusUpdateForm.controls['payslip_id'].setValue(res.id);
    }));
  }

  customEmailSearchFn(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item);
  }

  onUpdateCandidateSource() {
    let updateSource = this.statusUpdateForm.controls['update_candidate_source'].value;
    this.statusUpdateForm.controls['update_candidate_source'].setValue(!updateSource);
  }

  checkEmploymentType(employementTypeValue:any) {
    if(employementTypeValue === 'IC') {
      this.indirectContractMode = true;
    }
    else {
      this.indirectContractMode = false;
    }
  }

  toggleCheckBox(formControlName: string) {
    this.statusUpdateForm.controls[formControlName].setValue(!this.isFresherCandidate)
    this.isFresherCandidate = !this.isFresherCandidate;
    this.setFresherCandidateValidation();
  }

  formatName(e: any, field: string) {

    if (e.target.value) {
      let val = e.target.value.trim();
      this.statusUpdateForm.controls[field].patchValue(val);
    }
  }

  onWorkModeChange(workMode:any){
    if(workMode.id === 'remote') {
      this.statusUpdateForm.controls['remote_location']?.setValidators([Validators.required,Validators.maxLength(50)]);
      this.statusUpdateForm.controls['no_of_hybrid_days']?.clearValidators();
      this.statusUpdateForm.controls['no_of_hybrid_days']?.setValue(null);
    } else if (workMode.id === 'hybrid') {   
        this.hybridType = this.statusUpdateForm.controls['hybrid_type']?.value == 'week' ? regex.daysOfTheWeek:regex.daysOfTheMonth;
        this.statusUpdateForm.controls['no_of_hybrid_days']?.setValidators(Validators.pattern(this.hybridType));
        this.statusUpdateForm.controls['remote_location']?.clearValidators();
        this.statusUpdateForm.controls['remote_location']?.setValue(null);
    } else {
      this.statusUpdateForm.controls['remote_location']?.clearValidators();
      this.statusUpdateForm.controls['no_of_hybrid_days']?.clearValidators();
      this.statusUpdateForm.controls['no_of_hybrid_days']?.setValue(null);
      this.statusUpdateForm.controls['remote_location']?.setValue(null);
    }
    this.statusUpdateForm.controls['no_of_hybrid_days']?.updateValueAndValidity();
    this.statusUpdateForm.controls['remote_location']?.updateValueAndValidity();
    
  }

  onHybridTypeChange(hybridType:any) {
    this.statusUpdateForm.controls['no_of_hybrid_days']?.clearValidators();
    this.hybridType = hybridType.id === 'week' ? regex.daysOfTheWeek:regex.daysOfTheMonth;
    this.statusUpdateForm.controls['no_of_hybrid_days']?.setValidators(Validators.pattern(this.hybridType));
    this.statusUpdateForm.controls['no_of_hybrid_days']?.updateValueAndValidity();
  }

  setWorkModeValidation(val:any) {
    let workMode = { id:val }
    this.onWorkModeChange(workMode);
  }

  checkWorkAuthorization() {
    if (this.statusUpdateForm.controls['operating_country'].value !== 'US') {
      this.statusUpdateForm.controls['work_authorization'].setValue(null);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptions.forEach((item: any) => item.unsubscribe());
  }
}


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { datetimeformat } from './../../../shared/_services/format';
import { Activitylog } from '../../_models/activity-history.model';
@Component({
  selector: 'app-activity-history',
  templateUrl: './activity-history.component.html',
  styleUrls: ['./activity-history.component.scss']
})
export class ActivityHistoryComponent implements OnInit {
  _activityLogs: any;
  activityList: any = []
  _apiLoading: boolean = true;
  datetimeformat: string = datetimeformat;
  @Input() activityTitle: string = '';
  @Input() titleClass: string = '';
  @Input() set activityLogs(val: Activitylog) {
    this._activityLogs = val;
  }
  get activityLogs() {
    return this._activityLogs;
  }
  @Input() set apiLoading(flag: boolean) {
    this._apiLoading = flag;
  }
  get apiLoading() {
    return this._apiLoading;
  }
  @Output() onPageNavigate: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onNextClick(event: any) {
    if (event) {
      this.onPageNavigate.emit({ pageNo: this.activityLogs?.nextPage, data: event });
    }
  }

  onPrevClick(event: any) {
    if (event) {
      this.onPageNavigate.emit({ pageNo: this.activityLogs?.prevPage, data: event });
    }
  }

}
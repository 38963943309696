<form class="filter-form">
  <div class="filter-wrapper d-flex custom-style-ng-select align-items-center">
    <div class="applied-filters d-flex unit-flex"> <span *ngIf="showFiltersSelectedWithName.length > 0"
        class="filters-label">Filters:</span>
      <div class="d-flex flex-wrap ms-1 me-2 filters-container">
        <ng-container *ngIf="showFiltersSelectedWithName.length > 0">
          <ng-container *ngFor="let filter of showFiltersSelectedWithName;">
            <div *ngIf="filter.id || filter.id == 0" class="filters-name d-flex align-items-center"><span class="d-inline-block">{{filter.displayText}}</span> <span
                (click)="addOrRemoveFilterItem(filter.filterCategory, filter.id, filter.name, filter.displayTag, filter.type); applyFilter()"
                class="small-close-btn"> <img src="./assets/images/close-line-icon.svg" alt="remove" class="cursor-pointer" />
              </span> </div>
          </ng-container>
        </ng-container>
        <div class="actions" (clickOutside)="handleOutsideClick()"> 
              <div class="add-filter-btn cursor-pointer" (click)="openFilterList($event)">
              {{'Add Filter' | uppercase}}
              </div>
          <div [hidden]="!isFilterOpen" class="filter-list">
            <ul>
              <li *ngFor="let item of filterItems; let i = index;">
                <div class="filter-main"> {{ item && item.displayName}} <span>{{ item &&
                    (filtersSelected[item.id]?.length > 0 ? ( '('+ filtersSelected[item.id]?.length + ')' ) : '')}}
                  </span> <img src="./assets/images/right-angle.svg" alt="right-arrow" /> </div>
                <div *ngIf=" item && item?.type ==='dropdown-select'" class="sub-list dropdown"> <button
                    class="dropdown-toggle custom-dropdown-btn" type="button" [id]="item.id"
                    (click)="item.expanded = !item?.expanded"> {{setDropDownSelectPlaceholder(item.dropdownName)}} </button>
                  <ul class="custom-dropdown-menu" [ngClass]="item?.expanded ? 'show' : ''"
                    [attr.aria-labelledby]="item.id">
                    <li *ngFor="let subItem of item?.values; let i=index"> <a class="dropdown-item cursor-pointer"
                        [ngClass]="filtersSelected[item.id] == subItem.id ? 'active' : ''"
                        (click)="addOrRemoveFilterItem(item.id, subItem.id, subItem.name, item.displayTag, 'dropdown-select'); item.expanded = false">
                        {{subItem[item.filterDisplayKey]}}
                       </a> </li>
                  </ul>
                </div>
                  <div *ngIf="item && item.type === 'search-single-select'" class="sub-list overflow-visible type-search-select">
                  <ng-select #searchSelect [items]="item.values" [bindLabel]="item.dropdownKey" bindValue="id" 
                    [isOpen]="true" placeholder="{{item.placeholder}}"  [closeOnSelect]="false" 
                    (focus)="onFocus(searchSelect, item.id)" (change)="onAddSingleSelect(item.dropdownKey,$event, searchSelect,item)" [virtualScroll]="true"
                    [loading]="filterLoading" [typeahead]="input$" [markFirst]="false">
                    <ng-template ng-option-tmp let-subItem="item" let-subItem$="item$" let-subItemIndex="index">
                      <div class="option-padding"   [ngClass]="filtersSelected[item.id] == subItem.id ? 'active' : ''"                
                        (click)="addOrRemoveFilterItem(item.id, subItem.id, subItem[item.filterDisplayKey], item.displayTag,'search-single-select')">
                        <span> {{subItem[item.dropdownKey]}} </span> </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div *ngIf="item && item.type === 'search-multi-select'" class="sub-list overflow-visible type-search-select">
                  <ng-select #searchSelect [items]="item.values" [bindLabel]="item.dropdownKey" bindValue="id"
                    [isOpen]="true" placeholder="{{item.placeholder}}" [multiple]="multiple" [closeOnSelect]="false"
                    (focus)="onFocus(searchSelect, item.id)" (change)="onAdd(searchSelect)" [virtualScroll]="true"
                    [loading]="filterLoading" [typeahead]="input$">
                    <ng-template ng-option-tmp let-subItem="item" let-subItem$="item$" let-subItemIndex="index">
                      <div class="option-padding"
                        (click)="addOrRemoveFilterItem(item.id, subItem.id, subItem[item.filterDisplayKey], item.displayTag)">
                        <span> <img
                            [src]="filtersSelected[item.id]?.includes(subItem.id) ? './assets/images/checked.svg' : './assets/images/checked-empty.svg'"
                            alt="checked" /> {{subItem[item.dropdownKey]}} </span> </div>
                    </ng-template>
                  </ng-select>
                </div>
              </li>
            </ul>
            <div class="filter-buttons"> <button class="clear-filter" (click)="clearFilter()">Clear All</button> <button
                class="apply-filter" (click)="applyFilter()">Apply filters</button> </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="showFiltersSelectedWithName.length ? 'visible':'invisible'" class="cursor-pointer clear-btn d-flex align-items-start" (click)="clearFilter()"><span class="py-1">{{'clear'
        | titlecase}}</span></div>
  </div>
</form>
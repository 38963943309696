<div class="header expand-submenu ps-3" *ngIf="!isNavCollapsed">
  <div class="navbar-collapse">
    <ul class="navbar-nav">
      <ng-container *ngFor="let menu of subMenuItems">
        <li *userRole="menu.role" [routerLinkActive]="['active-sub-list']" #rla="routerLinkActive" class="nav-item menu-container"
          [ngClass]="{'disabled-icon': menu.isDisabled, 'pe-none': menu.isDisabled}">
          <a [routerLink]="[menu.routerLink]" (click)="!menu.isDisabled ? toggleMenu(menu) : ''"
            class="nav-link overflow-hidden">
            <div class="menu-item mp-7" [ngClass]="{'active' : rla.isActive }" (click)="handleClick($event, menu)">
              <div class="d-flex mr-img-5 align-items-center">
                <img class="side-nav-img" [ngClass]="{'nav-menu-selected active-icon': rla.isActive}"
                  [src]="'./assets/images/greyicon.svg'"
                  alt="{{menu.title}}"/>
              </div>
              <div>{{menu.title}} <span></span></div>
              <img *ngIf="menu.subMenu?.length > 0 && !isNavCollapsed"
                [src]="menu.toggleSubmenu ? '/assets/images/up-arrow-black.svg' : '/assets/images/down-arrow-black.svg'" />
            </div>
          </a>
          <ul [hidden]="menu.subMenu?.length == 0 || menu.toggleSubmenu == false">
            <app-nav-sub-menu [subMenuItems]="menu.subMenu" [menuTitle]="menu.title" [isNavCollapsed]="isNavCollapsed">
            </app-nav-sub-menu>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<div class="collapes-sub-menu-container submenu-left" *ngIf="isNavCollapsed" (mouseleave)="outsideHover()">
  <span class="sub-menu-title">{{menuTitle}}</span>
  <ul>
    <ng-container *ngFor="let menu of subMenuItems; let i = index">
      <li *userRole="menu.role" [routerLinkActive]="['active']" #rla="routerLinkActive"
        class="d-flex" (mouseenter)="submenuHoverItemIndex = i;">
        <img [src]="'./assets/images/greyicon.svg'"
          alt="{{menu.title}}" class="pe-2 icon" />
        <a class="submenu-item-name" [routerLink]="menu.routerLink" [ngClass]="{'nav-link-active' : rla.isActive }"
          (click)="!menu.isDisabled ? toggleMenu(menu) : ''">
          <div>{{menu.title}}</div>
        </a>
        <div *ngIf="menu.subMenu?.length > 0" class="ms-2 position-relative">
          <img [src]="menu.toggleSubmenu ? '/assets/images/up-arrow-black.svg' : '/assets/images/down-arrow-black.svg'" />
          <div *ngIf="submenuHoverItemIndex === i" class="submenu-next-level">
            <app-nav-sub-menu [subMenuItems]="menu.subMenu" [menuTitle]="menu.title" [isNavCollapsed]="isNavCollapsed">
            </app-nav-sub-menu>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
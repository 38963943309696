<div class="calender" (click)="setDropdownPosition()">
    <input type="text" readonly [disabled]="disabled" ngxDaterangepickerMd class="datepicker-calendar-icon input-style form-control cursor-view"
        [ngStyle]="{'outline':(focusElement && tabIndex != null && tabIndex>1 ) ? tabFocusBorder:'unset'}"
        (blur)="removeFocus()" (keyup)="onFocusElement($event)"
        [singleDatePicker]="true" [minDate]="item.minDate" [maxDate]="item.maxDate" [(ngModel)]="item.value"
        [locale]="locale" [ngClass]="{'no-border' : border===true }" placeholder="Mmm DD, YYYY" #pickerDirective
        (change)="change($event,item.id)" [showDropdowns]="true" (ngModelChange)="ngModelChange($event, item.id)"
        [showClearButton]="true" [tabindex]="tabIndex" (keydown)="onKeyDown($event)" [drops]="dropdownPosition" [id]="datePickerId"
        (startDateChanged)="setFocus()"/>
    <a class="icon-class theme-coloured-image blue-icon ngx-daterangepicker-action ms-1 me-1" (click)="open($event)">
    </a>

</div>
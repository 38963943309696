<div class="offerLetterForm" tabindex="0">
  <form [formGroup]="offerForm">
    <div class="fluid-container p-4">
      <div class="row mb-3">
        <div class="col-sm-12 popup-header">{{isEdit ? 'Edit Offer' : 'Create Offer'}}</div>
      </div>

      <div class="checkbox-div mb-2" *ngIf="isEdit && isOfferLetterSentOrOfferAccepted">
        <app-checkbox [checked]="generateNewOffer" (toggle)="toggleCheckbox()"></app-checkbox>
        <span class="ms-2">Generate New Offer</span>
      </div>

      <div class="description-section" [ngClass]="isEdit && isOfferLetterSentOrOfferAccepted && 'red-bg'">
        <div class="label-value" [innerHTML]="getWarningText()">

        </div>
        <!-- <div class="col-sm-12 description-section">
          <span class="label">Last drawn CTC:</span>
          <span class="value me-4" *ngIf="candidateData.current_CTC">{{candidateData.current_CTC}}</span>
          <span class="value me-4" *ngIf="!candidateData.current_CTC">&nbsp; -</span>
          <span class="label ms-5">Expected CTC:</span>
          <span class="value" *ngIf="candidateData.expected_ctc">{{candidateData.expected_ctc}}</span>
          <span class="value" *ngIf="!candidateData.expected_ctc">&nbsp; -</span>
        </div> -->
      </div>

      <div class="row item-list-container">
        <div class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Employment Type
              <span *ngIf="isEdit" class="other-info" (mouseover)="showOtherInfo = true" (mouseout)="showOtherInfo = false">
                <img src="./assets/images/info-message-icon.svg" alt="info">
                <span *ngIf="showOtherInfo" class="info-container">
                  Employment Type can be updated from the Edit candidate screen
                </span>
              </span>
            </label>
            <div [ngClass]="isEdit && 'disabled-field'">
              <ng-select placeholder="Select" formControlName="employment_type" [items]="employmentTypes" 
                bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false" (change)="onEmploymentTypeChange()">
              </ng-select>
              <div *ngIf="f.employment_type.touched && f.employment_type.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['employment_type'].hasError('required')">Required</div>
              </div>
          </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="row item">
              <label class=" col-form-label ">Work mode <span class="optional-field">(Optional)</span></label>
              <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
                <ng-select [items]="workModes" bindLabel="name" bindValue="id" formControlName="work_mode"
                [searchable]="false" [clearable]="false" placeholder="Select"
                (change)="onWorkModeChange($event)">
                </ng-select>
              </div>
          </div>
        </div>

        <div class="col-md-6 mb-3"  *ngIf="f.work_mode.value  === 'remote'">
          <div class="row item">
            <label class=" col-form-label ">Remote  Location</label>
            <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <input formControlName="remote_location" autocomplete="off" type="text" 
              (focusout)="formatName($event, 'remote_location')" class="form-control">
              <div *ngIf="f.remote_location.touched && f.remote_location.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['remote_location'].hasError('required')">Required</div>
                <div *ngIf="offerForm.controls['remote_location'].hasError('maxlength')">Max character allowed is 50</div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-6 mb-3" *ngIf="f.work_mode.value  === 'hybrid'">
          <div class="row item">
            <label class=" col-form-label ">Days in office <span class="optional-field">(Optional)</span></label>
            <div class="d-flex align-items-center">
              <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
                <input type="text" autocomplete="off" class="form-control"
                formControlName="no_of_hybrid_days"/>
                <div *ngIf="f.no_of_hybrid_days.touched && f.no_of_hybrid_days.invalid" class="alert alert-error">
                  <div *ngIf="offerForm.controls['no_of_hybrid_days'].hasError('pattern')">
                    Enter between 1 to {{ offerForm.controls['hybrid_type'].value == 'week' ? '7':'31'}}
                  </div>
                </div>
              </div>
              <label class="col-sm-1 text-center col-form-label">Per</label>
              <div class="col-sm-6" [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
                <ng-select [items]="hybridTypes" bindLabel="name" bindValue="id"[searchable]="false" 
                formControlName="hybrid_type" [clearable]="false"  (change)="onHybridTypeChange($event)">
                </ng-select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Department</label>
            <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="departments"
                class="remove-border" bindLabel="name" bindValue="id" #department_ref [handler_input]="department_ref"
                ngSelectHandler formControlName="department_id" [searchable]="true" [clearable]="true"
                placeholder="Select or start typing" (change)="onDepartmentChange($event, designation_ref)">
              </ng-select>
              <div *ngIf="f.department_id.touched && f.department_id.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['department_id'].hasError('required')">Required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Designation</label>
            <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="designationsFiltered"
                class="remove-border" bindLabel="name" bindValue="id" #designation_ref [handler_input]="designation_ref"
                ngSelectHandler formControlName="designation_id" [searchable]="true" [clearable]="true"
                placeholder="Select or start typing">
              </ng-select>
              <div *ngIf="f.designation_id.touched && f.designation_id.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['designation_id'].hasError('required')">Required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Joining Location</label>
            <div class="">
              <ng-select [items]="interviewLocations" bindLabel="name" bindValue="id"
                formControlName="joining_location_id" [searchable]="false" [clearable]="false"
                placeholder="Select">
              </ng-select>
              <div *ngIf="f.joining_location_id.touched && f.joining_location_id.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['joining_location_id'].hasError('required')">Required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3" *ngIf="isIndirectContract">
          <div class="row  item">
            <label class=" col-form-label ">Monthly Rate Card</label>
            <div>
              <div class="form-control d-flex align-items-center">
                <span class="border-right me-1 pe-2 input-prefix">
                  <ng-select [items]="currencyChoice" class="remove-border" bindLabel="name" bindValue="id"
                    [searchable]="false" [clearable]="false" formControlName="monthly_rate_card_currency">
                  </ng-select>
                </span>
                <input autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none"
                  formControlName="monthly_rate_card">
              </div>
              <div *ngIf="f.monthly_rate_card.touched && f.monthly_rate_card.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['monthly_rate_card'].hasError('pattern') ||
              offerForm.controls['monthly_rate_card'].hasError('max')">Invalid Amount
                </div>
                <div *ngIf="offerForm.controls['monthly_rate_card'].hasError('required')">Required</div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-6 mb-3" *ngIf="!isIndirectContract">
          <div class="row  item">
            <label class=" col-form-label ">Offered salary/month</label>
            <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <div class="form-control d-flex align-items-center">
                <span class="border-right me-1 pe-2 input-prefix">
                  <ng-select [items]="currencyChoice" class="remove-border" bindLabel="name" bindValue="id"
                    [searchable]="false" [clearable]="false" formControlName="currency">
                  </ng-select>
                </span>
                <input autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none"
                  formControlName="offered_salary">
              </div>
              <div *ngIf="f.offered_salary.touched && f.offered_salary.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['offered_salary'].hasError('pattern') ||
              offerForm.controls['offered_salary'].hasError('max')">Invalid Amount
                </div>
                <div *ngIf="offerForm.controls['offered_salary'].hasError('required')">Required</div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-6 mb-3" *ngIf="isFresherCandidate && !isIndirectContract">
          <div class="row item">
            <label class=" col-form-label">Stipend <span class="optional-field">(Optional)</span></label>
            <div class="col-md-12">
              <div class="form-control d-flex align-items-center" [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
                <span class="border-right me-1 pe-2 input-prefix c">
                  <ng-select formControlName="stipend_currency" [items]="currencyChoice" class="remove-border"
                    bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                  </ng-select>
                </span>
                <input formControlName="stipend" autocomplete="off" type="text"
                  class="hide-arrow w-100 border-0 o-none">
              </div>
              <div *ngIf="f.stipend.touched && f.stipend.invalid" class="alert alert-error">
                <ng-container
                  *ngIf="f.stipend.hasError('pattern') || f.stipend.hasError('max')">
                  Invalid Amount
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="row item">
            <label class=" col-form-label ">Joining Date <span class="optional-feild">(Optional)</span></label>
            <div class="">
              <app-single-date-picker-component [item]="datepickerConfig" [border]="false" [datePickerId]="'OfferJDPicker'"
                (ngSelectedDate)="onDateSelected($event)">
              </app-single-date-picker-component>
              <div *ngIf="f.joining_date.touched && f.joining_date.invalid"
                class="alert alert-error">
                <div *ngIf="f.joining_date.errors?.minimumDate">Chosen date is a past date</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3" *ngIf="!isIndirectContract && !isFresherCandidate && (candidateData.is_allow_joining_bonus || (isEdit && f?.joining_bonus?.value))">
          <div class="row item">
            <label class="col-form-label ">Joining Bonus <span class="optional-feild">(Optional)</span></label>
            <div [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <div class="form-control d-flex align-items-center" 
                [ngClass]="!(!generateNewOffer && isOfferLetterSentOrOfferAccepted) && !candidateData.is_allow_joining_bonus && 'disabled-col'">
                <span class="border-right me-1 pe-2 input-prefix">
                  <ng-select [items]="currencyChoice" class="remove-border" bindLabel="name" bindValue="id"
                    [searchable]="false" [clearable]="false" formControlName="joining_bonus_currency">
                  </ng-select>
                </span>
                <input autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none"
                  formControlName="joining_bonus" disabled="{{!candidateData.is_allow_joining_bonus}}"
                  [ngClass]="!candidateData.is_allow_joining_bonus && 'disabled-color'">
              </div>
              <div *ngIf="f.joining_bonus.touched && f.joining_bonus.invalid" class="alert alert-error">
                <div *ngIf="offerForm.controls['joining_bonus'].hasError('pattern') ||
              offerForm.controls['joining_bonus'].hasError('max')">Invalid Amount
                </div>
              </div>
            </div>
          </div>
        </div>
 
        <div class="col-md-6 mb-3" *ngIf="isIndirectContract">
          <div class="row item">
            <label class=" col-form-label"> Contract Duration <span class="optional-feild">(Optional)</span></label>
            <div class="">
              <ng-select placeholder="Select" formControlName="contract_duration" [items]="contractDurations"
                bindLabel="name" bindValue="id" [searchable]="false" [clearable]="true">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row item">
            <label class="col-form-label ">Onboarding Type</label>
            <div class="h-40 d-flex align-items-center" [ngClass]="!generateNewOffer && isOfferLetterSentOrOfferAccepted && 'disabled-field'">
              <input type="radio" class="radio-large me-1" formControlName="onboarding_type" value="ON" />
              <span class="radio-text">Online</span>
              <input type="radio" class="radio-large ms-4" formControlName="onboarding_type" value="OFF" />
              <span class="radio-text">Offline</span>
            </div>
          </div>
          <div *ngIf="f.onboarding_type.touched && f.onboarding_type.invalid" class="alert alert-error">
            <div *ngIf="offerForm.controls['onboarding_type'].hasError('required')">Required</div>
          </div>
        </div>

        <div class="col-sm-12 mb-3">
          <label class="col-form-label">Comments <span class="optional-feild">(Optional)</span></label>
          <textarea formControlName="comment" class="form-control remove-border textarea" rows="4"></textarea>
          <div *ngIf="f.comment.touched && f.comment.invalid" class="alert alert-error">
            <div *ngIf="f.comment.errors?.maxlength">Comment should be less than 500 characters</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-3">
          <div class="row justify-content-center mt-3">
            <button class="col-sm-3 m-1 me-4 btn button-cancel" (click)="cancel()">{{'cancel'|
              uppercase }}</button>
            <button class="col-sm-3 m-1 btn button-save red-btn" (click)="submitOffer()" [disabled]="editDisabled">
              {{isEdit ? 'UPDATE' : 'CREATE OFFER'}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { InterviewType, RecommendationChoices } from 'src/app/shared/_models/utils';
import { Subscription } from 'rxjs';
import { InterviewService } from 'src/app/interview/interview.service';

@Component({
  selector: 'app-interview-detail-popup',
  templateUrl: './interview-detail-popup.component.html',
  styleUrls: ['./interview-detail-popup.component.scss']
})
export class InterviewDetailPopupComponent implements OnInit {

  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  @Input() slotId: any;
  @Input() popUpContents: any;
  subscription1: Subscription = new Subscription();
  interviewDetails:any;
  interviewType: Array<InterviewType> = [];
  recommendations!: RecommendationChoices;
  pastInterview:boolean = false;
  apiLoading:boolean = false;

  constructor( 
    private interviewService:InterviewService,
    private appService: AppService,
    ) { }

  ngOnInit(): void {
    this.getDropdownsData();
  }

  getDropdownsData() {
    this.subscription1 = this.appService.generalDropdowns.subscribe(
      (data: any) => {
        const {
          Interview_mode_choice = [],
        } = data;
        this.interviewType = Interview_mode_choice;
        this.getInterviewDetails();
      }
    );
  }

  transformData() {
    let data = this.interviewType.filter((item: any) => item.id == this.interviewDetails?.mode);
    this.interviewDetails.mode_name = data.length ? data[0]?.name : '';
    if(new Date(this.popUpContents[0].end_time).getTime() < new Date().getTime()) {
      this.pastInterview = true;
    }
  }

  getInterviewDetails() {
    this.apiLoading = true;
    this.interviewService.getInterviewDetailsById(this.slotId).subscribe((response:any) => {
      this.apiLoading = false;
     this.interviewDetails = response.data;
     this.transformData();
    },
    (error) => {
      this.apiLoading = false;
    });
  }

  viewCandidate() {
    const id = this.interviewDetails.candidate.candidate_id;
    const link = `/candidates/`+id+'/view?tab=2&interview='+this.interviewDetails.id;
    window.open(link,'_blank')
  }

  onCancel() {
      this.closePopup.emit();
  }
}

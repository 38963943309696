<form [formGroup]="selectForm">
  <div class="form-group">
    <ng-select [items]="list" bindLabel="username" bindValue="id" labelForId="state" [multiple]="multiple" [placeholder]="placeholder"
      clearAllText="Clear" formControlName="selected" (change)="onChange($event)">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <p>{{item['username']}}
          <span>, </span>
          {{item['email']}}
          <span></span>
        </p>
      </ng-template>
    </ng-select>
  </div>
</form>

<div *ngIf="customMenu.length > 0" class="custom-nav">
  <ul #navList class="custom-nav-list">
    <ng-container *ngFor="let menu of customMenu; let i = index">
      <li *ngIf="!menu.isHidden" [routerLinkActive]="['active']" #rla="routerLinkActive" class="nav-item" [ngClass]="{'disabled-icon': menu.isDisabled, 'disable-feature': menu.isDisabled}">
        <a [routerLink]="menu.routerLink" class="nav-link">
          <div class="d-flex custom-menu-item"><span>{{menu.title}}</span> <span class="d-flex align-items-center"
              [routerLinkActive]="['chevron']"></span></div>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
import { NavMenu } from '../_models/navMenu.model';
import { PAGE } from '../_models/page.enum';
import { Role } from '../_models/role';

export const searchedLabels = {
  name: 'Name',
  skill: 'Skills',
  department: 'Dept',
  stream: 'Stream',
  experience: 'Exp',
  loc: 'Loc',
  jobcode: 'Job Code',
  agency: 'Agency',
  status: 'Status',
  source: 'Source',
  email: 'Email',
  country_code: 'Country Code',
  phone_number: 'Phone',
};

// Back button of header is shown only for selected routes
export const backBtnUrls = [
  {
    pageRegex: /^\/interviews\/schedule-invites\/\d+\/edit\?from=candidate-detail/g,
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=header/g,
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=interview/g,
    backPageUrl: 'interviews/list',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=dashboard/g,
    backPageUrl: 'dashboard',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=selected/g,
    backPageUrl: 'selected-candidates/list',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=jobpost/g,
    urlTerm: 'jobpost'
  },
  {
    pageRegex:  /^\/workbook\/view\/\d+\/candidates\/list\?from=bulk-upload/g,
    backPageUrl: 'bulk-upload',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=workbook/g,
    backPageUrl: '',
    urlTerm: 'workbook/view',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=review-rating/g,
    backPageUrl: 'action-items/review-rating',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=schedule-invites-list/g,
    backPageUrl: 'interviews/schedule-invites/list',
  },
  {
    pageRegex: /^\/interviews\/schedule-invites\/\d+\/edit/g,
    backPageUrl: 'interviews/schedule-invites/list',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=no-show-and-cancellations/g,
    backPageUrl: 'action-items/no-show-and-cancellations'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=offer-approvals/g,
    backPageUrl: 'action-items/offer-approvals'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=awaiting-rating/g,
    backPageUrl: 'action-items/awaiting-rating'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=referral-bonus-employee&active=referralBonusEmployeeOpen/g,
    backPageUrl: 'action-items/employee-referrals',
    params: { active: 1 }
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=referral-bonus-employee&active=referralBonusEmployeeApproved/g,
    backPageUrl: 'action-items/employee-referrals',
    params: { active: 2 }
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=referral-bonus-employee/g,
    backPageUrl: 'action-items/employee-referrals'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=referral-bonus-agency/g,
    backPageUrl: 'action-items/agency-referrals'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=referrals/g,
    backPageUrl: 'my-referrals/list'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=new-hire-intimation/g,
    backPageUrl: '/action-items/new-hires'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=joining-date-yet-to-confirm/g,
    backPageUrl: 'action-items/confirm-joining-date'
  },
  {
    pageRegex: /^\/candidates\/\d+\/view\?from=jobpostcandidates/g,
    backPageUrl: '',
    urlTerm: 'jobpost/view',
  },
  {
    pageRegex: /^\/candidates\/\d+\/view/g,
    backPageUrl: 'candidates/list',
  },
  {
    pageRegex: /^\/jobpost\/view\/\d+\/create-and-schedule/g,
    urlTerm: 'create-and-schedule/list'
  },
  {
    pageRegex: /^\/jobpost\/view\/\d+\/[a-z]+\?from=dashboard/g,
    backPageUrl: 'dashboard',
  },
  {
    pageRegex: /^\/jobpost\/view\/\d+\/manage-agencies/g,
    backPageUrl: '',
  },
  {
    pageRegex: /^\/jobpost\/view\/\d+/g,
    backPageUrl: 'jobpost/list',
  },
  {
    pageRegex: /^\/recruitment-drive\/view\/\d+/g,
    backPageUrl: 'recruitment-drive/list',
  },
  {
    pageRegex: /^\/workbook\/view\/\d+\/create-and-schedule\/list/g,
    backPageUrl: 'workbook/list'
  },
  {
    pageRegex: /^\/workbook\/view\/\d+\/create-and-schedule/g,
    urlTerm: 'create-and-schedule/list'
  },
  {
    pageRegex: /^\/workbook\/view\/\d+\/candidates\/edit/g,
    urlTerm: ''
  },
  {
    pageRegex: /^\/workbook\/view\/\d+/g,
    backPageUrl: 'workbook/list',
  },
  {
    pageRegex: /^\/jobpost\/edit\/\d+/g,
  },
  {
    pageRegex: /^\/organization-admin\/agencies\/add/g,
    backPageUrl: 'organization-admin/agencies',
  },
  {
    pageRegex: /^\/organization-admin\/agencies\/\d+/g,
    backPageUrl: 'organization-admin/agencies',
  },
  {
    pageRegex: /^\/organization-admin\/agencies/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/organization-admin\/activity-log/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/organization-admin\/offer-settings/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/organization-admin\/agency-users\/\d+\/add/g,
    backPageUrl: 'organization-admin/agency-users',
  },
  {
    pageRegex: /^\/organization-admin\/agency-users\/\d+\/edit\/\d+/g,
    backPageUrl: 'organization-admin/agency-users',
  },
  {
    pageRegex: /^\/organization-admin\/agency-users/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/organization-admin\/interview-settings/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/interviews\/interviewers\/\d+\/view/g,
    backPageUrl: 'interviews/interviewers/list',
  },
  {
    pageRegex: /^\/interviews\/interviewers\/\d+\/availability/g,
    backPageUrl: 'interviews/interviewers/list',
  },
  {
    pageRegex: /^\/interviews\/interviewers\/\d+\/activity-log/g,
    backPageUrl: 'interviews/interviewers/list',
  },
  {
    pageRegex: /^\/organization-admin\/interview-settings/g,
    backPageUrl: 'organization-admin/admin-dashboard',
  },
  {
    pageRegex: /^\/candidates\/\d+\/schedule-invite/g,
    backPageUrl: '',
  },
  {
    pageRegex: /^\/bulk-upload\/\d+\/view/g,
    backPageUrl: 'bulk-upload',
  },
];

export const secondaryMenus: any = {
  '': [],
  'action items': [
    {
      title: 'Offer Approvals',
      routerLink: `/action-items/offer-approvals`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.OFFERAPPROVALS,
    },
    {
      title: 'Review Ratings',
      routerLink: `/action-items/review-rating`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.ACTIONITEMSREVIEWRATING,
    },
    {
      title: 'No Show/Cancellations',
      routerLink: `/action-items/no-show-and-cancellations`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.NOSHOWANDCANCELLATIONS
    },
    {
      title: 'Awaiting Ratings',
      routerLink: `/action-items/awaiting-rating`,
      page: PAGE.AWAITINGRATING,
      role: [Role.IS],
    },
    {
      title: 'Employee Referrals',
      routerLink: `/action-items/employee-referrals`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.REFERRALBONUSEMPLOYEEOPEN,
      page1: PAGE.REFERRALBONUSEMPLOYEEAPPROVED
    },
    {
      title: 'Agency Referrals',
      routerLink: `/action-items/agency-referrals`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.REFERRALBONUSAGENCY
    },
    {
      title: 'Confirm Joining Date',
      routerLink: `/action-items/confirm-joining-date`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.JOININGDATEYETCONFIRM
    },
    {
      title: 'New Hires',
      routerLink: `/action-items/new-hires`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.NEWHIREINTIMATIONS
    }
  ],
  'reports': [
    {
      title: 'Standard Reports',
      routerLink: `/reports/standard-reports`,
      page: PAGE.REPORTSSTANDARDREPORT,
    },
    {
      title: 'Analytics',
      routerLink: `/reports/analytics`,
      page: PAGE.REPORTSANALYTICS,
      isDisabled: true,
    },
    {
      title: 'My Reports',
      routerLink: `/reports/my-reports`,
      page: PAGE.REPORTSMYREPORTS,
      isDisabled: true,
    },
  ],
  'interviews': [
    {
      title: 'Schedules',
      routerLink: '/interviews/list',
      role: [Role.HRM, Role.HR, Role.Director, Role.IS, Role.IN],
      page: PAGE.INTERVIEWS,
    },
    {
      title: 'Interviewers',
      routerLink: `/interviews/interviewers`,
      page: PAGE.INTERVIEWERS,
      role: [Role.HRM, Role.HR, Role.Director],
      isDisabled: false,
    },
    {
      title: 'Schedule Invites',
      routerLink: `/interviews/schedule-invites`,
      page: PAGE.INTERVIEWSCHEDULEINVITES,
      role: [Role.HRM, Role.HR, Role.Director],
      isDisabled: false,
    },
    {
      title: 'Availability',
      routerLink: `/interviews/availability`,
      page: PAGE.INTERVIEWERSDETAILAVAILABILITY,
      role: [Role.TEMP_IN, Role.IN],
      isDisabled: false,
    },
  ]
}

export const customSideNav: any = {
  workbook: [
    {
      title: 'Overview',
      routerLink: `/workbook/view/id/overview`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKOVERVIEW,
    },
    {
      title: 'Eazy Schedule',
      routerLink: `/workbook/view/id/create-and-schedule/`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKCREATEANDSCHEDULE,
    },
    {
      title: 'Candidates',
      routerLink: `/workbook/view/id/candidates`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKCANDIDATES,
    },
    {
      title: 'Schedule Interview',
      routerLink: `/workbook/view/id/schedule-interview`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSCHEDULEINTERVIEW,
    },
    {
      title: 'Interviewer - Driven Scheduling',
      routerLink: `/workbook/view/id/interviewer-driven-scheduling`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKINTERVIEWERDRIVEN,
    },
    {
      title: 'Send Call Letter',
      routerLink: `/workbook/view/id/send-call-letter`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSENDCALLLETTER,
    },
    {
      title: 'Send Offer Letter',
      routerLink: `/workbook/view/id/send-offer-letter`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSENDOFFERLETTER,
    },
    {
      title: 'Request Rating',
      routerLink: `/workbook/view/id/request-rating`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKREQUESTRATING,
    },
    {
      title: 'Create Candidate',
      routerLink: `/workbook/view/id/create-candidate`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKCREATECANDIDATE,
    },
    {
      title: 'Req Salary Docs',
      routerLink: `/workbook/view/id/request-salary-doc`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSALARYDOC,
    },
    // {
    //   title: 'Extend Offer',
    //   routerLink: `offer`,
    //   role: [Role.HRM, Role.HR, Role.Director],
    //   page: PAGE.WORKBOOKHISTORY
    // },
    {
      title: 'History',
      routerLink: `/workbook/view/id/task-history`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKHISTORY,
    },
    // {
    //   title: 'Notes',
    //   routerLink: `notes`,
    //   role: [Role.HRM, Role.HR, Role.Director],
    //   page: PAGE.WORKBOOKHISTORY
    // }
  ],
  interviewersDetail: [
    {
      title: 'Overview',
      routerLink: `/interviews/interviewers/id/view`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.INTERVIEWERSDETAILOVERVIEW,
    },
    {
      title: 'Availability',
      routerLink: `/interviews/interviewers/id/availability`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.INTERVIEWERSDETAILAVAILABILITY,
      isDisabled: false
    },
    {
      title: 'Activity Log',
      routerLink: `/interviews/interviewers/id/activity-log`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.INTERVIEWERSDETAILACTIVITYLOG,
      isDisabled: false
    },
  ],
  jobpost: [
    {
      title: 'Overview',
      routerLink: `overview`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKOVERVIEW,
    },
    {
      title: 'Details',
      routerLink: `details`,
      role: [Role.HR, Role.PM, Role.HRM, Role.Director, Role.Staff],
      page: PAGE.JOBPOSTDETAILS,
    },
    {
      title: 'Agencies',
      routerLink: `agencies`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.JOBPOSTAGENCIES,
    },
    {
      title: 'Candidates',
      routerLink: `candidates`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.JOBPOSTCANDIDATES,
    },
    {
      title: 'Schedule Interview',
      routerLink: `schedule-interview`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSCHEDULEINTERVIEW,
    },
    {
      title: 'Interviewer - Driven Scheduling',
      routerLink: `interviewer-driven-scheduling`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKINTERVIEWERDRIVEN,
    },
    {
      title: 'Send Call Letter',
      routerLink: `send-call-letter`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSENDCALLLETTER,
    },
    {
      title: 'Send Offer Letter',
      routerLink: `send-offer-letter`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSENDOFFERLETTER,
    },
    {
      title: 'Request Rating',
      routerLink: `request-rating`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKREQUESTRATING,
    },
    {
      title: 'Create Candidate',
      routerLink: `create-candidate`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKCREATECANDIDATE,
    },
    {
      title: 'Req Salary Docs',
      routerLink: `request-salary-doc`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKSALARYDOC,
    },
    {
      title: 'Comments',
      routerLink: `comments`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.JOBPOSTCOMMENTS,
    },
    {
      title: 'History',
      routerLink: `task-history`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKHISTORY,
    },
  ],
  candidates: [
    {
      title: 'Overview',
      routerLink: `overview`,
      page: PAGE.CANDIDATEOVERVIEW,
    },
    {
      title: 'Profession & Education',
      routerLink: `professional-details`,
      page: PAGE.CANDIDATEPROFESSIONALDETAILS,
    },
    {
      title: 'Interviews',
      routerLink: `interviews`,
      role: [Role.HR, Role.HRM, Role.Director, Role.RM, Role.PM, Role.Staff],
      page: PAGE.CANDIDATEHIRINGDETAILS,
    },
    {
      title: 'Hiring',
      routerLink: `hiring-details`,
      role: [Role.HR, Role.HRM, Role.Director],
      page: PAGE.CANDIDATEHIRINGDETAILS,
    },
    {
      title: 'Offers',
      routerLink: `offer-details`,
      role: [Role.HR, Role.HRM, Role.Director],
      page: PAGE.CANDIDATEOFFERDETAILS,
    },
    {
      title: 'Referral & Others',
      routerLink: `referral-details`,
      page: PAGE.CANDIDATEREFERRALDETAILS,
    },
    {
      title: 'Comments',
      routerLink: `comments`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.CANDIDATECOMMENTS,
    },
    {
      title: 'Activity Log',
      routerLink: `activity-log`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.CANDIDATEACTIVITYLOG,
    },
  ],
  recruitmentDrive: [
    {
      title: 'Overview',
      routerLink: `overview`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.RECRUITMENTDRIVEOVERVIEW,
    },
    {
      title: 'Comments',
      routerLink: `comments`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.RECRUITMENTDRIVECOMMENTS,
    },
    {
      title: 'History',
      routerLink: `task-history`,
      role: [Role.HRM, Role.HR, Role.Director],
      page: PAGE.WORKBOOKHISTORY,
    },
  ]

}


export const newMenu: NavMenu[] = [
  {
    title: 'Dashboard',
    icon: './assets/images/dashboard-icon.svg',
    routerLink: '/dashboard',
    role: [Role.HRM, Role.HR, Role.Director, Role.Staff, Role.PM, Role.RM],
    page: PAGE.DASHBOARD
  },
  {
    title: 'Job Posts',
    icon: './assets/images/jobpost.svg',
    routerLink: '/jobpost',
    role: [Role.HRM, Role.HR, Role.Director, Role.Staff, Role.PM],
    page: PAGE.JOBPOST,
  },
  {
    title: 'Recruitment Drives',
    icon: './assets/images/recruitment-drive.svg',
    routerLink: '/recruitment-drive',
    role: [Role.HRM, Role.HR, Role.Director],
    page: PAGE.RECRUITMENTDRIVE,
    tenantBasedMenuFlag: 'is_recruitment_drives_enabled',
    showMenuForTenant: false,
  },
  {
    title: 'Candidates',
    icon: './assets/images/candidates.svg',
    routerLink: '/candidates',
    role: [Role.HRM, Role.HR, Role.Director, Role.RM, Role.RM],
    page: PAGE.CANDIDATES,
  },
  {
    title: 'Action Items',
    icon: './assets/images/action-items.svg',
    routerLink: '/action-items',
    page: PAGE.ACTIONITEMS,
    subMenu: secondaryMenus['action items'],
    toggleSubmenu: false,
    mainMenuKey: 'action items',
  },
  {
    title: 'Bulk Upload',
    icon: './assets/images/bulk-upload.svg',
    routerLink: '/bulk-upload',
    role: [Role.HRM, Role.HR],
    page: PAGE.BULKUPLOAD,
  },
  {
    title: 'Interviews',
    icon: './assets/images/interviews.svg',
    routerLink: '/interviews',
    role: [Role.HRM, Role.HR, Role.Director, Role.IS, Role.IN],
    page: PAGE.INTERVIEWS,
    subMenu: secondaryMenus['interviews'],
    toggleSubmenu: false,
    mainMenuKey: 'interviews',
  },
  {
    title: 'My Referrals',
    icon: './assets/images/my-referrals.svg',
    routerLink: '/my-referrals',
    role: [Role.Staff, Role.PM],
    page: PAGE.MYREFERRALS,
  },
  {
    title: 'Selected Candidates',
    icon: './assets/images/selected-candidates.svg',
    routerLink: '/selected-candidates',
    role: [Role.HRM, Role.HR, Role.Director, Role.VT],
    page: PAGE.SELECTEDCANDIDATES,
  },
  {
    title: 'Workbooks',
    icon: './assets/images/workbook.svg',
    routerLink: '/workbook',
    role: [Role.HRM, Role.HR, Role.Director],
    page: PAGE.WORKBOOK,
  },
  {
    title: 'Reports',
    icon: './assets/images/reports.svg',
    routerLink: '/reports',
    role: [Role.HRM, Role.HR, Role.Director],
    page: PAGE.REPORTS,
    subMenu: secondaryMenus['reports'],
    toggleSubmenu: false,
    mainMenuKey: 'reports',
    tenantBasedMenuFlag: 'is_reports_enabled',
    showMenuForTenant: false,
  },
  {
    title: 'Settings',
    icon: './assets/images/settings.svg',
    routerLink: '/settings',
    role: [Role.HRM, Role.HR],
    page: PAGE.SETTINGS,
    isDisabled: true,
  },
  {
    title: 'Organization Admin',
    icon: './assets/images/org-admin-icon.svg',
    routerLink: '/organization-admin',
    role: [Role.OA],
    page: PAGE.ORGANIZATIONADMIN
  }
];

<div class="tab-container">
    <div class="row from-tab tab-header-container">
        <div class="col-sm-12 p-0 tab-div" [ngClass]="headerAlignment === 'left' ? 'text-start' : 'text-center'">
            <ul class="nav nav-tabs row">
                <ng-container *ngFor="let tab of tabs">
                    <li class="nav-item col" *userRole="tab.userRole" [ngClass]="customClass ? customClass : ''">
                        <span class="nav-link first-item" [ngClass]="activeTab === tab.tab ? 'active' : 'not-active'" aria-current="page" (click)="onTabChange(tab.tab)">
                        <span [innerHTML]="tab.label"></span>
                        <span *ngIf="tab.totalItems" class="ms-3 count-badge-div"><span class="count-badge-section" [ngClass]="{'count-badge-section-disabled' : activeTab !== tab.tab}">{{tab.totalItems}} No{{tab.totalItems > 1 ? 's' :''}}</span></span>
                        </span>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <ng-content></ng-content>
</div>
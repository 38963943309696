import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalStoreService } from '../../_services/global-store.service';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
  /**
  * @param  {InputFieldType} type
  * To be wrapped around an input field with label to input field and label styles according to theme
  * Wrap the input field inside a div having class 'input-plus-label' so that the label can be moved into that div to apply the necessary styles using adjacent element selector in css
  * Empty placeholder is required for floating label to work
  */
  @Input() type: InputFieldType = 'input';
  @ViewChild('inputContainerDiv') inputContainerDiv!: ElementRef;
  inputConfig: any = {};
  constructor(
    private globalStore: GlobalStoreService,
  ) { }

  ngOnInit(): void {
    this.getInputConfig();
    this.setPlaceholder();
  }

  getInputConfig() {
    let themeConfig = this.globalStore.getThemeConfig();
    this.inputConfig = themeConfig.input;
  }

  setPlaceholder() {
    if (this.inputConfig === 'floating-label') {
      setTimeout(() => {
        let inputField = this.inputContainerDiv?.nativeElement.querySelector('input') as HTMLInputElement;
        let label = this.inputContainerDiv?.nativeElement.querySelector('label') as HTMLLabelElement;
  
        switch(this.type) {
          case 'input': {
            this.setInputPlaceholder(inputField, label);
            break;
          }
          case 'select': {
            let ngPlaceholder = this.inputContainerDiv?.nativeElement.getElementsByClassName('ng-placeholder')[0];
            ngPlaceholder.style.display = 'none';
            this.setInputPlaceholder(inputField, label);
            break;
          }
          case 'textarea': {
            let textareaField = this.inputContainerDiv?.nativeElement.querySelector('textarea') as HTMLInputElement;
            this.setInputPlaceholder(textareaField, label);
            break;
          }
          case 'combo': {
            this.setInputPlaceholder(null, label, true);
            break;
          }
          default: {
            break;
          }
        }
      }, 0)
    }
  }

  /**
  * Moving label element inside the div after input element to apply the necessary styles
  * For combo fields copying the label element and doing the same for each of the fields
  */
  setInputPlaceholder(inputField: any, label: HTMLLabelElement, multiple: boolean = false) {
    let inputLabelDiv = this.inputContainerDiv?.nativeElement.querySelector('.input-plus-label') as HTMLDivElement;

    this.inputContainerDiv?.nativeElement.removeChild(label);
    inputLabelDiv.append(label);

    if (multiple) {
      let inputLabelDiv2 = this.inputContainerDiv?.nativeElement.querySelectorAll('.input-plus-label')[1] as HTMLDivElement;
      let duplicateLabel = label.cloneNode(true);
      inputLabelDiv2.append(duplicateLabel);
    } else {
      inputField.placeholder = " ";
    }
  }
}

const InputFields = ['input', 'select', 'combo', 'textarea'] as const;
type InputFieldType = typeof InputFields[number];
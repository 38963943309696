import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResumeService } from 'src/app/resume/resume.service';
import { SpaceUser } from '../../_models/utils';
import { CustomValidators } from '../../_services/custom-validators.service';
import { dateFormatForDatePipe } from '../../_services/format';
import { regex } from '../../_services/regex';
import { UtilService } from '../../_services/util.service';

@Component({
  selector: 'app-offer-letter-prerequisite',
  templateUrl: './offer-letter-prerequisite.component.html',
  styleUrls: ['./offer-letter-prerequisite.component.scss'],
})
export class OfferLetterPrerequisiteComponent implements OnInit {
  hiringForm!: FormGroup;
  currencyChoice: any = [];
  hrUsers: any = [];
  designation: any = [];
  designationsFiltered: any = [];
  status: any = [];
  phoneCodes: any = [];
  spaceUsers: SpaceUser[] = [];
  spaceUsersNStaff: SpaceUser[] = [];
  interviewLocations = [];
  localDateFormat: string = dateFormatForDatePipe;
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  isFresherCandidate: boolean = false;
  latestOffer:number = 0;
  @Input() hiringDetails: any;
  @Input() modalType: string = '';
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() reloadCandidateAndActivityLog: EventEmitter<any> = new EventEmitter();

  constructor(
    private formbuilder: FormBuilder,
    private appService: AppService,
    private _datepipe: DatePipe,
    private resumeService: ResumeService,
    private toaster: ToastrService,
    private customValidatorService: CustomValidators,
    private utilService: UtilService,
  ) {
    this.hiringForm = this.formbuilder.group({
      address: [null, [Validators.required, Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]],
      employment_type: [null, Validators.required],
      designation: [null, Validators.required],
      hiring_approved_by: [null, Validators.required],
      joining_bonus: [
        null,
        [Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)],
      ],
      joining_date: [null],
      joining_location: [null, Validators.required],
      offered_salary: [
        null,
        [
          Validators.required,
          Validators.pattern(regex.withoutLeadingZero),
          Validators.max(9999999),
        ],
      ],
      onboarding_type: [null, Validators.required],
      remote: [null, Validators.required],
      remote_location: [null, Validators.maxLength(50)],
      currency: ['INR'],
      joining_bonus_currency: ['INR'],
    });
  }

  ngOnInit(): void {
    this.getDropdownsData();
    this.getSpaceUsersNStaff();
    this.patchForm();
  }

  patchForm() {
    this.latestOffer = this.hiringDetails?.offer?.length - 1;
    let value = JSON.parse(JSON.stringify(this.hiringDetails));
    this.updateDateFields('joining_date', value.offer[this.latestOffer].joining_date, 'joining_date');
    this.hiringForm.controls['address'].setValue(value.address);
    this.hiringForm.controls['employment_type'].setValue(
      this.formatType(value.employment_type)
    );
    this.hiringForm.controls['designation'].setValue(
      value.offer[this.latestOffer].designation?.id
    );
    this.hiringForm.controls['hiring_approved_by'].setValue(
      value.offer[this.latestOffer].hiring_approved_by?.id
    );
    this.hiringForm.controls['joining_bonus'].setValue(
      value.offer[this.latestOffer].joining_bonus
    );
    this.hiringForm.controls['joining_location'].setValue(
      value.offer[this.latestOffer].joining_location?.id
    );
    this.hiringForm.controls['offered_salary'].setValue(
      value.offer[this.latestOffer].offered_salary
    );
    this.hiringForm.controls['onboarding_type'].setValue(
      value.offer[this.latestOffer].onboarding_type
    );
    this.hiringForm.controls['remote'].setValue(value.offer[this.latestOffer].remote);
    this.hiringForm.controls['remote_location'].setValue(
      value.offer[this.latestOffer].remote_location
    );
    this.hiringForm.controls['currency'].setValue(value.offer[this.latestOffer].currency);
    this.hiringForm.controls['joining_bonus_currency'].setValue(value.offer[this.latestOffer].joining_bonus_currency);
    this.onRemoteChange(this.hiringForm.controls['remote'].value);
    this.setDesignationDropdown(value.offer[this.latestOffer].designation);
    this.isFresherCandidate = this.hiringDetails.is_fresher;
    this.setFresherCandidateValidation();
  }

  setFresherCandidateValidation(){
    if (this.isFresherCandidate) {
      this.hiringForm.controls['address'].setValidators([Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]);
    } else {
      this.hiringForm.controls['address'].setValidators([Validators.required, Validators.maxLength(500), this.customValidatorService.limitMaxmRows(5)]);
    }
    this.hiringForm.controls['address'].updateValueAndValidity();
  }

  formatType(value: string) {
    if (value == 'Permanent') {
      return 'P';
    } else if (value == 'Contract') {
      return 'C';
    } else {
      return value;
    }
  }

  get f() {
    return this.hiringForm.controls;
  }
  setDesignationDropdown(designation: any) {
    //remove the previously added temporary designation
    let last: any = this.designation[this.designation.length - 1];
    if (last.is_temporary) {
      this.designation.pop();
    }
    //add current temporary designation to the dropdown if not already present
    let current: any = this.designation.find((d: any) => d?.id === designation?.id);
    if (!current) {
      let tempDesignation = {...designation, is_temporary: true};
      this.designation = [...this.designation, tempDesignation];
    }
  }

  getDropdownsData() {
    this.subscription1 = this.appService.generalDropdowns.subscribe(
      (data: any) => {
        const {
          Currency_choice = [],
          Designation = [],
          HR_users = [],
          Joining_location = [],
        } = data;

        this.currencyChoice = Currency_choice;
        this.designation = Designation;
        this.hrUsers = HR_users;
        this.interviewLocations = Joining_location;
        this.setDesignation(this.hiringDetails.stream?.department?.id);
      }
    );
  }

  getSpaceUsers() {
    this.subscription1 = this.appService.spaceUsers.subscribe(
      (response: any) => {
        if (response) {
          this.spaceUsers = response;
        }
      },
      (error) => {
        console.log(error)
      });
  }

  getSpaceUsersNStaff() {
    this.subscription2 = this.appService.spaceUsersNStaff.subscribe(
      (response: any) => {
        if (response) {
          this.spaceUsersNStaff = response;
        }
      },
      (error) => {
        console.log(error)
      });
  }

  updateDateFields(
    id: string,
    dateValue: string | null,
    formControl: string
  ): any {
    let date = {
      id: id,
      value: dateValue
        ? { startDate: dayjs(dateValue), endDate: dayjs(dateValue) }
        : undefined,
      minDate: dayjs.Dayjs,
      maxDate: id === 'date_of_birth' ? dayjs() : dayjs.Dayjs,
    };
    this.hiringForm.controls[formControl].setValue(date);
  }

  save() {
    if (this.isFresherCandidate){
      this.hiringForm.controls['joining_bonus'].setValue(null);
    }
    if (this.hiringForm.invalid) {
      this.hiringForm.markAllAsTouched();
      return;
    }
    let offerRequest = JSON.parse(JSON.stringify(this.hiringForm.value));

    offerRequest = this.constructRequestObj(offerRequest, true);
    this.subscription4 = this.resumeService
      .updateHiringDetails(offerRequest, this.hiringDetails.id)
      .subscribe(
        (res: any) => {
          if (res?.type != 'application/pdf') {
            this.toaster.error('Unable to generate offer letter. Please check the hiring details of the candidate');
            this.reloadCandidateAndActivityLog.emit();
            this.cancel();
          }
          else {
            let fileURL = URL.createObjectURL(res);
            this.toaster.success('Hiring Details Updated');
            this.onSave.emit(fileURL);
          }
        },
        (err: any) => {
          this.toaster.error('Something went wrong');
        }
      );
  }

  constructRequestObj(reqObject: any, is_view: boolean) {
    if (reqObject.joining_date) {
      reqObject.joining_date = this.convertDateFormat(
        reqObject.joining_date.value
      );
    }
    reqObject['is_view'] = is_view;
    reqObject['joining_bonus'] = parseFloat(
      this.hiringForm.controls['joining_bonus'].value
    );
    reqObject['offered_salary'] = parseFloat(
      this.hiringForm.controls['offered_salary'].value
    );
    return reqObject;
  }

  cancel() {
    this.onCancel.emit();
  }

  convertDateFormat(date: any): string | null {
    return this._datepipe.transform(date?.startDate, this.localDateFormat);
  }

  onRemoteChange(remote: boolean) {
    if (remote) {
      let x = this.hiringForm.controls['remote_location']?.validator;
      if (x) {
        this.hiringForm.controls['remote_location']?.setValidators([Validators.required, x,]);
      } else {
        this.hiringForm.controls['remote_location']?.setValidators(Validators.required);
      }
      this.hiringForm.controls['remote_location'].updateValueAndValidity();
    } else {
      this.hiringForm.controls['remote_location']?.setValidators(Validators.maxLength(50));
      this.hiringForm.controls['remote_location'].updateValueAndValidity();
    }
  }

  setDesignation(dept_id: string) {
    this.designationsFiltered = this.designation.filter((item: any) => item.department_id === dept_id
      || this.f.designation?.value == item.id || item.is_temporary);
  }

  customSearchFn(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../core/http/api.service';
export const routes = {
  dashboard: `/api/dashboard`,
  upcomingInterviews: `/interview-list/`,
  myReferrals: `/my-referrals/`,
  joblist: `/job-list/`,
  validateCandidate: `/api/candidate/validate-referal-candidate/`,
  awaitingRating: '/api/interviews/awaiting-rating',
};
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _refreshReferrals = new BehaviorSubject<boolean>(false);
  refreshReferrals$ = this._refreshReferrals.asObservable();

  constructor(private apiService: ApiService) { }

  getUpcomingInterviews() {
    return this.apiService.get(`${routes.dashboard}${routes.upcomingInterviews}`);
  }

  sendOpenForJobEmail(id: string, data: any) {
    return this.apiService.post(`${routes.dashboard}${routes.myReferrals}${id}/open-for-job/`, data);
  }

  getJobpostList(): Observable<any> {
    return this.apiService.get(`${routes.dashboard}${routes.joblist}`);
  }

  getMyReferrals() {
    return this.apiService.get(`${routes.dashboard}${routes.myReferrals}`);
  }

  validateReferalCandidate(params: any) {
    return this.apiService.get(`${routes.validateCandidate}`, params);
  }

  postReferalFriend(data: any) {
    return this.apiService.post(`${routes.dashboard}${routes.myReferrals}`, data);
  }

  updateReferalFriend(data: any, candidate_id: number) {
    return this.apiService.put(`${routes.dashboard}${routes.myReferrals}${candidate_id}`, data);
  }

  setRefreshReferral(val: boolean) {
    this._refreshReferrals.next(val);
  }

  getRefreshReferral() {
    return this.refreshReferrals$;
  }

  getAwaitingRatingsDashboard() {
    return this.apiService.get(`${routes.awaitingRating}/?dashboard=true&ordering=-scheduled_for`);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { regex } from 'src/app/shared/_services/regex';

@Pipe({
  name: 'editorContentLength'
})
export class EditorContentLengthPipe implements PipeTransform {
  transform(value: any): number {
    let parsedContent = value.replace(regex.htmlTagRegex, '');
    parsedContent = parsedContent.replace(regex.nbspRegex, '');
    parsedContent = parsedContent.replace(/&#160;/g, '');
    return parsedContent.trim().length;
  }
}

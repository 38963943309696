import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { LoaderService } from 'src/app/shared/_components/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/shared/_services/util.service';


/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private toaster: ToastrService,
    private utilService: UtilService,
    private router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error, request.method)));
  }

  // Customize the default error handler here if needed
  private errorHandler(error: any, httpMethod: string): Observable<HttpEvent<any>> {
    switch (error.status) {
      case 401: {
        //unauthorized
        this.toaster.error(`Your session has expired. Please login`);
        setTimeout(() => {
          this.loaderService.show();
        }, 0);
        setTimeout(() => {
          this.loaderService.hide();
          this.logout();
        }, 4500); //delay for toaster before redirecting to keycloak login page

        // If API returns 401 status code, two toast messages will be triggered.
        // From here(interceptor) and from individual API error callback
        // To get rid of executing API error callback, empty observable is returned
        return EMPTY;
      }
      case 403: {
        //Permissions changed
        this.loaderService.hide();
        this.router.navigateByUrl('/dashboard');
        break;
      }
      case 400: {
        // if (httpMethod === 'GET') {
        //   this.router.navigate(['/page-not-found']);
        //   break;
        // }
        throw error;
      }
      case 500: {
        // Internal server error
        // Open error toast
        break;
      }
      case 504: {
        // Gateway timeout
        // Open error toast
        break;
      }
      default: {
        let errMsg: string;
        if (error instanceof Response) {
          errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
        throw errMsg;
      }
    }
    throw error;
  }

  logout() {
    this.authService.logout();
    this.utilService.closeModals();
  }

}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "src/app/core/http/api.service";

export const routes = {
  checkAvailability: `/api/interviewer/check-availability/`,
  scheduleInvite: `/api/send-schedule-invite/`,
  putScheduleInvite: `/api/schedule-invites`
};

@Injectable({
  providedIn: 'root'
})
export class ScheduleInviteFormService {
  constructor(
    private apiService: ApiService
  ) { }

  checkAvailability(params: any): Observable<any> {
    return this.apiService.get(routes.checkAvailability, params);
  }

  scheduleInvite(data: any): Observable<any> {
    return this.apiService.post(routes.scheduleInvite, data)
  }

  updateScheduleInvite(id: any, data: any): Observable<any> {
    return this.apiService.put(`${routes.putScheduleInvite}/${id}/`, data)
  }

}
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { mediumDateformat } from './../../../shared/_services/format';
import * as dayjs from "dayjs";
import { dayOptions } from './constants';
import { DayRangeService } from './day-range.service';

@Component({
  selector: 'app-day-range-picker',
  templateUrl: './day-range-picker.component.html',
  styleUrls: ['./day-range-picker.component.scss']
})
export class DayRangePickerComponent implements OnInit {
  dayOptions: any;
  listableDayOptions: any;
  currentSelection: any = { id: '1', name: 'Last 30 days' };
  dateRangeObj: any;
  dateRange: any;
  startDate: any
  endDate: any
  dateFormat: any = mediumDateformat;
  filtersSelected: any = [];
  filtersSelectedWithName: any = [];
  showFiltersSelectedWithName: any = [];
  @Input() currentPage: string = "";
  @ViewChild('dateRangePicker') dateRangePicker!: any;
  @Output() onFilterApply: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dayRangeService: DayRangeService) { }

  ngOnInit(): void {
    this.setInitialDayOptions();
    this.setInitialDate()
  }

  setInitialDayOptions() {
    this.dateRangeObj = {
      "displayName": "Custom Date Range",
      "type": "date-range",
      "id": "report_date",
      "id_1": "from_date",
      "id_2": "to_date",
      "selectedDate": { "from_date": null, "to_date": null }
    };
    this.dayOptions = dayOptions;
    this.setListableOptions();
  }

  setInitialDate() {
    const { dateRangeId, startDate, endDate } = this.dayRangeService.getCurrentPageDateDetails(this.currentPage);
    if (startDate && endDate && dateRangeId) {
      this.startDate = startDate;
      this.endDate = endDate;
      if (dateRangeId == 6) {
        this.currentSelection = { id: 6, name: 'Custom Date Range' };
        this.dateRangeObj['selectedDate'] = { from_date: dayjs(this.startDate), to_date: dayjs(this.endDate) };
      } else {
        this.currentSelection = this.dayOptions.find((date: any) => date?.id == dateRangeId);
        this.dateRangeObj['selectedDate'] = undefined;
      }
      this.storeDateRange(this.startDate, this.endDate, this.currentSelection?.id);
    }
    else {
      this.getDateRangeForLastNDays(29);
      this.startDate = this.setReportDateTime(this.startDate);
      this.endDate = this.setReportDateTime(this.endDate, false);
      this.storeDateRange(this.startDate, this.endDate, 1);
      this.currentSelection = { id: 1, name: 'Last 30 days' };
      this.dateRangeObj['selectedDate'] = undefined;
    }
  }

  setListableOptions() {
    this.listableDayOptions = this.dayOptions.filter((option: any) => option.id != this.currentSelection.id)
  }

  onDaySelection(option: any) {
    this.currentSelection = { ...option };
    this.dateRangePicker.clear();
    this.setListableOptions();
    this.updateDateAndTimeOnChange(option?.id);
    this.storeDateRange(this.startDate, this.endDate, this.currentSelection?.id)
  }

  setReportDateTime(date: Date, isStartDate: boolean = true) {
    let dateTime: any;
    if (isStartDate)
      dateTime = new Date((date).setHours(0, 0, 0, 0)).toISOString();
    else
      dateTime = new Date((date).setHours(23, 59, 59, 0)).toISOString();
    return dateTime;
  }

  updateDateAndTimeOnChange(option: number) {
    this.setDateRangeValue(option);
    this.startDate = this.setReportDateTime(this.startDate);
    this.endDate = this.setReportDateTime(this.endDate, false);
  }

  setDateRangeValue(id: number) {
    switch (id) {
      case 1:
        this.getDateRangeForLastNDays(29);
        break;
      case 2:
        this.getDateRangeForLastNDays(6);
        break;
      case 3:
        this.getDateRangeOfPreviousWeek();
        break;
      case 4:
        this.getDateRangeOfPreviousMonth();
        break;
      case 5:
        this.getDateRangeOfCurrentMonth();
        break;
      default:
        this.dateRange = "";
    }
  }

  getDateRangeOfCurrentMonth() {
    let firstDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.startDate = firstDayOfCurrentMonth;
    this.endDate = new Date();

  }
  getDateRangeForLastNDays(days: number) {
    const today = new Date();
    const previousNdays = new Date(new Date().setDate(today.getDate() - days));
    this.startDate = previousNdays;
    this.endDate = today;
  }

  getNoOfDaysInCurrentMonth() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    return new Date(year, month, 0).getDate();
  }

  getDateRangeOfPreviousWeek() {
    let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    let beforeOneWeek2 = new Date(beforeOneWeek);
    let day = beforeOneWeek.getDay()
    let diffToSaturday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 0)
    let lastSunday = new Date(beforeOneWeek.setDate(diffToSaturday))
    let lastSaturday = new Date(beforeOneWeek2.setDate(diffToSaturday + 6));
    this.startDate = lastSunday;
    this.endDate = lastSaturday;
  }

  getDateRangeOfPreviousMonth() {
    const firstDayPrevMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    const lastDayPrevMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    this.startDate = firstDayPrevMonth;
    this.endDate = lastDayPrevMonth;
  }

  onDateSelected(event: any) {
    if (event.data[event.id1] !== null && event.data[event.id2] !== null) {
      this.currentSelection = { id: 6, name: 'Custom Date Range' };
      let start = new Date(event.data[event.id1]).setHours(0,0,0,0);
      let end = new Date(event.data[event.id2]).setHours(23, 59, 59, 0);
      this.startDate = dayjs(start).toISOString();
      this.endDate = dayjs(end).toISOString();
      this.closeDropDown();
      this.listableDayOptions = [...this.dayOptions];
      this.storeDateRange(this.startDate, this.endDate, this.currentSelection?.id);
    }
  }

  closeDropDown() {
    let listElement: any = document.getElementById("day-picker");
    listElement?.classList?.remove("show");
    let dropdownBtn: any = document.getElementById("dLabel");
    dropdownBtn?.classList?.remove("show");
  }

  storeDateRange(startDate: any, endDate: any, dateRangeId: number) {
    const dayObj: any = {
      page: this.currentPage, startDate: startDate, endDate: endDate, dateRangeId: dateRangeId
    };
    this.dayRangeService.setCurrentPageDateDetails(this.currentPage, dayObj)
    this.onFilterApply.emit({ 'isDateEvent': true });
  }

  noActionOnClick(event:any){
    event?.stopPropagation();
  }
}

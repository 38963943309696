import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { AppService } from 'src/app/app.service';
import { mediumDateformat, currencyformat } from './../../../shared/_services/format';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import { regex } from '../../../shared/_services/regex';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-referral-bonus-pop-up',
  templateUrl: './referral-bonus-pop-up.component.html',
  styleUrls: ['./referral-bonus-pop-up.component.scss']
})
export class ReferralBonusPopUpComponent implements OnInit, OnChanges {
  referralTypes: any = { employee: 'employee', agency: 'agency' };
  actionTypes: any = { approve: 'approve', reject: 'reject' }
  @Input() referralType: string = '';
  @Input() action: string = '';
  @Input() referralContents: any;
  @Input() reasonMaxLength: number = 250;
  @Input() mailContent: any = null;
  @Output() closePopup: EventEmitter<null> = new EventEmitter();
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Output() getEmailContent: EventEmitter<any> = new EventEmitter();
  @ViewChild('emailPopup', { static: true }) emailContent!: NgbModalRef;
  notifyUser: boolean = true;
  currencyList: any = [];
  subscriptions: any = [];
  dateformat: string;
  referralForm!: FormGroup;
  candidateCTC: any;
  invalidBonus: string = '';
  disableAction: boolean = false;
  emailPopupContent: any = null;
  hasMultipleCcAndBcc: boolean = false;
  emailUser: any = null;
  modalRef: any;
  userEditedEmailContent: any = null;
  hasClickedPreview:boolean=false;
  invalidReferralBonus: boolean = false;
  closeResult:any;
  constructor(private appService: AppService, private formBuilder: FormBuilder, private modalService: NgbModal,
) {
    this.dateformat = mediumDateformat;
  }

  ngOnInit(): void {
    this.getDropdownData();
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mailContent?.currentValue){
      let currentVal = changes?.mailContent?.currentValue;
      this.emailPopupContent = currentVal?.mailContents;
      this.hasMultipleCcAndBcc = currentVal?.hasMultipleCcAndBcc;
      this.emailUser = currentVal?.emailUser;
      this.hasClickedPreview && this.openModal(this.emailContent, 'xl', 'email-editor-modal');
    }
  }

  digitSeperatorInd(amount: any) {
    return amount.toLocaleString(currencyformat) as string;
  }

  initializeForm() {
    this.referralForm = this.formBuilder.group({});

    if (this.referralTypes.employee === this.referralType) {
      this.referralForm.addControl('referralBonus', new FormControl(null, [Validators.required, Validators.pattern(regex.integerNumber), Validators.max(9999999), Validators.min(1)]));
      this.referralForm.addControl('referralCurrency', new FormControl('INR'));
    }
    if (this.action === this.actionTypes.reject) {
      this.referralForm.addControl('reason', new FormControl(null, [Validators.required, Validators.maxLength(this.reasonMaxLength)]));
    }
    if (this.referralTypes.agency === this.referralType) {
      this.referralForm.addControl('referralPercentage', new FormControl(null, [Validators.required, Validators.pattern(regex.decimalWithThreePlace), Validators.min(0.001)]));
      this.referralForm.addControl('agencyReferralAmount', new FormControl(0));
    }
    this.setReferralData();
  }

  get f() {
    return this.referralForm.controls;
  }

  setReferralData() {
    if (this.referralTypes.employee === this.referralType) {
      this.referralForm.controls['referralBonus'].patchValue(this.referralContents?.referralBonusAmount);
      this.referralForm.controls?.['referralCurrency']?.patchValue(this.referralContents?.referralBonusCurrency);
    }
    if (this.referralTypes.agency === this.referralType) {
      this.referralForm.controls?.['referralPercentage']?.patchValue(this.referralContents?.referralPercentage);
      let bonusAmount = this.referralContents?.referralBonusAmount ? this.digitSeperatorInd(Number(this.referralContents?.referralBonusAmount)) : null;
      this.referralForm.controls?.['agencyReferralAmount']?.patchValue(bonusAmount);
      this.candidateCTC = this.digitSeperatorInd(this.referralContents?.candidateCTC);
      if (Number(this.referralContents?.candidateCTC) < 1) { this.disableAction = true; }
    }
  }

  getDropdownData() {
    this.subscriptions.push(
      this.appService.generalDropdowns.subscribe((data: any) => {
        const { Currency_choice = [] } = data;
        this.currencyList = Currency_choice;
      }, error => {
        console.log(error)
      }));
  }

  onNotifyUser() {
    this.notifyUser = !this.notifyUser;
  }


  formatInputAmount(field: string) {
    this.invalidBonus = '';
    let formattedValue = this.referralForm.controls[field].value;
    if (formattedValue) {
      if (!isNaN(formattedValue) && Number(formattedValue) > 0 && new RegExp(regex.integerNumber).test(formattedValue)) {
        this.invalidBonus = '';
        this.referralForm.controls?.[field]?.patchValue(formattedValue);
        this.invalidReferralBonus = false;
      } else {
        this.invalidBonus = 'Invalid Amount';
        this.invalidReferralBonus=true;
      }
    } else {
      this.invalidBonus = 'Required';
      this.invalidReferralBonus = true;
    }
  }

  formatCurrency(field: string) {
    let value = this.referralForm.controls[field].value;
    if (value && !this.invalidBonus && !isNaN(value.replace(/\,/g, ""))) {
      this.referralForm.controls?.[field]?.patchValue(value.replace(/\,/g, ""));
      this.invalidReferralBonus = false;
    } else if (value && Number(value) < 1){
      this.invalidBonus = 'Invalid Amount';
      this.invalidReferralBonus = true;
    }
    else if(!value){
      this.invalidBonus = 'Required';
      this.invalidReferralBonus = true;
    }
  }

  formatInputCurrency(field: string) {
    let value = this.referralForm.controls[field].value;
    if (value && !isNaN(value) && !this.invalidBonus) {
      const amount = this.digitSeperatorInd((Number(value)));
      this.referralForm.controls?.[field]?.patchValue(amount.toString());
      // enable if two way calculation is needed
      // const percentage = this.calculatePercentage(value, this.referralContents?.candidateCTC);
      // this.referralForm.controls?.['referralPercentage']?.patchValue(percentage);
    }
  }

  checkInvalidEmployeeReferral() {
    let invalid =false;
    if (this.referralForm.controls.referralBonus.invalid) {
      invalid= (this.referralForm.controls.referralBonus.invalid &&
        (this.referralForm.controls.referralBonus.hasError('pattern') ||
        this.referralForm.controls.referralBonus.hasError('required') ||
          this.referralForm.controls.referralBonus.hasError('max') ||
          this.referralForm.controls.referralBonus.hasError('min'))) ? true : false;
    }
    this.invalidReferralBonus =invalid;
  }

  calculateReferralBonus() {
    if (!this.f.referralPercentage.invalid) {
      const percentage = this.referralForm.controls['referralPercentage'].value;
      if (percentage && !isNaN(percentage) && Number(percentage) > 0) {
        const bonus = this.calculateBonus(percentage, this.referralContents?.candidateCTC);
        const amount = this.digitSeperatorInd(Number(bonus));
        this.referralForm.controls?.['agencyReferralAmount']?.patchValue(amount);
        this.invalidBonus = '';
      }
    }
  }

  calculatePercentage(bonus: any, ctc: any) {
    const percentage = (parseFloat(bonus) * 100) / parseFloat(ctc);
    return percentage.toFixed(3);
  }

  calculateBonus(percentage: any, ctc: any) {
    const bonus = (Number(ctc) * Number(percentage)) / 100;
    return Math.round(bonus);
  }

  onCancel() {
    this.hasClickedPreview=false;
    this.closePopup.emit();
  }

  formatReason(){
    let reason = this.referralForm.controls['reason']?.value
    reason && this.referralForm.controls['reason']?.patchValue(reason?.trim())
  }
  
  onSave() {
    this.referralForm.markAllAsTouched();
    if (this.referralForm.invalid || ((this.referralTypes.agency === this.referralType) && this.invalidBonus)) {
      return;
    }
    let payload: any;
    if (this.userEditedEmailContent && this.hasClickedPreview) {
      payload = { ...this.createPayloadData(), ...this.userEditedEmailContent, emailPreviewed: true };
    } else {
      payload = { ...this.createPayloadData(), emailPreviewed: false };
    }
    if (this.hasClickedPreview) {
      this.hasClickedPreview = false;
    }
    this.saveData.emit(payload);
  }

  createPayloadData(){
    const bonusData: any = JSON.parse(JSON.stringify(this.referralForm.value));
    if (this.action === this.actionTypes.reject) { bonusData['notifyUser'] = this.notifyUser }
    if (this.referralTypes.agency === this.referralType) { bonusData['agencyBonus'] = this.referralForm.controls['agencyReferralAmount'].value.replace(/\,/g, "") }
    return bonusData;
  }

  onPreview(){
    this.referralForm.markAllAsTouched();
    if (this.referralForm.invalid || ((this.referralTypes.agency === this.referralType) && this.invalidBonus)) {
      return;
    }
    this.hasClickedPreview=true;
    this.getEmailContent.emit(this.createPayloadData());
  }

  onClosePreview(event:any){
    this.hasClickedPreview=false;
    this.modalRef.close();
  }

  openModal(
      content: any,
      size: string,
      windowClass: string,
    ) {
    let modalObj: any = {
      size: size,
      centered: true,
      windowClass: windowClass,
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.open(content, modalObj)
    this.modalRef.result.then(
        (result:any) => {
         this.closeResult = `Closed with: ${result}`;
        },
        (reason:any) => {
          this.closeResult = reason;
        }
      );
    }

  onSendMailData(event:any){
    this.userEditedEmailContent={...event};
    this.onSave();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((item: any) => item.unsubscribe());
  }

}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { datetimeformat } from 'src/app/shared/_services/format';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../_services/util.service';

@Component({
  selector: 'app-activity-section',
  templateUrl: './activity-section.component.html',
  styleUrls: ['./activity-section.component.scss']
})
export class ActivitySectionComponent implements OnInit {
  private _comments: Array<Comment> = [];
  private _tabs: any = [
    { id: 'activity_log', label: 'Activity Log', tab: 1,  contentHeaderStyle: 'one-line'},
  ];
  @Input() candidateId: string = '';
  @Input() isDisabled = true;
  commentsForm!: FormGroup;
  dateTimeFormat: string = datetimeformat;
  activeTab: number = 1;
  activeTabDetails: any = { id: 'activity_log', label: 'Activity Log', tab: 1, contentHeaderStyle: 'one-line' };
  isInvalidComment: boolean = true;
  maxLengthAllowedForComment: number = 250;
  maxNumberOfLinesToDisplay: number = 10;
  @Output() commentData: EventEmitter<boolean> = new EventEmitter();
  @Output() commentUpdate: EventEmitter<any> = new EventEmitter();

  @Input() set comments(c: Array<Comment>) {
    this._comments = {...c};
    this.commentsForm?.reset();
    this.isInvalidComment = true;
    this.activeTab = 1;
  } get comments(): Array<Comment> {
    return this._comments;
  }

  @Input() set tabs(t: any) {
    this._tabs = t;
    this.activeTabDetails = t[0];
  } get tabs(): any {
    return this._tabs;
  }
  userEmail: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private utilService: UtilService,
  ) {
  }

  ngOnInit(): void {
    const formOptions: AbstractControlOptions = {};
    this.commentsForm = this.formBuilder.group({
      comment: ['', [Validators.maxLength(500),]]
    }, formOptions);
    this.userEmail = this.authService.getUser()?.email;
  }

  onSave() {
    this.commentsForm.markAllAsTouched();
    if (this.commentsForm.invalid || !this.commentsForm.value.comment.trim().length) {
      return;
    }
    this.commentsForm.controls['comment'].setValue(this.commentsForm.value.comment.trim());
    this.commentData.emit(this.commentsForm.value);

  }

  onTabChange(e: any) {
    this.activeTab = e;
    this.activeTabDetails = this.tabs[e - 1];
  }

  onCommentChange() {
    let commentLength = this.commentsForm.controls['comment']?.value?.trim()?.length
    this.isInvalidComment = commentLength == 0 || commentLength > 500;
  }

  getDisplayableContent(comment: any) {
    const charsInOneLine: number = this.maxLengthAllowedForComment / this.maxNumberOfLinesToDisplay;
    let total: number = 0;
    let indexToSlice: number = -1;

    let linesArray: string[] = comment.comment?.split("\n");
    linesArray.forEach((line: string) => {
      let charsRemaining: number = this.maxLengthAllowedForComment - total;
      let longer: number = line.length > charsInOneLine ? line.length : charsInOneLine;

      if (total < this.maxLengthAllowedForComment) {
        if (charsRemaining >= longer) {
          total += longer;
          indexToSlice += line.length;
        } else if (charsRemaining >= 0) {
          indexToSlice += charsRemaining;
        }
        indexToSlice += 1; // adding length of new line character
      }
    });

    comment.shrinked = indexToSlice < comment.comment.length;

    let breakPosition: number = comment.comment.slice(0,this.maxLengthAllowedForComment).lastIndexOf('>') === -1
      ? indexToSlice
      : comment.comment.slice(0,this.maxLengthAllowedForComment).lastIndexOf('>')
    return (comment.comment.slice(0, breakPosition) + (comment.comment.length > this.maxLengthAllowedForComment ? '... ' : ''));
  }

  openModal(modalRef: NgbModal, comment: any, type: string) {
    this.modalService.open(modalRef, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    }).result
    .then((result) => {
      if(result === type) {
        this.updateComment(comment, type)
      }
    }, () => {});
  }

  updateComment(comment: any, type: string) {
    this.commentUpdate.emit({
      commendId: comment.id,
      objectType: comment.object_type || null,
      type
    })
  }

  hideOrShowTooltip(event: any, activity_by: any, field: string) {
    this.utilService.hideOrShowTooltip(event, activity_by, field, false);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { Role } from '../../_models/role';
import { SearchParams } from '../../_models/utils';
import { GlobalStoreService } from '../../_services/global-store.service';
import { PAGE } from '../../_models/page.enum';

@Component({
  selector: 'filter-component',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() currentPage: string = "";
  @Input() roles: Role[] = [Role.HR, Role.HRM, Role.Director];
  @Input() set filterItems(data: any) {
    this.backupFilterItems = data;
  }
  get filterItems() {
    return this.reducedFilterItems;
  }

  @Input() set filterDataLoaded(dataLoaded: boolean) {
    if (dataLoaded === true) {
      this.reduceFilterData();
      this.virtualScrollEnabled = true;
    }
  }

  @Output() onFilterApply: EventEmitter<SearchParams> = new EventEmitter<SearchParams>();

  filtersSelected: any ={};
  filtersSelectedWithName: any = [];
  showFiltersSelectedWithName: any = [];
  isFilterOpen: boolean = false;
  reducedFilterItems: any[] = [];
  backupFilterItems: any[] = [];
  virtualBufferSize: number = 50;
  virtualScrollEnabled: boolean = true;
  filterLoading: boolean = false;
  currentSelectedFilter: any = [];
  currentBackupFilter: any = [];
  filterSearchTerm: string = '';
  input$ = new Subject<string>();
  selectedSortOption : any;
  orderBy: string = 'dsc';
  @ViewChildren('searchSelect') searchSelects!: NgSelectComponent[];

  constructor(
    private globalStore: GlobalStoreService,
  ) { }

  ngOnInit(): void {
    if (!this.virtualScrollEnabled) {
      this.reducedFilterItems = this.backupFilterItems;
    }

    this.setInitialFilters();
  }

  setInitialFilters() {
    let data = JSON.parse(this.globalStore.getFilters(this.currentPage));

    if (this.currentPage === PAGE.REPORTSCOMPLETEDINTERVIEWS && !Object.keys(data).length) {
      let filterData: any = {};
      filterData.sort = { id: 'interviews_completed', displayName: 'Completed Interviews' };
      filterData.orderBy = 'dsc';
      this.selectedSortOption = { id: 'interviews_completed', displayName: 'Completed Interviews' };
      this.orderBy = 'dsc';
      this.globalStore.setFilters(this.currentPage, JSON.stringify({ ...filterData }));
      data = JSON.parse(this.globalStore.getFilters(this.currentPage));
    }

      if (Object.keys(data).length) {
        this.filtersSelected = data.selected || {};
        this.filtersSelectedWithName = data.labels || [];
        this.selectedSortOption = data.sort || {};
        this.showFiltersSelectedWithName = [...this.filtersSelectedWithName];
      }
  }

  addOrRemoveFilterItem(key: string, value: string, itemName: string, displayTag: string | undefined, type: string, id1: string, id2: string, id_2: string) {
    const temp = {
      filterCategory: key,
      id: value,
      name: itemName,
      displayText: displayTag ? (displayTag + ': ' + itemName) : undefined
    }
    if (key in this.filtersSelected) {
      if (type === 'dropdown-select' || type ==='search-single-select') {
        //clear previous value
        this.filtersSelected[key] = [];
        const indexOfItemWithName = this.filtersSelectedWithName.findIndex((item: any) => item['filterCategory'] === key);
        this.filtersSelectedWithName.splice(indexOfItemWithName, 1);
      }

      if (this.filtersSelected[key].includes(value)) {
        const index = this.filtersSelected[key].indexOf(value);
        this.filtersSelected[key].splice(index, 1);
        const indexOfItemWithName = this.filtersSelectedWithName.findIndex((item: any) => item['id'] === value
          && item['filterCategory'] === key);
        this.filtersSelectedWithName.splice(indexOfItemWithName, 1);
        if (this.filtersSelected[key].length === 0) {
          delete this.filtersSelected[key];
        }
      }
      else {
        this.filtersSelected[key].push(value);
        this.filtersSelectedWithName.push(temp);
      }
    }
    else {
      this.filtersSelected[key] = [value];
      this.filtersSelectedWithName.push(temp);
    }
  }

  setDropDownSelectPlaceholder(placeholder: string, id: string): string {
    let selectedItemName: string = placeholder || 'Select an option';
    this.filtersSelectedWithName.forEach((item: any) => {
      if (item.filterCategory === id) {
        selectedItemName = item.name;
      }
    });
    return selectedItemName;
  }

  //reference to current search-multi-select filter that is being used
  setCurrentFilter(id: string) {
    this.currentSelectedFilter = this.reducedFilterItems.find(r => r?.id == id);
    this.currentBackupFilter = this.backupFilterItems.find(f => f?.id == id);
  }

  onVirtualScrollSearch(id: string) {
    this.setCurrentFilter(id);

    //subscribe to search tag change and filter out items from backup and set it as new list items in ng-select
    this.input$.subscribe((searchTerm: string) => {
      this.filterSearchTerm = searchTerm;
      this.currentSelectedFilter.values = [...this.currentBackupFilter.values]
      .filter((val: any) => val.email.includes(searchTerm));
    });
  }

  onFocus(searchSelect: NgSelectComponent, id: string) {
    searchSelect.handleClearClick();
    this.onVirtualScrollSearch(id);
  }

  onAdd(searchSelect: NgSelectComponent) {
    searchSelect.itemsList.clearSelected(true);
    searchSelect.searchTerm = "";
  }

  resetFilterListItems() {
    //reset values array for virtual scroll enabled filters, if it has been changed
    if(this.currentSelectedFilter?.values?.length) {
      this.reducedFilterItems.forEach((item: any) => {
        if (item.type == 'search-multi-select' || item.type == 'search-single-select') {
          item.values = [];
        }
      });
    }
  }

  clearSearchSelectInput() {
    this.searchSelects?.forEach((searchSelect: NgSelectComponent) => {
      searchSelect.handleClearClick();
    });
  }

  openFilterList(e: any) {
    this.isFilterOpen = !this.isFilterOpen;
    this.setDropdownPositionWRTButtonPosition(e.target.nextElementSibling);
  }

  setDropdownPositionWRTButtonPosition(filterListElem: any) {
    let filterListItems = Array.from(filterListElem.children[0].children);

    const filterBtn: any = document.querySelector('.add-filter-btn');
    const selectedFilterContainer: any = document.querySelector('.filters-container');
    const mainFilterContainer: any = document.querySelector('.applied-filters');
    const filterBtnRight: number = filterBtn.getBoundingClientRect()?.right;
    const selectedFilterContainerRight: number = selectedFilterContainer.getBoundingClientRect()?.right;
    const mainFilterContainerRight: number = mainFilterContainer.getBoundingClientRect()?.right;
    const btnToFilterContainerRatio: number = filterBtnRight / selectedFilterContainerRight;
    const selectedToMainFilterContainerRatio: number = selectedFilterContainerRight / mainFilterContainerRight;

    if ((btnToFilterContainerRatio > 0.6 && selectedToMainFilterContainerRatio > 0.7)) { //open to left
      if (!filterListElem.classList.contains('left-aligned')) {
        filterListElem.classList.add('left-aligned');

        filterListItems.forEach((listItem: any) => {
          listItem.classList.add('drop-left');
        })
      }
    } else { //open to right
      filterListElem.classList.remove('left-aligned');

      filterListItems.forEach((listItem: any) => {
        listItem.classList.remove('drop-left');
      })
    }
  }



  handleOutsideClick() {
    if (!this.isFilterOpen) {
      return;
    }

    this.isFilterOpen = false;

    this.filtersSelected = [];
    this.filtersSelectedWithName = [];

    this.resetFilterListItems();
    this.clearSearchSelectInput();

    //update filtersSelected and filtersSelectedWithName by taking values from current applied filters
    this.filtersSelectedWithName = JSON.parse(JSON.stringify(this.showFiltersSelectedWithName));
    this.showFiltersSelectedWithName.forEach((appliedFilter : any) => {
      if (appliedFilter != '') {
        let existingFilter = false;
        Object.keys(this.filtersSelected).forEach((selectedFilterName: string) => {
          //if the filterCategory is already in filtersSelected object, append the value to the previous array
          if (selectedFilterName == appliedFilter.filterCategory) {
            existingFilter = true;
            this.filtersSelected[selectedFilterName].push(appliedFilter.id);
          }
        });
        //if the filterCategory is not already in filtersSelected object, create a new key with the categoryname and assign value in array
        if (!existingFilter) {
          this.filtersSelected[appliedFilter.filterCategory] = [appliedFilter.id, ];
        }
      }
    });
  }

  reduceFilterData() {
    let temp: any = [];
    this.backupFilterItems.forEach((item: any) => {
      if(item) {
        temp.push({...item,
          values: (item.type == "search-multi-select" || item.type == "search-single-select")
            ? item.values?.slice(0, item.values.length > this.virtualBufferSize ? this.virtualBufferSize : item.values.length) || undefined
            : item.values});
      }
    });
    this.reducedFilterItems = temp;
  }

  clearFilter() {
    let hasSelectedValue = this.filtersSelected;
    this.filtersSelected = {};
    this.filtersSelectedWithName = [];
    this.showFiltersSelectedWithName = [];
    this.isFilterOpen = false;

    if (hasSelectedValue || JSON.parse(this.globalStore.getFilters(this.currentPage))?.selected) {
      this.handleSubmit();
    }
  }

  applyFilter() {
    this.handleSubmit();
  }


  formatAndEmitData() {
    let additionalParams: any = {};
    if (this.orderBy.length > 0 && this.selectedSortOption?.id) {
      additionalParams = { 'ordering': this.orderBy == 'dsc' ? ("-" + this.selectedSortOption?.id) : this.selectedSortOption?.id }
    }
    const data = {
      ...this.filtersSelected,
      ...additionalParams
    }
    this.onFilterApply.emit(data);
  }

  handleSubmit(e?: any) {
    e?.preventDefault();
    this.isFilterOpen = false;
    this.showFiltersSelectedWithName = [...this.filtersSelectedWithName];
    this.formatAndEmitData();

    let filterData: any = {};
    if(Object.keys(this.filtersSelected).length) {
      filterData['selected'] = this.filtersSelected;
    }

    if (this.filtersSelectedWithName.length > 0) {
      filterData['labels'] = this.filtersSelectedWithName;
    }

    if (Object.keys(this.selectedSortOption).length && this.selectedSortOption?.id !== '') {
      filterData['sort'] = this.selectedSortOption;
    }

      filterData['orderBy'] = this.orderBy;
     
    if (Object.keys(filterData).length) {
      this.globalStore.setFilters(this.currentPage, JSON.stringify({ ...filterData }));
    }
    else {
      this.globalStore.removeFilters(this.currentPage);
    }
    this.resetFilterListItems();
    this.clearSearchSelectInput();
  }
  

  onAddSingleSelect(displayValue: string = '', event: any, searchSelect: NgSelectComponent,item:any) {
    if (displayValue && event) {
      searchSelect.searchTerm = event?.[displayValue];
    } else {
      searchSelect.searchTerm = "";
    }  
  }
}

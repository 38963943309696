import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { take } from "rxjs/operators";

import { AuthService } from "../auth/auth.service";
import { LoaderService } from "../shared/_components/loader/loader.service";
import { UtilService } from './../shared/_services/util.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private injector: Injector,
        private authService: AuthService,
        private utilService: UtilService,
        private loaderService: LoaderService,
        ) { }

    handleError(error: any): void {
        let toaster = this.injector.get(ToastrService);
        // For unauthorized cases other than from API
        // For eg: if token is deleted from localStorage
        if (!this.authService.isAuthorized() && error.status !== 401) {
          toaster.error(`Your session has expired. Please login`);
          this.utilService.closeModals();
          setTimeout(() => {
            this.loaderService.show();
          }, 0);
          setTimeout(() => {
              this.loaderService.hide();
              this.authService.logout();
          }, 4500); //delay for toaster before redirecting to keycloak login page
        }
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if(!error?.message?.length) return
        if (chunkFailedMessage.test(error.message)) {
            toaster.warning('New version available. Please refresh the page or click here to refresh')
                .onTap
                .pipe(take(1))
                .subscribe(() => { window.location.reload(); });
        }
        console.error(error);
    }
}
export const defaultStatusColor = "#42a7fe";
export const red50 = "rgba(255, 0, 0, 0.5)";
import * as dayjs from "dayjs";
import { Role } from "../shared/_models/role";

export const statusColors = [
  {
    status: "N",
    color: "#3faaff",
  },
  {
    status: "S",
    color: "#00a400",
  },
];

export const orderByItems = [
  { displayName: "First name", id: "first_name" },
  { displayName: "Applied on", id: "reapplied_date"},
  // will uncomment this once the API is set to accept the folowing key
  // { "displayName": "Experience", "id": "experience" },
  { displayName: "Last updated on", id: "last_modified_at_qrms" },
];

export const items = [
  {
    displayName: "Estimated Experience",
    dropdownKey: "name",
    type: "single-select",
    source: "",
    id: "is_estimated_exp",
    apiKey: "",
    values: [{ id: "1", name: "Estimated Experience" }],
  },
  {
    displayName: "Status",
    dropdownKey: "name",
    type: "multi-select",
    source: "dropdown-api",
    id: "status",
    apiKey: "Candidate_status",
  },
  {
    displayName: "Source",
    dropdownKey: "name",
    type: "multi-select",
    source: "dropdown-api",
    id: "source",
    apiKey: "Candidate_source",
  },
  {
    displayName: "Job Code",
    dropdownKey: "name",
    type: "multi-select",
    source: "",
    id: "job_code",
    apiKey: "Job_codes",
  },
  {
    displayName: "Department",
    dropdownKey: "name",
    type: "multi-select",
    source: "dropdown-api",
    id: "department",
    apiKey: "Department",
  },
  {
    displayName: 'Stream',
    dropdownKey: "name",
    type: "filter-multi-select",
    source: "dropdown-api",
    id: "stream",
    apiKey: "Stream",
    placeholder: "Search Stream",
    filterDisplayKey: "name",
  },
  {
    displayName: "Applied On",
    dropdownKey: "",
    type: "date-range",
    source: "custom",
    id: "rcd_on",
    id_1: "applied_on_from",
    id_2: "applied_on_to",
    apiKey: "",
    min_date: dayjs.Dayjs,
    max_date: dayjs().endOf("day"),
  },
  {
    displayName: "Notice Period",
    dropdownKey: "name",
    type: "multi-select",
    source: "dropdown-api",
    id: "notice_period",
    apiKey: "notice_period",
  },
  {
    displayName: "Last Updated On",
    dropdownKey: "",
    type: "date-range",
    source: "custom",
    id: "lst_updated",
    id_1: "last_modified_at_from",
    id_2: "last_modified_at_to",
    apiKey: "",
    min_date: dayjs.Dayjs,
    max_date: dayjs().endOf("day"),
  },
  {
    displayName: "Experience",
    dropdownKey: "name",
    type: "multi-input",
    source: "",
    id: "experience",
    apiKey: "",
  },
  {
    displayName: 'Candidate Type',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'custom',
    id: 'candidate_type',
    apiKey: 'CandidateTypes',
    values: [
      {
        id: 'fresher',
        name: 'Freshers',
      },
      {
        id: 'lateral',
        name: 'Laterals',
      },
    ]
  },
  {
    displayName: "10th %",
    dropdownKey: "name",
    type: "dropdown-select",
    dropdownName: "Select Percentage",
    displayTag: "10th %",
    source: "",
    id: "tenth_percent",
    apiKey: "",
    values: [
      { name: "Above 80%", id: "80" },
      { name: "Above 70%", id: "70" },
      { name: "Above 60%", id: "60" },
      // will uncomment this once the API is set to accept the folowing key
      // { "name": "Below 60%", "id": "60" }
    ],
  },
  {
    displayName: "12th %",
    dropdownKey: "name",
    type: "dropdown-select",
    dropdownName: "Select Percentage",
    displayTag: "12th %",
    source: "",
    id: "twelfth_percent",
    apiKey: "",
    values: [
      { name: "Above 80%", id: "80" },
      { name: "Above 70%", id: "70" },
      { name: "Above 60%", id: "60" },
    ],
  },
  {
    displayName: "Degree %",
    dropdownKey: "name",
    type: "dropdown-select",
    dropdownName: "Select Percentage",
    displayTag: "Degree %",
    source: "",
    id: "degree_percent",
    apiKey: "",
    values: [
      { name: "Above 80%", id: "80" },
      { name: "Above 70%", id: "70" },
      { name: "Above 60%", id: "60" },
    ],
  },
  {
    displayName: "Parsing Status",
    dropdownKey: "name",
    type: "multi-select",
    source: "dropdown-api",
    id: "parsing_status",
    apiKey: "Parsing_status_choice",
    role: [Role.Director, Role.HR, Role.HRM, Role.RM],
  },
];

export const resumeActions = [
  {
    id: "email",
    icon: "./assets/images/mail-blue.svg",
    label: "Email",
  },
  {
    id: "schedule",
    icon: "./assets/images/schedule.svg",
    label: "Schedule interview",
  },
  {
    id: "download",
    icon: "./assets/images/download.svg",
    label: "Download resume",
  },
  {
    id: "edit",
    icon: "./assets/images/edit.svg",
    label: "Edit candidate",
    isAllowed: true,
  },
  {
    id: "CH",
    icon: "./assets/images/hold.svg",
    label: "On hold",
  },
  {
    id: "SL",
    icon: "./assets/images/resume-and-cv.svg",
    label: "Shortlist",
  },
  {
    id: "RJ",
    icon: "./assets/images/remove.svg",
    label: "Reject",
  },
];

export const hiringStatusColors = [
  {
    status: "S",
    color: "#ffbd02",
    status_name: "Offer Letter Sent",
    font: "#000",
  },
  {
    status: "D",
    color: "#ff0000",
    status_name: "Offer Declined",
    font: "#fff",
  },
  {
    status: "OR",
    color: "#999999",
    status_name: "Offer Revoked",
    font: "#fff",
  },
  {
    status: "N",
    color: "#3faaff",
    status_name: "New",
    font: "#fff",
  },
  {
    status: "OA",
    color: "#1b8c03",
    status_name: "Offer Accepted",
    font: "#fff",
  },
  {
    status: "SOA",
    color: "#555555",
    status_name: "Awaiting Approval",
    font: "#fff",
  },
  {
    status: "OAP",
    color: "#1b8c03",
    status_name: "Offer Approved",
    font: "#fff",
  },
  {
    status: "OD",
    color: "#ff0000",
    status_name: "Approval Denied",
    font: "#fff",
  },
];
 
export const recommendation = [
  {
    id: "CAN_HIRE",
    name: "Can be hired",
    interviewLevel: ["T", "M"]
  },
  {
    id: "ANT_RND",
    name: "Recommended for another round",
    interviewLevel: ["T"]
  },
  {
    id: "ANT_TEC",
    name: "Can be considered for another technology",
    interviewLevel: ["T"]
  },
  {
    id: "NO_QB_STD",
    name: "Not meeting QB standards",
    interviewLevel: ["T"]
  },
  {
    id: "REJ",
    name: "Rejected after interview",
    interviewLevel: ["M"]
  },
  {
    id: "ON_HOLD",
    name: "On hold after interview",
    interviewLevel: ["T", "M"]
  },
  {
    id: "ANT_RND ",
    name: "One more round",
    interviewLevel: ["M"]
  },
];

export const supportedFilesForResumes = ['.pdf', '.odt', '.odx', '.png', '.jpg' , '.jpeg', '.doc', '.docx', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text', 'image/png', 'image/jpeg', 'image/jpg', 'application/msword']
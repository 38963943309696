import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { IDatePickerDirectiveConfig } from 'ng2-date-picker'
import { DatePipe } from '@angular/common'

const modeToFormat: any = {
  day: {
    input: 'DD-MM-YYYY',
    output: 'MM-dd-yyyy'
  },
  month: {
    input: 'MM',
    output: 'MM'
  } ,
  time: {
    input: 'HH:mm',
    output: 'HH:mm'
  } ,
  daytime: {
    input: 'DD-MM-YYYY HH:mm',
    output: 'MM-dd-yyyy HH:mm'
  } 
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
})

export class DatepickerComponent implements OnInit {

  dpForm!: FormGroup;
  dpConfig: IDatePickerDirectiveConfig = {};

  @Input() mode: string = 'day';
  @Input() displayDate?: Moment | string;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() validate: boolean = false;
  @Input() touched?: boolean = false;

  @Output() inputChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.dpForm = this.fb.group({
      selected: ['', [Validators.required]]
    });
    this.setDatePickerConfig();
  }

  get f() { return this.dpForm.controls; }

  setDatePickerConfig() {
    this.dpConfig = {
      disableKeypress: true,
      format: modeToFormat[this.mode]['input'],
    }
  }

  onChange($event: any) {
    const val = this.dpForm.value.selected ?
      this.datePipe.transform($event._d, modeToFormat[this.mode]['output']) : ''
    this.inputChanged.emit(val);
  }

}

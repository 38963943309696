import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ResumeService } from '../../../resume/resume.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnInit {
  selectForm!: FormGroup;

  @Input() multiple: boolean = false;
  @Input() placeholder: string = '';
  @Input() list: Array<any> = [];
  @Output() inputChanged: EventEmitter<File> = new EventEmitter();


  constructor(
    private fb: FormBuilder,
    private resumeService: ResumeService,
  ) {
  }

  ngOnInit() {
    this.selectForm = this.fb.group({
      selected: []
    });
  }

  onChange($event: any) {
    this.inputChanged.emit($event);
  }

}

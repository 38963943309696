import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import * as dayjs from 'dayjs';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Role } from '../../_models/role';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-single-date-picker-component',
    templateUrl: './single-date-picker.component.html',
    styleUrls: ['./single-date-picker.component.scss'],

})
export class SingleDatePickerComponent {
    @Input() item: any = {};
    @Input() border: boolean = false;
    @Input() disabled: boolean = false;
    @Input() tabIndex: number | null = null;
    @Input() roles: Role[] = [Role.CL, Role.Director, Role.HR, Role.HRM, Role.PM, Role.Staff, Role.VT];
    @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();
    @Output() ngSelectedDate: EventEmitter<any> = new EventEmitter<any>();
    @Output() isCleared: EventEmitter<any> = new EventEmitter<any>();
    @Input() datePickerId: string = '';
    @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective!: DaterangepickerDirective;
    
    focusElement: boolean = false;
    tabFocusBorder: string = '1px solid #8f8f8f';
    inlineDate: any;
    inlineDateTime: any;
    picker!: DaterangepickerComponent;
    locale: any = {
        applyLabel: 'Apply', format: 'MMM DD, YYYY'
    };
    dropdownPosition: string = 'down';
    constructor(
        private authService: AuthService
    ) { }

    //Default function of Date range picker which triggers on input change.
    ngModelChange(e: any, id: string) {
        let date: any = {};
        date[id] = dayjs(e.endDate).format("YYYY-MM-DD")
        this.ngSelectedDate.emit(date);
    }
    change(e: any, id: string) {
        let dateData: any = {};
        dateData[id] = dayjs(e.endDate).format("YYYY-MM-DD")
        this.selectedDate.emit(dateData);
    }
    choosedDate(e: any) {
        this.inlineDate = e;
    }

    choosedDateTime(e: any) {
        this.inlineDateTime = e;
    }
    open(e: any) {
      this.pickerDirective.open(e);  
    }
    clear(e: any) {
        this.picker.clear();
        this.isCleared.emit(true);
    }

    onKeyDown(e: KeyboardEvent) {
        if (e?.code == "Enter") {
            this.open(e);
        }
    }
    onFocusElement(event: KeyboardEvent) {
        if (event?.code == 'Tab') {
            this.focusElement = true;
        }
    }

    removeFocus() {
        this.focusElement = false;
    }

    setDropdownPosition() {
        const datePickerElement = document.getElementById(this.datePickerId) as any;
        if ((window.innerHeight - Number(datePickerElement.getBoundingClientRect().bottom)) < 300) {
            this.dropdownPosition = 'up';
        } else {
            this.dropdownPosition = 'down';
        }
    }

    setFocus() {
        this.pickerDirective.viewContainerRef?.element?.nativeElement?.focus();
    }
}

<div class="referral-bonus-popup-wrapper custom-style-ng-select referral-bonus-popup" tabindex="0">
    <form [formGroup]="referralForm">
        <div class="referral-title mb-3 mx-1">{{action | titlecase }} Referral Bonus</div>
        <div class="referral-sub-title mb-2 mx-1 d-flex align-items-center">
            <span class="ref-name  mx-1">{{referralContents?.referrerName | titlecase}} {{referralContents?.agencyLocation | titlecase}}</span>
            <span class="ref-email ms-2 align-self-end">{{referralContents?.email}}</span>
        </div>
        <div class="referral-bonus-contents">
            <div class="ref-row mb-3">
                <label class="ref-label "> Referred Candidate </label>
                <div class="ref-item"> {{referralContents?.candidateName | titlecase }} </div>
            </div>
            <div *ngIf="referralContents?.experience" class="ref-row mb-3">
                <label class="ref-label "> Experience </label>
                <div class="ref-item">{{referralContents?.experience}} </div>
            </div>
            <div *ngIf="referralContents?.designation" class="ref-row mb-3">
                <label class="ref-label "> Designation </label>
                <div class="ref-item">{{referralContents?.designation}} </div>
            </div>
            <div class="ref-row mb-3" *ngIf="referralContents?.joinedOn">
                <label class="ref-label"> Joined On </label>
                <div class="ref-item">{{referralContents?.joinedOn | date: dateformat}} </div>
            </div>
            <div class="ref-row mb-3" *ngIf="referralContents?.location">
                <label class="ref-label"> Location </label>
                <div class="ref-item">{{referralContents?.location?.name}} </div>
            </div>
            <div class="ref-row mb-3" *ngIf="referralContents?.refferedOn">
                <label class="ref-label "> Referred On </label>
                <div class="ref-item">{{referralContents?.refferedOn | date: dateformat}}</div>
            </div>
            <ng-container *ngIf="referralTypes.employee===referralType">
                <div class="ref-row mb-3">
                    <label class="ref-label align-self-center" *ngIf="referralTypes.employee===referralType"> Bonus Amount </label>
                    <label class="ref-label align-self-center" *ngIf="referralTypes.agency===referralType"> Referral Amount </label>
                    <div class="ref-item d-flex gap-3">
                        <div class="input-flex-30 input-border input-selection ref-input d-flex align-items-center">
                            <ng-select [items]="currencyList" formControlName="referralCurrency" class="w-100 remove-border border-0" bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false" [readonly]="true">
                            </ng-select>
                        </div>
                        <div class="input-flex-01 position-relative">
                            <div class="ref-input input-border d-flex align-items-center px-2 input-flex-01">
                                <input autocomplete="off" (keyup)="checkInvalidEmployeeReferral()" formControlName="referralBonus" name="referralBonus" type="text" class="hide-arrow w-100 o-none border-0">
                            </div>
                            <div *ngIf="f.referralBonus.touched && f.referralBonus.invalid" class="ref-error input-flex-01 flex-wrap position-absolute">
                                <ng-container *ngIf="f.referralBonus.errors?.required">Required</ng-container>
                                <ng-container *ngIf="f.referralBonus.errors?.pattern || f.referralBonus.errors?.max || f.referralBonus.errors?.min">
                                    Invalid Amount</ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="referralTypes.agency===referralType">
                <div class="ref-row mb-3 align-items-center">
                    <label class="ref-label"> Billing % </label>
                    <div class="ref-item position-relative">
                        <div class="d-flex align-items-baseline">
                            <div class="ref-input input-border ref-md-input d-flex align-items-center px-2">
                                <input type="text" formControlName="referralPercentage" (change)="calculateReferralBonus()" name="referralPercentage" class="w-100 border-0" autocomplete="off">
                                <span class="ms-1"><small>%</small></span>
                            </div>
                            <span class="ms-2">of Offered CTC {{ referralContents?.CTCCurrency }} {{candidateCTC}}/Annum</span>
                        </div>
                        <div *ngIf="f.referralPercentage.touched && f.referralPercentage.invalid" class="ref-error input-flex-01 flex-wrap position-absolute">
                            <ng-container *ngIf="f.referralPercentage.errors?.required">Required</ng-container>
                            <ng-container *ngIf="f.referralPercentage.errors?.pattern || f.referralPercentage.errors?.min">Invalid Referral Percentage </ng-container>
                        </div>
                    </div>
                </div>
                <div class="ref-row mb-3 align-items-center">
                    <label class="ref-label" *ngIf="referralTypes.employee===referralType"> Bonus Amount </label>
                    <label class="ref-label" *ngIf="referralTypes.agency===referralType"> Referral Amount </label>
                    <div class="position-relative">
                        <div class="ref-input input-border d-flex align-items-center px-2 ref-lg-input">
                            <span class="pe-2 ref-prefix"><small>INR</small></span>
                            <input type="text" formControlName="agencyReferralAmount" autocomplete="off" (focus)="formatCurrency('agencyReferralAmount')" (blur)="formatInputCurrency('agencyReferralAmount')" (keyup)="formatInputAmount('agencyReferralAmount')" name="agencyReferralAmount"
                                class="w-100 border-0 px-2">
                        </div>
                        <div *ngIf="f.agencyReferralAmount.touched" class="ref-error input-flex-01 flex-wrap position-absolute">
                            <ng-container *ngIf="invalidBonus">{{invalidBonus}}</ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="actionTypes.reject===action">
                <div class="ref-row mb-3">
                    <label class="ref-label "> Reason </label>
                    <div class="ref-item">
                        <textarea rows="2" class="txt-area" name="reason" formControlName="reason" (blur)="formatReason()"></textarea>
                        <div *ngIf="f.reason.touched && f.reason.invalid" class="ref-error input-flex-01 flex-wrap">
                            <ng-container *ngIf="f.reason.errors?.required">Required</ng-container>
                            <ng-container *ngIf="f['reason'].hasError('maxlength')">Max character allowed is {{reasonMaxLength}}</ng-container>
                        </div>
                        <div class="d-flex">
                            <app-checkbox [checked]="notifyUser" (toggle)="onNotifyUser()"></app-checkbox>
                            <span class="ms-2">Notify {{referralType | titlecase}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="ref-action d-flex justify-content-center mt-3">
            <button type="button" class="col-sm-5 btn button-cancel" (click)="onCancel()" [ngClass]="{'me-4': !(notifyUser && (referralTypes.agency===referralType || actionTypes.reject===action))}">{{'cancel'| uppercase
        }}</button>
        <button *ngIf="(notifyUser && (referralTypes.agency===referralType || actionTypes.reject===action))" type="button" [disabled]="disableAction" class="col-sm-5 mx-2 btn button-save blue-btn-shadow" (click)="onPreview()">{{'preview' |
            uppercase}}</button>
            <button type="button" [disabled]="disableAction" class="col-sm-5 btn button-save red-btn red-btn-shadow" (click)="onSave()">{{action | uppercase}}</button>
        </div>
    </form>
</div>

<!-- email preview on reject -->
<ng-template #emailPopup let-modal>
    <app-email-popup [openModal]="true" [popUpContents]="emailPopupContent" [hasMultipleCcAndBcc]="hasMultipleCcAndBcc" [canRemoveTo]="false"
        (closePopup)="onClosePreview($event)" [emailUserType]="emailUser" (sendData)="onSendMailData($event)" [emailBtnName]="mailContent?.emailBtnName">
    </app-email-popup>
</ng-template>
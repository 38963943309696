import { Role } from "../shared/_models/role";
import * as dayjs from 'dayjs';
import { PAGE } from "../shared/_models/page.enum";


export const red50 = 'rgba(255, 0, 0, 0.5)';
export const darkgrey = "#737373";
export const greyishBrown = "#595959";
export const greytext = '#4c4c4c';
export const whiteTwo = '#ffffff';

export const statusColors = [
  {
    status: 'APR',
    color: '#1b8c03'
  },
  {
    status: 'NEW',
    color: '#3faaff'
  },
  {
    status: 'CAN',
    color: '#ff0000'
  },
  {
    status: 'CLS',
    color: '#741B47'
  },
  {
    status: '',
    color: '#3faaff'
  }
];

export const status = [
  {
    id: 'APR',
    name: 'Approved'
  },
  {
    id: 'NEW',
    name: 'Un-Approved'
  },
  {
    id: 'CLS',
    name: 'Closed'
  },
  {
    id: 'CAN',
    name: 'Cancelled'
  }
];

export const priority =
  [
    {
      id: 'L',
      name: 'Low'
    },
    {
      id: 'M',
      name: 'Medium'
    },
    {
      id: 'H',
      name: 'High'
    }
  ];

export const filterItems =
  [
    {
      "displayName": "Status",
      "dropdownKey": "name",
      "type": "multi-select",
      "source": "dropdown-api",
      "id": "status",
      "apiKey": "Request_status_choice",
      "priority": 0,

    },
    {
      "displayName": "Requested Date",
      "type": "date-range",
      "source": "dropdown-api",
      "id": "req_date",
      "min_date": dayjs.Dayjs,
      "max_date": dayjs(),
      "id_1": "created_at_from",
      "id_2": "created_at_to",
      "apiKey": "",
      "priority": 1,
      // "role": [Role.Director, Role.HR, Role.HRM]

    },
    {
      "displayName": "Location",
      "dropdownKey": "display_name",
      "type": "multi-select",
      "source": "dropdown-api",
      "id": "location",
      "apiKey": "Job_location",
      "priority": 2,
      // "role": [Role.Director, Role.HR, Role.HRM]
    },

    {
      "displayName": "Owner",
      "dropdownKey": "email",
      "filterDisplayKey": "username",
      "displayTag": "Owner",
      "type": "search-multi-select",
      "placeholder": "Search Owner",
      "source": "dropdown-api",
      "id": "owner",
      "apiKey": "HR_users",
      "priority": 3,
      "role": [Role.HR, Role.HRM, Role.Director]

    },
    {
      "displayName": "Department",
      "dropdownKey": "name",
      "type": "multi-select",
      "source": "dropdown-api",
      "id": "department",
      "apiKey": "Department",
      "priority": 4,
      // "role": [Role.Director, Role.HR, Role.HRM]

    },
    {
      "displayName": "Designation",
      "dropdownKey": "name",
      "type": "filter-multi-select",
      "source": "dropdown-api",
      "id": "designation",
      "apiKey": "Designation",
      "placeholder": "Designation",
      "filterDisplayKey": "name",
      "priority": 5,
      // "role": [Role.Director, Role.HR, Role.HRM]

    },
    {
      "displayName": "Priority",
      "dropdownKey": "name",
      "type": "multi-select",
      "source": "dropdown-api",
      "id": "priority",
      "apiKey": "Priority_choice",
      "priority": 6,
      // "role": [Role.Director, Role.HR, Role.HRM]

    },
    {
      "displayName": "Experience",
      "type": "multi-input",
      "source": "dropdown-api",
      "id": "experience",
      "apiKey": "",
      "priority": 7,
      // "role": [Role.Director, Role.HR, Role.HRM]


    },
    {
      "displayName": "Approved By",
      "dropdownKey": "email",
      "filterDisplayKey": "username",
      "displayTag": "Approved By",
      "type": "search-multi-select",
      "source": "dropdown-api",
      "id": "approved_by",
      "placeholder": "Search Approved By",
      "apiKey": "HR_users",
      "priority": 8,
      "role": [Role.HR, Role.HRM, Role.Director]
    },
    {
      "displayName": "Requested By",
      "dropdownKey": "email",
      "filterDisplayKey": "username",
      "displayTag": "Requested By",
      "type": "search-multi-select",
      "source": "space-api",
      "id": "requested_by",
      "placeholder": "Search Requested By",
      "apiKey": "",
      "priority": 9,
      "role": [Role.HR, Role.HRM, Role.Director]
    }
  ];


export const orderByItems = [
  { "displayName": "Requested Date", "id": "created_at" },
  { "displayName": "Last Updated On", "id": "last_modified_at_qrms" },
];

export const jobposActions = [
  {
    id: 'approve',
    icon: './assets/approve.svg',
    label: 'Approve',
    role: [Role.HRM, Role.HR, Role.Director]
  },
  {
    id: 'edit',
    icon: './assets/edit-icon.svg',
    label: 'Edit Job Post',
    role: [Role.HRM, Role.HR, Role.Director]
  },
  {
    id: 'refer',
    icon: './assets/refer.svg',
    label: 'Refer a Friend'
  },
  {
    id: 'publish',
    icon: './assets/upload-active.svg',
    label: 'Publish Job Post',
    role: [Role.HRM]
  }
]

export const cancelAction = {
  id: 'cancel',
  label: 'Cancel',
  isAllowed: true,
  status: 'CAN',
  successText: 'cancelled',
};

export const closeAction = {
  id: 'close',
  label: 'Close',
  isAllowed: true,
  status: 'CLS',
  successText: 'closed',
};

export const deleteAction = {
  id: 'delete',
  label: 'Delete',
  isAllowed: true,
  status: 'DEL',
  successText: 'deleted',
};

export const fitColors = [
  {
    name: 'Best Fit',
    color: '#ffffff',
    bgcolor: '#6AA2D0'
  },
  {
    name: 'Good Fit',
    color: '#565656',
    bgcolor: '#F6E334'
  },
  {
    name: 'Medium Fit',
    color: '#ffffff',
    bgcolor: '#EF6B6B'
  },
  {
    name: 'Low Fit',
    color: '#ffffff',
    bgcolor: '#E82B2B'
  },
];

export const  matchingCandidateColor = {
  color: '#fff',
  bgcolor: '#00C614'
}

export const JobPostWorkbookPageAccessPermission: any = {
  [PAGE.WORKBOOKOVERVIEW]: ['NEW', 'APR', 'CLS', 'CAN'],
  [PAGE.JOBPOSTDETAILS]: ['NEW', 'APR', 'CLS', 'CAN'],
  [PAGE.JOBPOSTAGENCIES]: ['APR'],
  [PAGE.JOBPOSTCANDIDATES]: ['APR', 'CLS'],
  [PAGE.WORKBOOKSCHEDULEINTERVIEW]:['APR'],
  [PAGE.WORKBOOKINTERVIEWERDRIVEN]: ['APR'],
  [PAGE.WORKBOOKSENDCALLLETTER]: ['APR'],
  [PAGE.WORKBOOKSENDOFFERLETTER]: ['APR'],
  [PAGE.WORKBOOKREQUESTRATING]: ['APR'],
  [PAGE.WORKBOOKCREATECANDIDATE]: ['APR'],
  [PAGE.WORKBOOKSALARYDOC]: ['APR'],
  [PAGE.JOBPOSTCOMMENTS]: ['NEW', 'APR', 'CLS', 'CAN'],
  [PAGE.WORKBOOKHISTORY]: ['NEW', 'APR', 'CLS', 'CAN'],
}

export const SkillLevels: any = {
  EXPERT: 'Expert Level',
  PROFICIENT: 'Proficient Level',
  COMPETENT: 'Competent Level',
  OTHER: 'Other',
}
import { Component, OnInit, Output, Input, EventEmitter, ViewChildren } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select'
import { FilterItems } from '../../_models/filter-item.model';
import { Role } from '../../_models/role';

import { searchedLabels } from './../../_constants/constants';
import { FiltersSelectedWithName as filtersSelectedWithName } from './../../_models/filterSelectedWithname';

import {
  SearchParams,
} from './../../../shared/_models/utils';
import { dynamicDropdowns, recommendationChoice } from './constants';
import * as dayjs from 'dayjs';
import { GlobalStoreService } from '../../_services/global-store.service';
import { PAGE } from '../../_models/page.enum';
import { AuthService } from 'src/app/auth/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'search-sort-filter',
  templateUrl: './search-sort-filter.component.html',
  styleUrls: ['./search-sort-filter.component.scss']
})
export class SearchSortFilterComponent implements OnInit {

  filtersForm!: FormGroup;
  isFilterOpen: boolean = false;
  filtersSelected: any = {};
  filtersSelectedWithName: [any] = [''];
  showFiltersSelectedWithName: [any] = [''];
  orderBy: string = 'asc';
  selectedSortOption: any;
  filterErrors: any = {};
  selectedDaterange: any = {};
  searchedLabels: any;
  searchBarTags: any = {};
  allowedTagsLength: number = 80;
  reducedFilterItems: any[] = [];
  backupFilterItems: any[] = [];
  virtualBufferSize: number = 50;
  virtualScrollEnabled: boolean = false;
  filterLoading: boolean = false;
  currentSelectedFilter: any = [];
  currentBackupFilter: any = [];
  filterSearchTerm: string = '';
  datePickerIds: string[] = ['req_date', 'rcd_on', 'lst_updated', 'sch_date', 'join_date'];
  input$ = new Subject<string>();
  Role = Role;
  recommendationChoice = recommendationChoice;
  userRole: any;
  getAppliedFiltersPermissionCheck: boolean = false;
  isInitialCandidateLoad: boolean = false;
  onClearCandidateFilters: boolean = false;
  appliedTheme: string = '';
  @ViewChildren('searchSelect') searchSelects!: NgSelectComponent[];
  @ViewChildren('dateRangePicker') dateRangePickers!: any[];

  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearchWithFilter: EventEmitter<SearchParams> = new EventEmitter<SearchParams>();
  @Output() updateSearchParamOnly: EventEmitter<SearchParams> = new EventEmitter<SearchParams>();

  @Input() orderByItems: [any] = [null];
  @Input() searchInputPlaceholder: string = '';
  searchQuery: any;
  @Input() roles: Role[] = [Role.CL, Role.Director, Role.HR, Role.HRM, Role.PM, Role.Staff, Role.VT, Role.RM];
  @Input() currentPage = "";
  @Input() dateFilterData: any = {};
  @Input() isFilterHidden: boolean = false;
  @Input() isSortHidden: boolean = false;
  @Input() mustShowAppliedFilters: boolean = false;
  @Input() disableSort: boolean = false;

  @Input() set searchedTags(data: any) {
    if (data) {
      this.setSearchedTags(data);
    }
  }

  @Input() set filterItems(data: any) {
    this.backupFilterItems = data;
  }
  get filterItems() {
    return this.reducedFilterItems;
  }

  @Input() set filterDataLoaded(dataLoaded: boolean) {
    if (dataLoaded === true) {
      this.reduceFilterData();
      this.virtualScrollEnabled = true;
    }
  }

  applied_on_from: any;
  applied_on_to: any;

  constructor(
    private formBuilder: FormBuilder,
    private globalStorage: GlobalStoreService,
    private authService: AuthService
  ) {
    this.selectedSortOption = {
      id: '',
      displayName: 'Select Option'
    };
    this.searchedLabels = searchedLabels;
    this.userRole = this.authService.getUser()?.role;


    this.globalStorage.quickSearchQuery$.subscribe(res => {
      if (this.currentPage == PAGE.CANDIDATES) {
        this.searchQuery = res;
        if (this.searchQuery) {
          this.setSearchQueryFilters();
          this.init();
        }
      }
    });
  }

  ngOnInit(): void {
    this.init();
    this.checkScreenSize();
    this.getAppliedFiltersPermission();
    this.getAppliedTheme();
  }

  init() {
    if (!this.virtualScrollEnabled) {
      this.reducedFilterItems = this.backupFilterItems;
    }
    const formOptions: AbstractControlOptions = {};
    this.filtersForm = this.formBuilder.group({
      search: [this.searchQuery ? this.searchQuery : ''],
      ordering: null,
      selected: [],
    }, formOptions);
    this.setInitialFilters();
    
  }

  ngAfterViewInit(): void {
    document.getElementById('searchKeyword')?.focus();
  }

  checkScreenSize() {
    this.allowedTagsLength = window.screen.width / 50 + 16;
  }

  setSearchQueryFilters() {
    let filterData: any = {};
    filterData.orderBy = 'dsc';
    filterData.sort = { displayName: "Applied on", id: "reapplied_date" };
    filterData.search = this.searchQuery;
    this.globalStorage.setFilters(this.currentPage, JSON.stringify({ ...filterData }));
  }

  reduceFilterData() {
    let temp: any = [];
    this.backupFilterItems.forEach((item: any) => {
      if (item) {
        const sliceConditionValue = item.values?.length && ((item.values.length > this.virtualBufferSize) ? this.virtualBufferSize : item.values.length);
        temp.push({
          ...item,
          values: item.type == "search-multi-select"
            ? item.values?.slice(0, sliceConditionValue) || undefined
            : item.values
        });
      }
    });

    this.reducedFilterItems = temp;
  }
  setInitialFilters() {
    let data = JSON.parse(this.globalStorage.getFilters(this.currentPage));

  if (!Object.keys(data).length) {
    data = this.setDefaultFilters();
  }
    if (data) {
      this.setDateRangeFilters(data);

      if (Object.keys(data).length) {
        setTimeout(() => {
          this.filtersForm?.controls['search'].setValue(data.search || '');

          this.filtersSelected = data.selected || {};
          this.filtersSelectedWithName = data.labels || [''];

          this.showFiltersSelectedWithName = [...this.filtersSelectedWithName];
          this.orderBy = data.orderBy || 'asc';
          this.selectedSortOption = data.sort || { id: '', displayName: 'Select Option' };
          this.filtersForm?.controls['ordering'].setValue(this.selectedSortOption.id);
          this.formatAndEmitData();
        }, 200);
      }
    }
  }

  setDefaultFilters() {
    let filterData: any = {};
    let userRole = this.authService.getUser()?.role;
    //Job post list - Status: Approved by default - Default sort option : Descending order of Requested Date
    if (this.currentPage === PAGE.JOBPOST) {
      filterData.selected = { status: ["APR"] };
      filterData.labels = ["",
        { filterCategory: "status", id: "APR", name: "Approved" },
      ];
      filterData.orderBy = 'dsc';
      filterData.sort = { id: 'created_at', displayName: 'Requested Date' };
    }

    //Candidate list -List candidates applied from last 3 months
    if (this.currentPage === PAGE.CANDIDATES) {
      this.isInitialCandidateLoad = true;
      filterData.selected = this.getCandidateAppliedOnDateRange();
      filterData.orderBy = 'dsc';
      filterData.sort = { displayName: "Applied on", id: "reapplied_date" };
    }

    //Interviewer list -List interviewers for HR users, sorted in alphabetical order of interviewer name
    if (this.currentPage === PAGE.INTERVIEWERS) {
      filterData.sort = { displayName: "Name", id: "name" };
      filterData.orderBy = 'asc';
    }

    //Schedule invites list -List candidates for HR users and Directors, sorted in desc order of Invite Schedule sent date
    if (this.currentPage === PAGE.INTERVIEWSCHEDULEINVITES) {
      filterData.sort = { displayName: "Invite Sent On", id: "sent_on" };
      filterData.orderBy = 'dsc';
    }

    //Interview list - Upcoming filter is applied by default - Default sort option: ascending order of Interview Date
    if (this.currentPage === PAGE.INTERVIEWS) {
      filterData.selected = { status: ["NEW"] };
      filterData.labels = ["",
        { filterCategory: "status", id: "NEW", name: "Upcoming" },
      ];

      //Set My interviews filter for users other than HR and Director
      if (![Role.HR, Role.HRM, Role.Director].includes(userRole)) {
        filterData.selected['view_only_my_interviews'] = [true];
        filterData.labels.push({ filterCategory: "view_only_my_interviews", id: true, name: "My Interviews" });
      }

      filterData.sort = { displayName: "Interview Date", id: "scheduled_for" };
    }

    //Selected candidates - Set default sort option - Last updated on as descending
    if (this.currentPage === PAGE.SELECTEDCANDIDATES) {
      filterData.sort = { id: 'candidate__last_modified_at_qrms', displayName: 'Last updated on' };
      filterData.orderBy = 'dsc';
    }

    //Review rating & No show/Cancellations - Set default sort option - Interview date as descending
    if (this.currentPage === PAGE.ACTIONITEMSREVIEWRATING || this.currentPage === PAGE.NOSHOWANDCANCELLATIONS) {
      filterData.sort = { id: 'scheduled_for', displayName: 'Interview Date' };
      filterData.orderBy = 'dsc';
    }
    //Joining Date Yet To Confirm - Set default sort option - Offer letter send date as ascending
    if (this.currentPage === PAGE.JOININGDATEYETCONFIRM) {
      filterData.sort = { displayName: "Offer letter sent date", id: "offer_sent_on" };
      filterData.orderBy = 'asc';
    }

    //Offer letter approvals - Set default sort option - Offer letter send date as descending
    if (this.currentPage === PAGE.OFFERAPPROVALS) {
      filterData.sort = { displayName: "Sent for approval on", id: "offer_approval_sent_on" };
      filterData.orderBy = 'dsc';
    }

    //Awaiting rating - Set default sort option - Interview date as descending
    if (this.currentPage === PAGE.AWAITINGRATING) {
      filterData.sort = { displayName: "Interview Date", id: "scheduled_for" };
      filterData.orderBy = 'dsc';
    }

    //Referral bonus employee - Set default sort option - Joining date as descending
    if ((this.currentPage === PAGE.REFERRALBONUSEMPLOYEEOPEN || this.currentPage === PAGE.REFERRALBONUSEMPLOYEEAPPROVED)) {
      filterData.sort = { displayName: "Joining Date", id: "candidate__offer__joining_date" };
      filterData.orderBy = 'dsc';
    }

    //Referral bonus agency - Set default sort option
    if (this.currentPage === PAGE.REFERRALBONUSAGENCY) {
      filterData.sort = { displayName: "Joining Date", id: "candidate__offer__joining_date" };
      filterData.orderBy = 'dsc';
    }

    //Workbook - Set default sort option : Descending order of Created Date
    if (this.currentPage === PAGE.WORKBOOK) {
      filterData.sort = { id: 'created_at', displayName: 'Created Date' };
      filterData.orderBy = 'dsc';
    }

    // New Hire Intimations - Set default sort option : Descending order of Name
    if (this.currentPage === PAGE.NEWHIREINTIMATIONS) {
      filterData.sort = { id: 'candidate__full_name', displayName: 'Name' };
    }

    // Recruitment Drives - Set default sort option : Descending order of Created Date
    if (this.currentPage === PAGE.RECRUITMENTDRIVE) {
      filterData.sort = { id: 'created_at', displayName: 'Created Date' };
    }
    
    this.globalStorage.setFilters(this.currentPage, JSON.stringify({ ...filterData }));
    return JSON.parse(this.globalStorage.getFilters(this.currentPage));
  }

  setDateRangeFilters(data: any) {
    data.labels?.forEach((parmData: filtersSelectedWithName<any>) => {
      if (parmData.type === 'date-range') {
        if (this.filterItems.length > 0) {
          setTimeout(() => {
            this.filterItems.forEach((filData: FilterItems) => {
              if (filData.id == parmData.name) {
                filData['selectedDate'] = { [filData.id_1!]: dayjs(parmData.id), [filData.id_2!]: dayjs(parmData.id2) };
              }
            })
          }, 1000)
        }
      }
    })
  }

  setDropDownSelectPlaceholder(placeholder: string): string {
    let selectedItemName: string = placeholder || 'Select an option';
    this.filtersSelectedWithName.forEach(item => {
      if (item.filterCategory === 'show_past_data') {
        selectedItemName = item.name;
      }
    });
    return selectedItemName;
  }


  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  applyFilter() {
    this.handleSubmit()
  }

  //reference to current search-multi-select filter that is being used
  setCurrentFilter(id: string) {
    this.currentSelectedFilter = this.reducedFilterItems.find(r => r.id == id);
    this.currentBackupFilter = this.backupFilterItems.find(f => f.id == id);
  }

  onVirtualScrollSearch(id: string) {
    this.setCurrentFilter(id);

    //subscribe to search tag change and filter out items from backup and set it as new list items in ng-select
    this.input$.subscribe((searchTerm: string) => {
      this.filterSearchTerm = searchTerm;
      this.currentSelectedFilter.values = [...this.currentBackupFilter.values]
        .filter((val: any) => val.email.includes(searchTerm));
    });
  }

  onDateSelected(event: any) {
    if (event.data[event.id1] !== null && event.data[event.id2] !== null) {
      this.clearDefaultFilterForCandidateList();
      const displayDate1 = dayjs(event.data[event.id1]).format('MMM DD, YYYY ');
      const displayDate2 = dayjs(event.data[event.id2]).format('MMM DD, YYYY ');
      const temp1 = {
        filterCategory: event.id1,
        id: dayjs(event.data[event.id1]).toISOString(),
        id2: dayjs(event.data[event.id2]).endOf('day').toISOString(),
        id_1: event.id1,
        id_2: event.id2,
        name: event.id,
        displayText: `${event.displayName}: ${displayDate1}- ${displayDate2}`,
        type: "date-range"
      };
      const elementsIndex1 = this.filtersSelectedWithName.findIndex(element => element.filterCategory == event.id1);
      //check data present
      if (elementsIndex1 !== -1) {
        event.data[event.id1] = displayDate1;
        this.filtersSelectedWithName.splice(elementsIndex1, 1);

      }
      this.filtersSelectedWithName.push(temp1);

      this.filtersSelected[event.id1] = [dayjs(event.data[event.id1]).toISOString()];
      this.filtersSelected[event.id2] = [dayjs(event.data[event.id2]).endOf('day').toISOString()];
    }
    else if (event.data[event.id1] === null && event.data[event.id2] === null) {
      delete this.filtersSelected[event.id1];
      delete this.filtersSelected[event.id2];
      const elementsIndex1 = this.filtersSelectedWithName.findIndex(element => element.filterCategory == event.id1);
      if (elementsIndex1 !== -1) {
        this.filtersSelectedWithName.splice(elementsIndex1, 1);
      }
    }
  }



  addOrRemoveFilteItem(key: string, value: string, itemName: string, displayTag: string | undefined, type: string, id_2: string) {

    this.clearDefaultFilterForCandidateList();

    const temp = {
      filterCategory: key,
      id: value,
      name: itemName,
      displayText: displayTag ? (displayTag + ': ' + itemName) : undefined
    }
    if (key in this.filtersSelected) {
      if (type === 'date-range') {
        let elementsIndex = this.filterItems.findIndex((element: any) => element.id == itemName);
        delete this.filterItems[elementsIndex].selectedDate;
        delete this.filtersSelected[id_2];
      }

      if (type === 'dropdown-select') {
        //clear previous value
        this.filtersSelected[key] = [];
        this.filtersSelectedWithName.pop();
      }

      if (this.filtersSelected[key].includes(value)) {
        const index = this.filtersSelected[key].indexOf(value);
        this.filtersSelected[key].splice(index, 1);
        const indexOfItemWithName = this.filtersSelectedWithName.findIndex(item => item['id'] === value
          && item['filterCategory'] === key);
        this.filtersSelectedWithName.splice(indexOfItemWithName, 1);
        if (this.filtersSelected[key].length === 0) {
          delete this.filtersSelected[key];
        }
      }
      else {
        this.filtersSelected[key].push(value);
        this.filtersSelectedWithName.push(temp);
      }
    }
    else {
      this.filtersSelected[key] = [value];
      this.filtersSelectedWithName.push(temp);
    }
    this.ChangeFilterOptions(key);
  }

  ChangeFilterOptions(key: string) {
    if (key === 'level' && this.filterItems.find((item: any) => item?.id === 'recommendation')) {
      delete this.filtersSelected['recommendation'];
      let filteredArray: any = [];
      filteredArray = this.filtersSelectedWithName.filter((item: any) => { return item?.filterCategory != 'recommendation' });
      this.filtersSelectedWithName = filteredArray;
      let array: any[] = [];
      if (!this.filtersSelected.level?.length) {
        Object.keys(recommendationChoice).forEach((choice: any) => {
          array = [...array, ...recommendationChoice[choice]];
        });
      } else {
        this.filtersSelected.level?.forEach((item: any) => {
          array = [...array, ...recommendationChoice[item]];
        });
      }
      array = array.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id
        )));
      let recommendationValue = this.filterItems.find((item: any) => item.id === 'recommendation');
      if (recommendationValue) {
        recommendationValue.values = array;
      }
    }
  }


  addOrRemoveFilteItemOnChangeInput(e: any, filterCategory: string) {
    const temp = {
      filterCategory: e.target.name,
      id: e.target.value,
      name: e.target.placeholder,
      displayText: e.target.placeholder + ': ' + e.target.value
    }
    if (e.target.name in this.filtersSelected) {
      if (e.target.value) {
        this.filtersSelected[e.target.name] = [e.target.value];
        const indexOfItemWithName = this.filtersSelectedWithName.findIndex(item => item['filterCategory'] === e.target.name);
        this.filtersSelectedWithName.splice(indexOfItemWithName, 1);
        this.filtersSelectedWithName.push(temp);
      }
      else {
        delete this.filtersSelected[e.target.name];
        const indexOfItemWithName = this.filtersSelectedWithName.findIndex(item => item['filterCategory'] === e.target.name);
        this.filtersSelectedWithName.splice(indexOfItemWithName, 1);
      }
    }
    else {
      this.filtersSelected[e.target.name] = [e.target.value];
      this.filtersSelectedWithName.push(temp);
    }
    this.clearDefaultFilterForCandidateList();
  }

  returnNumber(e: any) {
    var ASCIICode = (e.which) ? e.which : e.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
    return true;
  }

  getSearchParams(e: SearchParams) {
    if (e) {
      Object.keys(e).forEach(k => (!e[k]) && delete e[k]);
    }
    return e;

  }

  toggleOrderBy() {
    if (this.orderBy === 'asc') {
      this.orderBy = 'dsc';
    }
    else {
      this.orderBy = 'asc'
    }
    if (this.filtersForm?.value.ordering) {
      this.handleSubmit();
    }
  }

  changeSortOption(option: any) {
    if (option.id != this.selectedSortOption.id) {
      this.selectedSortOption = option;
      this.filtersForm?.controls['ordering'].setValue(option.id);
      this.handleSubmit();
    }
  }

  onFocus(searchSelect: NgSelectComponent, id: string, canClear: boolean = true) {
    canClear && searchSelect.handleClearClick();
    this.onVirtualScrollSearch(id);
  }

  onAdd(searchSelect: NgSelectComponent) {
    searchSelect.itemsList.clearSelected(true);
    searchSelect.searchTerm = "";
  }

  formatAndEmitData(updateSearchParamOnly = false) {
    const params = this.getSearchParams(this.filtersForm?.value);
    let temp = {};
    if (this.orderBy === 'dsc') {
      if (params['ordering'])
        temp = { 'ordering': "-" + params['ordering'] }
    }
    let data = {
      ...params,
      ...temp,
      ...this.filtersSelected
    }

    const paramsKeys: any = Object.keys(params);
    let hasSearch: boolean = false;
    if (this.currentPage == PAGE.CANDIDATES && paramsKeys.length > 0 && paramsKeys.includes('search')) {
      hasSearch = true;
    }
    if (this.currentPage === PAGE.CANDIDATES && (((this.isInitialCandidateLoad || this.filtersSelectedWithName.length == 1) && !hasSearch)
      || this.onClearCandidateFilters)) {
      data = { ...data, ...this.getCandidateAppliedOnDateRange(),'status': 'N' }

      if (this.onClearCandidateFilters) {
        this.onClearCandidateFilters = false;
        data['ordering'] = "-reapplied_date";
        this.orderBy = 'dsc';
        this.selectedSortOption = { displayName: "Applied on", id: "reapplied_date" }
        this.filtersForm?.controls['ordering'].setValue(this.selectedSortOption.id);
      }
    }

    if (updateSearchParamOnly) {
      this.updateSearchParamOnly.emit(data);
      return;
    }
    this.onSearchWithFilter.emit(data);
  }

  handleSubmit(e?: any, isFocusOut = false) {
    //to prevent trying to clear filters when there are none
    if (e?.className === 'clear-filter') {
      //do nothing if there are no filters
      if (!JSON.parse(this.globalStorage.getFilters(this.currentPage))?.selected) {
        this.isFilterOpen = false;
        return
      }
    }
    e?.preventDefault();
    !isFocusOut && this.clearDefaultFilterForCandidateList();
    this.formatAndEmitData(isFocusOut);
    this.isFilterOpen = false;
    this.showFiltersSelectedWithName = [...this.filtersSelectedWithName];

    //Query params for filtering
    let filterData: any = {};
    if (Object.keys(this.filtersSelected).length) {
      filterData['selected'] = this.filtersSelected;
    }

    if (Object.keys(this.filtersSelectedWithName).length > 1) {
      filterData['labels'] = this.filtersSelectedWithName;
    }

    if (Object.keys(this.selectedSortOption).length && this.selectedSortOption.id !== '') {
      filterData['sort'] = this.selectedSortOption;
    }

    if (this.orderBy !== 'asc') {
      filterData['orderBy'] = this.orderBy;
    }

    if (this.filtersForm?.value.search?.length) {
      filterData['search'] = this.filtersForm?.value.search;
    }

    if (Object.keys(filterData).length) {
      this.globalStorage.setFilters(this.currentPage, JSON.stringify({ ...filterData }));
    }
    else {
      this.globalStorage.removeFilters(this.currentPage);
    }

    this.resetFilterListItems();
    this.clearSearchSelectInput();
  }

  clearFilter() {
    this.filtersSelected = {};
    this.filtersSelectedWithName = [''];
    this.filterItems.map((filter: any) => {
      if (filter.type === 'date-range') {
        delete filter.selectedDate;
      }
    })

    if (this.currentPage == PAGE.ACTIONITEMSREVIEWRATING) {
      let array: any[] = [];
      Object.keys(recommendationChoice).forEach((choice: any) => {
        array = [...array, ...recommendationChoice[choice]];
      });
      //remove duplicates in array
      array = array.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id
      )));
      let recommendationValue = this.filterItems.find((item: any) => item.id === 'recommendation');
      if (recommendationValue) {
        recommendationValue.values = array;
      }
    }

    //add applied date range for default candidate data
    if (this.currentPage == PAGE.CANDIDATES) {
      this.isInitialCandidateLoad = true;
      this.onClearCandidateFilters = true;
    }

    //clear filters from queryparams only if filter params are present
    if (JSON.parse(this.globalStorage.getFilters(this.currentPage))?.selected) {
      this.handleSubmit();
    }
  }

  setSearchedTags(search: any) {
    if (Object.keys(search).length) {
      this.searchBarTags = {
        full: Object.entries(search), // Searched tags from API response is stored here
        list: [], // searched tags list to be shown in searchbar is stored here
        dropdown: [] //searched tags list to be shown in dropdown is stored here
      }

      let listCount = 0, list: any = [], dropdown: any = [], dropdownTagsCount = 0;

      this.searchBarTags['full'].forEach((item: any) => {
        let itemLength = 0
        itemLength = item[0].length + item[1].join().length + (2 * item[1].length);

        if (listCount + itemLength < this.allowedTagsLength) {
          listCount += listCount + itemLength;
          list.push(item);
        } else {
          dropdownTagsCount += item[1].length
          dropdown.push(item);
        }
      });

      this.searchBarTags = {
        ...this.searchBarTags,
        list: [...list],
        dropdown: [...dropdown],
        more: dropdownTagsCount
      }
    }
    else {
      //to remove searchBarTags when search is empty
      this.searchBarTags = {};
    }
  }

  searchBarkeyDown(e: any) {
    var ASCIICode = (e.which) ? e.which : e.keyCode
    if (ASCIICode === 13) {
      this.handleSubmit(e)
    }
  }

  handleDropdownPosition(e: any, id: any) {
    if (dynamicDropdowns.includes(id)) {
      const isInViewport = this.isInViewport(e.currentTarget.nextElementSibling);
      if (!isInViewport) {
        let bottomPos = e.currentTarget.nextElementSibling.getBoundingClientRect().bottom;
        // if (!e.currentTarget.parentElement.classList.contains('dropup')) {
        //   e.currentTarget.parentElement.classList.add('dropup')
        // } else {
        //   e.currentTarget.parentElement.classList.remove('dropup')
        // }
        if (bottomPos - window.innerHeight > 100) {
          e.currentTarget.nextElementSibling.classList.add('bottom-zero-top-auto')
          e.currentTarget.nextElementSibling.classList.remove('top-bottom-auto');
        } else {
          e.currentTarget.nextElementSibling.classList.add('top-bottom-auto');
          e.currentTarget.nextElementSibling.classList.remove('bottom-zero-top-auto');
        }
      }
    }
  }

  isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    let { top, bottom } = bounding;

    return (
      top >= (window.pageYOffset - window.scrollY) &&
      bottom <= (window.pageYOffset - window.scrollY + window.innerHeight)
    );
  };

  resetFilterListItems() {
    //reset values array for virtual scroll enabled filters, if it has been changed
    if (this.currentSelectedFilter?.values.length) {
      this.reducedFilterItems.forEach((item: any) => {
        if (item.type == 'search-multi-select') {
          item.values = [];
        }
      });
    }
  }

  clearDateRangePicker(datePickerNames: string[]) {
    this.dateRangePickers.forEach((daterangePicker: any) => {
      datePickerNames.forEach((name: string) => {
        if (name == daterangePicker?.item?.id) {
          daterangePicker.clear();
        }
      });
    });
  }

  clearSearchSelectInput() {
    this.searchSelects?.forEach((searchSelect: NgSelectComponent) => {
      searchSelect.handleClearClick();
    });
  }

  handleOutsideClick() {
    if (!this.isFilterOpen) {
      return;
    }

    this.isFilterOpen = false;
    let clearableDates = [...this.datePickerIds];

    this.filtersSelected = [];
    this.filtersSelectedWithName = [''];

    this.resetFilterListItems();
    this.clearSearchSelectInput();

    //update filtersSelected and filtersSelectedWithName by taking values from current applied filters
    this.filtersSelectedWithName = JSON.parse(JSON.stringify(this.showFiltersSelectedWithName));
    this.showFiltersSelectedWithName.forEach((appliedFilter: any) => {
      if (appliedFilter == '') {
        return;
      }
      let existingFilter = false;
      Object.keys(this.filtersSelected).forEach((selectedFilterName: string) => {
        //if the filterCategory is already in filtersSelected object, append the value to the previous array
        if (selectedFilterName == appliedFilter.filterCategory) {
          existingFilter = true;
          this.filtersSelected[selectedFilterName].push(appliedFilter.id);
        }
      });
      //if the filterCategory is not already in filtersSelected object, create a new key with the categoryname and assign value in array
      if (!existingFilter) {
        if (appliedFilter.type == 'date-range') {
          this.filtersSelected[appliedFilter.id_1] = [appliedFilter.id,];
          this.filtersSelected[appliedFilter.id_2] = [appliedFilter.id2,];
        }
        else {
          this.filtersSelected[appliedFilter.filterCategory] = [appliedFilter.id,];
        }
      }

      if (clearableDates.includes(appliedFilter.name)) {
        clearableDates.splice(clearableDates.indexOf(appliedFilter.name), 1);
      }
    });
    this.clearDateRangePicker(clearableDates);
  }

  getAppliedFiltersPermission() {
    if (this.authService.isAuthorized()) {
      let isCL: boolean = this.authService.isCL();
      let isVT: boolean = this.authService.isVT();
      let isIS: boolean = this.authService.hasInterview();
      let isIntwr:boolean = this.authService.isInterviewer() && (this.currentPage === (PAGE.INTERVIEWS || PAGE.AVAILABILITY));
      this.getAppliedFiltersPermissionCheck = this.showAppliedFilterOnUser() && ([Role.HR, Role.HRM, Role.Director].includes(this.userRole) || isCL || isVT || this.mustShowAppliedFilters || isIS || isIntwr);
    }
  }

  showAppliedFilterOnUser() {
    //hide filter tags in Jobpost filter for Staff user
    let userRole = this.authService.getUser()?.role;
    if (this.currentPage === PAGE.JOBPOST && userRole === Role.Staff) {
      return false;
    }
    return true;
  }

  clearSearchInput() {
    this.filtersForm.controls['search'].setValue('');
  }

  getCandidateAppliedOnDateRange() {
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    let getThreeMonthDate = new Date().setMonth(new Date().getMonth() - 3);
    let lastThreeMonthDate = new Date(getThreeMonthDate);
    lastThreeMonthDate.setHours(0, 0, 0, 0);
    let applied_on_from = new Date(lastThreeMonthDate).toISOString();
    let applied_on_to = new Date(today).toISOString();
    return { applied_on_from: applied_on_from, applied_on_to: applied_on_to };
  }

  clearDefaultFilterForCandidateList() {
    if (this.currentPage === PAGE.CANDIDATES && this.isInitialCandidateLoad) {
      delete this.filtersSelected['applied_on_from']
      delete this.filtersSelected['applied_on_to']
      this.isInitialCandidateLoad = false;
    }
  }

  onUpdateMultiSearchFilter(searchSelect: NgSelectComponent) {
    if (!searchSelect.searchTerm) {
      searchSelect.handleClearClick();
    }
  }

  getAppliedTheme() {
    this.appliedTheme = this.globalStorage.getTenandDetails().theme;
  }
}


import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AngularEditorModule } from '@kolkov/angular-editor';
// Modules

// Components
import { LoaderComponent } from './_components/loader/loader.component';
import { FileUploadComponent } from './_components/file-upload/file-upload.component';
import { PaginationComponent } from './_components/pagination/pagination.component';
import { ConfirmationComponent } from './_components/confirmation/confirmation.component';
import { SelectComponent } from './_components/select/select.component';
import { CheckboxComponent } from './_components/checkbox/checkbox.component';
import { LabelTagComponent } from './_components/label-tag/label-tag.component';
import { HeaderComponent } from './_components/header/header.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './_components/side-nav/side-nav.component';
import { DatepickerComponent } from './_components/datepicker/datepicker.component';
import { DateRangeComponent } from './_components/date-range-picker/date-range-picker.component';
import { StarRatingComponent } from './_components/star-rating/star-rating.component';
import { TableRowComponent } from './_components/table-row/table-row.component';
import { ActivitySectionComponent } from './_components/activity-section/activity-section.component';
import { SingleDatePickerComponent } from './_components/single-date-picker/single-date-picker.component';

import { SearchSortFilterComponent } from './_components/search-sort-filter/search-sort-filter.component';
import { KebabMenuComponent } from './_components/kebab-menu/kebab-menu.component';
import { NgbdTimepickerMeridian } from './_components/time-picker/time-picker.component';
import { NgbdTimepickerMeridianPopup } from './_components/time-picker-popup/time-picker-popup.component';
import { DatePickerPopUp } from './_components/date-picker-popup/date-picker-popup.component';
import { TabSectionComponent } from './_components/tab-section/tab-section.component';
import { DashboardWidgetComponent } from './_components/dashboard-widget/dashboard-widget.component';
import { ReferCandidateComponent } from './_components/refer-candidate/refer-candidate.component';
import { InterviewRatingComponent } from './_components/interview-rating/interview-rating.component';
import { CommonListItemSearchComponent } from './_components/common-list-item-search/common-list-item-search.component';
import { WorkbookCreateComponent } from './_components/workbook-create/workbook-create.component';

// Directives
import { UserRoleDirective } from './_directives/user-roles.directive';
import { ClickOutsideDirective } from './_directives/click-outside.directive';
import { NgSelectHandlerDirective } from './_directives/ng-select-handler.directive';
import { ConfirmationPopupComponent } from './_components/confirmation-popup/confirmation-popup.component';
import { NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailPopupComponent } from './_components/email-popup/email-popup.component';
import { ScheduleEditInterviewComponent } from './_components/schedule-edit-interview/schedule-edit-interview.component';
import { OfferLetterPrerequisiteComponent } from './_components/offer-letter-prerequisite/offer-letter-prerequisite.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UpdateStatusPopupComponent } from './_components/update-status-popup/update-status-popup.component';
import { CommentPopupComponent } from './_components/comment-popup/comment-popup.component';
import { SectionLoaderComponent } from './_components/section-loader/section-loader.component';
import { ReferralBonusPopUpComponent } from './_components/referral-bonus-pop-up/referral-bonus-pop-up.component';
import { CustomSideNavComponent } from './_components/custom-side-nav/custom-side-nav.component';
import { ChartsComponent } from './_components/chart/chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FilterComponent } from './_components/filter/filter.component';
import { NavSubMenuComponent } from './_components/side-nav/nav-sub-menu/nav-sub-menu.component';
import { DayRangePickerComponent } from './_components/day-range-picker/day-range-picker.component';
import { DndDirective } from '../shared/_directives/dnd.directive';

import { EditorContentLengthPipe } from './_pipes/editor-content-length.pipe';
import { SwitchComponent } from './_components/switch/switch.component';
import { WarningPopupComponent } from './_components/warning-popup/warning-popup.component';
import { ActivityHistoryComponent } from './_components/activity-history/activity-history.component';
import { ScheduleInviteFormComponent } from './_components/schedule-invite-form/schedule-invite-form.component';
import { CustomCheckboxComponent } from './_components/custom-checkbox/custom-checkbox.component';
import { AvailabilityModalComponent } from './_components/availability-modal/availability-modal.component';
import { CreateOfferComponent } from './_components/create-offer/create-offer.component';
import { SimpleTabSectionComponent } from './_components/simple-tab-section/simple-tab-section.component';
import { InterviewDetailPopupComponent } from './_components/interview-detail-popup/interview-detail-popup.component'
import { InputFieldComponent } from './_components/input-field/input-field.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    NgbModule,
    AngularEditorModule,
    NgxDaterangepickerMd.forRoot(),
    DragDropModule,
    NgApexchartsModule,
  ],
  declarations: [
    LoaderComponent,
    FileUploadComponent,
    PaginationComponent,
    ConfirmationComponent,
    UserRoleDirective,
    CheckboxComponent,
    SelectComponent,
    LabelTagComponent,
    HeaderComponent,
    SideNavComponent,
    DatepickerComponent,
    DateRangeComponent,
    StarRatingComponent,
    SearchSortFilterComponent,
    TableRowComponent,
    ActivitySectionComponent,
    KebabMenuComponent,
    ClickOutsideDirective,
    NgSelectHandlerDirective,
    SingleDatePickerComponent,
    ConfirmationPopupComponent,
    NgbdTimepickerMeridian,
    NgbdTimepickerMeridianPopup,
    DatePickerPopUp,
    TabSectionComponent,
    EmailPopupComponent,
    ScheduleEditInterviewComponent,
    OfferLetterPrerequisiteComponent,
    UpdateStatusPopupComponent,
    CommentPopupComponent,
    DashboardWidgetComponent,
    SectionLoaderComponent,
    ReferCandidateComponent,
    ReferralBonusPopUpComponent,
    InterviewRatingComponent,
    CommonListItemSearchComponent,
    CustomSideNavComponent,
    ChartsComponent,
    FilterComponent,
    NavSubMenuComponent,
    DayRangePickerComponent,
    EditorContentLengthPipe,
    ActivityHistoryComponent,
    SwitchComponent,
    WorkbookCreateComponent,
    CustomCheckboxComponent,
    ScheduleInviteFormComponent,
    AvailabilityModalComponent,
    WarningPopupComponent,
    CreateOfferComponent,
    SimpleTabSectionComponent,
    DndDirective,
    InterviewDetailPopupComponent,
    InputFieldComponent
  ],

  exports: [
    LoaderComponent,
    FileUploadComponent,
    PaginationComponent,
    ConfirmationComponent,
    HeaderComponent,
    CheckboxComponent,
    SelectComponent,
    LabelTagComponent,
    SideNavComponent,
    DatepickerComponent,
    StarRatingComponent,
    SearchSortFilterComponent,
    TableRowComponent,
    UserRoleDirective,
    ActivitySectionComponent,
    KebabMenuComponent,
    ClickOutsideDirective,
    NgSelectHandlerDirective,
    SingleDatePickerComponent,
    ConfirmationPopupComponent,
    NgbTimepickerModule,
    NgbdTimepickerMeridian,
    NgbdTimepickerMeridianPopup,
    DatePickerPopUp,
    TabSectionComponent,
    EmailPopupComponent,
    ScheduleEditInterviewComponent,
    OfferLetterPrerequisiteComponent,
    CommentPopupComponent,
    DashboardWidgetComponent,
    SectionLoaderComponent,
    ReferCandidateComponent,
    ReferralBonusPopUpComponent,
    InterviewRatingComponent,
    CustomSideNavComponent,
    NavSubMenuComponent,
    CommonListItemSearchComponent,
    CustomSideNavComponent,
    ChartsComponent,
    FilterComponent,
    DayRangePickerComponent,
    SwitchComponent,
    WarningPopupComponent,
    ActivityHistoryComponent,
    ScheduleInviteFormComponent,
    WorkbookCreateComponent,
    CustomCheckboxComponent,
    AvailabilityModalComponent,
    CreateOfferComponent,
    SimpleTabSectionComponent,
    DndDirective,
    InterviewDetailPopupComponent,
    InputFieldComponent,
  ],
  providers: [DatePipe, TitleCasePipe, EditorContentLengthPipe],
})
export class SharedModule {
}

<div class="donut-chart-wrapper card">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [responsive]="chartOptions.responsive"
        [colors]="chartOptions.colors"
        [tooltip]="chartOptions.tooltip"
        [legend]="chartOptions.legend"
        [dataLabels]="chartOptions.dataLabels"
        [states]="chartOptions.states"
        [plotOptions]="chartOptions.plotOptions"
        [stroke]="chartOptions.stroke"
        [fill]="chartOptions.fill"
    ></apx-chart>
  </div>
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { regex } from 'src/app/shared/_services/regex';
import { AppService } from 'src/app/app.service';
import { WorkbookService } from '../../../workbook/workbook.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/_models/user';
import { GlobalStoreService } from 'src/app/shared/_services/global-store.service';
import { UtilService } from 'src/app/shared/_services/util.service';
@Component({
  selector: 'app-workbook-create',
  templateUrl: './workbook-create.component.html',
  styleUrls: ['./workbook-create.component.scss']
})
export class WorkbookCreateComponent implements OnInit {

  @Output() workBookCreated: EventEmitter<string> = new EventEmitter();

  addWorkBookForm !: FormGroup;
  item!: any;
  spaceUserData!: any
  dataLoaded: boolean = true;
  bindData: string = "email";
  selectedusers: any = [];
  currentUser: User;
  ownerList: any = [];
  ownerDetails: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private workbookService: WorkbookService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private authService: AuthService,
    private globalStore: GlobalStoreService,
    private utilService: UtilService,
  ) {
    this.currentUser = this.authService.getUser();
    this.globalStore.setSecondaryNavMenu('');
  }

  ngOnInit(): void {
    this.getSpaceUserData();
    this.setInitialValues();
  }

  buildAddWBForm() {
    this.addWorkBookForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(75), Validators.pattern(regex.empty)]],
      description: ['', [Validators.maxLength(255)]],
      due_date: [''],
      owners: [null]
    });
  }

  setInitialValues() {
    this.buildAddWBForm();
    this.item = {
      "id": "due_date",
      "value": undefined,
      "minDate": dayjs(),
      "maxDate": dayjs.Dayjs
    };
    let splitName = this.currentUser.name.split(" ");
    let initialUser = {
      "id": this.currentUser.id,
      "email": this.currentUser.email,
      "first_name": splitName[0],
      "last_name": this.currentUser.name?.substring(splitName[0].length).trim(),
      "role": this.currentUser.role
    };
    this.addWorkBookForm.patchValue({ owners: [initialUser] });
    this.ownerList = this.addWorkBookForm.controls['owners'].value?.map((user: any) => user.id)
    this.selectDropdownItems();
  }

  onDueDateSelected(event: any) {
    this.addWorkBookForm.patchValue({ due_date: event.due_date })
  }

  getSpaceUserData() {
    this.appService.generalDropdowns.subscribe((data: any) => {
      this.spaceUserData = data.HR_users;
      this.spaceUserData.forEach((e: any) => {
        e.name = e.first_name + " " + e.last_name;
      })
      this.dataLoaded = false;
    })
  }
  // convenience getter for easy access to form fields
  get f() { return this.addWorkBookForm.controls }

  onCreateClick() {
    if (this.addWorkBookForm.invalid) {
      this.addWorkBookForm.markAllAsTouched();
      return;
    }

    if (this.addWorkBookForm.value.due_date === "Invalid Date") {
      this.addWorkBookForm.removeControl('due_date');
    }
    let workbookReq = { ...this.addWorkBookForm.value };
    workbookReq['owners'] = [...this.ownerList];

    this.workbookService.addWorkBook(`/api/work_books/`, workbookReq)
      .subscribe(
        (response: any) => {
          if (response.status === "OK") {
            this.toaster.success(`Success! The workbook is created`);
            this.modalService.dismissAll();
            this.workBookCreated.emit(response.data.id);

          }
        },
        (error) => {
          this.toaster.error(`Something went wrong, please try again`);
        });
  }

  onCancelClick() {
    this.modalService.dismissAll();
  }


  identify(index: any, item: any) {
    return item.id;
  }

  onRemoveOwner(user: any) {
    this.ownerDetails = this.ownerDetails.filter((item: any) => item?.id !== user?.id);
    this.ownerList = this.ownerList.filter((item: any) => item !== user?.id);
  }

  onOwnerSelection(event: any) {
    if (event) {
      this.ownerDetails = this.spaceUserData.filter((user:any) => this.ownerList.includes(user?.id));
      setTimeout(() => {
        this.retainPlaceHolderForMultiSelect();
      }, 0);

    }
  }

  selectDropdownItems() {
    this.ownerDetails = this.spaceUserData.filter((user: any) => this.ownerList.includes(user?.id));
    if (this.ownerList?.length) {
      setTimeout(() => {
        this.retainPlaceHolderForMultiSelect();
      }, 0);
    }
  }

  retainPlaceHolderForMultiSelect() {
    let multiSelectClass = ' .multi-select-checkbox-dropdown .ng-select .ng-select-container';
    const multiSelect = document.querySelector(multiSelectClass) as any;
    multiSelect.classList.remove("ng-has-value");
  }

  onDropdownOpen() {
    this.utilService.dropdownScrollToTop('.multi-select-checkbox-dropdown');
  }

}

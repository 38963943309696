import { Component, Injectable, Input, TemplateRef, ViewChild } from '@angular/core'
import { ModalConfig } from './../../_models/modal'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
})
@Injectable()
export class ConfirmationComponent {
  @Input() public modalConfig!: ModalConfig
  @ViewChild('modal') private modalContent!: TemplateRef<ConfirmationComponent>
  private modalRef!: NgbModalRef

  constructor(private modalService: NgbModal) { }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { centered: true, backdrop: 'static'})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async accept(): Promise<void> {
    this.modalRef.close(true)
  }

  async close(): Promise<void> {
    this.modalRef.close(false)
  }

  async dismiss(): Promise<void> {
    this.modalRef.dismiss()
  }
}

import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DashboardJobPostItem } from 'src/app/shared/_models/job-post';
import { CandidateSource } from 'src/app/shared/_models/utils';
import { regex } from 'src/app/shared/_services/regex';
import { countryCode } from 'src/constants/constants';
import { DashboardService } from '../../../dashboard/dashboard.service';

@Component({
  selector: 'app-refer-candidate',
  templateUrl: './refer-candidate.component.html',
  styleUrls: ['./refer-candidate.component.scss']
})
export class ReferCandidateComponent implements OnInit {

  referForm!: FormGroup;
  basicInfoForm!: FormGroup;
  countryCode = countryCode;
  subscriptions: Subscription[] = [];
  department: CandidateSource[] = [];
  stream: CandidateSource[] = [];
  streamBackup: CandidateSource[] = [];
  resumeValidationMessage: string = "";
  hasResume: boolean = false;
  resetFile!: string | null;
  isValidated: boolean = false;
  isDuplicate: boolean = false;
  isWithinAnYear: boolean = false;
  infoToggle: boolean = false;
  candidateId: number = 0;
  closeResult: string = ''

  @Input() jobpost: DashboardJobPostItem | undefined;
  @Output() refreshReferrals: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('successPopup', { static: true }) successPopup!: NgbModalRef;

  constructor(
    private formbuilder: FormBuilder,
    private appService: AppService,
    private dashboardService: DashboardService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private titleCasePipe: TitleCasePipe,
  ) { }

  ngOnInit(): void {
    this.initBasicInfo();
    this.initForm();
    this.getDropdownsData();
  }

  get f() {
    return this.referForm.controls;
  }

  get bf() {
    return this.basicInfoForm.controls;
  }

  initForm() {
    this.referForm = this.formbuilder.group({
      department: [null, Validators.required],
      experience_months: [null, [
        Validators.pattern(regex.expMonths),
        Validators.pattern(regex.blockSpecialCharacters),
      ]],
      experience_years: [null, [
        Validators.pattern(regex.oneToHundredNumber),
        Validators.pattern(regex.blockSpecialCharacters),
      ]],
      stream: [null, Validators.required],
      job_code: [null],
      recommendation_by_referrer: [null, Validators.maxLength(200)],
      relationship_with_referrer: [null, Validators.maxLength(100)],
      resume: [null, Validators.required],
      existing_qrms_file_key: [null]
    });
  }

  initBasicInfo() {
    this.basicInfoForm = this.formbuilder.group({
      first_name: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(regex.empty)]],
      last_name: [null, [Validators.maxLength(50), Validators.pattern(regex.empty)]],
      primary_contact_country_code: ['+91'],
      primary_contact_no: [null, [Validators.required, Validators.pattern(regex.phoneNumber)]],
      email: [null, [Validators.required, Validators.pattern(regex.email)]]
    });
  }

  getDropdownsData() {
    this.subscriptions.push(this.appService.generalDropdowns.subscribe((data: any) => {
      this.department = data.Department;
      this.stream = data.Stream;
      this.streamBackup = [...this.stream];
    }));
  }

  onDepartmentChange(dept_id: string) {
    if (!dept_id) {
      this.referForm.controls['stream'].setValue(null);
      this.stream = [...this.streamBackup];
    } else {
      this.referForm.controls['stream'].setValue(null);
      this.stream = [];
      if (dept_id) {
        this.streamBackup.forEach((s: any) => {
          if (dept_id == s.department_id) {
            this.stream = [...this.stream, s];
            //automatically set stream for non development departments
            if (dept_id != '2') {
              this.referForm.controls['stream'].setValue(s.id);
            }
          }
        });
      }
    }
  }

  onStreamChange(stream_id: string) {
    this.stream.forEach((stream: any) => {
      if (stream.id == stream_id) {
        this.referForm.controls['department'].setValue(
          stream.department_id
        );
      }
    });
  }

  setStreams(dept_id: string) {
    this.stream = this.streamBackup.filter((stream: any) => {
      return dept_id === stream.department_id;
    });
  }

  onDepartmentModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.department);
  }

  onStreamModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.stream);
  }

  onModelChange(event: any, ref: NgSelectComponent, data: any) {
    if (event) {
      let filterValue = data.find((str: any) => str.id === event);
      ref.searchTerm = filterValue ? filterValue.name : '';
    } else {
      ref.searchTerm = '';
    }
  }

  resumeHandler(e: any) {
    e && this.referForm.controls['resume'].markAsTouched();
    if (e?.id) {
      let fileDetails = this.getFileDetails(e);
      this.referForm.controls["resume"].setValue(fileDetails.id);
      this.referForm.controls["existing_qrms_file_key"].setValue(fileDetails.existing_qrms_file_key);
      this.resetFile = e;
      //hide message if resume is uploaded
      this.hasResume = this.checkFileTypeExist("RES", e);
      if (this.hasResume)
        this.resumeValidationMessage = "";
    }
    //on removing file
    if (Number.isInteger(e)) {
      let currentDeletedFile = e;
      this.referForm.controls["resume"].setValue(null);
      this.referForm.controls["existing_qrms_file_key"].setValue(null);
      if ( 
        currentDeletedFile?.["file_type"] === "RES"
      ) {
        this.resumeValidationMessage = "Required";
        this.hasResume = false;
      }
    }
  }

  getFileDetails(file: any) {
    return {
      existing_qrms_file_key: file?.existing_qrms_file_key,
      id: file?.id,
      file_type: file?.file_type,
    };
  }

  checkFileTypeExist(fileType: string, file: any) {
    let fileStatus = false;
    if (this.getFileDetails(file)?.file_type === fileType) fileStatus = true;
    return fileStatus;
  }

  onSubmit() {
    this.referForm.markAllAsTouched();
    if (this.f.resume.invalid) this.resumeValidationMessage = "Required";
    if ((!this.f.experience_years?.value && !this.f.experience_months?.value) || this.referForm.invalid) {
      return;
    }
    let reqObj = this.constructReqObj();
    if (this.isDuplicate && !this.isWithinAnYear) {
      this.updateCandidate(reqObj);
    } else {
      this.postCandidate(reqObj)
    }
  }

  updateCandidate(req: any) {
    this.subscriptions.push(
      this.dashboardService.updateReferalFriend(req,this.candidateId ).subscribe((res: any) => {
        this.onClose(true);
      },
      ()=> {
        this.toaster.error("Refer friend failed. Something went wrong");
      })
    );
  }

  postCandidate(req: any) {
    this.subscriptions.push(
      this.dashboardService.postReferalFriend(req).subscribe((res: any) => {
        this.onClose(true);
      },
      ()=> {
        this.toaster.error("Refer friend failed. Something went wrong");
      })
    );
  }

  onReset() {
    this.isDuplicate = false;
    this.isValidated = false;
    this.isWithinAnYear = false;
    this.initBasicInfo();
  }


  onProceed() {
    if (this.basicInfoForm.invalid) {
      this.basicInfoForm.markAllAsTouched();
      return;
    }
    this.duplicateCheck();
  }

  onClose(val: boolean) {
    this.refreshReferrals.emit(val);
    if (val) {
      setTimeout(() => {
        this.open(this.successPopup);
      }, 100);
    }
  }

  constructReqObj() {
    let data = {
      first_name: this.bf.first_name.value,
      last_name: this.bf.last_name.value,
      primary_contact_country_code: this.bf.primary_contact_country_code.value,
      primary_contact_no: this.bf.primary_contact_no.value,
      email: this.bf.email.value,
      department_id: this.f.department.value,
      experience_months: this.f.experience_months.value ? parseFloat(this.f.experience_months.value) : null,
      experience_years: this.f.experience_years.value ? parseFloat(this.f.experience_years.value) : null,
      stream_id: this.f.stream.value,
      job_code: this.jobpost?.code ? this.jobpost?.code : null,
      job_id: this.jobpost?.id ? this.jobpost.id : null,
      recommendation_by_referrer: this.f.recommendation_by_referrer.value,
      relationship_with_referrer: this.f.relationship_with_referrer.value,
      resume: this.f.resume.value,
      existing_qrms_file_key: this.f.existing_qrms_file_key.value
    }
    return data;

  }

  duplicateCheck() {
    this.isValidated = false;
    let params = this.prepareRequestParams();
    this.subscriptions.push(this.dashboardService.validateReferalCandidate(params).subscribe(
      (res: any) => {
        this.isValidated = true;
        if (res?.status == 400) {
          this.isDuplicate = true;
          if (!res.is_within_a_year) {
            this.candidateId = res.candidate_id;
            this.isWithinAnYear = false;
          } else {
            this.isWithinAnYear = true;
          }
        } else {
          this.isDuplicate = false;
        }
      },
      () => {
        this.toaster.error('Something went wrong');
      }
    ));

  }

  prepareRequestParams(): any {
    let params = {
      phone_number: null,
      phone_code: null,
      email: null,
    };
    if (this.basicInfoForm.controls["primary_contact_no"].valid) {
      params.phone_code =
        this.basicInfoForm.value.primary_contact_country_code;
      params.phone_number = this.basicInfoForm.value.primary_contact_no;
    }
    if (this.basicInfoForm.controls["email"].valid) {
      params.email = this.basicInfoForm.value.email;
    }
    if (!params.email && !params.phone_number) {
      return null;
    }
    return params;
  }

  formatName(e: any, field: string) {
    if (e.target.value) {
      let val = e.target.value;
      if (typeof val != 'number') {
        val = val.replace(/\r\n|\r|\n/g, '');
        val = val.replace(/[\u{0080}-\u{10FFFF}]/gu, '');
      }
      if (field == 'first_name' || field == 'last_name') {
        val = this.titleCasePipe.transform(val).trim();
      }
      this.basicInfoForm.controls[field].patchValue(val);
    }
  }

  trimSpace() {
    this.basicInfoForm.controls['email'].value?.length && this.basicInfoForm.controls['email'].patchValue(this.basicInfoForm.controls['email'].value.trim())
  }

  open(ref: any) {
    this.modalService.open(ref, { size: 'md', centered: true, backdrop: 'static', keyboard: false })
    .result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed with ${reason}`;
      }
    );
  }
}

<div class="container-fluid schedule-invite-form">
  <form [formGroup]="scheduleInviteForm">
    <div class="row">

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Job code</div>
            <div class="optional-field">(Optional)</div>
          </label>
          <div class="col-sm-9 single-select-dd">
            <ng-select [items]="jobCodes" [editableSearchTerm]="true" class="remove-border" bindLabel="name"
              bindValue="id" formControlName="job" [searchable]="true" [clearable]="true"
              placeholder="Select or start typing" (change)="onJobCodeChange($event)">
            </ng-select>
            <div *ngIf="f.job.touched && f.job.invalid" class="alert alert-error">
              <div *ngIf="f.job.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Department</div>
            <div class="optional-field">(Optional)</div>
          </label>
          <div class="col-sm-9 single-select-dd">
            <ng-select #dept_dropdown [items]="departments" [editableSearchTerm]="true" class="remove-border"
              bindLabel="name" bindValue="id" formControlName="department" [searchable]="true" [clearable]="true"
              (change)="onDepartmentChange($event?.id)" placeholder="Select or start typing"
              (ngModelChange)="onDepartmentModelChange($event, dept_dropdown)">
            </ng-select>
            <div *ngIf="f.department.touched && f.department.invalid" class="alert alert-error">
              <div *ngIf="f.department.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Stream</div>
            <div class="optional-field">(Optional)</div>
          </label>
          <div class="col-sm-9 single-select-dd">
            <ng-select #stream_dropdown [items]="streams" [editableSearchTerm]="true" class="remove-border"
              bindLabel="name" bindValue="id" formControlName="stream" [searchable]="true" [clearable]="true"
              (change)="onStreamChange($event?.id)" placeholder="Select or start typing"
              (ngModelChange)="onStreamModelChange($event, stream_dropdown)">
            </ng-select>
            <div *ngIf="f.stream.touched && f.stream.invalid" class="alert alert-error">
              <div *ngIf="f.stream.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Designation</div>
            <div class="optional-field">(Optional)</div>
          </label>
          <div class="col-sm-9 single-select-dd">
            <ng-select #designation_dropdown [items]="designation" class="remove-border" bindLabel="name" bindValue="id"
              formControlName="designation" [searchable]="true" [clearable]="true" [editableSearchTerm]="true"
              placeholder="Select or start typing"
              (ngModelChange)="onDesignationModelChange($event, designation_dropdown)">
            </ng-select>
            <div *ngIf="f.designation.touched && f.designation.invalid" class="alert alert-error">
              <div *ngIf="f.designation.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Type</label>
          <div class="col-sm-9 single-select-dd">
            <ng-select class="feild-value remove-border form-view" [items]="interviewTypes" [searchable]="false"
              [clearable]="false" bindValue="id" bindLabel="name" formControlName="mode" placeholder="Select"
              (change)="onUpdateInterviewMode()">
            </ng-select>
            <div *ngIf="f.mode.touched && f.mode.invalid" class="alert alert-error modal-error error-height">
              <div *ngIf="f.mode.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Location</div>
            <div class="optional-field" *ngIf="f.mode.value === 'V'">(Optional)</div>
          </label>
          <div class="col-sm-9 single-select-dd">
            <ng-select class="feild-value remove-border form-view" [items]="location" [searchable]="true"
              [editableSearchTerm]="true" [clearable]="true" bindValue="id" bindLabel="name" formControlName="location"
              placeholder="Select or start typing">
            </ng-select>
            <div *ngIf="f.location.touched && f.location.invalid" class="alert alert-error modal-error error-height">
              <div *ngIf="f.location.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Level</label>
          <div class="col-sm-9 single-select-dd">
            <ng-select class="feild-value remove-border form-view" [items]="interviewLevels" [searchable]="false"
              [clearable]="false" bindValue="id" bindLabel="name" formControlName="level" placeholder="Select"
              (change)="onUpdateInterviewLevel()">
            </ng-select>
            <div *ngIf="f.level.touched && f.level.invalid" class="alert alert-error modal-error error-height">
              <div *ngIf="f.level.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Duration</label>
          <div class="col-sm-9 single-select-dd">
            <ng-select class="feild-value remove-border form-view" [items]="interviewDurations" [searchable]="false"
              [clearable]="false" bindValue="id" bindLabel="name" formControlName="duration" placeholder="Select">
            </ng-select>
            <div *ngIf="f.duration.touched && f.duration.invalid" class="alert alert-error modal-error error-height">
              <div *ngIf="f.duration.errors?.required">Required</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">
            <div>Other Interviewers</div>
            <div class="optional-field">(Optional)</div>
          </label>
          <div class="col-sm-9 multi-select-checkbox-dd multi-select-dropdown multi-select-hide-values">
            <ng-select [items]="otherInterviewersList" [(ngModel)]="selectedOtherInterviewersList" bindValue="id"
              bindLabel="id" placeholder="Select or start typing" [ngModelOptions]="{standalone: true}"
              [closeOnSelect]="false" [multiple]="true" [clearOnBackspace]="false" class="feild-value remove-border"
              [searchFn]="customSearchFn.bind(this)" (change)="onOtherInterviewerSelection($event)"
              (open)="onOtherInterviewerDropdownOpen()">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                  [ngModelOptions]="{standalone: true}" />
                {{item.full_name}} &nbsp; [<span class="label-value-blue">{{' ' + item.email + ' '}}</span>]
              </ng-template>
            </ng-select>
          </div>
          <div class="row min-height-tab">
            <div class="col-sm-9 offset-sm-3 d-flex  align-items-center flex-wrap mt-1">
              <span class="mx-1 d-flex selected-view align-items-center"
                *ngFor="let interviewer of selectedOtherInterviewersDetails">
                {{interviewer.full_name}}
                <img class="ms-2 cursor-pointer" (click)="onRemoveOtherInterviewer(interviewer)"
                  src="./assets/images/remove.svg" alt="close">
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Interviewers</label>
          <div class="col-sm-9 multi-select-checkbox-dd multi-select-interviewers-dropdown multi-select-hide-values">
            <ng-select [items]="interviewersList" [(ngModel)]="selectedInterviewersIds" bindValue="id" bindLabel="id"
              placeholder="Select or start typing" [ngModelOptions]="{standalone: true}" [closeOnSelect]="false"
              [multiple]="true" class="feild-value remove-border" [searchFn]="customSearchFn.bind(this)" [groupBy]="groupByFn"
              [groupValue]="groupValueFn" [clearOnBackspace]="false" (add)="onInterviewerSelect($event)" 
              (remove)="onInterviewerUnSelect($event)" (open)="onInterviewerDropdownOpen()" 
              (close)="onInterviewersDropdownClose()">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ item.id }}" type="checkbox" [ngModel]="item$.selected"
                  [ngModelOptions]="{standalone: true}" />
                {{item.full_name}} &nbsp;[<span class="label-value-blue">{{' ' + item.email + ' '}}</span>]
              </ng-template>
              <ng-template ng-optgroup-tmp let-item="item">
                {{item.group == 'filtered' ? '' : 'Show all interviewers'}}
              </ng-template>
            </ng-select>
            <div *ngIf="f.interviewers.touched && f.interviewers.invalid" class="alert alert-error modal-error error-height">
              <div *ngIf="f.interviewers.errors?.required">Required</div>
              <div *ngIf="f.interviewers.errors?.invalidInterviewersCount">Maximum interviewers count is 10</div>
            </div>
            
          </div>
          <div class="min-height-tab">
            <div class="col-sm-9 offset-sm-3 d-flex  align-items-center flex-wrap mt-1">
              <span class="mx-1 d-flex selected-view align-items-center" *ngFor="let interviewer of selectedInterviewersDetails">
                {{interviewer.full_name}}
                <img class="ms-2 cursor-pointer" (click)="onRemoveSelectedInterviewer(interviewer)"
                  src="./assets/images/remove.svg" alt="close">
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Availability</label>
          <div class="col-sm-9 flexbox">
            <div class="half-block">
              <div class="date-picker align-middle position-relative agency-col">
                <date-picker-popup [item]="f.availability_from.value" [value]="f.display_availability_from.value"
                  (selectedDate)="onAvailabilityFromSelected($event, scheduleInviteForm)"
                  (isCleared)="onAvailabilityFromSelected($event, scheduleInviteForm)">
                </date-picker-popup>
              </div>
              <div *ngIf="f.display_availability_from.touched && f.display_availability_from.invalid"
                class="alert alert-error modal-error">
                <div *ngIf="f.display_availability_from.errors?.required">Required</div>
              </div>
              <div *ngIf="f.availability_from.touched && f.availability_from.invalid"
                class="alert alert-error modal-error">
                <div *ngIf="f.availability_from.errors?.minimumDate">Chosen date is a past date</div>
              </div>
              </div>
              
              <div class="half-block">
              <div class="date-picker align-middle position-relative agency-col">
                <date-picker-popup [item]="f.availability_to.value" [value]="f.display_availability_to.value"
                  (selectedDate)="onAvailabilityToSelected($event, scheduleInviteForm)"
                  (isCleared)="onAvailabilityToSelected($event, scheduleInviteForm)">
                </date-picker-popup>
              </div>
              <div *ngIf="f.display_availability_to.touched && f.display_availability_to.invalid"
                class="alert alert-error modal-error">
                <div *ngIf="f.display_availability_to.errors?.required">Required</div>
                <div *ngIf="f.display_availability_to.errors?.invalidDate">To date must be within 2 weeks</div>
              </div>
              <div *ngIf="f.availability_to.touched && f.availability_to.invalid && !f.display_availability_to.errors?.invalidDate"
                class="alert alert-error modal-error">
                <div *ngIf="f.availability_to.errors?.minimumDate">Chosen date is a past date</div>
              </div>
              </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <div class="col-sm-9 offset-sm-3 flexbox">
            <button class="send-schedule-invite-btn btn check-availability-btn" 
              (click)="getAvailabilityData()" 
              *userRole="[Role.HR, Role.HRM, Role.Director]" 
              [disabled]="
                !scheduleInviteForm.value.display_availability_from ||
                !scheduleInviteForm.value.display_availability_to || 
                !scheduleInviteForm.value.duration ||
                !scheduleInviteForm.value.interviewers.length ||
                this.selectedInterviewersDetails.length > 10
            ">
              {{ 'Check Availability' | uppercase}}
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field">
        <div class="row">
          <label class="col-sm-3 col-form-label">Invite Expires In</label>
          <div class="col-sm-9 single-select-dd flexbox">
            <div class="half-block">
              <div class="date-picker align-middle position-relative agency-col">
                <date-picker-popup [item]="f.invite_expires.value" [value]="f.display_invite_expires.value"
                  (selectedDate)="onInviteExpirySelected($event, scheduleInviteForm)"
                  (isCleared)="onInviteExpirySelected($event, scheduleInviteForm)">
                </date-picker-popup>
              </div>
              <div *ngIf="f.display_invite_expires.touched && f.display_invite_expires.invalid" class="alert alert-error modal-error">
                <div *ngIf="f.display_invite_expires.errors?.required">Required</div>
              </div>
              <div *ngIf="f.invite_expires.touched && f.invite_expires.invalid"
                class="alert alert-error modal-error">
                <div *ngIf="f.invite_expires.errors?.minimumDate">Chosen date is a past date</div>
              </div>
              </div>
              
              <div class="half-block">
                <div class="time-picker  align-middle position-relative agency-col">
                  <ngbd-timepicker-meridian-popup class="feild-value remove-border form-view"
                    (selectedTime)="onInviteExpiryTimeSelect($event);" [time]="timepickerConfig" [timeValue]="timeValue" placeholder="HH:MM">
                  </ngbd-timepicker-meridian-popup>
                  <div *ngIf="f.invite_expiry_time.touched && f.invite_expiry_time.invalid"
                  class="alert alert-error modal-error">
                  <div *ngIf="f.invite_expiry_time.errors?.required">Required</div>
                  <div *ngIf="f.invite_expiry_time.errors?.minimumTime">Chosen time is a past time</div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3 form-field d-flex align-items-center">
        <div class="row">
          <div class="d-flex align-items-center previous-interview-wrapper">
            <app-checkbox [disabled]="f.level.value === 'M' && f.show_previous_interview.value" [checked]="f.show_previous_interview.value"
              (toggle)="toggleCheckBox('show_previous_interview')"></app-checkbox>
            <span class="ms-2">Interviewers can view the previous ratings</span>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
<div class="submit-section">
  <button type="button" (click)="onCancel()" class="button-cancel btn mx-3">
    {{'cancel'| uppercase }}
  </button>
  <button type="button" (click)="onSave()" [disabled]="isEditMode ? scheduleDisabled : !scheduleInviteForm.touched || !scheduleInviteForm.valid" class="button-save btn mx-3">
    {{isEditMode ? ('Update & Send Invite' | uppercase) : ('Send schedule invite' | uppercase)}}
  </button>
</div>

<ng-template #warningPopup let-modal>
  <warning-popup (closePopup)="closeWarningPopup($event)" [popupData]="warningPopupData"></warning-popup>
</ng-template>
<ng-template #confirmRequest let-modal>
  <div class="confirmation-popup-div">
    <div class="modal-body">
      <div class="heading">{{confirmationTitle}}</div>
      <div class="body text-center">{{confirmationMessage}}
        <div class="my-3">
          <button class="btn cancel-btn" (click)="modal.dismiss('NO')">NO</button>
          <button class="btn save-btn ms-4" (click)="modal.close('YES')">YES</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<ng-template #availabilityModal let-modal>
  <app-availability-modal (closeModal)="closeWarningPopup($event)" [availabilityData]="availabilityData" 
    [availabilityRange]="scheduleInviteForm.controls['display_availability_from'].value + ' - ' + scheduleInviteForm.controls['display_availability_to'].value">
  </app-availability-modal>
</ng-template>
<div class="kebab-menu dropdown">
  <div class="btn dropdown-toggle kebab-icon" type="button" id="kebabDropdownMenuButton" data-bs-toggle="dropdown"
    aria-expanded="false">
    <figure></figure>
    <figure></figure>
    <figure></figure>
  </div>
  <ul class="dropdown-menu" aria-labelledby="kebabDropdownMenuButton">
    <ng-container *ngFor="let item of menu; let i = index">
      <ng-container *ngIf="item.isHidden != true">
        <li (click)="item.isAllowed ? confirmAction(item, confirmRequest) : ''" *userRole="item.role"
          [ngClass]="item.isAllowed ? '' : 'disabled-icon'" class="cursor-pointer">
          <span class="dropdown-item">
            <img *ngIf="item.icon" [src]="item.icon" alt="icon" /> {{item.label}}
          </span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #confirmRequest let-modal>
  <div class="confirmation-popup-div">
    <div class="modal-body">
      <div class="heading">{{confirmationTitle}}</div>
      <div class="body text-center">{{confirmationMessage}}
        <div class="my-3">
          <button class="btn cancel-btn" (click)="modal.dismiss('Cross click')">NO</button>
          <button class="btn save-btn ms-4" (click)="modal.close()">YES</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { ApiService } from '../core/http/api.service';

export const routes = {
  getResumes: `/api/resumes/`,
  getResumeById: `/api/resumes`,
  addResume: `/api/resumes`,
  addResumeComments: `/api/resumes/<int:candidate_id>/comments/`,
  scheduleInterview: `/api/interviews/`,
  getWorkBookList: '/api/workbook-list-dropdown/',
  addWorkbookCandidate: '/api/work_books/add-candidate',
  validateCandidate: '/api/candidate/validate-email-phone-number/',
  createCandidate: '/api/candidate/create/',
  salaryRequestParams: '/api/candidates/salary-request-email',
  offerLetterEditorParams: '/api/candidates/send-offer-letter',
  feedbackEmailParams: '/api/candidate/feedback-email',
  candidates: `/api/candidates/`,
  candidateComments: `/api/candidate/comments/`,
  virtualTeam: `/api/space-users`,
  interviewSettings: `/api/organizational_admin/settings/interview/`,
  offer: '/api/offer/',
  offerDetailsEditorParams:`/api/candidates/offer/update-status`,
  updateHiringStatus:`/api/candidates/offer/update-hiring-status/`,
  uploadCandidateResume: '/api/candidates/upload-resume/',
};

@Injectable({
  providedIn: 'root',
})
export class ResumeService {
  private resumeData = new BehaviorSubject<any>({} as any);
  reloadResume = new Subject<void>();
  reloadActivity = new Subject<void>();
  constructor(
    private apiService: ApiService
  ) { }

  // APIs

  getResumes(url: string, params?: any) {
    return this.apiService.get(url, params);
  }

  getResumeById(id: string, params?: any): Observable<any> {
    return this.apiService.get(`${routes.getResumeById}/${id}/`, params);
  }

  createResume(data: any) {
    return this.apiService.post(`${routes.addResume}/`, data);
  }

  updateResume(id: any, data: any) {
    return this.apiService.put(`${routes.addResume}/${id}/`, data);
  }

  getResumeComments(id: any) {
    return this.apiService.get(`/api/candidates/${id}/comments/`);
  }

  addResumeComments(id: any, data: any) {
    return this.apiService.post(`/api/candidates/${id}/comments/`, data);
  }

  updateResumeStatus(id: any, data: any) {
    return this.apiService.patch(`/api/resumes/${id}/update-status/`, data);
  }

  scheduleInterview(data: any) {
    return this.apiService.post(routes.scheduleInterview, data);
  }

  editInterview(data: any, id: number) {
    return this.apiService.put(`${routes.scheduleInterview}${id}/`, data);
  }

  downloadFile(id: string, type: string, params: any = {}) {
    return this.apiService.get(
      `${routes.getResumeById}/file-download/${id}/${type}/`,
      params
    );
  }

  downloadToe(id: string) {
    return this.apiService.download(
      `/api/candidates/${id}/terms-of-employment/`
    );
  }

  sendSalaryRequestEmail(id: any): Observable<any> {
    return this.apiService.get(
      `/api/candidates/send-salary-request-email/${id}/`
    );
  }

  getWorkBookList() {
    return this.apiService.get(`${routes.getWorkBookList}`);
  }

  addCandidates(data: any) {
    return this.apiService.post(`${routes.addWorkbookCandidate}`, data);
  }

  validateCandidateDetails(params: any): Observable<any> {
    return this.apiService.get(routes.validateCandidate, params);
  }

  createCandidate(data: any) {
    return this.apiService.post(`${routes.createCandidate}`, data);
  }

  createCandidateGet(params: any) {
    return this.apiService.get(`${routes.createCandidate}`, params);
  }

  getSalaryRequestParams(id: any) {
    return this.apiService.get(`${routes.salaryRequestParams}/${id}`);
  }
  sendSalaryRequestMail(data: any, id: any) {
    return this.apiService.post(`${routes.salaryRequestParams}/${id}`, data);
  }
  getOfferLetterEditorParams(id: any) {
    return this.apiService.get(`${routes.offerLetterEditorParams}/${id}`);
  }
  sendOfferLetterMail(data: any, id: any) {
    return this.apiService.post(
      `${routes.offerLetterEditorParams}/${id}`,
      data
    );
  }

  getFeedbackEmailParams(id: any) {
    return this.apiService.get(`${routes.feedbackEmailParams}/${id}`);
  }

  sendFeedbackEmail(data: any, id: any) {
    return this.apiService.post(`${routes.feedbackEmailParams}/${id}`, data);
  }

  updateHiringDetails(data: any, id: any): Observable<any> {
    return this.apiService.download(`${routes.candidates}${id}/view-offer-letter/`, data, undefined, true);
  }

  getAppointmentLetter(data: any, id: any): Observable<any> {
    return this.apiService.download(`${routes.candidates}${id}/view-appointment-letter/`, data, undefined, true);
  }

  setCandidateData(data: any) {
    this.resumeData.next(data);
  }

  getCandidateData() {
    return this.resumeData.asObservable();
  }
  forwardCandidateResume(id:any,data:any){
    return this.apiService.post(`${routes.candidates}${id}/forward-resume/`, data);
  }

  deleteCandidateComment(id: any, object_type: string) {
    return this.apiService.delete(`${routes.candidateComments}${id}/?object_type=${object_type}`);
  }

  getSpaceUserVirtualTeam(id: any) {
    return this.apiService.get(`${routes.virtualTeam}/${id}/`);
  }
  getOfferLetter(id: any): Observable<any> {
    return this.apiService.download(`${routes.offer}${id}/view-offer-letter/`, null, undefined, false);
  }

  getOfferDetails(id: any): Observable<any> {
    return this.apiService.get(`/api/candidates/${id}/offers/`);
  }

  getInterviewSettings() {
    return this.apiService.get(routes.interviewSettings);
  }

  getOfferDetailsEditorParams(id: any) {
    return this.apiService.get(`${routes.offerDetailsEditorParams}/${id}`);
  }
  
  sendOfferApproveMail(data: any, id: any) {
    return this.apiService.post(
      `${routes.offerDetailsEditorParams}/${id}/`,
      data
    );
  }
  createOffer(id: any, data: any) {
    return this.apiService.post(`/api/candidates/${id}/offers/`, data);
  }

  updateHiringStatus(id: any, data: any) {
   return this.apiService.put(`${routes.updateHiringStatus}${id}/`, data);
  }

  downloadAttachment(id: number | undefined, data:any) {
    return this.apiService.get(
      `${routes.candidates}offers/file-download/${id}/`, data
    );
  }

  updateOffer(id: any, data: any, offer_id: any) {
    return this.apiService.put(`/api/candidates/${id}/offers/${offer_id}/`, data);
  }

  getOfferById(id: any, offer_id: any): Observable<any> {
    return this.apiService.get(`/api/candidates/${id}/offers/${offer_id}/`);
  }
  
  getApproveAndSendOffer(offerId:any, isApproveAndSend:boolean =false ){
    let url = ``;
    if (!isApproveAndSend) {
      url = `/api/offer/${offerId}/send-offer-letter/`;
    } else {
      url = `/api/offer/${offerId}/send-and-approve-offer-letter/`;
    }
    return this.apiService.get(url);
  }

  approveAndSendOffer(offerId: any, data: any, isApproveAndSend: boolean = false) {
    let url = ``;
    if (!isApproveAndSend) {
      url = `/api/offer/${offerId}/send-offer-letter/`;
    } else {
      url=`/api/offer/${offerId}/send-and-approve-offer-letter/`;
    }
    return this.apiService.post(url, data);
  }

  uploadCandidateResume(data: any) {
    return this.apiService.post(`${routes.uploadCandidateResume}`, data);
  }

  getCandidateOverview(id: string): Observable<any> {
    return this.apiService.get(`${routes.candidates}${id}/overview/`);
  }

  getCandidateProfessionalDetails(id: string): Observable<any> {
    return this.apiService.get(`${routes.candidates}${id}/professional-details/`);
  }

  getCandidateReferralDetails(id: string): Observable<any> {
    return this.apiService.get(`${routes.candidates}${id}/referral-and-others/`);
  }

  getCandidateHiringDetails(id: string): Observable<any> {
    return this.apiService.get(`${routes.candidates}${id}/hiring-details/`);
  }

  getCandidateActivityLog(candidate: any, page: any) {
    return this.apiService.get(
      `${routes.candidates}${candidate}/activity-log/?page=${page}`
    );
  }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResumeService } from 'src/app/resume/resume.service';
import { OfferDetails } from '../../_models/offer-details.model';
import { AppModel } from '../../_models/utils';
import { CustomValidators } from '../../_services/custom-validators.service';
import { dateFormatForDatePipe } from '../../_services/format';
import { regex } from '../../_services/regex';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss']
})
export class CreateOfferComponent implements OnInit {
  offerForm!: FormGroup;
  currencyChoice: any = [];
  designations: any = [];
  departments: any = [];
  phoneCodes: any = [];
  interviewLocations = [];
  employmentTypes: any = [];
  contractDurations: any = [];
  localDateFormat: string = dateFormatForDatePipe;
  subscriptions: Subscription[] = [];
  designationsFiltered: any = [];
  isFresherCandidate: boolean = false;
  datepickerConfig: any = {
    id: "joining_date",
    value: undefined,
    minDate: dayjs(),
    maxDate: dayjs.Dayjs,
  };
  isOfferLetterSentOrOfferAccepted: boolean = false;
  generateNewOffer: boolean = false;
  editDisabled: boolean = false;
  warningText: string = ''
  isIndirectContract: boolean = false;
  isPermanentContract: boolean = false;
  showOtherInfo: boolean = false;
  hybridType: any;
  hybridTypes:any;
  workModes:any;

  @Input() offerDetails: OfferDetails | undefined;
  @Input() candidateData: any;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() reloadCandidateAndActivityLog: EventEmitter<any> = new EventEmitter();
  @Input() isEdit: boolean = false;

  @ViewChild('department_ref') departmentRef!: NgSelectComponent;
  @ViewChild('designation_ref') designationRef!: NgSelectComponent;

  constructor(
    private formbuilder: FormBuilder,
    private appService: AppService,
    private toaster: ToastrService,
    private customValidatorService: CustomValidators,
    private datePipe: DatePipe,
    private resumeService: ResumeService,
  ) {
    this.offerForm = this.formbuilder.group({
      employment_type: [null, Validators.required],
      remote: [false],
      remote_location: [null, Validators.maxLength(50)],
      department_id: [null, Validators.required],
      designation_id: [null, Validators.required],
      joining_location_id: [null, Validators.required],
      joining_date: [null, [this.customValidatorService.minimumDate(this.datePipe.transform(new Date(), this.localDateFormat)!)]],
      joining_bonus: [
        null,
        [Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)],
      ],
      onboarding_type: [null, Validators.required],
      comment: ['', [Validators.maxLength(500)]],
      work_mode:[null],
      no_of_hybrid_days:[null],
      hybrid_type:['week'],
    });
  }

  ngOnInit(): void {
    this.isFresherCandidate = this.candidateData.is_fresher;
    this.checkForContract(this.candidateData);
    this.setAdditionalFields();
    this.getDropdownsData();
    this.offerDetails && (this.isEdit ? this.getOfferById(this.offerDetails?.id) : this.patchForm(this.offerDetails));
    this.isOfferLetterSentOrOfferAccepted = (this.offerDetails && this.candidateData.status_code === 'S' 
      && ['S', 'OA'].includes(this.offerDetails?.status)) ? true : false;
  }

  get f() {
    return this.offerForm.controls;
  }

  onEmploymentTypeChange() {
    this.checkForContract(this.offerForm.value);
    if(this.offerForm.value.employment_type === 'P') {
      this.designations.forEach((element: any) => {
        if(this.offerForm.controls.designation_id.value === element.id) {
          if(['DC', 'QAC'].includes(element.code)) {
            this.offerForm.controls.designation_id.setValue(null);
            if (this.designationRef) {
              this.designationRef.clearModel();
              this.designationRef.searchTerm = '';
            }
          }
        }
      });
    } 
    this.setDesignation(this.offerForm.controls.department_id.value);
  }

  getOfferById(offer_id: number) {
    this.subscriptions.push(
      this.resumeService.getOfferById(this.candidateData.id, offer_id).subscribe(
        (res: AppModel<OfferDetails>) => {
          if (res?.status == 'OK') {
            this.offerDetails = res.data;
            this.patchForm(this.offerDetails);
            this.detectChanges();
          }
        }
      )
    );
  }

  setAdditionalFields() {
    if(this.isIndirectContract) {
      this.offerForm.addControl('monthly_rate_card_currency', new FormControl('INR'));
      this.offerForm.addControl('monthly_rate_card', new FormControl(null,
        [Validators.required, Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));
      this.offerForm.addControl('contract_duration',new FormControl(null));
    }
    else {
      this.offerForm.addControl('currency', new FormControl('INR'));
      this.offerForm.addControl('offered_salary', new FormControl(null,
        [Validators.required, Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));

      this.offerForm.addControl('joining_bonus_currency', new FormControl('INR'));
      this.offerForm.addControl('joining_bonus', new FormControl(null,
        [Validators.pattern(regex.withoutLeadingZero), Validators.max(9999999)]));

      if (this.isFresherCandidate) {
        this.offerForm.addControl('stipend', new FormControl(null,
          [Validators.pattern(regex.withoutLeadingZero), Validators.max(99999)]));
        this.offerForm.addControl('stipend_currency', new FormControl('INR'));
      }
    }
  }

  patchForm(value: OfferDetails, triggeredByToggle: boolean = false) {
    if (triggeredByToggle && this.editDisabled) return; // return if there is no change in form values when toggling the generate offer checkbox
    value = JSON.parse(JSON.stringify(value));

    //fix - formcontrol values are not reflected unless we focus on the ng select component
    if (this.departmentRef) {
      this.departmentRef.clearModel();
      this.departmentRef.searchTerm = '';
    }
    if (this.designationRef) {
      this.designationRef.clearModel();
      this.designationRef.searchTerm = '';
    }
    this.setDesignation(value.department?.id);

    this.offerForm.controls['employment_type'].setValue(value.employment_type);
    this.offerForm.controls['remote'].setValue(value.remote);
    this.offerForm.controls['remote_location'].setValue(value.remote_location);
    this.offerForm.controls['work_mode'].setValue(value.work_mode);
    this.offerForm.controls['hybrid_type'].setValue(value?.hybrid_type??'week');
    this.offerForm.controls['no_of_hybrid_days'].setValue(value.no_of_hybrid_days);
    this.offerForm.controls['department_id'].setValue(value.department?.id);
    this.offerForm.controls['designation_id'].setValue(value.designation?.id);

    this.offerForm.controls['onboarding_type'].setValue(value.onboarding_type);
    if(this.offerForm.controls['work_mode']?.value) this.setWorkMode(this.offerForm.controls['work_mode']?.value);
    this.offerForm.controls['joining_location_id'].setValue(value.joining_location?.id);
    this.offerForm.controls['joining_date'].setValue(value.joining_date);
    this.offerForm.controls['comment'].setValue('');

    if (this.isIndirectContract) {
      this.offerForm.controls['monthly_rate_card_currency'].setValue(value.monthly_rate_card_currency);
      this.offerForm.controls['monthly_rate_card'].setValue(value.monthly_rate_card);

      let contract_duration = this.contractDurations.find((c: any) => c.name === value.contract_duration)?.id;
      this.offerForm.controls['contract_duration'].setValue(contract_duration);
    } else {
      this.offerForm.controls['offered_salary'].setValue(value.offered_salary);
      this.offerForm.controls['joining_bonus'].setValue(value.joining_bonus);
      this.offerForm.controls['currency'].setValue(value.currency);
      this.offerForm.controls['joining_bonus_currency'].setValue(value.joining_bonus_currency);
    }

    if(this.isFresherCandidate) {
      this.offerForm.controls['stipend_currency'].setValue(value.stipend_currency || 'INR');
      this.offerForm.controls['stipend'].setValue(value.stipend);
    }

    if (value.joining_date) {
      this.datepickerConfig = {
        ...this.datepickerConfig,
        minDate: dayjs(value.joining_date) < dayjs() ? dayjs(value.joining_date) : dayjs(),
        value: { startDate: dayjs(value.joining_date), endDate: dayjs(value.joining_date), }
      };
    } else {
      this.datepickerConfig.value = undefined;
    }
    
    this.setDesignationDropdown(value.designation);
  }

  getDropdownsData() {
    this.subscriptions.push(this.appService.generalDropdowns.subscribe(
      (data: any) => {
        const {
          Currency_choice = [],
          Designation = [],
          Joining_location = [],
          Department = [],
          Employment_type = [],
          Contract_duration = [],
          work_mode = [],
          hybrid_type = []
        } = data;

        this.currencyChoice = Currency_choice;
        this.designations = Designation;
        this.departments = Department;
        this.interviewLocations = Joining_location;
        this.employmentTypes = this.isEdit ? Employment_type : Employment_type.filter((type: any) => type.id !== 'IC');
        this.contractDurations = Contract_duration;
        this.workModes = work_mode;
        this.hybridTypes = hybrid_type;
        this.setDesignation(this.offerDetails?.department?.id);
      }
    ))
  }

  onDateSelected(e: any) {
    if (e && e.joining_date != "Invalid Date") {
      this.f.joining_date.setValue(e.joining_date);
    }
    else {
      this.f.joining_date.setValue(null);
    }
  }

  onDepartmentChange(department: any, designationRef: NgSelectComponent) {
    designationRef.clearModel();
    designationRef.searchTerm = '';
    if (department?.id) {
      this.setDesignation(department.id);
    } else {
      this.setDesignation();
    }
  }

  setDesignationDropdown(designation: any) {
    //remove the previously added temporary designation
    let last: any = this.designations[this.designations.length - 1];
    if (last.is_temporary) {
      this.designations.pop();
    }
    //add current temporary designation to the dropdown if not already present
    let current: any = this.designations.find((d: any) => d?.id === designation?.id);
    if (!current) {
      let tempDesignation = {...designation, is_temporary: true};
      this.designations = [...this.designations, tempDesignation];
    }
  }

  setDesignation(dept_id?: number) {
    if(this.isPermanentContract) {
      this.designationsFiltered = this.designations.filter((item: any) => (item.department_id === dept_id
        || this.f.designation_id?.value == item.id || item.is_temporary || item.id ===this.offerForm.controls['designation_id'].value) && !['DC', 'QAC'].includes(item.code));
    } else {
      this.designationsFiltered = this.designations.filter((item: any) => item.department_id === dept_id
        || this.f.designation_id?.value == item.id || item.is_temporary || item.id === this.offerForm.controls['designation_id'].value);
    }
  }

  submitOffer() {
    if (this.offerForm.invalid) {
      this.offerForm.markAllAsTouched();
      return;
    }
    let payload = this.constructPayload();
    this.isEdit ? this.editOffer(payload) : this.createOffer(payload); 
  }

  editOffer(payload: any) {
    payload = {
      ...payload,
      generate_new_offer: this.generateNewOffer,
    }
    this.resumeService.updateOffer(this.candidateData.id, payload, this.offerDetails?.id).subscribe((res: any) => {
      if (res.status === 200) {
        this.toaster.success('Offer has been successfully updated');
        this.reloadCandidateAndActivityLog.emit();
      } else if (res.status === 400) {
        this.toaster.error(res.message);
      } else {
        this.toaster.error('Could not update the offer. Please try again later');
      }
    }, (err: any) => {
      this.toaster.error('Could not update the offer. Please try again later');
    })
  }

  createOffer(payload: any) {
    this.resumeService.createOffer(this.candidateData.id, payload).subscribe((res: any) => {
      if (res.status === '200') {
        this.toaster.success('Offer has been successfully created');
        this.reloadCandidateAndActivityLog.emit();
      } else {
        this.toaster.error('Could not create an offer. Please try again');
      }
    }, (err: any) => {
      this.toaster.error('Could not create an offer. Please try again');
    })
  }

  constructPayload() {
    let payload = this.offerForm.value;
    if (this.isIndirectContract) {
      payload = {
        ...payload,
        monthly_rate_card: payload.monthly_rate_card ? parseInt(payload.monthly_rate_card) : null,
      }
    } else {
      payload = {
        ...payload,
        joining_bonus: ((this.isEdit || this.candidateData?.is_allow_joining_bonus) && payload.joining_bonus)
          ? parseInt(payload.joining_bonus) : null,
        offered_salary: payload.offered_salary ? parseInt(payload.offered_salary) : null,
      }
    }
    if (this.isFresherCandidate) {
      payload = {
        ...payload,
        stipend: payload.stipend ? parseInt(payload.stipend) : null,
      }
    }
    payload.no_of_hybrid_days = payload.no_of_hybrid_days? Number(payload.no_of_hybrid_days):null;
    payload.hybrid_type = payload.no_of_hybrid_days?payload.hybrid_type:'week';
    return payload;
  }

  cancel() {
    this.onCancel.emit();
  }


  toggleCheckbox() {
    this.generateNewOffer = !this.generateNewOffer;
    if (!this.generateNewOffer && this.offerDetails) {
      this.patchForm(this.offerDetails, true);
    }
  }

  getWarningText() {
    if (this.isEdit) {
      if (this.offerDetails && this.isOfferLetterSentOrOfferAccepted) {
        return this.generateNewOffer ? 'New offer will be generated.' :
        "New version of the offer will not be generated. <br/> If you want to generate a new offer, please tick the 'Generate New Offer' option.";
      } else {
        return 'Any updates made here will generate a new version of the offer. The details will be reflected with that in candidate details.'
      }
    } else {
      return 'You are about to create offer for the candidate. Any updates made here will be reflected with that in candidate details.'
    }
  }

  checkForContract(candidateData: any) {
    this.isIndirectContract = ['Indirect Contract', 'IC'].includes(candidateData.employment_type);
    this.isPermanentContract = ['Permanent', 'P'].includes(candidateData.employment_type);
  }

  detectChanges() {
    this.editDisabled = true;
    setTimeout(() => {
      this.subscriptions.push(this.offerForm.valueChanges.subscribe((data: any) => {
        this.editDisabled = false;
      }));
    }, 100)
  }

  formatName(e: any, field: string) {

    if (e.target.value) {
      let val = e.target.value.trim();
      this.offerForm.controls[field].patchValue(val);
    }
  }

  onWorkModeChange(workMode:any){
    if(workMode.id === 'remote') {
      this.offerForm.controls['remote_location']?.setValidators([Validators.required,Validators.maxLength(50)]);
      this.offerForm.controls['no_of_hybrid_days']?.clearValidators();
      this.offerForm.controls['no_of_hybrid_days']?.setValue(null);
    } else if (workMode.id === 'hybrid') {   
        this.hybridType = this.offerForm.controls['hybrid_type']?.value == 'week' ? regex.daysOfTheWeek:regex.daysOfTheMonth;
        this.offerForm.controls['no_of_hybrid_days']?.setValidators(Validators.pattern(this.hybridType));
        this.offerForm.controls['remote_location']?.clearValidators();
        this.offerForm.controls['remote_location']?.setValue(null);
    } else {
      this.offerForm.controls['remote_location']?.clearValidators();
      this.offerForm.controls['no_of_hybrid_days']?.clearValidators();
      this.offerForm.controls['no_of_hybrid_days']?.setValue(null);
      this.offerForm.controls['remote_location']?.setValue(null);
    }
    this.offerForm.controls['no_of_hybrid_days']?.updateValueAndValidity();
    this.offerForm.controls['remote_location']?.updateValueAndValidity();
    
  }

  onHybridTypeChange(hybridType:any) {
    this.offerForm.controls['no_of_hybrid_days']?.clearValidators();
    this.hybridType = hybridType.id === 'week' ? regex.daysOfTheWeek:regex.daysOfTheMonth;
    this.offerForm.controls['no_of_hybrid_days']?.setValidators(Validators.pattern(this.hybridType));
    this.offerForm.controls['no_of_hybrid_days']?.updateValueAndValidity();
  }

  setWorkMode(val:any) {
    let workMode = { id:val }
    this.onWorkModeChange(workMode);
  }
}


import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

import { FileUploadService } from './file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  file: File | undefined; // Variable to store file
  blobUrl: string = '';
  fileSizeError: boolean = false;
  file_id!: number | undefined;
  fileTypeError: boolean = false;
  id!: string;
  mandatoryFileMessage: string = '';
  disableClick: boolean = false;
  focusElement: boolean = false;
  @Input()
  fileLink: string = '';
  @Input() set resetFile(value: any) {
    if (!value) this.reset();
  }
  fileName: string = '';

  @Input()
  btnName: string = '';
  @Input()
  fileType: string = '';
  @Input()
  type: string = '';
  @Input()
  size: number = 0.5;
  @Input() fileData: any = undefined;
  @Input() fetchPrevFile: boolean = true;
  @Input() toggleEnabled: boolean = false;
  @Input() uploadDisabled: boolean = false;
  @Input() validationMessage: string = '';
  @Input() isHintInRow: boolean = false;
  @Output() fileChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileFetched: EventEmitter<any> = new EventEmitter<any>();
  @Input() resumeData: any = {}
  @Input() disableRemoveIcon: boolean = false;
  @Input() tabIndex: number | null = null;
  @Input() filePreviewOnly: boolean = false;
  @Input() showHint: boolean = true;
  @Input() showUploadedFile: boolean = true;
  @ViewChild('uploader')
  InputVar!: ElementRef;
  fileSizeText: string = '500 KB';
  isNewFile: boolean = false;
  @Input() mode: string = 'candidate';
  @Input() isUpperCase: boolean = false;
  @Input() acceptType:string='';

  constructor(
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    if (this.fetchPrevFile) {
      this.fetchFileLink();
    }
  }

  // On file Select
  onChange(event: any) {
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.file = event.target.files[0];
    this.uploadFile(this.file);
    this.isNewFile = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //validation message for resume
    if (changes?.validationMessage)
      this.mandatoryFileMessage = changes?.validationMessage?.currentValue;

    if (this.resumeData && this.resumeData.name && this.resumeData.link) {
      this.setFile(this.resumeData);
    }

    if (changes?.disableRemoveIcon) {
      this.disableClick = changes?.disableRemoveIcon?.currentValue
    }

    this.setFileSizeText();
  }

  setFileSizeText() {
    if (this.size < 1) {
      this.fileSizeText = `${this.size * 1000} KB`;
    } else {
      this.fileSizeText = `${this.size} MB`;
    }
  }


  uploadFile(file: any) {
    let acceptableFileTypes: any = {
      'PS': ['doc', 'docx', 'pdf', 'odt', 'odx',],
      'PH': ['png', 'jpeg', 'jpg'],
      'OFFR': ['pdf'],
      'OTH': ['doc', 'docx', 'pdf', 'odt', 'odx', 'png', 'jpeg', 'jpg',],
    }
    let fileType = ['RES', 'EC', 'SC', 'RL'].includes(this.fileType) ? 'OTH' : this.fileType;
    if (!this.checkFileExtension(this.file!, acceptableFileTypes[fileType])) {
      this.fileTypeError = true;
      this.removeFile();
      return;
    }

    if (file?.size / 1048576 > this.size) {
      this.fileSizeError = true;
      return;
    }

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('document', file, file.name);
    formData.append('file_type', this.fileType);
    // invoke upload API
    if (this.mode === 'candidate') {
      this.fileUploadService.upload(formData).subscribe(
        (data: any) => {
          //remove old file on adding new one if it is present
          if (this.file_id) {
            this.fileChanged.emit(this.file_id);
          }
          this.file_id = data.data?.id;
          this.onUploadSuccess(data);
        },
        (error: any) => {
          this.toaster.error(`Failed to upload ${this.btnName}. Please try again after some time`);
        }
      );
    } else if (this.mode === 'interview') {
      this.fileUploadService.uploadInterviewRatingAttachment(formData).subscribe(
        (data: any) => {
          //remove old file on adding new one if it is present
          if (this.fileLink) {
            this.fileChanged.emit('');
          }
          this.onUploadSuccess(data);
        },
        (error: any) => {
          this.toaster.error(`Failed to upload ${this.btnName}. Please try again after some time`);
        }
      );
    } else if (this.mode === 'offer') {
      this.fileUploadService.uploadOffer(formData).subscribe(
        (data: any) => {
          //remove old file on adding new one if it is present
          if (this.file_id) {
            this.fileChanged.emit(this.file_id);
          }
          this.file_id = data.data?.id;
          this.onUploadSuccess(data);
        },
        (error: any) => {
          this.toaster.error(`Failed to upload ${this.btnName}. Please try again after some time`);
        }
      );
    }
  } 

  onUploadSuccess(data: any) {
    this.fileLink = data.data?.link || '';
    this.fileName = data.data?.name || '';
    this.truncateFileName();
    this.fileChanged.emit(data.data);
  }

  // Reset file input
  reset() {
    this.fileTypeError = false;
    this.removeFile();
  }

  removeFile() {
    if (this.InputVar?.nativeElement) {
      this.InputVar.nativeElement.value = '';
    }
    this.file = undefined;
    this.fileChanged.emit(this.file_id);
    this.file_id = undefined;
    this.fileName = '';
    this.fileLink = '';
  }

  fetchFileLink() {
    if (this.fileType == 'EC') {
      this.fileLink = this.fileData?.link;
      this.fileName = this.fileData?.name || '';
      this.file_id = this.fileData?.id;
      this.truncateFileName();
      this.fileFetched.emit(this.fileData);
      this.isNewFile = false;
    } else {
      this.id && this.fileUploadService
        .downloadFile(this.id, this.fileType)
        .subscribe((res: any) => {
          this.setFile(res)
          this.isNewFile = false;
        });
    }
  }

  setFile(res: any) {
    this.fileLink = res.link;
    this.fileName = res.name;
    this.file_id = res.id;
    this.truncateFileName();
    this.fileFetched.emit(res);
  }

  download(newTab: boolean = false) {
    let downloadOnlyTypes = ['doc', 'docx'];
    let fileNameArray = this.fileName?.split('.')
    let currentFileType = fileNameArray?.[fileNameArray?.length - 1];
    if (downloadOnlyTypes.includes(currentFileType)) {
      window.open(this.fileLink, '_self');
    } else {
      !newTab && window.open(this.fileLink, '_self');
      newTab && window.open(this.fileLink, '_blank');
    }
  }

  checkFileExtension(file: File, exts: string[]) {
    var extension = file.name
      .substr(file.name.lastIndexOf('.') + 1)
      .toLowerCase();
    var allowedExtensions = exts;
    if (file.name.length > 0) {
      if (allowedExtensions.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    } else return false;
  }

  truncateFileName() {
    if (this.fileName?.length > 16) {
      this.fileName =
        this.fileName.slice(0, 4) +
        '...' +
        this.fileName.slice(this.fileName.length - 8, this.fileName.length);
    }
  }
  onFocusElement(event: KeyboardEvent) {
    if (event?.code == 'Tab') {
      this.focusElement = true;
    }
  }

  removeFocus() {
    this.focusElement = false;
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from './../../shared/_components/loader/loader.service';
import { StorageService } from 'src/app/shared/_services/storage.service';

import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { apisUsedInMultiplePages, pagesWithoutLoader, getApisWithoutLoader, postApisWithoutLoader } from 'src/constants/constants';
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  getApisWithoutLoader: string[] = getApisWithoutLoader;
  postApisWithoutLoader: string[] = postApisWithoutLoader;
  apisUsedInMultiplePages: string[] = apisUsedInMultiplePages;
  pagesWithoutLoader: string[] = pagesWithoutLoader;

  constructor(
    private loaderService: LoaderService,
    private storageService: StorageService,
    private performanceMonitoringService: AngularFirePerformance,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //For performance monitoring of api
    let trace: any;
    this.performanceMonitoringService.trace(request.url).then(t => {
      trace = t;
      trace?.start();
    });

    this.totalRequests++;
    this.showLoader(request.url, request.method);

    request = request.clone({

      setHeaders: {
        'Accept': 'application/json',
        'token': this.storageService.get('token') ? this.storageService.get('token') : ''
      },

      withCredentials: true
    });

    return next.handle(request).pipe(
      finalize(() => {
        trace?.stop();
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.hideLoader(request.url);
        }
      })
    );
  }

  showLoader(url: string, method: string) {
    //api url without domain and params
    const currentApi = url?.split('/api/')[1]?.split('?')[0];
    if ((this.getApisWithoutLoader.includes(currentApi) && method == 'GET') || (this.postApisWithoutLoader.includes(currentApi) && method == 'POST')) {
      if (this.apisUsedInMultiplePages.includes(currentApi)) {
        if (this.pagesWithoutLoader.includes(document.title)) {
          return;
        }
      } else {
        return;
      }
    }
    this.loaderService.show();
  }

  hideLoader(url: string) {
    //api urls where loader hiding not to be handled in interceptor
    const currentApi = url?.split('/api/')[1]?.split('?')[0];
    if (!['login/', 'logout/'].includes(currentApi)) {
      this.loaderService.hide();
    }
  }
}

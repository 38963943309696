import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface popupDataModel {
  isWarning?: boolean;
  title: string;
  description: string;
  warningDesc?: string;
  closeBtn?: string;
}

@Component({
  selector: 'warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent {

  @Input()
  popupData!: popupDataModel;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onCancel() {
    this.closePopup.emit();
  }

}

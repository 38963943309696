<div class="confirmation-popup-div refer-a-friend-popup">
    <div class="modal-body">
        <div class="heading">Refer a Friend</div>
        <div class="body">
            <p class="label-value text-center">Refer your friends now and win exciting rewards!</p>
            <div *ngIf="jobpost" class="text-center mb-2 rectangle">
                <span *ngIf="jobpost?.title" class="label-text">Job Post: </span>
                <span *ngIf="jobpost?.title" class="placeholder me-4">{{jobpost?.title}}</span>
                <span *ngIf="jobpost?.code" class="label-text">Job Code: </span>
                <span class="placeholder">{{jobpost?.code}}</span>
            </div>
            <form [formGroup]="basicInfoForm" tabindex="0">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label>Candidate Name</label>
                        <div class="d-flex">
                            <div class="me-2 w-100">
                                <input placeholder="First Name" autocomplete="off" type="text" name="first_name" class="form-control" formControlName="first_name" (focusout)="formatName($event, 'first_name')" [ngClass]="{'disable-bg': isValidated}" />

                                <div class="alert alert-error" *ngIf="bf.first_name?.invalid && bf.first_name?.touched">
                                    <div *ngIf="bf.first_name.errors?.maxlength">
                                        Max 50 characters
                                    </div>
                                    <div *ngIf="bf.first_name.errors?.required">
                                        Required
                                    </div>
                                    <div *ngIf="bf.first_name.errors?.pattern">
                                        Invalid name
                                    </div>
                                </div>
                            </div>

                            <div class="ms-2 w-100">
                                <input placeholder="Last Name" autocomplete="off" type="text" name="last_name" class="form-control" formControlName="last_name" (focusout)="formatName($event, 'last_name')" [ngClass]="{'disable-bg': isValidated}" />
                                <div *ngIf="bf.last_name?.invalid && bf.last_name?.touched">
                                    <div class="alert alert-error" *ngIf="bf.last_name.errors?.maxlength">
                                        Max 50 characters
                                    </div>
                                    <div class="alert alert-error" *ngIf="bf.last_name.errors?.pattern">
                                        Invalid name
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label>Email</label>
                        <input (keyup)="trimSpace()" placeholder="Enter Email Address" autocomplete="off" type="text" formControlName="email" name="email" class="form-control" (focusout)="formatName($event, 'email')" [ngClass]="{'disable-bg': isValidated}" />
                        <div class="alert alert-error" *ngIf="bf.email?.invalid && bf.email?.touched">
                            <div *ngIf="bf.email.errors?.pattern">
                                Inavlid email
                            </div>
                            <div *ngIf="bf.email.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label>Phone Number</label>
                        <div class="form-control d-flex align-items-center contact" [ngClass]="{'disable-bg': isValidated}">
                            <span class="border-right me-1 pe-2 input-prefix c">
                <ng-select [ngClass]="{'disable-bg': isValidated}" [items]="countryCode" class="remove-border" bindLabel="value" bindValue="value"
                  formControlName="primary_contact_country_code" [searchable]="false" [clearable]="false"></ng-select>
              </span>
                            <input placeholder="Enter Phone Number" formControlName="primary_contact_no" autocomplete="off" type="text" class="hide-arrow w-100 border-0 o-none" [ngClass]="{'disable-bg': isValidated}" />
                        </div>
                        <div class="alert alert-error" *ngIf="bf.primary_contact_no?.invalid && bf.primary_contact_no?.touched">
                            <div *ngIf="bf.primary_contact_no.errors?.pattern">
                                Invalid contact number
                            </div>
                            <div *ngIf="bf.primary_contact_no.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                    </div>
                </div>
            </form>


            <form [formGroup]="referForm" *ngIf="isValidated && (!isDuplicate || !isWithinAnYear)">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label>Department</label>
                        <ng-select [editableSearchTerm]="true" formControlName="department" [items]="department" class="remove-border" bindLabel="name" bindValue="id" [searchable]="true" [clearable]="true" (change)="onDepartmentChange($event?.id)" #dept_dropdown placeholder="Select or start typing"
                            (ngModelChange)="onDepartmentModelChange($event, dept_dropdown)"> </ng-select>
                        <div *ngIf="f.department?.invalid && f.department?.touched">
                            <div class="alert alert-error" *ngIf="f.department.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label>Stream</label>
                        <ng-select [editableSearchTerm]="true" #stream_dropdown formControlName="stream" [items]="stream" class="remove-border" bindLabel="name" bindValue="id" [searchable]="true" [clearable]="true" (change)="onStreamChange($event?.id)" (ngModelChange)="onStreamModelChange($event, stream_dropdown)"
                            placeholder="Select or start typing"></ng-select>
                        <div class="alert alert-error" *ngIf="f.stream?.invalid && f.stream?.touched">
                            <div *ngIf="f.stream.errors?.required">
                                Required
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label class="wcs-label">Experience</label>
                        <div class="d-flex">
                            <div class="form-control d-flex align-items-center pe-2 me-2">
                                <input autocomplete="off" name="experience_years" formControlName="experience_years" type="text" class="w-100 border-0 o-none" />
                                <span class="ms-1 ps-2 pe-0"><small>yrs</small></span>
                            </div>
                            <div class="form-control d-flex align-items-center pe-2 ms-2">
                                <input name="experience_months" autocomplete="off" type="text" class="w-100 border-0 o-none" formControlName="experience_months" />
                                <span class="ms-1 ps-2 pe-0"><small>mnths</small></span>
                            </div>
                        </div>
                        <div class="alert alert-error" *ngIf="(f.experience_years?.touched || f.experience_months?.touched) && (!f.experience_years?.value && !f.experience_months?.value)">
                            Required
                        </div>
                        <div class="alert alert-error" *ngIf="(f.experience_years?.invalid || f.experience_months?.invalid)  && (f.experience_years?.touched || f.experience_months?.touched)">
                            <div *ngIf="f.experience_years.errors?.pattern">Invalid year</div>
                            <div *ngIf="f.experience_months.errors?.pattern">Invalid month . Enter month between (0-11)</div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3 pt-2 refer-candidate-file-upload">
                        <file-upload #resumeUpload btnName="resume" type="both" size="15" fileType="RES" [fetchPrevFile]="true" [validationMessage]="resumeValidationMessage" (fileChanged)="resumeHandler($event)" [isHintInRow]="true">
                        </file-upload>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label>If related to candidate, enter the relationship <span
                class="optional-feild">(optional)</span></label>
                        <input placeholder="Enter the relationship" autocomplete="off" type="text" name="relationship_with_referrer" class="form-control" formControlName="relationship_with_referrer" />
                        <div *ngIf="f.relationship_with_referrer?.invalid && f.relationship_with_referrer?.touched">
                            <div class="alert alert-error" *ngIf="f.relationship_with_referrer.errors?.maxlength">
                                Max 100 characters
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label>How do you know this candidate and your reasons for recommending <span
                class="optional-feild">(optional)</span></label>
                        <textarea formControlName="recommendation_by_referrer" rows="4" class="form-control txtarea" placeholder="Enter reasons"></textarea>
                        <div class="alert alert-error" *ngIf="f.recommendation_by_referrer?.invalid && f.recommendation_by_referrer?.touched">
                            <div *ngIf="f.recommendation_by_referrer.errors?.maxlength">
                                Max 200 characters
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <span (mouseover)="infoToggle = true" (mouseout)="infoToggle = false" class="eligibility">
                            Eligibility Criteria
                        </span>
                        <div *ngIf="infoToggle" class="info-container">
                            Tenth Percentage: 80% <br> Twelth Percentage: 70% <br> Degree Percentage: 60%</div>
                    </div>
                </div>
            </form>

        </div>
        <p *ngIf="isValidated && isDuplicate && isWithinAnYear" class="label-value text-center mt-2">Candidate already available in the system.</p>
        <div class="actions text-center mb-2">
            <button class="btn cancel-btn" (click)="onClose(false)">CANCEL</button>
            <button *ngIf="!isValidated" class="btn save-btn ms-4" (click)="onProceed()">PROCEED</button>
            <button *ngIf="isValidated && (!isDuplicate || !isWithinAnYear)" class="btn save-btn ms-4" (click)="onSubmit()">SUBMIT</button>
            <button *ngIf="isValidated && isDuplicate && isWithinAnYear" class="btn save-btn ms-4" (click)="onReset()">RESET</button>
        </div>
    </div>
</div>

<ng-template #successPopup let-modal>
    <div class="confirmation-popup-div">
        <div class="modal-body">
            <div class="heading">Refer a Friend</div>
            <div class="body text-center mb-4">
                <p>Great job!</p>
                <p>Thank you for your referral. Your confidence in us is greatly appreciated.</p>
                <p>Have a great time ahead.</p>
            </div>
            <div class="actions text-center mb-2">
                <button class="btn save-btn" (click)="modal.close('OK')">OK</button>
            </div>
        </div>
    </div>
</ng-template>
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-kebab-menu',
  templateUrl: './kebab-menu.component.html',
  styleUrls: ['./kebab-menu.component.scss']
})
export class KebabMenuComponent {

  confirmationTitle: string = "";
  confirmationMessage: string = "";
  @Input()
  menu: any = [];
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal
  ) { }

  onClick(e: any) {
    this.notify.emit(e)
  }

  confirmAction(item: any, content: any) {
   
    this.confirmationTitle = item.label;
    this.confirmationMessage = item.confirmationMessage;
    if (item.confirmationMessage) {
      this.modalService.open(content, { centered: true }).result
        .then((result) => {
          this.onClick(item);
        }, (reason) => {
          return;
        });
    }
    else {
      this.onClick(item);
    }

  }


}

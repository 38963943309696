import { Injectable } from '@angular/core';

import { ApiService } from './../../../core/http/api.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private apiService: ApiService
  ) { }

  // Returns an observable
  upload(data: any) {
    return this.apiService.post('/api/resumes/file-upload/', data);
  }

  downloadFile(id: string, type: string) {
    return this.apiService.get(`/api/resumes/file-download/${id}/${type}/`);
  }

  uploadInterviewRatingAttachment(data: any) {
    return this.apiService.post('/api/interview-rating/file-upload/', data);
  }

  uploadOffer(data: any) {
    return this.apiService.post('/api/candidates/offers/file-upload/', data);
  }
}

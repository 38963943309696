import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { InterviewService } from 'src/app/interview/interview.service';
import { recommendation } from '../../../resume/constants';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorContentLengthPipe } from '../../_pipes/editor-content-length.pipe';
import { UtilService } from '../../_services/util.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-interview-rating',
  templateUrl: './interview-rating.component.html',
  styleUrls: ['./interview-rating.component.scss']
})
export class InterviewRatingComponent implements OnInit, OnDestroy {
  // @Input() recommendations: Array<RecommendationChoices> = [];
  @Input() interviewId!: number;
  @Input() interviewLevel!: string;
  @Input() interviewMode!: string;
  @Input() mandateCandidatePhoto!: boolean;
  @Input() readMode: boolean = false;
  @Input() ratingData: any = {};
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
  recommendationIds: Array<string> = [];
  closeResult: string = '';
  ratingForm!: FormGroup;
  provideRatingDisabled: boolean = false;
  subscription: Subscription = new Subscription();
  attachmentData: any = {};
  recommendations: any = [];
  @Input() useSecondaryButton: boolean = false;
  isSubmitAllowed: boolean = true;
  config: AngularEditorConfig = {};
  isValidComment: boolean = true;
  photoValidationMessage: string = '';
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private interviewService: InterviewService,
    private toaster: ToastrService,
    private editorLengthPipe: EditorContentLengthPipe,
    private utilService: UtilService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.recommendations = this.convertData(recommendation);
    this.recommendationIds = recommendation
      .filter((item: any) => item.interviewLevel.includes(this.interviewLevel))
      .map((item: any) => item.id)

    this.initializeForm();
    if (this.readMode) {
      this.attachmentData = this.ratingData.file;
      this.setValues();
    }

    this.setValidations();
    this.setEditorConfig();
  }

  initializeForm() {
    this.ratingForm = this.formBuilder.group({
      technical: [null,],
      logical: [null,],
      communication: [null,],
      attitude: [null,],
      overall: [null,],
      recommendation: ['',],
      comment: [''],
      file: [null]
    });
  }

  setEditorConfig() {
    this.config = {
      editable: this.readMode ? false : true,
      sanitize: true,
      spellcheck: true,
      height: this.interviewLevel === 'T' ? '23rem' : '15rem',
      minHeight: this.interviewLevel === 'T' ? '23rem' : '15rem',
      maxHeight: this.interviewLevel === 'T' ? '23rem' : '15rem',
      placeholder: '',
      translate: 'no',
      toolbarPosition: 'bottom',
      toolbarHiddenButtons: [
        [
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'fontName',
          'heading'
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]]
    };
  }

  convertData(arr: any) {
    let temp: any = {};
    arr.forEach((element: any) => {
      temp[element.id] = element.name;
    });
    return temp;
  }

  setValidations() {
    if (this.interviewLevel === 'H') {
      this.ratingForm.controls['communication'].setValidators(Validators.required);
      this.ratingForm.controls['comment'].setValidators(Validators.required);
    } else if (this.interviewLevel === 'T') {
      this.ratingForm.controls['recommendation'].setValidators(Validators.required);
      this.ratingForm.controls['overall'].setValidators(Validators.required);
    } else if (this.interviewLevel === 'M') {
      this.ratingForm.controls['recommendation'].setValidators(Validators.required);
      this.ratingForm.controls['overall'].setValidators(Validators.required);
      this.ratingForm.controls['comment'].setValidators(Validators.required);
    }
    this.photoValidationMessage = '';
  }

  setValues() {
    Object.keys(this.ratingData).forEach((key: string) => {
      this.ratingForm?.controls[key]?.setValue(this.ratingData[key]);
    });
  }

  get r() { return this.ratingForm.controls }

  changeValue(field: string, rating: any) {
    this.ratingForm.controls[field].setValue(rating);
    if (field == 'overall' && parseInt(rating) > 2.5) {
      this.isSubmitAllowed = true;
    }
  }

  handleRadioSelect(id: string) {
    this.ratingForm.controls['recommendation'].setValue(id);
    if (['ON_HOLD', 'REJ', 'NO_QB_STD', 'ANT_TEC'].some(e => e == id) && parseInt(this.ratingForm.controls['overall'].value) <= 2.5) {
      this.isSubmitAllowed = true;
    }
  }

  saveRating() {
    let isFormValid = true;
    if ((['M', 'H'].includes(this.interviewLevel) && this.editorLengthPipe.transform((this.ratingForm.controls['comment'].value)) > 0) || 
        (this.interviewLevel === 'T' && this.editorLengthPipe.transform((this.ratingForm.controls['comment'].value)) >= 200)){
      this.isValidComment = true;
    }else{
      this.isValidComment = false;
    }
    Object.keys(this.ratingForm.controls).forEach(control => {
      if (this.ratingForm.controls[control].errors) {
        this.ratingForm.markAllAsTouched();
        isFormValid = false;
      }
    })

    if (this.mandateCandidatePhoto && this.interviewMode === 'V' && !this.ratingForm.value.file) {
      this.photoValidationMessage = 'Please upload the Photo of the Candidate';
      isFormValid = false;
    } else {
      this.photoValidationMessage = '';
    }

    if (isFormValid && this.isValidComment) {
      let requestObj: any = this.convertTextToNumbers(this.ratingForm.value);
      if (['CAN_HIRE', 'ANT_RND'].some(e => e == requestObj.recommendation) && requestObj.overall <= 2.5) {
        this.isSubmitAllowed = false;
        return;
      }
      if (this.interviewLevel === 'H') {
        /*
         https://code.qburst.com/qrms-extended/common/-/issues/2100 Hack for setting overall rating
         in the first time itself rather than waiting for the migration
         */
        requestObj.overall = requestObj.communication
      }
      this.subscription = this.interviewService.addInterviewRating(this.interviewId, requestObj)
        .subscribe(
          (response: any) => {
            this.modalService.dismissAll();
            this.success.emit(true);
            this.resetForm();
          },
          (error) => {
            this.toaster.error(`Sorry, we could not update the rating. Please try again`);
          });
    }
  }

  convertTextToNumbers(ratingData: any) {
    let ratingFields = ['technical', 'logical', 'communication', 'attitude', 'overall'];
    ratingFields.forEach((fieldName: any) => {
      ratingData[fieldName] = ratingData[fieldName] == '0' ? 0 : parseFloat(ratingData[fieldName]) || null;
    });
    ratingData.recommendation = ratingData.recommendation.trim();
    return ratingData;
  }

  open(content: any) {
    this.isValidComment = true;
    this.setValidations();
    setTimeout(() => {
      this.removeStylesOnPaste();
    }, 500);
    this.modalService.open(content, { windowClass: this.readMode ? "interview-rating-details-modal" : "interview-provide-rating-modal", centered: true, backdrop: 'static', keyboard: true }).result
      .then((result) => {
        this.modalClosed.emit(true);
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.modalClosed.emit(true);
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  //editor customisation starts
  removeStylesOnPaste = () => {
    const description = (document.querySelector('.angular-editor-textarea') as any);
    description.addEventListener('paste', () => {
      setTimeout(() => {
        this.removeStyles();
      }, 700);
    });
  }

  removeStyles() {
    const textEditorContent: any = document.querySelector('.angular-editor-textarea');
    textEditorContent.innerHTML = textEditorContent.innerHTML.replace(/style="([^"]*)"/g, '');  
    const selection = window.getSelection() as any;
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(textEditorContent);
    range.collapse(false);
    selection.addRange(range);
    textEditorContent.focus();
  }

  hideErroMessage(){
    this.isValidComment = true;
  }
  
  //editor customisation ends

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  attachmentHandler(e: any) {
    if (e?.link) {
      this.ratingForm.controls['file'].patchValue(e);
      this.photoValidationMessage = '';
    } else if (!e) {
      this.ratingForm.controls['file'].patchValue(null);
      if (this.mandateCandidatePhoto && this.interviewMode === 'V') {
        this.photoValidationMessage = 'Please upload the Photo of the Candidate'
      }
    }
  }

  resetForm() {
    this.ratingForm.reset();
    this.isSubmitAllowed = true;
    this.initializeForm();
  }

  commentBlur() {
    this.ratingForm.controls['comment'].markAsUntouched()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

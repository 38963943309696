import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.history.push(this.router.url);
    this.saveToStorage();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getFromStorage();
        //to avoid adding same url again on refresh
        if (this.history[this.history.length - 1] !== event.urlAfterRedirects) {
          this.history.push(event.urlAfterRedirects);
        }
        this.saveToStorage();
      }
    })
  }

  navigate() {
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  //back function for detail page.
  back(urlTerm: string = ''): void {
    let navigation_status = true;

    this.history.pop();


    if (navigation_status) {
      let numberOfBackSteps = 0;
      const historyData = [...this.history];
      //checking how many steps it takes to reach the list page
      historyData.reverse();
      numberOfBackSteps = historyData.findIndex(h => h.includes(urlTerm || 'list'));
      if (numberOfBackSteps == -1) {
        this.router.navigateByUrl('/dashboard');
      }
      this.router.navigateByUrl(historyData[numberOfBackSteps]);
    }
    else {
      //if previous page is not the edit page
      this.navigate();
    }
    this.removeFromStorage(); // For clearing navigation history.
  }

  //back function that doesn't clear the whole history
  liteBack() {
    this.history.pop();
    this.saveToStorage();

    this.navigate();
  }

  saveToStorage() {
    sessionStorage.setItem('navigation_history', JSON.stringify(this.history));
  }

  getFromStorage() {
    this.history = JSON.parse(sessionStorage.getItem('navigation_history') || '[]');
  }

  removeFromStorage() {
    sessionStorage.removeItem('navigation_history');
  }
}
<div class="day-options-container d-flex">
  <div *ngIf="startDate && endDate" class="date-filter-list-fonts font-12 d-flex align-items-center">
    <span class="ps-2 day-lh"><img src="/assets/calender-blue.svg" alt="Calendar" class="pb-1" /></span>
    <span class="px-1 day-lh">{{startDate | date:dateFormat}} - {{endDate | date: dateFormat}}</span> |
  </div>
  <div class="dropdown d-flex">
    <div id="dLabel" data-bs-toggle="dropdown" data-target="#" class="d-flex align-items-center">
      <div class="date-filter-list-fonts font-12  d-flex align-items-center cursor-pointer">
        <span class="px-1 day-lh">{{currentSelection.name}}</span><span class="pe-1"><img
            src="/assets/images/black-down-arrow.svg" alt="arrow" /></span>
      </div>
    </div>
    <ul id="day-picker" class="dropdown-menu multi-level date-filter-list-fonts font-13 dropdown-menu-end"  role="menu"
      aria-labelledby="dropdownMenu">
      <li *ngFor="let option of listableDayOptions" class="py-1 ps-2" (click)="onDaySelection(option)"> {{option.name}}
      </li>
      <li class="dropdown-submenu py-1 ps-2" (click)="noActionOnClick($event)">
        <a tabindex="-1">Custom Date Range</a>
        <ul class="dropdown-menu py-0">
          <li class="date-range-item"><a tabindex="-1">
              <app-daterange-picker-component #dateRangePicker [item]="dateRangeObj"
                (dateFilterData)="onDateSelected($event)">
              </app-daterange-picker-component>
            </a></li>
        </ul>
      </li>
    </ul>
  </div>
</div>
export const datetimeformat = "MMM dd, y h:mm a";
export const dateformat = "MMM dd, y";
export const timeformat = "h:mm a";
export const yearFormat = "y";
export const daydatatimeformat = "dd MMM y, h:mm a";
export const dateFormatForDatePipe = "yyyy-MM-dd";
export const mediumDateformat = "MMM d, y";
export const fullDateTimeFormat = "yyyy-MM-dd hh:mm:ss";
export const fullDateFormat = "EEEE, MMM d, y";

export const currencyformat = "en-IN";

<div class="addWorkBookForm" tabindex="0">
    <form [formGroup]="addWorkBookForm">
        <div class="fluid-container p-4">
            <div class="row mb-4">
                <div class="col-sm-12 popup-header">Create Workbook </div>
            </div>

            <div class="col-sm-12 mb-3">
                <div class="row">
                    <label class="col-sm-3 col-form-label label-feild">Workbook Name</label>
                    <div class="col-sm-9">
                        <input name="name" autocomplete="off" formControlName="name" type="text"
                            placeholder="Enter name of the workbook" class="form-control feild-value form-view">
                        <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-error modal-error">
                            <div *ngIf="f.name.errors?.required">Required</div>
                            <div *ngIf="addWorkBookForm.controls['name'].hasError('maxlength')">Maximum character length
                                is 75</div>
                            <div *ngIf="addWorkBookForm.controls['name'].hasError('pattern')">Invalid name</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-3">
                <div class="row">
                    <label class="col-sm-3 col-form-label label-feild">Due Date <span class="optional-feild">(Optional)</span></label>
                    <div class="col-sm-9 ">
                        <app-single-date-picker-component [item]="item" class="feild-value form-view " [border]="false"
                            (selectedDate)="onDueDateSelected($event)" [datePickerId]="'CreateWBPicker'">
                        </app-single-date-picker-component>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-3">
                <div class="row">
                    <label class="col-sm-3 col-form-label label-feild">Description <span class="optional-feild">(Optional)</span></label>
                    <div class="col-sm-9">
                        <textarea class="form-control txtarea feild-value " rows="5" formControlName="description"
                            placeholder="Enter Description"></textarea>
                        <div *ngIf="f.description.touched && f.description.invalid"
                            class="alert alert-error modal-error">
                            <div *ngIf="addWorkBookForm.controls['description'].hasError('maxlength')">Maximum character
                                length
                                is 255</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-5">
                <div class="row">
                    <label class="col-sm-3 col-form-label label-feild">Owner(s) <span class="optional-feild">(Optional)</span></label>
                    <div class="col-sm-9 multi-select-checkbox-dropdown">
                        <ng-select [items]="spaceUserData" [multiple]="true" bindLabel="name" #selectedUserRef [closeOnSelect]="false"
                            bindValue="id" [(ngModel)]="ownerList" [ngModelOptions]="{standalone: true}" [clearOnBackspace]="false" 
                            (change)="onOwnerSelection($event)" class="placeholder" placeholder="Select or start typing" (open)="onDropdownOpen()">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
                                {{item.name}} 
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="row mt-1">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9 padding-l-20 d-flex  align-items-center flex-wrap">
                            <span class="skill-display d-flex  align-items-center" *ngFor="let user of ownerDetails">
                                {{user.first_name}} {{user.last_name ? user.last_name : ''}}
                                <img class="ms-2 cursor-pointer" (click)="onRemoveOwner(user)"
                                    src="./assets/images/remove.svg" alt="close">
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-12 d-flex justify-content-center">
                <div class="row">
                    <div class="col-md-9 d-flex">
                        <button class="btn btn-cancel me-3" (click)="onCancelClick()">{{'cancel'|
                            uppercase }}</button>
                        <button class="btn btn-submit red-btn ms-2" (click)="onCreateClick()">{{'create' |
                            uppercase}}</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>
import { Component, EventEmitter, OnInit, Output, Input, ViewChild, Injector } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';

import { AuthService } from 'src/app/auth/auth.service';
import { NavigationService } from '../../_services/navigation.service';
import { backBtnUrls } from './../../_constants/constants';
import { Subject, Subscription } from 'rxjs';
import { ResumeService } from 'src/app/resume/resume.service';
import { UtilService } from '../../_services/util.service';
import { GlobalStoreService } from '../../_services/global-store.service';
import { Role } from '../../_models/role';
import { StorageService } from '../../_services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  username: string = '';
  isHR: boolean = false;
  headerMenu: any = [];
  toggleProfile = false;
  imageUrl = "";
  isSideNavCollapsed: boolean = false;
  showBackBtnInHeader: boolean = false;
  subscription: Subscription = new Subscription();
  subscription1: Subscription = new Subscription();
  candidates: any = [];
  searchTerm: string = '';
  apiLoading: boolean = true;
  Role = Role;
  globalStore: GlobalStoreService;
  storageService: StorageService;
  

  @ViewChild("myInput", { static: true }) myInput: any;
  private inputEvent = new Subject<any>();


  @Output() sideNavCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Input()
  set isSideNavCollapsedInput(collapsed: boolean) {
    this.isSideNavCollapsed = collapsed;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private navigationService: NavigationService,
    private location: Location,
    private candidateService: ResumeService,
    private utilService: UtilService,
    private injector: Injector,
  ) {
    this.globalStore = this.injector.get<GlobalStoreService>(GlobalStoreService);
    this.storageService = this.injector.get<StorageService>(StorageService);

    this.showBackButton(this.router.url); // fix for Back button not showing on reload
    this.router.events.pipe(
      filter(
        (event: any) => event instanceof NavigationEnd)).subscribe(
          event => {
            this.showBackButton(event.url);
          }
        );
        window.addEventListener('storage', (event) => {
          if (event.storageArea != localStorage) return;
          if (event.key === 'user') {
            const data = JSON.parse(this.storageService.get('user'));
            this.username = data.name;
            this.imageUrl = data.image;
          }
         });

         this.utilService.clearGlobalSearch$.subscribe((res: boolean) => {
          if (res) this.handleOutsideClick();
         })
         
  }

  showBackButton(url: any) {
    let patternData = false;
    backBtnUrls.forEach(pageDetail => {
      if (new RegExp(pageDetail.pageRegex).test(url)) {
        patternData = true;
      }
    })
    if (patternData) {
      this.showBackBtnInHeader = true;
    } else {
      this.showBackBtnInHeader = false;
    }
  }

  ngOnInit(): void {
    const { name, image } = this.authService.getUser();
    this.username = name;
    this.imageUrl = image;

    this.inputEvent
    .pipe(
      debounceTime(500),
      distinctUntilChanged((previous: any, current: any) => {
        return previous.value === current.value
      })
    )
    .subscribe(res => {
      this.searchTerm.trim();
      if (res && res.value?.trim().length >= 3) {
        this.getCandidateList(res.value.trim());
      } else {
        this.candidates = [];
        this.apiLoading = true;
      }
    });
  }

  navigateToHome() {
    this.router.navigate(['/dashboard']);
  }

  logout() {
    this.toggleMenu();
    this.authService.logout();
  }

  toggleMenu() {
    this.toggleProfile = !this.toggleProfile;
  }

  toggleIcon() {
    this.isSideNavCollapsed = !this.isSideNavCollapsed;
    this.sideNavCollapsed.emit(this.isSideNavCollapsed);
  }

  handleBackButtonClick() {
    const page = backBtnUrls.find((pages) => new RegExp(pages.pageRegex).test(this.router.url));
    if (page && Object.keys(page).length > 0) {
      if (page.backPageUrl) {
        this.router.navigate([page.backPageUrl], {queryParams: page.params});
      } else if (page.urlTerm) {
        this.navigationService.back(page.urlTerm);
      } else {
        this.location.back();
      }
    } else {
      this.navigationService.back();
    }
  }

  onCandidateSearch(evt: any, param:any) {
    this.inputEvent.next({evt, param, value: evt.target.value});
  }

  getCandidateList(searchTerm: string) {
    let url = `/api/resumes/`;
    this.subscription1 = this.candidateService.getResumes(url,{search: searchTerm, limit: 5}).subscribe(
      (res: any) => {
        if (res?.status == 'OK') {
          this.apiLoading = false;
          this.candidates = res.results;
          this.candidates.forEach((candidate: any) => {
            candidate['hasOverflow'] = false;
            candidate['full_name'] = candidate.last_name ? candidate.first_name+' '+candidate.last_name : candidate.first_name;
          });
        }
      }
    )
  }

  hideOrShowTooltip(event: any, offer: any, field: string) {
    this.utilService.hideOrShowTooltip(event, offer, field, false);
  }

  handleOutsideClick() {
    this.candidates = [];
    this.apiLoading = true;
    this.searchTerm = '';
    this.inputEvent.next('');
  }

  get page() {
    return this.globalStore.getCurrentPage();
  }

  viewAll(event: any) {
    if (event.keyCode === 13) {
      if (this.searchTerm?.trim()) {
        this.subscription1.unsubscribe();
        this.candidates = [];
        this.apiLoading = true;
        this.setSearchText();
        this.router.navigate(['/candidates/list']);
        this.searchTerm = '';
        this.inputEvent.next('');
      } else {
        this.clearSearch();
       }
   }
  }

   clearSearch() {
    this.searchTerm = '';
    this.candidates = [];
    this.inputEvent.next('');
   }

   setSearchText() {
    this.globalStore.setSearchQuery(this.searchTerm.trim());
   }

}

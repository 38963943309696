import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'ngbd-timepicker-meridian-popup',
    templateUrl: './time-picker-popup.component.html',
    styleUrls: ['./time-picker-popup.component.scss']
})
export class NgbdTimepickerMeridianPopup implements OnInit {
    _timeValue: any;
    focusElement:boolean=false;
    tabFocusBorder: string ='1px solid #8f8f8f';
    @Input() tabIndex: number | null = null;
    @Input() time: any;
    @Input() placeholder: any = '';
    @Input() disabledVal: boolean = false;
    @Output() selectedTime: EventEmitter<any> = new EventEmitter<any>();
    @Input() border: boolean = true;
    @Input() set timeValue(val: any) {
      if (val) {
        this.newTime = this.timeConvert(val);
      }
      this._timeValue = val;
      this.setPickerTime();
    }
    get timeValue() {
      return this._timeValue;
    }
    newTime: any;
    popOverposition: string = 'bottom-left'

    @ViewChild('popOver') popover!: NgbPopover;

    ngOnInit(): void {
      if (!this.timeValue && !Object.keys(this.time).length) {
        this._timeValue = '10:00';
        this.setPickerTime();
      }
    }

    setPickerTime = () => {
      if (this.timeValue) {
        this.time = { hour: parseInt(this.timeValue.split(':')[0]), minute: parseInt(this.timeValue.split(':')[1]) };
      }
    }

    applyTime = () => {
        if (this.timeValue) {
          let hoursAndMinutesArr: string[] = this.timeValue.split(':');
          this.newTime = this.timeConvert(this.timeValue);
          this.timeValue = hoursAndMinutesArr[0].padStart(2, '0') + ':' + hoursAndMinutesArr[1].padEnd(2, '0');
        }
        this.selectedTime.emit(this.timeValue);
        this.popover.close();
    }

    clearTime() {
        this.timeValue = "10:00";
        this.newTime = undefined;
        this.selectedTime.emit(null);
        this.popover.close();
    }

    timeConvert(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]?\d|2[0-3])(:)([0-5]?\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            if (time[0] < 10) {
                time[0] = '0' + time[0].toString();
            }
            if (time[2].length === 1) {
                time[2] = '0' + time[2].toString();
            }
            delete time[3];
        }
        return time.join(''); // return adjusted time or original string
    }

    onPopOverOpen(e: any) {
        let currentPosition = e.target.getBoundingClientRect();
        if (currentPosition.top > 480) {
            this.popOverposition = 'top-left'
        }
    }
  onFocusElement(event: KeyboardEvent) {
    if (event?.code == 'Tab') {
      this.focusElement=true;
    }
  }

  removeFocus() {
    this.focusElement = false;
  }
}
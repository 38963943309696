<nav id="main-side-nav" class="header navbar navbar-expand-lg navbar-light"  (mouseleave)="hoveredItemIndex = headerMenu.length">
    <div [ngClass]="{'collapsed': isNavCollapsed ? true: false, 'expanded': isNavCollapsed?false:true}"
        class="navbar-collapse">
        <ul class="navbar-nav">
            <li class="nav-item hide-visibility-mobile logo-container" (click)="toggleIcon($event)"
                [ngStyle]="{'text-align': isNavCollapsed?'center':'unset'}">
                <span (click)="toggleMenu()" class="nav-link logo d-flex w-100">
                    <div>
                        <img class="nav-menu-selected hamb-color" [src]="'./assets/images/hamburger.svg'" alt="brand logo"  />
                    </div>
                    <a *ngIf="!isNavCollapsed" class="navbar-brand" [routerLink]="['/dashboard']">
                        <img src="./assets/images/qb-logo.svg" alt="brand logo" class="brand-logo-img" />
                    </a>
                    <div *ngIf="!isNavCollapsed" class="nav-collapse-section">
                        <img class="nav-menu-selected navbar-collapse-icon " [src]="'./assets/images/nav-collapse-icon.svg'" alt="collapse icon" />
                    </div>
                </span>
            </li>
            <ng-container *ngFor="let menu of headerMenu; let i = index">
                <ng-container *ngIf="menu?.showMenuForTenant">
                    <li *userRole="menu.role" #rla="routerLinkActive" [routerLinkActive]="['active-list']" class="nav-item menu-container main-list"
                        [ngStyle]="{'text-align': isNavCollapsed?'center':'unset'}"
                        [ngClass]="{'disabled-icon': menu.isDisabled, 'pe-none': menu.isDisabled, 'border-collapse-radius':isNavCollapsed}" placement="right"
                        ngbTooltip="{{isNavCollapsed && !menu.subMenu ? menu.title : ''}}">
                        <a [routerLink]="[menu.routerLink]" (click)="!menu.isDisabled ? toggleMenu(menu) : ''"
                            class="nav-link overflow-hidden" (mouseenter)="hoveredItemIndex = i;">
                            <div class="item-container menu-item mp-10" [ngClass]="{'active': rla.isActive, 'colapsed-menu-item': isNavCollapsed}"
                                (click)="handleClick($event, menu)">
                                <div class="d-flex align-items-center menu-section">
                                    <div class="mr-img-5 d-flex align-items-center">
                                        <img [ngClass]="{'nav-menu-selected': rla.isActive}" [src]="menu.icon" alt="{{menu.title}}" />
                                    </div>
                                    <div *ngIf="!isNavCollapsed" class="main-menu-title">{{menu.title}} <span></span></div>
                                </div>
                                <img *ngIf="menu.subMenu?.length > 0 && !isNavCollapsed" class="toggle-img"
                                    [src]="menu.toggleSubmenu ? '/assets/images/up-arrow-black.svg' : '/assets/images/down-arrow-black.svg'" />
                            </div>
                        </a>
                        <div *ngIf="!isNavCollapsed && menu.subMenu && menu.toggleSubmenu == true" [@inOutAnimation] class="">
                            <app-nav-sub-menu [subMenuItems]="menu.subMenu" [menuTitle]="menu.title" [isNavCollapsed]="isNavCollapsed"
                                [toggleSubmenu]="menu.toggleSubmenu">
                            </app-nav-sub-menu>
                        </div>
                        <div *ngIf="(isNavCollapsed && hoveredItemIndex === i && menu.subMenu?.length > 0)" class="position-relative">
                            <app-nav-sub-menu [subMenuItems]="menu.subMenu" [menuTitle]="menu.title" [isNavCollapsed]="isNavCollapsed"
                                (hoverOutside)="hoveredItemIndex = headerMenu.length">
                            </app-nav-sub-menu>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>

</nav>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './core/http/api.service';
import { switchMap, shareReplay, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

export const routes = {
  getDropdowns: `/api/dropdown-items/`,
  getSpaceUsers: `/api/space-users/`,
  getSpaceUserNStaff: `/api/space-users-exclude-staff/`,
  getSpaceUserIncludingStaff: `/api/space-users-include-staff/`,
  searchCandidates: `/api/candidates/email-search/`,
  candidateAPI:`/api/candidates`,
  updateHiringStatus:`update-hiring-status/`

};

@Injectable({
  providedIn: 'root'
})

export class AppService {

  // For storing General Dropdowns
  private dropdownsSubject = new BehaviorSubject<void>(void 0);

  // For storing Space Users
  private spaceUsersSubject = new BehaviorSubject<void>(void 0);

  // For storing Space Users
  private spaceUsersNStaffSubject = new BehaviorSubject<void>(void 0);

  // For storing Space Users including staff
  private spaceUsersIncludingStaffSubject = new BehaviorSubject<void>(void 0);

  constructor(
    private apiService: ApiService,
    private titleService: Title,
  ) { }

  updateCurrentDropdowns(data: any) {
    this.dropdownsSubject.next(data)
  }

  updateSpaceUsers(data: any) {
    this.spaceUsersSubject.next(data)
  }

  updateSpaceUsersNStaff(data: any) {
    this.spaceUsersNStaffSubject.next(data)
  }

  // APIs

  readonly spaceUsers = this.spaceUsersSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.getSpaceUsers)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  readonly spaceUsersNStaff = this.spaceUsersNStaffSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.getSpaceUserNStaff)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  readonly spaceUsersIncludingStaff = this.spaceUsersIncludingStaffSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.getSpaceUserIncludingStaff)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  readonly generalDropdowns = this.dropdownsSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.getDropdowns)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  getGeneralDropdowns() {
    return this.apiService.get(routes.getDropdowns);
  }

  getSpaceUsers() {
    return this.apiService.get(routes.getSpaceUsers);
  }

  getSpaceUsersNStaff() {
    return this.apiService.get(routes.getSpaceUserNStaff);
  }

  searchCandidates(params: any) {
    return this.apiService.get(routes.searchCandidates, params);
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  updateStatus(id: any, data: any) {
    return this.apiService.put(`/api/candidates/${id}/update-status/`, data);
  }

  updateHiringStatus(id: any, data: any) {
    return this.apiService.put(`${routes.candidateAPI}/${id}/${routes.updateHiringStatus}`,data);
  }
}

<app-tab-section *ngIf="tabs.length > 1" [tabs]="tabs" [activeTab]="activeTab" (tabChanged)="onTabChange($event)"
  headerAlignment="left">
  <ng-container *ngTemplateOutlet="activityLog"></ng-container>
</app-tab-section>

<ng-container *ngIf="tabs.length == 1">
  <ng-container *ngTemplateOutlet="activityLog"></ng-container>
</ng-container>

<ng-template #activityLog>
  <div class="activity-tab" [ngClass]="tabs.length > 1 && activeTab === 1 ? 'top-left-radius-0' : 'top-right-radius-0'">

    <p *ngIf="tabs.length == 1" class="heading">{{tabs[0].label}}</p>

    <ng-container *ngIf="activeTab == 1">
      <div class="row">
        <div class="col-sm-12">
          <form class="text-center" [formGroup]="commentsForm">
            <textarea class="form-control comment-field" id="resumeComments" formControlName="comment" rows="5"
              placeholder="Enter Notes" (input)="onCommentChange(event)"></textarea>
            <div *ngIf="commentsForm.controls.comment.touched && commentsForm.controls.comment.invalid"
              class="alert alert-error fs-12 text-start">
              <div *ngIf="commentsForm.controls.comment.hasError('maxlength')">
                Max character allowed is 500</div>
            </div>
            <div class="text-end col-md-12">
              <button (click)="onSave()" class="btn my-3 custom-btn text-center"
                [disabled]="isDisabled || isInvalidComment">
                {{'Add Comment' | uppercase}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-container>

    <div class="comments-history px-2" *ngFor="let comment of comments[activeTabDetails.id]; let i = index;">
      <div *ngIf="i === 0 && activeTabDetails.tab === 1" class="separator-line mt-0 long-line"></div>

      <div class="comment row">
        <ng-container *ngIf="this.activeTabDetails.contentHeaderStyle === 'two-line'">
          <div class="col-xxl-8 col-md-7 pe-0">
            <div *ngIf="comment.comment" class="label-text">
              <small class="label-bold label-dark" *ngIf="comment.created_by">{{comment.created_by.length ?
                comment.created_by : comment.created_by?.username}}</small>
              <small class="label-bold label-dark" *ngIf="!comment.created_by">{{comment?.commented_by?.username}}
              </small>
              <br>
              <small class="label-dark" *ngIf="comment.created_at">{{comment.created_at | date: dateTimeFormat}}</small>
              <small class="label-dark" *ngIf="!comment.created_at">{{comment.commented_at | date:
                dateTimeFormat}}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-md-5 icon-align"
            *ngIf="(comment.created_by &&  comment.created_by?.email === userEmail) || (!comment.created_by && comment?.commented_by?.email === userEmail)">
            <span class="me-3">
              <img src="./assets/images/edit-icon-blue.svg" class="action-icon disabled-icon d-none"
                (click)="openModal(updateComment, comment, 'edit')" />
            </span>
            <span *ngIf="!comment.is_mandatory">
              <img src="./assets/images/close.svg" class="action-icon"
                (click)="openModal(updateComment, comment, 'delete')" />
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="this.activeTabDetails.contentHeaderStyle === 'one-line'">
          <div class="col-sm-5 pe-0">
            <div *ngIf="comment.comment" class="text-muted overflow-section">
              <small *ngIf="comment.created_by" placement="bottom-left"
                  (mouseenter)="hideOrShowTooltip($event,comment.created_by,'hasCommentedPersonNameOverFlow')"
                  ngbTooltip="{{comment.created_by?.hasCommentedPersonNameOverFlow ? comment.created_by.username : ''}}">
                  {{comment.created_by.length ? comment.created_by : comment.created_by?.username}}
              </small>
              <small *ngIf="!comment.created_by" placement="bottom-left"
                  (mouseenter)="hideOrShowTooltip($event,comment.commented_by,'hasCommentedPersonNameOverFlow')"
                  ngbTooltip="{{comment.commented_by?.hasCommentedPersonNameOverFlow ? comment.commented_by.username : ''}}">
                  {{comment?.commented_by?.username}}
              </small>
            </div>
          </div>
          <div class="col-sm-7">
            <div *ngIf="comment.comment" class="text-muted text-end">
              <small *ngIf="comment.created_at">{{comment.created_at | date: dateTimeFormat}}</small>
              <small *ngIf="!comment.created_at">{{comment.commented_at | date: dateTimeFormat}}</small>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-1">
        <div class="col-sm-12 value pre-wrapped-text" *ngIf="comment.comment">
          <div *ngIf="!comment.full" [innerHTML]="getDisplayableContent(comment)">
          </div>
          <div *ngIf="comment.full" [innerHTML]="comment.comment"></div>
          <span *ngIf="(comment.comment.length > maxLengthAllowedForComment || comment.comment.lastIndexOf('>') !== -1 || comment.shrinked)
            && !comment.full" (click)="comment.full = true"><span class="link">View more</span></span>
          <span *ngIf="(comment.comment.length > maxLengthAllowedForComment || comment.comment.lastIndexOf('>') !== -1 || comment.shrinked)
            && comment.full" (click)="comment.full = false"><span class="link">View less</span></span>
        </div>
      </div>

      <div *ngIf="comment.comment" class="separator-line"></div>
    </div>
  </div>
</ng-template>

<ng-template #updateComment let-modal>
  <div class="confirmation-popup-div">
    <div class="modal-body">
      <div class="heading">Delete Comment</div>
      <div class="body text-center mb-4">
        <p>Are you sure you want to delete the comments?</p>
      </div>
      <div class="actions text-center mb-2">
        <button class="btn cancel-btn" (click)="modal.dismiss()">CANCEL</button>
        <button class="btn save-btn ms-4" (click)="modal.close('delete')">YES</button>
      </div>
    </div>
  </div>
</ng-template>
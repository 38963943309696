export enum Theme {
  QBURST_RED = 'qburst-red',
  CLASSIC_BLUE = 'classic-blue',
  CLASSIC_GREEN = 'classic-green',
}

export interface ThemeConfig {
  labels: ThemeLabelStylesType,
  input: InputFieldStylesType,
}


const ThemeLabelStyles = ['', 'rounded-style-1'] as const;
type ThemeLabelStylesType = typeof ThemeLabelStyles[number];

const InputFieldStyles = ['normal', 'floating-label'] as const;
type InputFieldStylesType = typeof InputFieldStyles[number];
<div class="availability-modal">
  <h3 class="title">Availability {{' '}} {{'[' + availabilityRange + ']'}}</h3>
  <input hidden />
  <section class="interviewer-section" *ngFor="let interviewerSlots of availabilityData">
    <div class="row">
      <div class="col-sm-3 align-center-y">
        <h4 class="interviewer-name">{{interviewerSlots.interviewer_name}}</h4>
      </div>
      <div class="col-sm-3 align-center-y">
        <h4 class="slots-count" [ngClass]="interviewerSlots.count === 0 ? 'no-count' : ''">Available Slots:
          <span class="ml-1">{{interviewerSlots.count}}</span>
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 pr-zero" *ngFor="let slot of interviewerSlots.available_slots">
        <div class="slot">
          <div class="date mb-2">
            <div class="calendar-container">
              <img src="./assets/calender-blue.svg" alt="calendar" class="theme-coloured-image blue-icon"/>
            </div>
            <div class="date-value">{{slot.date | date: 'MMM dd, EEEE'}}</div>
          </div>
          <div class="time align-center-y">
            <div class="clock-container">
              <img src="./assets/clock-blue.svg" alt="clock" class="theme-coloured-image blue-icon"/>
            </div>
            <div class="time-value">{{formatAMPM(slot.start_time)}}</div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <div class="text-center mb-2">
    <button class="btn save-blue-btn mt-4" (click)="onClose()">
      {{'CLOSE'}}
    </button>
  </div>
</div>
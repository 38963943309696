import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthResolver } from '../core/guard/auth.resolver';

import { AuthComponent } from './auth.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthResolver],
    component: AuthComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class AuthRoutingModule { }

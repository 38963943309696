import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-section',
  templateUrl: './tab-section.component.html',
  styleUrls: ['./tab-section.component.scss']
})
export class TabSectionComponent {

  @Input() tabs: any = [];
  @Input() activeTab: number = 1;
  @Input() headerAlignment: string = 'center';
  @Input() totalItems: any;
  @Input() customClass: string = '';
  @Output() tabChanged: EventEmitter<number> = new EventEmitter();

  onTabChange(tab:number){
    this.tabChanged.emit(tab)
  }
}

import { Component, OnInit } from '@angular/core';
import { ConfirmOptions, ConfirmState } from 'src/app/shared/_models/confirmation.model';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
})
export class ConfirmationPopupComponent implements OnInit {
  
  options: ConfirmOptions;
 
  constructor(private state: ConfirmState) {  this.options = state.options;  }
 
  ngOnInit(): void { }

  onAccept() {
    this.state.modal.close(true);
  }

  onReject() {
    this.state.modal.close(false);
  }
}

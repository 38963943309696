<div class="updateStatusForm custom-style-ng-select" tabindex="0">
  <form [formGroup]="statusUpdateForm">
    <div class="fluid-container p-4">
      <div class="row mb-3">
        <div class="col-sm-12 popup-header"> {{formContents?.title}}</div>
      </div>
      <div class="mb-2">
        <div [innerHTML]="formContents?.description" class="col-sm-12 label-value description-section">
        </div>
      </div>
      <div class="row item-list-container">
        <div class="col-md-6">
          <div class="row item">
            <label class=" col-form-label">Operating Country</label>
            <div>
              <div class="h-40 d-flex align-items-center">
                <div class="unit-flex d-flex align-items-center">
                  <input type="radio" formControlName="operating_country" class="radio-large me-1" [value]="'IN'" />
                  <span class="radio-text">India</span>
                </div>
                <div class="unit-flex d-flex align-items-center">
                  <input type="radio" formControlName="operating_country" class="radio-large m-l-20" [value]="'US'" />
                  <span class="radio-text">USA</span>
                </div>

              </div>
              <div *ngIf="f.operating_country.touched && f.operating_country.invalid" class="alert alert-error">
                <ng-container *ngIf="statusUpdateForm.controls['operating_country'].hasError('required')">Required
                </ng-container>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-6 mb-2" *ngIf="f.operating_country.value === 'US'">
          <div class="row item">
            <label class=" col-form-label ">Work Authorization <span class="optional-field">(Optional)</span></label>
            <div class="">
              <ng-select [items]="workAuthorization" [editableSearchTerm]="true" class="remove-border"
                bindLabel="name" bindValue="id" formControlName="work_authorization" [searchable]="false" [clearable]="true"
                placeholder="Select">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-2">
          <div class="row item">
            <label class=" col-form-label ">Department</label>
            <div class="">
              <ng-select #dept_dropdown [items]="departments" [editableSearchTerm]="true" class="remove-border"
                bindLabel="name" bindValue="id" formControlName="department" [searchable]="true" [clearable]="true"
                (change)="onDepartmentChange($event?.id)" placeholder="Select or start typing"
                (ngModelChange)="onDepartmentModelChange($event, dept_dropdown)">
              </ng-select>
              <div *ngIf="f.department.touched && f.department.invalid" class="alert alert-error">
                <ng-container *ngIf="statusUpdateForm.controls['department'].hasError('required')">Required
                </ng-container>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="row item">
            <label class=" col-form-label ">Stream</label>
            <div class="">
              <ng-select #stream_dropdown [items]="streams" [editableSearchTerm]="true" class="remove-border"
                bindLabel="name" bindValue="id" formControlName="stream" [searchable]="true" [clearable]="true"
                (change)="onStreamChange($event?.id)" placeholder="Select or start typing"
                (ngModelChange)="onStreamModelChange($event, stream_dropdown)">
              </ng-select>
              <div *ngIf="f.stream.touched && f.stream.invalid" class="alert alert-error">
                <ng-container *ngIf="statusUpdateForm.controls['stream'].hasError('required')">Required </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-2" *ngIf="f.operating_country.value === 'US'">
          <div class="row item">
            <label class=" col-form-label ">Hiring Approved by</label>
            <div class="">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="spaceUsers" bindLabel="username"
                bindValue="id" formControlName="hiring_approved_by" [searchable]="true" [clearable]="true"
                #approved_by_ref [handler_input]="approved_by_ref" placeholder="Select or start typing" ngSelectHandler
                [searchFn]="customEmailSearchFn.bind(this)" (change)="onHiringApprovedChange($event)">
                <ng-template ng-option-tmp let-subItem="item"> {{subItem.username}} &nbsp;[<span
                    class="label-value-blue">{{subItem.email}}</span>]
                </ng-template>
              </ng-select>
              <div *ngIf="f.hiring_approved_by.touched && f.hiring_approved_by.invalid" class="alert alert-error">
                <ng-container *ngIf="statusUpdateForm.controls['hiring_approved_by'].hasError('required')">Required
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-2 mx-0">
          <div class="d-flex checkbox-div w-50">
            <app-checkbox [checked]="f.is_fresher.value"
              (toggle)="toggleCheckBox('is_fresher')"></app-checkbox>
            <span class="ms-2">Fresher</span>
          </div>
        </div>

        <div class="col-sm-6 col-md-4" *ngIf="!isFresherCandidate && !indirectContractMode">
          <label class=" col-form-label ">Last Salary Payslip</label>
          <div class="flexbox flex-column">
            <div>
              <input hidden #uploader class="file-input" type="file" (change)="onChange($event)">
              <button (click)="uploader.click()" type="button" class="btn btn-uploader page-add-button">
                {{ salarySlipName ? 'Update' : 'Upload'}} Last Payslip
              </button>
              <div class="hint" *ngIf="!salarySlipLink?.length">
                <div>.jpg, .jpeg, .png, .pdf supported</div>
                <div>and max file size 2.5 MB</div>
              </div>
            </div>
            <div *ngIf="salarySlipLink?.length" class="file-msg col-auto">
              <span class="filename me-2 download-icon" (click)="download()">{{salarySlipName || 'Download File'}}</span>
              <span (click)="removeSalarySlip()" class="clear-icon">
                <img src="./assets/images/close.svg" alt="clear" />
              </span>
            </div>
          </div>
          
          <div *ngIf="fileSizeError" class="alert alert-error p-0">
            Supported is 2.5 MB
          </div>
          <div *ngIf="fileTypeError" class="alert alert-error p-0">
            Invalid file format
          </div>
          <div *ngIf="f.payslip_id.touched && f.payslip_id.invalid" class="alert alert-error p-0">
            <ng-container *ngIf="statusUpdateForm.controls['payslip_id'].hasError('required')">Salary slip is
              mandatory</ng-container>
          </div>
        </div>

        <div class="col-md-6 mb-2" *ngIf="f.operating_country.value !== 'US'">
          <div class="row item">
            <label class=" col-form-label ">Hiring Approved by</label>
            <div class="">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="spaceUsers" bindLabel="username"
                bindValue="id" formControlName="hiring_approved_by" [searchable]="true" [clearable]="true"
                #approved_by_ref [handler_input]="approved_by_ref" placeholder="Select or start typing" ngSelectHandler
                [searchFn]="customEmailSearchFn.bind(this)" (change)="onHiringApprovedChange($event)">
                <ng-template ng-option-tmp let-subItem="item"> {{subItem.username}} &nbsp;[<span
                    class="label-value-blue">{{subItem.email}}</span>]
                </ng-template>
              </ng-select>
              <div *ngIf="f.hiring_approved_by.touched && f.hiring_approved_by.invalid" class="alert alert-error">
                <ng-container *ngIf="statusUpdateForm.controls['hiring_approved_by'].hasError('required')">Required
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="row item">
            <label class=" col-form-label ">Virtual Team <span class="optional-field">(Optional)</span> </label>
            <div class="">
              <ng-select [editableSearchTerm]="true" [isEditable]="true" [items]="virtualTeams" bindLabel="name"
                bindValue="id" formControlName="virtual_team" [searchable]="true" [clearable]="true" #virtual_team_ref
                [handler_input]="virtual_team_ref" placeholder="Select or start typing" ngSelectHandler
                (ngModelChange)="onVirtualTeamModelChange($event, virtual_team_ref)">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="details-separator"></div>
        </div>
<!--For Direct contract and Permanent form-->
        <ng-container *ngIf="!indirectContractMode">
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Designation</label>
              <div class="">
                <ng-select #designation_dropdown [items]="designation" class="remove-border" bindLabel="name"
                  bindValue="id" formControlName="designation" [searchable]="true" [clearable]="true"
                  [editableSearchTerm]="true" placeholder="Select or start typing"
                  (ngModelChange)="onDesignationModelChange($event, designation_dropdown)">
                </ng-select>
                <div *ngIf="f.designation.touched && f.designation.invalid" class="alert alert-error">
                  <ng-container *ngIf="statusUpdateForm.controls['designation'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
              <label class=" col-form-label ">Employment Type</label>
              <span class="other-info" (mouseover)="showOtherInfo = true" (mouseout)="showOtherInfo = false">
                <img src="./assets/images/info-message-icon.svg" alt="info">
                <span *ngIf="showOtherInfo" class="info-container">
                  Employment Type can be updated from the Edit candidate screen
                </span>
              </span>
              <div class="">
                <ng-select placeholder="Select" formControlName="employment_type" [items]="employmentType" 
                  bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                </ng-select>
              </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row item">
                <label class=" col-form-label ">Work mode <span class="optional-field">(Optional)</span></label>
                <div>
                  <ng-select [items]="workModes" bindLabel="name" bindValue="id" formControlName="work_mode"
                  [searchable]="false" [clearable]="false" placeholder="Select" (change)="onWorkModeChange($event)">
                  </ng-select>   
                </div>
            </div>
          </div>

          <div class="col-md-6 mb-2" *ngIf="f.work_mode.value  === 'remote'">
            <div class="row item">
              <label class=" col-form-label ">Remote Location</label>
              <div class="">
                <input formControlName="remote_location" autocomplete="off" type="text"
                 class="form-control" (focusout)="formatName($event, 'remote_location')">
                <div *ngIf="f.remote_location.touched && f.remote_location.invalid" class="alert alert-error">
                  <div *ngIf="statusUpdateForm.controls['remote_location'].hasError('required')">Required</div>
                  <div *ngIf="statusUpdateForm.controls['remote_location'].hasError('maxlength')">Max character allowed is 50</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2" *ngIf="f.work_mode.value  === 'hybrid'">
            <div class="row item">
              <label class=" col-form-label ">Days in office <span class="optional-field">(Optional)</span></label>
              <div class="d-flex align-items-center">
                <div class="">
                  <input type="text" autocomplete="off" class="form-control" formControlName="no_of_hybrid_days"/>
                  <div *ngIf="f.no_of_hybrid_days.touched && f.no_of_hybrid_days.invalid" class="alert alert-error">
                    <div *ngIf="statusUpdateForm.controls['no_of_hybrid_days'].hasError('pattern')">
                      Enter between 1 to {{ statusUpdateForm.controls['hybrid_type'].value == 'week' ? '7':'31'}}
                    </div>
                  </div>
                </div>
                <label class="col-sm-1 text-center col-form-label">Per</label>
                <div class="col-sm-6">
                  <ng-select [items]="hybridTypes" bindLabel="name" bindValue="id"[searchable]="false"
                  formControlName="hybrid_type" [clearable]="false"  (change)="onHybridTypeChange($event)">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label">Offered salary/month</label>
              <div class="col-md-12">
                <div class="form-control d-flex align-items-center">
                  <span class="border-right me-1 pe-2 input-prefix c">
                    <ng-select formControlName="offered_salary_currency" [items]="currencyList" class="remove-border"
                      bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false"
                      (change)="changeBonusCurrency($event)">
                    </ng-select>
                  </span>
                  <input formControlName="offered_salary" autocomplete="off" type="text"
                    class="hide-arrow w-100 border-0 o-none">
                </div>
                <div *ngIf="f.offered_salary.touched && f.offered_salary.invalid" class="alert alert-error">
                  <ng-container
                    *ngIf="statusUpdateForm.controls['offered_salary'].hasError('pattern') ||statusUpdateForm.controls['offered_salary'].hasError('max')">
                    Invalid Amount
                  </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['offered_salary'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2" *ngIf="isFresherCandidate">
            <div class="row item">
              <label class=" col-form-label">Stipend <span class="optional-field">(Optional)</span></label>
              <div class="col-md-12">
                <div class="form-control d-flex align-items-center">
                  <span class="border-right me-1 pe-2 input-prefix c">
                    <ng-select formControlName="stipend_currency" [items]="currencyList" class="remove-border"
                      bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                    </ng-select>
                  </span>
                  <input formControlName="stipend" autocomplete="off" type="text"
                    class="hide-arrow w-100 border-0 o-none">
                </div>
                <div *ngIf="f.stipend.touched && f.stipend.invalid" class="alert alert-error">
                  <ng-container
                    *ngIf="statusUpdateForm.controls['stipend'].hasError('pattern') ||statusUpdateForm.controls['stipend'].hasError('max')">
                    Invalid Amount
                  </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['stipend'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Joining Location</label>
              <div class="">
                <ng-select placeholder="Select" formControlName="joining_location" [items]="joiningLocation"
                  bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                </ng-select>
              </div>
            </div>
            <div *ngIf="f.joining_location.touched && f.joining_location.invalid" class="alert alert-error">
              <ng-container *ngIf="statusUpdateForm.controls['joining_location'].hasError('required')">Required
              </ng-container>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Joining Date <span class="optional-field">(Optional)</span></label>
              <div class="">
                <app-single-date-picker-component [item]="statusUpdateForm.controls['joining_date'].value"
                  [border]="false" [datePickerId]="'updateStatusPicker'">
                </app-single-date-picker-component>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!isFresherCandidate && isAllowJoiningBonus">
            <div class="col-md-6 mb-2">
              <div class="row item">
                <label class=" col-form-label">Joining Bonus <span class="optional-field">(Optional)</span></label>
                <div class="col-md-12">
                  <div class="form-control d-flex align-items-center">
                    <span class="border-right me-1 pe-2 input-prefix c">
                      <ng-select formControlName="joining_bonus_currency" [readonly]="true" [items]="currencyList"
                        class="remove-border" bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                      </ng-select>
                    </span>
                    <input formControlName="joining_bonus" autocomplete="off" type="text"
                      class="hide-arrow w-100 border-0 o-none">
                  </div>
                  <div *ngIf="f.joining_bonus.touched && f.joining_bonus.invalid" class="alert alert-error">
                    <ng-container
                      *ngIf="statusUpdateForm.controls['joining_bonus'].hasError('pattern') || statusUpdateForm.controls['joining_bonus'].hasError('max')">
                      Invalid Amount
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="col-md-6 mb-2">
            <div class="row item">
              <div class="col-sm-6">
                <label class=" col-form-label ">Onboarding Type</label>
                <div class="d-flex h-40 align-items-center">
                  <div class="unit-flex d-flex align-items-center">
                    <input formControlName="onboarding" type="radio" class="radio-large me-1" [value]="'ON'" />
                    <span class="radio-text ">Online</span>
                  </div>
                  <div class="unit-flex d-flex align-items-center">
                    <input formControlName="onboarding" type="radio" class="radio-large m-l-20" [value]="'OFF'" />
                    <span class="radio-text">Offline</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="f.onboarding.touched && f.onboarding.invalid" class="alert alert-error">
              <ng-container *ngIf="statusUpdateForm.controls['onboarding'].hasError('required')">
                Required
              </ng-container>
            </div>
          </div>

          <div class="col-md-12" *ngIf="!indirectContractMode">
            <div class="details-separator"></div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row">
              <label class="col-form-label "> Address </label>
              <div>
                <textarea formControlName="address" class="form-control txtarea" placeholder="House name  /  Post Office
  Place  /  District
  State - Pincode">
                  </textarea>
                <div *ngIf="f.address.touched && f.address.invalid" class="alert alert-error">
                  <ng-container *ngIf="statusUpdateForm.controls['address'].hasError('required')">Required </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['address'].hasError('actualRow')">Address field is
                    limited to 5 lines </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['address'].hasError('maxlength')">Maximum 500 characters
                    allowed </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row">
              <label class="col-form-label "> Comments <span class="optional-field">(Optional)</span> </label>
              <div>
                <textarea formControlName="comments" class="form-control txtarea"></textarea>
                <div *ngIf="f.comments.touched && f.comments.invalid" class="alert alert-error">
                  <ng-container *ngIf="statusUpdateForm.controls['comments'].hasError('required')">Required
                  </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['comments'].hasError('maxlength')">Max character allowed is 500
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
<!--For Indirect contract form-->
        <ng-container *ngIf="indirectContractMode">

          <div class="col-md-6 mb-2">
            <label class=" col-form-label ">Employment Type</label>
            <span class="other-info" (mouseover)="showOtherInfo = true" (mouseout)="showOtherInfo = false">
              <img src="./assets/images/info-message-icon.svg" alt="info">
              <span *ngIf="showOtherInfo" class="info-container">
                Employment Type can be updated from the Edit candidate screen
              </span>
            </span>
            <div class="">
              <ng-select placeholder="Select" formControlName="employment_type" [items]="employmentType" 
                bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
              </ng-select>
            </div>
        </div>

        <div class="col-md-6 mb-2">
          <div class="row item">
              <label class=" col-form-label ">Work mode <span class="optional-field">(Optional)</span></label>
              <div>
                <ng-select [items]="workModes" bindLabel="name" bindValue="id" formControlName="work_mode"
                [searchable]="false" [clearable]="false" placeholder="Select" (change)="onWorkModeChange($event)">
                </ng-select>   
              </div>
          </div>
        </div>

        <div class="col-md-6 mb-2" *ngIf="f.work_mode.value  === 'remote'">
          <div class="row item">
            <label class=" col-form-label ">Remote Location</label>
            <div class="">
              <input formControlName="remote_location" autocomplete="off" type="text"
               class="form-control" (focusout)="formatName($event, 'remote_location')">
              <div *ngIf="f.remote_location.touched && f.remote_location.invalid" class="alert alert-error">
                <div *ngIf="statusUpdateForm.controls['remote_location'].hasError('required')">Required</div>
                <div *ngIf="statusUpdateForm.controls['remote_location'].hasError('maxlength')">Max character allowed is 50</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-2" *ngIf="f.work_mode.value  === 'hybrid'">
          <div class="row item">
            <label class=" col-form-label ">Days in office <span class="optional-field">(Optional)</span></label>
            <div class="d-flex align-items-center">
              <div class="">
                <input type="text" autocomplete="off" class="form-control" formControlName="no_of_hybrid_days"/>
                <div *ngIf="f.no_of_hybrid_days.touched && f.no_of_hybrid_days.invalid" class="alert alert-error">
                  <div *ngIf="statusUpdateForm.controls['no_of_hybrid_days'].hasError('pattern')">
                    Enter between 1 to {{ statusUpdateForm.controls['hybrid_type'].value == 'week' ? '7':'31'}}
                  </div>
                </div>
              </div>
              <label class="col-sm-1 text-center col-form-label">Per</label>
              <div class="col-sm-6">
                <ng-select [items]="hybridTypes" bindLabel="name" bindValue="id"[searchable]="false"
                formControlName="hybrid_type" [clearable]="false"  (change)="onHybridTypeChange($event)">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
  
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Designation</label>
              <div class="">
                <ng-select #designation_dropdown [items]="designation" class="remove-border" bindLabel="name"
                  bindValue="id" formControlName="designation" [searchable]="true" [clearable]="true"
                  [editableSearchTerm]="true" placeholder="Select or start typing"
                  (ngModelChange)="onDesignationModelChange($event, designation_dropdown)">
                </ng-select>
                <div *ngIf="f.designation.touched && f.designation.invalid" class="alert alert-error">
                  <ng-container *ngIf="statusUpdateForm.controls['designation'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Joining Location</label>
              <div class="">
                <ng-select placeholder="Select" formControlName="joining_location" [items]="joiningLocation"
                  bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false">
                </ng-select>
              </div>
            </div>
            <div *ngIf="f.joining_location.touched && f.joining_location.invalid" class="alert alert-error">
              <ng-container *ngIf="statusUpdateForm.controls['joining_location'].hasError('required')">Required
              </ng-container>
            </div>
          </div>
  
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label">Monthly Rate Card</label>
              <div class="col-md-12">
                <div class="form-control d-flex align-items-center">
                  <span class="border-right me-1 pe-2 input-prefix c">
                    <ng-select formControlName="monthly_rate_card_currency" [items]="currencyList" class="remove-border"
                      bindLabel="name" bindValue="id" [searchable]="false" [clearable]="false"
                      (change)="changeBonusCurrency($event)">
                    </ng-select>
                  </span>
                  <input formControlName="monthly_rate_card" autocomplete="off" type="text"
                    class="hide-arrow w-100 border-0 o-none">
                </div>
                <div *ngIf="f.monthly_rate_card.touched && f.monthly_rate_card.invalid" class="alert alert-error">
                  <ng-container
                    *ngIf="statusUpdateForm.controls['monthly_rate_card'].hasError('pattern') ||statusUpdateForm.controls['monthly_rate_card'].hasError('max')">
                    Invalid Amount
                  </ng-container>
                  <ng-container *ngIf="statusUpdateForm.controls['monthly_rate_card'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label ">Joining Date <span class="optional-field">(Optional)</span></label>
              <div class="">
                <app-single-date-picker-component [item]="statusUpdateForm.controls['joining_date'].value"
                  [border]="false" [datePickerId]="'updateStatusPicker'">
                </app-single-date-picker-component>
              </div>
            </div>
          </div>
  
          <div class="col-md-12" *ngIf="!indirectContractMode">
            <div class="details-separator"></div>
          </div>
  
          <div class="col-md-6 mb-2">
            <div class="row item">
              <label class=" col-form-label "> Contract Duration <span class="optional-field">(Optional)</span></label>
              <div class="">
                <ng-select placeholder="Select" formControlName="contract_duration" [items]="contractDuration"
                  bindLabel="name" bindValue="id" [searchable]="false" [clearable]="true">
                </ng-select>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="row item">
              <div class="col-sm-6">
                <label class=" col-form-label ">Onboarding Type</label>
                <div class="d-flex h-40 align-items-center">
                  <div class="unit-flex d-flex align-items-center">
                    <input formControlName="onboarding" type="radio" class="radio-large me-1" [value]="'ON'" />
                    <span class="radio-text ">Online</span>
                  </div>
                  <div class="unit-flex d-flex align-items-center">
                    <input formControlName="onboarding" type="radio" class="radio-large m-l-20" [value]="'OFF'" />
                    <span class="radio-text">Offline</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="f.onboarding.touched && f.onboarding.invalid" class="alert alert-error">
              <ng-container *ngIf="statusUpdateForm.controls['onboarding'].hasError('required')">
                Required
              </ng-container>
            </div>
          </div>
  
          <div class="col-md-6 mb-2">
            <div class="row">
              <label class="col-form-label "> Comments <span class="optional-field">(Optional)</span> </label>
              <div>
                <textarea formControlName="comments" class="form-control txtarea"></textarea>
                <div *ngIf="f.comments.touched && f.comments.invalid" class="alert alert-error">
                  <ng-container *ngIf="statusUpdateForm.controls['comments'].hasError('required')">Required
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </ng-container>

        <ng-container *ngIf="formContents?.hasPromptSourceUpdate">
          <div class="col-md-6 mb-2">
            <div class="d-flex source-prompt-checkbox px-2 py-2">
              <app-checkbox [checked]="f?.update_candidate_source?.value" (toggle)="onUpdateCandidateSource()">
              </app-checkbox>
              <div class="ms-2 info-msg">Update the source to HR since the candidate's last application was an year ago.
                <img class="cursor-pointer" (mouseover)="toggleInfo=true" (mouseout)="toggleInfo=false"
                  src="./assets/images/info-message-icon.svg" alt="info">
                <div class="position-relative">
                  <div *ngIf="toggleInfo" class="info-box-source"> Applied on date will be set to today's date </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-1">
          <div class="d-flex justify-content-center mt-3">
            <button type="button" class="col-sm-3 m-1 me-4 btn button-cancel" (click)="onCancel()">{{'cancel'| uppercase
              }}</button>
            <button type="button" class="col-sm-3 m-1 btn button-save red-btn" (click)="updateStatus()">UPDATE
              STATUS</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
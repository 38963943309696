import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { SearchSortFilterComponent } from 'src/app/shared/_components/search-sort-filter/search-sort-filter.component';

import { PAGE } from 'src/app/shared/_models/page.enum';
import { Role } from 'src/app/shared/_models/role';

@Component({
  selector: 'app-common-list-item-search',
  templateUrl: './common-list-item-search.component.html',
  styleUrls: ['./common-list-item-search.component.scss']
})
export class CommonListItemSearchComponent {
  filterItems: any;
  orderByItems: any;
  customData: any;
  Role = Role;
  Page = PAGE;
  _constants: any;
  dataLoaded: boolean = false;
  locations: any;
  
  @Input() searchedTags: any = [];
  @Input() searchQuery: any;
  @Input() page: string = ''
  @Input() set constants(data: any) {
    this._constants = data;
    this.filterItems = data.filterItems;
    this.orderByItems = [...data.sortBy];
    this.customData = data.customData;
    this.setFilters();
  } get constants() {
    return this._constants;
  }
  @Output() searchChange: EventEmitter<any> = new EventEmitter();
  @Output() updateSeachParams: EventEmitter<any> = new EventEmitter();
  subscription1: Subscription = new Subscription();
  @Input() searchInputPlaceholder: string = 'Search by Name, Email';
  @Input() hideFilter: boolean =false;
  @Input() disableSort: boolean = false;
  
  @ViewChild('commonSearchSortFilter') searchSortFilter!: SearchSortFilterComponent;

  constructor(private appService: AppService,) { 

  }

  setFilters() {
    this.filterItems.some((item: any) => item.source == 'dropdown-api') && this.getDropdownsData();
    this.filterItems.some((item: any) => item.source == 'custom') && this.setAdditionalFilters();
  }

  getDropdownsData() {
    this.subscription1 = this.appService.generalDropdowns.subscribe((data: any) => {
      this.locations = data.Joining_location;
      const items = this.filterItems.filter((item: any) => item.source == 'dropdown-api');

      for (const key of items) {
        const temp = {
          ...key,
          values: data[key.apiKey]
        }
        this.filterItems.splice(key.priority, 1, temp);
      }
    });
  }

  setAdditionalFilters() {
    let items;

    items = this.filterItems.filter((item: any) => item.source == 'custom');

    for (const key of items) {
      const temp = {
        ...key,
        ...(key.apiKey && { values: this.customData[key.apiKey] })
      }
      this.filterItems.splice(key.priority, 1, temp);
    }
  }

  onSearchWithFilter(params: any) {
    this.searchChange.emit(params);
  }

  updateSeachParamsEvent(params: any) {
    this.updateSeachParams.emit(params);
  }

  clearSearchInput() {
    this.searchSortFilter.clearSearchInput();
  }

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
  }


}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent {
  @Input() firstTitle: string = '';
  @Input() secondTitle: string = '';
  @Input() itemCount: number = 0;
  @Input() isReferral: boolean = false;
  @Output() viewAll: EventEmitter<any> = new EventEmitter();
  @Output() refer: EventEmitter<any> = new EventEmitter();
  @Input() hasNoData: boolean = true;
  infoToggle: boolean = false;


  viewAllData() {
    this.viewAll.emit();
  }

  referCandidate() {
    this.refer.emit();
  }

}

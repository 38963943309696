<div class="file-drop-area">
  <div *ngIf="!filePreviewOnly && (!toggleEnabled || !fileLink?.length)" [ngClass]="{'d-flex': isHintInRow}">
    <input hidden #uploader class="file-input" type="file" (change)="onChange($event)" [accept]="acceptType">
    <button type="button" (click)="!uploadDisabled ? uploader.click() : ''" class="btn btn-uploader m-0"
      [ngClass]="{'button-highlight':focusElement}" [disabled]="uploadDisabled" [tabindex]="tabIndex"
      (blur)="removeFocus()" (keyup)="onFocusElement($event)">{{file_id ? (isUpperCase? ('Update' | uppercase) : 'Update') : 
      (isUpperCase? ('Upload' | uppercase) : 'Upload')}} {{ isUpperCase ? (btnName | uppercase) : btnName }}
    </button>
      
    <ng-container *ngIf="showHint">
      <div *ngIf="!isHintInRow" class="hint">
        <div>
          <span *ngIf="type=='doc'">.doc, .docx, .pdf, .odt, .odx supported</span>
          <span *ngIf="type=='image'">.jpg, .jpeg, .png, supported</span>
          <span *ngIf="type=='both'"> .doc, .docx, .pdf, .odt, .odx, .jpg, <div>.jpeg, .png, supported</div>
          </span>
          <div *ngIf="fileType != 'RES'"> and max file size {{fileSizeText}}</div>
          <span *ngIf="fileType === 'RES'"> and max file size {{fileSizeText}}</span>
        </div>
      </div>
      <div *ngIf="isHintInRow" class="d-flex">
        <div class="hint d-flex flex-column ms-2"><span>.doc, .docx, .pdf, .odt, .odx, .jpg, .jpeg, .png,
            supported</span><span> and max file {{fileSizeText}}</span></div>
      </div>
    </ng-container>

  </div>
  <ng-container *ngIf="showUploadedFile">
    <div class="row nm21">
      <div *ngIf="fileLink?.length" class="file-msg col-auto" [ngClass]="{'disable-file-icon': disableClick}">
        <span class="filename me-2 download-icon"
          (click)="download(filePreviewOnly || isNewFile ? true : false)">{{fileName || 'Download File'}}</span>
        <span *ngIf="!filePreviewOnly" class="lh16">
          <span (click)="reset()" class="clear-icon">
            <img src="./assets/images/close.svg" alt="clear" />
          </span>
        </span>
      </div>
    </div>
  </ng-container>
 
  <div *ngIf="fileSizeError && fileType === 'RES'" class="alert alert-error">
    Max file size supported is {{fileSizeText}}
  </div>
  <div *ngIf="fileSizeError && fileType != 'RES'" class="alert alert-error">
    Max file size supported is {{fileSizeText}}
  </div>
  <div *ngIf="fileTypeError" class="alert alert-error">
    Invalid file format
  </div>
  <div *ngIf="mandatoryFileMessage" class="alert alert-error">
    {{mandatoryFileMessage}}
  </div>
</div>
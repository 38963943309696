<div class="half-stars-container" [ngClass]="readonly === true ? '' : 'active-container'">
  <div class="rating-group">
    <input class="rating__input rating__input--none" [checked]="rating == 0 ? true : false" [disabled]="readonly == true" [name]="id + '-rating'"
      [id]="id + 'rating-0'" [value]="null" type="radio" (click)="handleClick($event)">
    <label class="rating__label" [for]="id + 'rating-0'">&nbsp;</label>

    <ng-container *ngFor="let val of values">
      <label class="rating__label" [ngClass]="val.value % 1 === 0 ? '' : 'rating__label--half'" [for]="id + 'rating-' + val.value" ngbTooltip="{{!readonly ? val.label : ''}}" tooltipClass="{{customClass}}" placement="bottom">

        <svg *ngIf="val.value % 1 !== 0" class="rating__icon rating__icon--star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="18" height="17">
          <g transform="matrix(1.125 0 0 1.1333334 0 0)">
            <g transform="matrix(1 0 0 1 -37 -0)">
            </g>
              <path transform="matrix(1 0 0 1 -37 -0)"  d="M45.333332 1.5192443L46.8598 5.8990026L51.4969 5.9973364L47.803204 8.802509L49.142628 13.243041L45.333332 10.596975L41.52404 13.243041L42.863464 8.802509L39.16977 5.9973364L43.80687 5.8990026L45.333332 1.5192443z" stroke="#FFBD02" stroke-width="1" fill="#FFBD02" fill-rule="nonzero" />
          </g>
        </svg>

        <svg *ngIf="val.value % 1 === 0" class="rating__icon rating__icon--star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="18" height="17">
          <g transform="matrix(1.125 0 0 1.1333334 0 0)">
            <g transform="matrix(1 0 0 1 -37 -0)">
            </g>
              <path transform="matrix(1 0 0 1 -37 -0)"  d="M45.333332 1.5192443L46.8598 5.8990026L51.4969 5.9973364L47.803204 8.802509L49.142628 13.243041L45.333332 10.596975L41.52404 13.243041L42.863464 8.802509L39.16977 5.9973364L43.80687 5.8990026L45.333332 1.5192443z" stroke="#FFBD02" stroke-width="1" fill="#FFBD02" fill-rule="nonzero" />
          </g>
        </svg>

      </label>
      <input class="rating__input" [name]="id + '-rating'" [id]="id + 'rating-' + val.value" [value]="val.value" [checked]="rating == val.value"
        [disabled]="readonly == true" type="radio" (click)="handleClick($event)">
    </ng-container>

  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dateformat } from 'src/app/shared/_services/format';

export interface popupDataModel {
  isWarning?: boolean;
  title: string;
  description: string;
  warningDesc?: string;
  closeBtn?: string;
}

@Component({
  selector: 'app-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.scss']
})
export class AvailabilityModalComponent {

  dateformat = dateformat;
  @Input() availabilityData: any ;
  @Input() availabilityRange: string = '';
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  formatAMPM = (time: string) => {
    let hours = parseInt(time.split(':')[0]);
    let minutes = time.split(':')[1];    
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours %= 12;
    hours = hours || 12;    
    minutes = (parseInt(minutes) > 0 && parseInt(minutes) < 10) ? `0${minutes}` : minutes;
  
    const strTime = `${hours}:${minutes} ${ampm}`;
  
    return strTime;
  };

  onClose() {
    this.closeModal.emit();
  }

}

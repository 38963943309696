import { Component, OnInit, Output, EventEmitter, Input, OnDestroy,Injector, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import { ResumeService } from '../../../resume/resume.service';
import { Interviewers as InterviewerModel, JobCode, JobLocation, Department as DepartmentModel, Stream as StreamModel, Designation as DesignationModel, InterviewType, InterviewLevel } from '../../_models/utils';
import { AppService } from '../../../app.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as dayjs from 'dayjs';
import { InterviewService } from 'src/app/interview/interview.service';
import { DatePipe } from '@angular/common';
import { CustomValidators } from 'src/app/shared/_services/custom-validators.service';
import { Subscription } from 'rxjs';
import { PAGE } from '../../_models/page.enum';
import { GlobalStoreService } from '../../_services/global-store.service';
import { dateFormatForDatePipe } from '../../_services/format';
import { UtilService } from '../../_services/util.service';
import { InterviewerService } from 'src/app/interview/interviewers/interviewers.service';
import { Slot } from '../../_models/slot.model';

@Component({
  selector: 'schedule-edit-interview',
  templateUrl: './schedule-edit-interview.component.html',
  styleUrls: ['./schedule-edit-interview.component.scss']
})
export class ScheduleEditInterviewComponent implements OnInit, OnDestroy {
  @Input() resume!: any;
  @Input() editMode: boolean = false;
  @Input() interviewId!: number;
  @Input() fromInterviewList: boolean = false;
  @Output() reloadCandidate: EventEmitter<boolean> = new EventEmitter();
  @Output() updateInterviews: EventEmitter<boolean> = new EventEmitter();
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
  candidate: any;
  interviewCompleted: boolean = false;
  scheduleForm!: FormGroup;
  closeResult: string = '';
  jobCodes: Array<JobCode> = [];
  interviewLevels: Array<InterviewLevel> = [];
  interviewType: Array<InterviewType> = [];
  interviewLocation: Array<JobLocation> = [];
  departments: Array<DepartmentModel> = [];
  streams: Array<StreamModel> = [];
  streamBackup: Array<StreamModel> = [];
  designations: Array<DesignationModel> = [];
  interviewers: Array<InterviewerModel> = [];
  interviewersBackup: any = [];
  interview_duration: Array<any> = [];
  timepickerConfig: any = {};
  datepickerConfig: any = {
    id: "scheduled_date",
    value: undefined,
    minDate: dayjs(),
    maxDate: dayjs.Dayjs,
  };
  localDateFormat: string = dateFormatForDatePipe;
  localTimeFormat: string = 'HH:mm';
  slotDayFormat: string = 'EEEE';
  slotDateFormat: any = "MMM d y";
  slotTimeFormatModal: any = "hh:mm a";
  slotsApiLoading: boolean = true;
  timeValue: string = '';
  interviewDataBackup: string = '';
  scheduleDisabled: boolean = false;
  designationsFiltered: Array<DesignationModel> = [];
  showInfo: boolean = false;
  availableSlots: Slot[] = [];
  availableSlotsFiltered: Slot[] = [];
  currentSlotIndex: number = 0;
  showOtherInfo: boolean = false;

  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  resumeService:ResumeService;
  toaster:ToastrService;
  modalService: NgbModal;
  interviewService: InterviewService;
  globalStoreService: GlobalStoreService;
  interviewerService: InterviewerService;
  @ViewChild('content', { static: true }) content!: NgbModalRef;
  slotDiv: HTMLElement | null = null;
  otherInterviewersList: any = [];
  otherInterviewersDetails: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private customValidatorService: CustomValidators,
    private injector: Injector,
    private utilService: UtilService,
  ) {
  //moved inside constructor to reduce the no of constructor parameters to 7
  this.resumeService = injector.get<ResumeService>(ResumeService);
  this.toaster = this.injector.get<ToastrService>(ToastrService);
  this.modalService = this.injector.get<NgbModal>(NgbModal);
  this.interviewService = this.injector.get<InterviewService>(InterviewService);
  this.globalStoreService = this.injector.get<GlobalStoreService>(GlobalStoreService);
  this.interviewerService = this.injector.get<InterviewerService>(InterviewerService);
  }

  ngOnInit(): void {
    this.candidate = this.route.snapshot.params['id'] || this.resume.id;
  }

  openModal() {
    this.open(this.content)
  }

  InitializeForm() {
    this.scheduleForm = this.formBuilder.group({
      level: [null, [Validators.required]],
      scheduled_date: [null, [Validators.required, this.customValidatorService.minimumDate(this.datePipe.transform(new Date(), this.localDateFormat)!)]],
      scheduled_time: [null, [Validators.required]],
      mode: [null, [Validators.required]],
      duration: [3, [Validators.required]],
      slot_id: [null],
      location: [null, [Validators.required]],
      lead_interviewer: [null, [Validators.required]],
      other_interviewers: [[]],
      job: [null],
      department: [null],
      stream: [null],
      designation: [null],
      comment: [null, [Validators.maxLength(255)]],
      show_previous_interview: [false],
      allow_conflicting_interviews: [false]
    });

  this.scheduleForm.controls['scheduled_date'].valueChanges
    .subscribe(selectedDate => {
      const date = new Date();
      const today = this.datePipe.transform(date, this.localDateFormat)!;
      const timeNow = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');

      if (selectedDate === today) {
        this.scheduleForm.controls['scheduled_time'].setValidators([Validators.required, this.customValidatorService.minimumTime(timeNow)]);
      } else {
        this.scheduleForm.controls['scheduled_time'].setValidators([Validators.required]);
      }

      this.scheduleForm.controls['scheduled_time'].updateValueAndValidity();
    });

    if (this.resume?.is_prompt_source_update){
      this.scheduleForm.addControl('update_schedule_interview_source', new FormControl(false));
    }
  }

  get f() { return this.scheduleForm.controls; }

  onDateSelected(e: any) {
    if (e && e.scheduled_date != "Invalid Date") {
      if (e.scheduled_date !== this.f.scheduled_date.value) {
        this.scheduleForm.controls['slot_id'].setValue(null); //clear selected slot on value change
      }
      this.checkForSlot(e.scheduled_date);
      this.f.scheduled_date.setValue(e.scheduled_date);
    }
    else {
      this.f.scheduled_date.setValue(null);
      this.scheduleForm.controls['slot_id'].setValue(null); //clear selected slot on value change
    }
  }

  onTimeSelected(e: any) {
    if (e !== this.f.scheduled_time.value) {
      this.scheduleForm.controls['slot_id'].setValue(null); // clear selected slot on value change
    }
    this.f.scheduled_time.setValue(e);
    if (e) {
      this.timeValue = e.split(':')[0] + ':' + e.split(':')[1];
      this.timepickerConfig = { hour: parseInt(e.split(':')[0]), minute: parseInt(e.split(':')[1]) };
    }
  }

  onDurationChange() {
    this.scheduleForm.controls['slot_id'].setValue(null); // clear sselected slot on value change
  }

  open(content: any) {
    this.InitializeForm();
    this.getDropdownsData();
    let isFresher = this.resume?.is_fresher;
    if (!isFresher && !this.resume?.is_resume_exists) {
      this.modalClosed.emit(true);
      this.toaster.error(`Please upload resume to ${this.editMode ? 'edit' : 'schedule'} the interview`);
      return;
    }
    this.scheduleForm.reset();
    this.InitializeForm();
    this.streams = [...this.streamBackup];
    this.prefillCandidate();
    this.modalService.open(content, { size: 'lg', centered: true, windowClass: 'schedule-modal', backdrop: 'static', keyboard: true }).result
      .then((result) => {
        this.closeResult = `Closed with: ${result}`;
        this.modalClosed.emit(true);
      }, (reason) => {
        this.clearAvailableSlots();
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.modalClosed.emit(true);
      });
  }

  prefillCandidate() {
    if (this.editMode) {
      this.scheduleDisabled = true;
      this.interviewService.getInterviewById('' + this.interviewId).subscribe((response: any) => {
        if (response.data?.other_interviewers && response.data?.other_interviewers.length > 0) {
          response.data?.other_interviewers.forEach((interviewer: any) => {
            interviewer['full_name'] = interviewer.username;
          })
        }
        this.populateInterviewData(response.data);
      }, (error: any) => {
        this.modalService.dismissAll();
        console.log(error);
      });
    }
    else {
      if (this.resume?.job_code?.status === 'APR') {
        this.f.job.setValue(this.resume.job_code.id)
      }
      if (this.resume?.stream) {
        this.f.stream.setValue(this.resume.stream.id)
      }
      if (this.resume?.stream?.department) {
        const dept_id = this.resume.stream.department.id;
        this.setDesignation(dept_id);
        this.setStreams(dept_id);
        this.f.department.setValue(this.resume.stream.department.id)
      }
      if (this.resume?.designation) {
        this.f.designation.setValue(this.resume.designation?.id);
        this.setDesignationDropdown(this.resume.designation);
        this.setDesignation(this.resume.stream?.department.id);
      }
      this.datepickerConfig.value = undefined;
      this.timepickerConfig = {};
      this.timeValue = '10:00';
      this.onTimeSelected(this.timeValue);
      this.f.mode.setValue('V'); //set default interview mode to Video
    }
  }

  setDesignationDropdown(designation: any) {
    //remove the previously added temporary designation
    let last: any = this.designations[this.designations.length - 1];
    if (last.is_temporary) {
      this.designations.pop();
    }
    //add current temporary designation to the dropdown if not already present
    let current: any = this.designations.find(d => d?.id === designation?.id);
    if (!current) {
      let tempDesignation = {...designation, is_temporary: true};
      this.designations = [...this.designations, tempDesignation];
    }
  }

  populateInterviewData(interview: any) {
    let { localDate, localTime } = this.convertIsoToLocalDateAndTime(interview.scheduled_for);

    this.f.scheduled_date.setValue(localDate);
    this.datepickerConfig = {
      ...this.datepickerConfig,
      minDate: dayjs(localDate) < dayjs() ? dayjs(localDate) : dayjs(),
      value: { startDate: dayjs(localDate), endDate: dayjs(localDate), }
    };

    this.onTimeSelected(localTime);
    this.timeValue = localTime || '';
    this.f.scheduled_time.setValue(localTime);

    this.f.level.setValue(interview.level || null);
    this.f.mode.setValue(interview.mode || null);
    this.f.location.setValue(interview.location?.id || null);
    this.f.duration.setValue(interview.duration?.id || null);
    this.f.lead_interviewer.setValue(interview.lead_interviewer?.id || null);
    this.f.other_interviewers.setValue(interview.other_interviewers || []);
    this.otherInterviewersList = interview.other_interviewers.map((interviewer: any) => interviewer?.id) || [];
    this.selectDropdownItems();
    this.f.job.setValue(interview.job?.id || null);
    this.f.stream.setValue(interview.stream?.id || null);
    this.f.department.setValue(interview.department?.id || null);
    this.f.designation.setValue(interview.designation?.id || null);

    this.setDesignationDropdown(interview.designation);

    if (interview.department?.id || interview.designation?.id) {
      this.setDesignation(interview.department?.id);
      this.setStreams(interview.department?.id);
    }

   
    this.f.show_previous_interview.setValue(interview?.show_previous_interview);
    
    this.interviewDataBackup = JSON.stringify(this.scheduleForm.value);
    this.detectChanges();

    this.checkForInterviewer(interview.lead_interviewer?.id)
  }

  checkForInterviewer(interviewerId: any) {
    if (interviewerId) {
      this.fetchAvailableSlots(interviewerId);
      let tempArray = this.f.other_interviewers.value ? this.f.other_interviewers.value : [];
      tempArray = tempArray.filter((interviewer: any) => interviewerId != interviewer.id);
      this.scheduleForm.get('other_interviewers')?.setValue(tempArray);

      this.interviewersBackup = this.interviewers.filter((user: any) => interviewerId != user.id);
    }
    else {
      this.interviewersBackup = [...this.interviewers];
    }
  }

  onInterviewerChange(e: any) {
    this.clearAvailableSlots();
    if (e) {
      this.checkForInterviewer(e.id);

      if (e.interview_level_id) {
        this.f.level.setValue(e.interview_level_id);
      } else {
        this.f.level.setValue(null);
      }
      if (e.interview_location_id) {
        this.f.location.setValue(e.interview_location_id);
      } else {
        this.f.location.setValue(null);
      }
      this.scheduleForm.controls['slot_id'].setValue(null); // clear selected slot if any
      this.onUpdateInterviewLevel();
      this.onRemoveOtherInterviewer(e);
    }
    else {
      this.interviewersBackup = [...this.interviewers];
    }
  }

  onJobDetailsChange(e: any, item: string) {
    if(e)
    {
      item === 'job' && this.setJobDetails(e);
      item === 'stream' && this.setStreamDetails(e);
    }
    if (item === 'department') {
      this.setDepartmentDetails(e);
    }
  }

  setJobDetails(e:any)
  {
    this.streams = [...this.streamBackup];
    this.setDesignation(e.dept);
    this.setStreams(e.dept);
    this.f.stream.setValue(e.stream)
    this.f.department.setValue(e.dept)
    this.f.designation.setValue(e.designation)
  }

  setStreamDetails(e:any)
  {
    this.setDesignation(e.department_id);
    let currentDeptId = this.scheduleForm.controls['department'].value;
    if (currentDeptId && e.department_id !== currentDeptId) {
      this.f.designation.setValue(null);
    }
    this.f.department.setValue(e.department_id)
  }

 setDepartmentDetails(e:any)
 {
   this.f.stream.setValue(null);
   this.f.designation.setValue(null);
   this.setDesignation(e?.id || null);
   this.streams = [];
   if (e?.id) {
     this.streamBackup.forEach((s: any) => {
       if (e.id == s.department_id) {
         this.setDesignation(s.department_id);
         this.streams.push(s);
         //automatically set stream for non development departments
         if (e.id != '2') {
           this.f.stream.setValue(s.id);
         }
       }
     });
   }
 }
  setDesignation(dept_id: string) {
    this.designationsFiltered = this.designations.filter((item: any) => item.department_id === dept_id
      || this.f.designation?.value == item.id || item.is_temporary);
  }

  setStreams(dept_id: string) {
    this.streams = this.streamBackup.filter((stream: any) => {
      return (dept_id === stream.department_id)
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCancelClick() {
    this.clearOtherInterviewerList();
    this.clearAvailableSlots();
    this.modalService.dismissAll();
  }

  getDropdownsData() {
    this.subscription2 = this.appService.generalDropdowns.subscribe((data: any) => {
      const {
        Job_codes = [],
        Stream = [],
        Department = [],
        Interview_level_choice = [],
        Interview_Location = [],
        Interview_mode_choice = [],
        Designation = [],
        Interviewers = [],
        interview_duration= [],
      } = data;

      this.jobCodes = Job_codes;
      this.departments = Department;
      this.streams = Stream;
      this.streamBackup = Stream;
      this.interviewLocation = Interview_Location;
      this.interviewLevels = Interview_level_choice;
      this.interviewType = Interview_mode_choice;
      this.designations = Designation;
      this.interview_duration = interview_duration;
      if (this.f.designation?.value || this.f.department?.value) {
        this.setDesignation(this.f.designation?.value);
      }
      this.interviewers = Interviewers;
      this.interviewersBackup = [...this.interviewers];
    })
  }

  scheduleInterview() {
    if (!this.scheduleForm.valid || this.isTimeInvalid()) {
      this.scheduleForm.markAllAsTouched();
      return;
    }
    const requestObject: any = this.constructRequestObject();
     if (this.resume?.is_prompt_source_update && this.scheduleForm.controls['update_schedule_interview_source'].value) {
      requestObject['source'] = this.scheduleForm.controls['update_schedule_interview_source'].value ? 'HR' : null;
    }
    this.f.other_interviewers.patchValue(this.otherInterviewersDetails);
    if (this.editMode && JSON.stringify(this.scheduleForm.value) == this.interviewDataBackup) {
      this.modalService.dismissAll();
      if (!this.fromInterviewList) {
        this.navigateToCandidateDetailsPage();
      }
      return;
    }

    if (!this.editMode) {
      this.subscription3 = this.resumeService.scheduleInterview(requestObject)
        .subscribe(
          (response: any) => {
            if (response.status == 'success') {
              this.interviewId = response.data;
              this.successAction('scheduled');
              return;
            } else if (response.status == 400) {
              this.toaster.error(response.message);
              return;
            }
            this.toaster.error(`Something went wrong`);
          },
          (error) => {
            this.toaster.error(`Something went wrong`);
          });
    }
    else {
      this.subscription3 = this.resumeService.editInterview(requestObject, this.interviewId)
        .subscribe(
          (response: any) => {
            if (response.status == 'success') {
              this.successAction('edited');
              return;
            } else if (response.status == 400) {
              this.toaster.error(response.message);
              return;
            }
            this.toaster.error(`Something went wrong`);
          },
          (error) => {
            this.toaster.error(`Something went wrong`);
          });
    }
  }

  successAction(action: string) {
    this.clearOtherInterviewerList();
    this.toaster.success(`Interview ${action} successfully`);
    this.reloadCandidate.emit();
    this.updateInterviews.emit(true);
    this.modalService.dismissAll();
    if (!this.fromInterviewList) {
      this.navigateToCandidateDetailsPage();
    }
  }

  navigateToCandidateDetailsPage() {
    let fromPage = this.globalStoreService.getCurrentPageDetails(PAGE.CANDIDATEDETAILS)?.from;
    let queryParamsObj: any = { interview: this.interviewId };
    if (fromPage) {
      //from param should be the first param to match the regex for back navigation in header component
      queryParamsObj = { from: fromPage, ...queryParamsObj }
    }
    this.router.navigate(['/candidates/' + this.candidate + '/view/interviews'], { queryParams: queryParamsObj });
  }

  isTimeInvalid() {
    const date = new Date();
    const today = this.datePipe.transform(date, this.localDateFormat)!;
    const timeNow = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    const timeControl = this.scheduleForm.controls['scheduled_time'];

    if (this.scheduleForm.controls['scheduled_date'].value === today && timeControl.value <= timeNow) {
      timeControl.setErrors({'minimumTime': timeNow, 'actual': timeControl.value});
      return true;
    }
    else {
      return false;
    }
  }    

  constructRequestObject() {
    const formDatas = { ...this.scheduleForm.value };
    let tempTime = "";
    if (formDatas.scheduled_time.split(':')[0].length === 1) {
      tempTime += '0' + formDatas.scheduled_time.split(':')[0];
    } else {
      tempTime += formDatas.scheduled_time.split(':')[0];
    }
    if (formDatas.scheduled_time.split(':')[1].length === 1) {
      tempTime += ':0' + formDatas.scheduled_time.split(':')[1];
    } else {
      tempTime += ':' + formDatas.scheduled_time.split(':')[1];
    }
    formDatas.scheduled_time = tempTime;
   
    formDatas.other_interviewers = [...this.otherInterviewersList];
    this.calculateStreamInfo(formDatas);
    const scheduledDate = (formDatas.scheduled_date + ' ' + formDatas.scheduled_time).replace(/-/g, "/") // Replace fn is used as fix for Safari returning invalid date on new Date() conversion
    const requestObject = {
      ...formDatas,
      status: 'NEW',
      candidate: this.candidate,
      comment: formDatas.comment ? formDatas.comment.trim() : '',
      scheduled_for: new Date(scheduledDate).toISOString()
    };
    delete requestObject['scheduled_time'];
    delete requestObject['scheduled_date'];
    delete requestObject['slot_id'];
    return requestObject;
  }

  onInterviewerSelected(e: any, ref: NgSelectComponent) {
    var tempArra = this.f.other_interviewers.value ? this.f.other_interviewers.value : [];
    if (e) {
      if (!tempArra.some((item: any) => item.id == e.id)) {
        tempArra.push(e);
        this.scheduleForm.get('other_interviewers')?.setValue(tempArra);
      }
      ref.handleClearClick();
    }
  }

  onRemove(e: any) {
    var tempArr = this.f.other_interviewers.value;
    tempArr = tempArr.filter((item: any) => item.id != e.id);
    this.scheduleForm.get('other_interviewers')?.setValue(tempArr);
  }

  convertIsoToLocalDateAndTime(isoDate: string) {
    const localDate = this.datePipe.transform(isoDate, this.localDateFormat);
    const localTime = this.datePipe.transform(isoDate, this.localTimeFormat);
    return { localDate, localTime };
  }

  identify(index: any, item: any) {
    return item.id;
  }

  detectChanges() {
    this.subscription4 = this.scheduleForm.valueChanges.subscribe((data: any) => {
      this.scheduleDisabled = false;
    })
  }

  onDepartmentModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.departments)
  }

  onStreamModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.streams)
  }

  onDesignationModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.designationsFiltered)
  }

  onModelChange(event: any, ref: NgSelectComponent, data: any) {
    if (event) {
      let filterValue = data.find((str: any) => str.id === event);
      ref.searchTerm = filterValue ? filterValue.name : '';
    } else {
      ref.searchTerm = '';
    }
  }

  calculateStreamInfo(formData: any) {
    if (formData?.department && !formData?.stream) {
      const departmentInfo = this.departments.find(
        (data: any) => data?.id === formData?.department
      );
      if (departmentInfo) {
        const streamInfo = this.streams.find(
          (data: any) => data?.name === departmentInfo?.name
        );
        formData['stream'] = streamInfo?.id || null;
        this.scheduleForm.controls['stream'].setValue(formData['stream']);
      }
    }
  }

  customSearchFn(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item, 'full_name');
  }

  onRemoveOtherInterviewer(user: any) {
    this.otherInterviewersDetails = this.otherInterviewersDetails.filter((item: any) => item?.id !== user?.id);
    this.otherInterviewersList = this.otherInterviewersList.filter((item: any) => item !== user?.id);
  }

  onOtherInterviewerSelection(event: any) {
    if (event) {
      this.otherInterviewersDetails = this.interviewersBackup.filter((user: any) => this.otherInterviewersList.includes(user?.id));
      setTimeout(() => {
        this.retainPlaceHolderForMultiSelect();
      }, 0);

    }
  }

  selectDropdownItems() {
    this.otherInterviewersDetails = this.f.other_interviewers.value ? [...this.f.other_interviewers.value] : [];
    if (this.otherInterviewersList?.length) {
      setTimeout(() => {
        this.retainPlaceHolderForMultiSelect();
      }, 0);
    }
  }

  retainPlaceHolderForMultiSelect() {
    let multiSelectClass = ' .multi-select-interviewer .ng-select .ng-select-container';
    const multiSelect = document.querySelector(multiSelectClass) as any;
    multiSelect.classList.remove("ng-has-value");
  }

  onDropdownOpen() {
    this.utilService.dropdownScrollToTop('.multi-select-interviewer');
  }

  searchUser(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item, 'full_name');
  }
  
  clearOtherInterviewerList(){
    this.otherInterviewersList = [];
    this.otherInterviewersDetails = [];
  }

  clearAvailableSlots() {
    this.availableSlots = [];
    this.availableSlotsFiltered = [];
    this.currentSlotIndex = 0;
  }

  onUpdateScheduleInterviewSource(){
    let updateSource = this.scheduleForm.controls['update_schedule_interview_source'].value;
    this.scheduleForm.controls['update_schedule_interview_source'].setValue(!updateSource);
  }

  toggleInfoShow() {
    this.showInfo = true;
  }

  toggleInfoHide() {
    this.showInfo = false;
  }

  onUpdateInterviewLevel(){
    if (this.f.level.value === 'M') {
      this.f.show_previous_interview.setValue(true);
    } else {
      this.f.show_previous_interview.setValue(false);
    }
  }

  toggleCheckBox(formControlName: string){
    let value = this.scheduleForm.controls[formControlName].value;
    this.scheduleForm.controls[formControlName].setValue(!value);
  }

  fetchAvailableSlots(id: any) {
    this.slotsApiLoading = true;
    this.interviewerService.getSlots(id, {}).subscribe(
      (response: any) => {
        if (response?.status === 'OK') {
          this.availableSlots = response.data.slots;
          this.availableSlotsFiltered = this.availableSlots.slice(0, 5);
          setTimeout(() => {
            this.slotDiv = document.getElementById('slotDiv');
          }, 100)
          this.slotsApiLoading = false;
        } else {
          this.toaster.error(response.message);
        }
      },
      () => {
        this.toaster.error(`Something went wrong`);
      }
    )
  }

  onPrev() {
    if (this.currentSlotIndex > 0) {
      this.currentSlotIndex = this.currentSlotIndex - 5;
      if (this.currentSlotIndex < 0) {
        this.currentSlotIndex = 0;
      }
      this.setViewableSlots();
    }
  }

  onNext() {
    if (this.currentSlotIndex < this.availableSlots.length - 5) {
      this.currentSlotIndex = this.currentSlotIndex + 5;
      if (this.currentSlotIndex > this.availableSlots.length - 1) {
        this.currentSlotIndex = this.availableSlots.length - 5;
      }
      this.setViewableSlots();
    }
  }

  setViewableSlots() {
    this.availableSlotsFiltered = this.availableSlots.slice(this.currentSlotIndex, this.currentSlotIndex + 5);
  }

  onSlotSelect(slot: Slot) {
    this.scheduleForm.controls['slot_id'].setValue(slot.id);

    this.f.scheduled_date.setValue(slot.date);
    this.datepickerConfig = {
      ...this.datepickerConfig,
      minDate: dayjs(slot.date) < dayjs() ? dayjs(slot.date) : dayjs(),
      value: { startDate: dayjs(slot.date), endDate: dayjs(slot.date), }
    };

    slot.start_time = slot.start_time.split(':')[0] + ':' + slot.start_time.split(':')[1];
    this.timeValue = slot.start_time || '';
    this.f.scheduled_time.setValue(slot.start_time);
    this.timepickerConfig = { hour: parseInt(slot.start_time.split(':')[0]), minute: parseInt(slot.start_time.split(':')[1]) };

    const durationId = slot?.duration === 60 ? 3 : (slot.duration === 45 ? 2 : 1)
    this.f.duration.setValue(durationId);
  }

  checkForSlot(date: string) {
    if (this.availableSlots.length > 5) {
      let itemIndex = this.availableSlots.findIndex((slot: Slot) => slot.date >= date)
      this.currentSlotIndex = itemIndex !== -1 ? itemIndex : this.currentSlotIndex;
      this.setViewableSlots();
    }
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
  }
}

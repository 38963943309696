// These IDs will be having dropdowns 
// which change position based on viewport
export const dynamicDropdowns = [
  'location',
  'department',
  'stream',
  'job_code',
  'joining_location',
  'candidate__stream__department',
  'candidate__stream',
  'designation',
  'virtual_team',
  'parsing_status'
];

export const recommendationChoice:any = {
  T: [ 
    {id: "CAN_HIRE", name: "Can be hired"},
    {id: "ANT_RND", name: "Recommended for another round"},
    {id: "ANT_TEC", name: "Can be considered for another technology"},
    {id: "NO_QB_STD", name: "Not meeting QB standards"},
    {id: "ON_HOLD", name: "On hold after interview"},
  ],
  M:[
    {id: "CAN_HIRE", name: "Can be hired"},
    {id: "ANT_RND", name: "Recommended for another round"},
    {id: "ON_HOLD", name: "On hold after interview"},
    {id: "REJ", name: "Rejected after interview"}
  ],
  H:[]
}
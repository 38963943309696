export enum PAGE {
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
  JOBPOST = 'jobpost',
  JOBPOSTDETAILS = 'job post details',
  JOBPOSTAGENCIES = 'job post agencies',
  JOBPOSTCANDIDATES = 'job post candidates',
  JOBPOSTDISCOVERCANDIDATES = 'job post discover candidates',
  JOBPOSTDISTAGGEDCANDIDATES = 'job post tagged candidates',
  JOBPOSTCOMMENTS = 'job post comments',
  JOBPOSTHISTORY = 'job post hitory',
  CANDIDATES = 'candidates',
  CANDIDATEDETAILS = 'candidate details',
  CANDIDATEOVERVIEW = 'candidate overview',
  CANDIDATEHIRINGDETAILS = 'candidate hiring details',
  CANDIDATEPROFESSIONALDETAILS = 'candidate professional and education details',
  CANDIDATEOFFERDETAILS = 'candidate offer details',
  CANDIDATEREFERRALDETAILS = 'candidate referral details',
  CANDIDATEINTERVIEWS = 'candidate interviews',
  CANDIDATECOMMENTS = 'candidate comments',
  CANDIDATEACTIVITYLOG = 'candidate activity log',
  ACTIONITEMS = 'action items',
  BULKUPLOAD = 'bulk upload',
  INTERVIEWS = 'interviews',
  SELECTEDCANDIDATES = 'selected canidates',
  SETTINGS = 'settings',
  WORKBOOK = 'workbook',
  WORKBOOKOVERVIEW = 'workbook overview',
  WORKBOOKCANDIDATES = 'workbook candidates',
  WORKBOOKSCHEDULEINTERVIEW = 'workbook schedule interview',
  WORKBOOKREQUESTRATING = 'workbook request rating',
  WORKBOOKHISTORY = 'workbook hitory',
  WORKBOOKSENDCALLLETTER = 'workbook send call letter',
  WORKBOOKSALARYDOC = 'workbook salary doc',
  WORKBOOKCREATECANDIDATE = 'workbook create candidate',
  ACTIONITEMSREVIEWRATING = 'Action items review ratings',
  WORKBOOKCREATEANDSCHEDULE = 'workbook create and schedule candidate interview',
  WORKBOOKINTERVIEWERDRIVEN = 'workbook interviewer driven scheduling',
  NOSHOWANDCANCELLATIONS = 'no show and cancellations',
  AWAITINGRATING = 'awaiting rating',
  MYREFERRALS = 'my referrals',
  REFERRALBONUSEMPLOYEEOPEN = 'referral bonus employee open',
  REFERRALBONUSEMPLOYEEAPPROVED = 'referral bonus employee approved',
  REFERRALBONUSAGENCY = 'referral bonus agency',
  JOININGDATEYETCONFIRM = 'joining date yet to confirm',
  REPORTS = 'reports',
  REPORTSSTANDARDREPORT = 'reports standard report',
  REPORTSANALYTICS = 'reports analytcs',
  REPORTSMYREPORTS = 'reports my reports',
  REPORTSCOMPLETEDINTERVIEWS = 'reports completed interviews',
  NEWHIREINTIMATIONS = 'new hire intimation',
  HRPERFORMANCEREPORT = 'HR performance report',
  ORGANIZATIONADMIN = 'organization admin',
  WORKBOOKSENDOFFERLETTER = 'workbook send offer letter',
  OFFERAPPROVALS = 'action item offer approvals',
  INTERVIEWERS = 'interviewers',
  AVAILABILITY = 'availability',
  INTERVIEWERSDETAIL = 'interviewersDetail',
  INTERVIEWERSDETAILOVERVIEW = 'interviewers detail overview',
  INTERVIEWERSDETAILAVAILABILITY = 'interviewers detail availability',
  INTERVIEWERSDETAILACTIVITYLOG = 'interviewers detail activity log',
  INTERVIEWSCHEDULEINVITES = 'interview schedule invites',
  EDITCANDIDATE = 'edit candidate',
  CREATECANDIDATE = 'create candidate',
  RECRUITMENTDRIVE = 'recruitmentDrive',
  RECRUITMENTDRIVEOVERVIEW = 'recruitment drive overview',
  RECRUITMENTDRIVECOMMENTS = 'recruitment drive comments',
}

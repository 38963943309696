import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/guard/auth.guard';

const routes: Routes = [

  {
    path: 'login', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    canActivate: [AuthenticationGuard],
    path: '', loadChildren: () => import('./layout/layout.module').then(mod => mod.LayoutModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

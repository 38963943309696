export const feildName = {
  refer_cannidate: 'Refer a cannidate',
  add_job_requestion: 'Add a Job Requisition',
};

export const candidateStatusColors = [
  {
    status: 'N',
    bgcolor: '#3faaff',
    color: '#fff',
    name: 'New',
  },
  {
    status: 'S',
    bgcolor: '#1b8c03',
    color: '#fff',
    name: 'Selected',
  },
  {
    status: 'J',
    bgcolor: '#1b8c03',
    color: '#fff',
    name: 'Joined',
  },
  {
    status: 'RJ',
    bgcolor: '#ff0000',
    color: '#fff',
    name: 'Rejected',
  },
  {
    status: 'RJI',
    bgcolor: '#ff0000',
    color: '#fff',
    name: 'Rejected after interview',
  },
  {
    status: 'D',
    bgcolor: '#3faaff',
    color: '#fff',
    name: 'New',
  },
  {
    status: 'OR',
    bgcolor: '#3faaff',
    color: '#fff',
    name: 'New',
  },
  {
    status: 'E',
    bgcolor: '#ffeb00',
    color: '#000',
    name: 'Eligible for next round',
  },
  {
    status: 'NA',
    bgcolor: '#ffff00',
    color: '#000',
    name: 'Did not appear for interview',
  },
  {
    status: 'OI',
    bgcolor: '#555555',
    color: '#fff',
    name: 'Onhold after interview',
  },
  {
    status: 'IS',
    bgcolor: '#97E039',
    color: '#000',
    name: 'Interview Scheduled',
  },
  {
    status: 'CI',
    bgcolor: '#48AB48',
    color: '#fff',
    name: 'Call for interview',
  },
  {
    status: 'CH',
    bgcolor: '#555555',
    color: '#fff',
    name: 'Cv on Hold',
  },
  {
    status: 'RA',
    bgcolor: '#00a1d9',
    color: '#fff',
    name: 'Reapplied',
  },
  {
    status: 'SL',
    bgcolor: '#E5BD00',
    color: '#000',
    name: 'Shortlisted',
  },
  {
    status: 'I',
    bgcolor: '#97E039',
    color: '#000',
    name: 'Interview',
  },
  {
    status: 'W',
    bgcolor: '#ff0000',
    color: '#fff',
    name: 'Withdrawn',
  },
  {
    status: 'HR',
    bgcolor: '#E5BD00',
    color: '#000',
    name: 'Hiring Recommended',
  },
];

export const interviewStatusColors = [
  {
    status: 'NEW', //Upcoming
    bgcolor: '#3faaff',
    color: '#fff',
    name: 'Upcoming',
  },
  {
    status: 'CMP', //Completed
    bgcolor: '#1b8c03',
    name: 'Completed',
    color: '#fff',
  },
  {
    status: 'CAN', //Cancelled
    bgcolor: '#ff0000',
    name: 'Cancelled',
    color: '#fff',
  },
  {
    status: 'RTD', //Rated
    bgcolor: '#E5BD00',
    name: 'Rated',
    color: '#000',
  },
  {
    status: 'NS', //No Show
    bgcolor: '#ff0000',
    name: 'No Show',
    color: '#fff',
  },
  {
    status: 'AR', //Awaiting Rating
    bgcolor: '#ffeb00',
    name: 'Awaiting Rating',
    color: '#000',
  },
];

export const FresherCandidateColors = {
  status: 'fresher',
  name: 'Fresher',
  color: '#fff',
  bgcolor: '#808080'
}

export const defaultCandidateStatusColor = '#898f89';
export const defaultCandidateStatusFontColor = '#fff';

export const countryCode: any = [
  { value: '+91', country: 'India' },
  { value: '+1', country: 'US' },
  { value: '+44', country: 'UK' },
  { value: '+81', country: 'Japan' },
  { value: '+971', country: 'Dubai' },
  { value: '+65', country: 'Singapore' },
  { value: '+61', country: 'Australia' },
];

export const referralBonusStatusColor=[
  {
    status: 'A', 
    bgColor:  '#00a400',
    name: 'Bonus Approved',
    color: '#ffffff',
  },
]
export const offerStatusColors = [
  {
    status: "S",
    bgColor: "#ffbd02",
    name: "Offer Letter Sent",
    color: "#000",
  },
  {
    status: "D",
    bgColor: "#ff0000",
    name: "Offer Declined",
    color: "#fff",
  },
  {
    status: "OR",
    bgColor: "#999999",
    name: "Offer Revoked",
    color: "#fff",
  },
  {
    status: "N",
    bgColor: "#3faaff",
    name: "New",
    color: "#fff",
  },
  {
    status: "OA",
    bgColor: "#1b8c03",
    name: "Offer Accepted",
    color: "#fff",
  },
  {
    status: "SOA",
    bgColor: "#555555",
    name: "Awaiting Approval",
    color: "#fff",
  },
  {
    status: "OAP",
    bgColor: "#1b8c03",
    name: "Offer Approved",
    color: "#fff",
  },
  {
    status: "OD",
    bgColor: "#ff0000",
    name: "Approval Denied",
    color: "#fff",
  },
];

export const getApisWithoutLoader = [
  'workbook-list-dropdown/',
  'dashboard/job-list/',
  'dashboard/interview-list/',
  'dashboard/my-referrals/',
  'interviews/awaiting-rating/',
  'candidates/new-hire-intimation/'
];

export const postApisWithoutLoader = [
  'candidates/upload-resume/'
];

export const apisUsedInMultiplePages = [
  'dashboard/my-referrals/',
  'interviews/awaiting-rating/',
]

//pages in which loader is not needed for an api that is used in multiple pages
export const pagesWithoutLoader = [
  'QRMS V2 - Dashboard',
]
import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'ngbd-timepicker-meridian',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class NgbdTimepickerMeridian {
    @Input() time: any = {};
    @Input() readonly: boolean = false;
    @Output() selectedTime: EventEmitter<any> = new EventEmitter<any>();

    // time: any = { hour: 12, minute: 21 };
    meridian: boolean = true;

    toggleMeridian() {
        this.meridian = !this.meridian;
    }

    onTimeChange() {
        let hours = Number(this.time.hour);
        let minutes = Number(this.time.minute);
        let timeValue = "";

        timeValue += hours + ":" + minutes;

        this.selectedTime.emit(timeValue);
    }

}
import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { PAGE } from '../../shared/_models/page.enum';
import { Theme, ThemeConfig } from '../../shared/_models/theme';
import { User } from '../../shared/_models/user';

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {

  private _user: User = new User();
  private _tenantDetails: any;
  private _currentPage: string = "";
  private _filterData: any[] = [];
  private _createCandidateResumeData: any = {};
  private _jobPostWorkbookId: any;
  private _jobPostPage: any = {
    url: '',
    pageCount: {}
  };
  private _candidatesPage: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  };
  private _interviewPage: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  };
  private _selectedCandidatePage: any = {
    params: '',
    pageCount: {}
  };
  private _workbookPage: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  };
  private _candidateDetailsPage: any = {
    activeTab: 0,
    activeInterview: 0,
    from: ''
  };
  private _reviewRatingPage: any = {
    params: '',
    pageCount: {}
  };
  private _noShowAndCancellationsPage: any = {
    params: '',
    pageCount: {}
  };
  private _awaitingRatingPage: any = {
    params: '',
    pageCount: {}
  };
  private _myReferralsPage: any = {
    params: '',
    pageCount: {}
  };
  private _referralBonusEmployeeOpenPage: any = {
    params: '',
    pageCount: {}
  };
  private _referralBonusEmployeeApprovedPage: any = {
    params: '',
    pageCount: {}
  };
  private _referralBonusAgencyPage: any = {
    params: '',
    pageCount: {}
  };
  private _joiningDateYetToConfirm: any = {
    params: '',
    pageCount: {}
  }
  private _newHireIntimation: any = {
    params: '',
    weekCount: 1
  }
  private _completedInterviewReportPage: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  }
  private _hrPerformanceReport: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  }
  private _offerApprovals: any = {
    params: '',
    pageCount: {}
  }
  private _interviewersPage: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  };
  private _scheduleInvitesList: any = {
    params: '',
    currentPage: 1,
    pageCount: {}
  };
  private _themeConfig: ThemeConfig = {
    labels: '',
    input: 'normal',
  };
  private _secondaryNavigationMenu = new Subject<string>();
  private _routerVariables = new Subject<any>();
  private _hideMenu = new Subject<any>();
  private _isNavCollapsed = new BehaviorSubject<boolean>(false);
  private _showScheduleInvites = new BehaviorSubject<boolean>(false);
  private _quickSearchQuery = new BehaviorSubject<string>('');
  private _jobPostData = new BehaviorSubject<any>({});
  quickSearchQuery$ = this._quickSearchQuery.asObservable();
  
  constructor() { }

  getUser(): User {
    return this._user;
  }

  setUser(user: User) {
    this._user = user;
  }

  setSearchQuery(searchQuery: string) {
    this._quickSearchQuery.next(searchQuery);
  }

  getSearchQuery() {
    return this._quickSearchQuery;
  }

  getCurrentPage() {
    return this._currentPage;
  }

  setCurrentPage(page: string) {
    this._currentPage = page;
  }

  setTenandDetails(tenant: any) {
    this._tenantDetails = tenant;
  }

  getTenandDetails() {
    return this._tenantDetails;
  }

  getFilters(page: string) {
    let _filters = '{}';
    this._filterData.forEach(element => {
      if (page == element.page) {
        _filters = element.filters;
      }
    });
    return _filters;
  }

  setSecondaryNavMenu(menuName: string) {
    //menuName from secondaryMenus - src/app/shared/_constants/constants.ts
    this._secondaryNavigationMenu.next(menuName);
  }

  getSecondarynavMenu(): Observable<string> {
    return this._secondaryNavigationMenu.asObservable();
  }

  setRoutervariables(varObj: any) {
    //in the form {StringToBeReplacedFromRouterLink: valueToBeReplacedWith}
    this._routerVariables.next(varObj);
  }

  getRoutervariables(): Observable<any> {
    return this._routerVariables.asObservable();
  }

  setJobPostWorkbookId(id: any) {
    this._jobPostWorkbookId = id;
  }

  getJobPostWorkbookId() {
    return this._jobPostWorkbookId;
  }

  setJobPostData(jobData: any) {
    this._jobPostData.next(jobData);
  }

  getJobPostData() {
    return this._jobPostData.asObservable();
  }

  setFilters(page: string, filterValue: string) {
    this._filterData.some(item => item.page == page) ? this.updateFilters(page, filterValue) : this.addFilter(page, filterValue);
  }

  updateFilters(page: string, filterValue: string) {
    this._filterData.forEach(element => {
      if (page == element.page) {
        element.filters = filterValue;
      }
    });
  }

  addFilter(page: string, filterValue: string) {
    let d = {
      page: page,
      filters: filterValue
    }
    this._filterData.push(d);
  }

  removeFilters(page: string) {
    this._filterData.forEach(element => {
      if (page == element.page) {
        element.filters = "{}";
      }
    });
    switch (page) {
      case PAGE.JOBPOST:
        this._jobPostPage = {
          url: '',
          pageCount: {}
        };
        break;
      case PAGE.CANDIDATES:
        this._candidatesPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWS:
        this._interviewPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.SELECTEDCANDIDATES:
        this._selectedCandidatePage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.WORKBOOK:
        this._workbookPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.CANDIDATEDETAILS:
        this._candidateDetailsPage = {
          activeTab: 0,
          activeInterview: 0,
          from: ''
        }
        break;
      case PAGE.ACTIONITEMSREVIEWRATING:
        this._reviewRatingPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.NOSHOWANDCANCELLATIONS:
        this._noShowAndCancellationsPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.AWAITINGRATING:
        this._awaitingRatingPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.MYREFERRALS:
        this._myReferralsPage = {
          params: '',
          pageCount: {}
        }
        break;
      case PAGE.NEWHIREINTIMATIONS:
        this._newHireIntimation = {
          params: '',
          weekCount: 1
        }
        break;
      case PAGE.REFERRALBONUSEMPLOYEEOPEN:
        this._referralBonusEmployeeOpenPage = {
          params: '',
          pageCount: {}
        }
        break;
      case PAGE.REFERRALBONUSEMPLOYEEAPPROVED:
        this._referralBonusEmployeeApprovedPage = {
          params: '',
          pageCount: {}
        }
        break;
      case PAGE.REFERRALBONUSAGENCY:
        this._referralBonusAgencyPage = {
          params: '',
          pageCount: {}
        }
        break;
      case PAGE.JOININGDATEYETCONFIRM:
        this._joiningDateYetToConfirm = {
          params: '',
          pageCount: {}
        }
        break;
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        this._completedInterviewReportPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        }
        break;
      case PAGE.HRPERFORMANCEREPORT:
        this._hrPerformanceReport = {
          params: '',
          currentPage: 1,
          pageCount: {}
        }
        break;
      case PAGE.OFFERAPPROVALS:
        this._offerApprovals = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWERS:
        this._interviewersPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWSCHEDULEINVITES:
        this._scheduleInvitesList = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      default:
        break;
    }
  }

  removeAllFilters() {
    this._filterData = [];
    this._jobPostPage = {
      url: '',
      pageCount: {}
    };
    this._candidatesPage = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._interviewPage = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._selectedCandidatePage = {
      params: '',
      pageCount: {}
    };
    this._workbookPage = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._candidateDetailsPage = {
      activeTab: 0,
      activeInterview: 0,
      from: ''
    };
    this._reviewRatingPage = {
      params: '',
      pageCount: {}
    };
    this._noShowAndCancellationsPage = {
      params: '',
      pageCount: {}
    };
    this._awaitingRatingPage = {
      params: '',
      pageCount: {}
    };
    this._myReferralsPage = {
      params: '',
      pageCount: {}
    };
    this._referralBonusEmployeeOpenPage = {
      params: '',
      pageCount: {}
    }
    this._referralBonusEmployeeApprovedPage = {
      params: '',
      pageCount: {}
    };
    this._referralBonusAgencyPage = {
      params: '',
      pageCount: {}
    };
    this._joiningDateYetToConfirm = {
      params: '',
      pageCount: {}
    };
    this._completedInterviewReportPage = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._newHireIntimation = {
      params: '',
      weekCount: 1
    };
    this._hrPerformanceReport = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._offerApprovals = {
      params: '',
      pageCount: {}
    };
    this._interviewersPage = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
    this._scheduleInvitesList = {
      params: '',
      currentPage: 1,
      pageCount: {}
    };
  }

  getCurrentPageDetails(page: string) {
    switch (page) {
      case PAGE.JOBPOST:
        return this._jobPostPage;
      case PAGE.CANDIDATES:
        return this._candidatesPage;
      case PAGE.INTERVIEWS:
        return this._interviewPage;
      case PAGE.SELECTEDCANDIDATES:
        return this._selectedCandidatePage;
      case PAGE.WORKBOOK:
        return this._workbookPage;
      case PAGE.CANDIDATEDETAILS:
        return this._candidateDetailsPage;
      case PAGE.ACTIONITEMSREVIEWRATING:
        return this._reviewRatingPage;
      case PAGE.NOSHOWANDCANCELLATIONS:
        return this._noShowAndCancellationsPage;
      case PAGE.AWAITINGRATING:
        return this._awaitingRatingPage
      case PAGE.MYREFERRALS:
        return this._myReferralsPage;
      case PAGE.REFERRALBONUSEMPLOYEEOPEN:
        return this._referralBonusEmployeeOpenPage;
      case PAGE.REFERRALBONUSEMPLOYEEAPPROVED:
        return this._referralBonusEmployeeApprovedPage;
      case PAGE.REFERRALBONUSAGENCY:
        return this._referralBonusAgencyPage;
      case PAGE.JOININGDATEYETCONFIRM:
        return this._joiningDateYetToConfirm;
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        return this._completedInterviewReportPage;  
      case PAGE.NEWHIREINTIMATIONS:
        return this._newHireIntimation;
      case PAGE.HRPERFORMANCEREPORT:
        return this._hrPerformanceReport;
      case PAGE.OFFERAPPROVALS:
        return this._offerApprovals;
      case PAGE.INTERVIEWERS:
        return this._interviewersPage;
      case PAGE.INTERVIEWSCHEDULEINVITES:
        return this._scheduleInvitesList;
      default:
        break;
    }
  }

  setCurrentPageDetails(page: string, pageObject: object) {
    switch (page) {
      case PAGE.JOBPOST:
        this._jobPostPage = pageObject;
        break;
      case PAGE.CANDIDATES:
        this._candidatesPage = pageObject;
        break;
      case PAGE.INTERVIEWS:
        this._interviewPage = pageObject;
        break;
      case PAGE.SELECTEDCANDIDATES:
        this._selectedCandidatePage = pageObject;
        break;
      case PAGE.WORKBOOK:
        this._workbookPage = pageObject;
        break;
      case PAGE.CANDIDATEDETAILS:
        this._candidateDetailsPage = pageObject;
        break;
      case PAGE.ACTIONITEMSREVIEWRATING:
        this._reviewRatingPage = pageObject;
        break;
      case PAGE.NOSHOWANDCANCELLATIONS:
        this._noShowAndCancellationsPage = pageObject;
        break;
      case PAGE.AWAITINGRATING:
        this._awaitingRatingPage = pageObject;
        break;
      case PAGE.MYREFERRALS:
        this._myReferralsPage = pageObject;
        break;
      case PAGE.REFERRALBONUSEMPLOYEEOPEN:
        this._referralBonusEmployeeOpenPage = pageObject;
        break;
      case PAGE.REFERRALBONUSEMPLOYEEAPPROVED:
        this._referralBonusEmployeeApprovedPage = pageObject;
        break;
      case PAGE.REFERRALBONUSAGENCY:
        this._referralBonusAgencyPage = pageObject;
        break;
      case PAGE.JOININGDATEYETCONFIRM:
        this._joiningDateYetToConfirm = pageObject;
        break;
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        this._completedInterviewReportPage = pageObject;
        break;
      case PAGE.NEWHIREINTIMATIONS:
        this._newHireIntimation = pageObject;
        break;
      case PAGE.HRPERFORMANCEREPORT:
        this._hrPerformanceReport = pageObject;
        break;
      case PAGE.OFFERAPPROVALS:
        this._offerApprovals = pageObject;
        break;
      case PAGE.INTERVIEWERS:
        this._interviewersPage = pageObject;
        break;
      case PAGE.INTERVIEWSCHEDULEINVITES:
        this._scheduleInvitesList = pageObject;
        break;
      default:
        break;
    }
  }

  removePageDetails(page: string) {
    switch (page) {
      case PAGE.JOBPOST:
        this._jobPostPage = {
          url: '',
          pageCount: {}
        };
        break;
      case PAGE.CANDIDATES:
        this._candidatesPage = {
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWS:
        this._interviewPage = {
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.SELECTEDCANDIDATES:
        this._selectedCandidatePage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.WORKBOOK:
        this._workbookPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.CANDIDATEDETAILS:
        this._candidateDetailsPage = {
          activeTab: 0,
          activeInterview: 0,
          from: ''
        };
        break;
      case PAGE.ACTIONITEMS:
      case PAGE.ACTIONITEMSREVIEWRATING:
        this._reviewRatingPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.NOSHOWANDCANCELLATIONS:
        this._noShowAndCancellationsPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.AWAITINGRATING:
        this._awaitingRatingPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.MYREFERRALS:
        this._myReferralsPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.REFERRALBONUSEMPLOYEEOPEN:
        this._referralBonusEmployeeOpenPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.REFERRALBONUSEMPLOYEEAPPROVED:
        this._referralBonusEmployeeApprovedPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.REFERRALBONUSAGENCY:
        this._referralBonusAgencyPage = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.JOININGDATEYETCONFIRM:
        this._joiningDateYetToConfirm = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        this._completedInterviewReportPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.NEWHIREINTIMATIONS:
        this._myReferralsPage = {
          params: '',
          weekCount: 1
        };
        break;
      case PAGE.HRPERFORMANCEREPORT:
        this._hrPerformanceReport = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.OFFERAPPROVALS:
        this._offerApprovals = {
          params: '',
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWERS:
        this._interviewersPage = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      case PAGE.INTERVIEWSCHEDULEINVITES:
        this._scheduleInvitesList = {
          params: '',
          currentPage: 1,
          pageCount: {}
        };
        break;
      default:
        break;
    }

  }

  hideMenu(setSecondaryMenu: boolean = false) {
    this._hideMenu.next(setSecondaryMenu);
  }

  getHideMenu(): Observable<string> {
    return this._hideMenu.asObservable();
  }
 
  setIsNavCollapsed(_isNavCollapsed: boolean = false) {
    this._isNavCollapsed.next(_isNavCollapsed);
  }

  getIsNavCollapsed(): Observable<boolean> {
    return this._isNavCollapsed.asObservable();
  }

  setShowSchedulesInvites(showScheduleInvites: boolean = false) {
    this._showScheduleInvites.next(showScheduleInvites);
  }

  getShowSchedulesInvites(): Observable<boolean> {
    return this._showScheduleInvites.asObservable();
  }

  setThemeConfig(theme: any) {
    switch (theme) {
      case Theme.CLASSIC_GREEN:
        this._themeConfig = {
          labels: 'rounded-style-1',
          input: 'floating-label',
        };
        break;
      default:
        break;
    }
  }

  getThemeConfig() {
    return this._themeConfig;
  }
  
  setCreateCandidateResumeData(data : any) {
    this._createCandidateResumeData = data;
  }

  getCreateCandidateResumeData() {
    return this._createCandidateResumeData;
  }
  
  removeCreateCandidateResumeData() {
    this._createCandidateResumeData = {};
  }
}
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import * as dayjs from 'dayjs';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Role } from '../../_models/role';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-daterange-picker-component',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() item: any = {};
  @Input() roles: Role[] = [Role.CL, Role.Director, Role.HR, Role.HRM, Role.PM, Role.Staff, Role.VT];
  @Output() dateFilterData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective!: DaterangepickerDirective;

  inlineDate: any;
  inlineDateTime: any;
  picker!: DaterangepickerComponent;
  dateRangesWithDefaultEndDateAsToday: string[] = ['req_date', 'rcd_on', 'lst_updated','report_date'];

  ngOnInit() {
    this.picker = this.pickerDirective.picker;
    if (this.item.id == "sch_date") {
      const { role } = this.authService.getUser();
      if (role == Role.Staff || role == Role.PM) {
        this.item.min_date = dayjs().subtract(4, 'months')
      }
    }
    if (this.item.id == "report_date") {
        this.item.min_date = dayjs().subtract(1, 'year').add(1,'day');
        this.item.max_date = dayjs() 
      }
  }

  constructor(
    private authService: AuthService
  ) { }

  change(e: any, id1: string, id2: string, id: string, displayName: string) {
    let dateData = {
      "data": e,
      id1,
      id2,
      id,
      displayName
    }

    //set end date to today if it's invalid
    if (dateData?.data[id2]?.$d == 'Invalid Date' && this.dateRangesWithDefaultEndDateAsToday.includes(id)) {
      dateData.data[id2].$d = dayjs();
      this.picker.setEndDate(dayjs());
      this.item.selectedDate = {...e, [id2]: dayjs()};
    }

    this.dateFilterData.emit(dateData);
  }
  choosedDate(e: any) {
    this.inlineDate = e;
  }

  choosedDateTime(e: any) {
    this.inlineDateTime = e;
  }
  open(e: any) {
    this.pickerDirective.open(e);
  }
  clear(e: any) {
    this.picker.clear();
  }

}

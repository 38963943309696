import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {

  mode = 'indeterminate';
  value = 50;
  isLoading: Observable<boolean> = this.loaderService.isLoading.pipe(delay(0)); //given delay to avoid infinite loading

  constructor(private loaderService: LoaderService) { }

}

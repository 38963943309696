import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CryptoService {

  //The set method is use for encrypt the value.
  set(keys: string, value: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), keys).toString();
  }

  //The get method is use for decrypt the value.
  get(keys: string, value: string) {
    var decrypted = CryptoJS.AES.decrypt(value, keys);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {

  @Input() checked!: boolean;
  @Input() disabled!: boolean;
  @Input() checkboxValue!: string;
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  @Input() description!: string;

  constructor() { }

  ngOnInit(): void {
  }

  toggleCheckbox() {
    this.checked = !this.checked;
    this.toggle.emit({selectedValue: this.checkboxValue, checked: this.checked});
  }

}

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Role } from '../_models/role';

@Directive({
  selector: '[userRole]'
})
export class UserRoleDirective implements OnInit {
  userRoles: Role[] = [];
  @Input()
  set userRole(roles: Role[]) {
    this.userRoles = roles;
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    let hasAccess = false;
    if (this.authService.isAuthorized()) {
      if (this.userRoles)
        hasAccess = this.userRoles.some(role => this.authService.hasRole(role));
      else hasAccess = true;
    }
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.VT) && this.authService.isVT()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.CL) && this.authService.isCL()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.IS) && this.authService.hasInterview()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.OA) && this.authService.isOA()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.IN) && !([Role.HR, Role.HRM, Role.Director].some(role => this.authService.hasRole(role))) && this.authService.getUser().is_interviewer) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!hasAccess && this.userRoles.some(role => role == Role.TEMP_IN) && !([Role.HR, Role.HRM, Role.Director].some(role => this.authService.hasRole(role))) && this.authService.getUser()?.enable_availability_menu) {
      this.viewContainer.createEmbeddedView(this.templateRef); // user role check for privileged intreviewers (Temporary feature)
    } 
    else {
      this.viewContainer.clear();
    }
  }



}

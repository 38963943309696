import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { regex } from 'src/app/shared/_services/regex';
import { UtilService } from '../../_services/util.service';

@Component({
  selector: 'app-comment-popup',
  templateUrl: './comment-popup.component.html',
  styleUrls: ['./comment-popup.component.scss']
})
export class CommentPopupComponent implements OnInit {
  commentForm!: FormGroup;
  @Input() popUpContents!: any;
  @Output() closePopup: EventEmitter<null> = new EventEmitter();
  @Output() saveComment: EventEmitter<null> = new EventEmitter();
  @Input() commentLabel: string = 'Comments';
  @Input() isMandatory: boolean = false;
  @Input() commentMaxLength: number = 500; 
  hrUsers:any=[]
  subscriptions:any=[];
  userList: any = [];
  toList:any=[];
  ccList:any=[];
  toUserList: any = [];
  ccUserList: any = [];
  toggleInfo: boolean = false;
  toggleSourceInfo: boolean = false;
  notifyRequired: boolean = false;
  @Input() btnText:string='update status';
  constructor(private formBuilder: FormBuilder, private appService: AppService, private utilService: UtilService) { }

  ngOnInit(): void {
    (this.popUpContents?.isForwardResume || this.popUpContents?.isApproveJobPost) && this.getDropdownData();
    this.initializeForm();
  }
  get f() {
    return this.commentForm.controls;
  }

  initializeForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.maxLength(this.commentMaxLength)]]
    });
    if (this.popUpContents?.hasDateField) {
      let experience = this.popUpContents?.additionalField;
      this.commentForm.addControl('joining_date', new FormControl(this.popUpContents?.dateValue, Validators.required));
      this.commentForm.addControl('experience_years', new FormControl(experience?.experience_years, [Validators.pattern(regex.oneToHundredNumber),
      Validators.pattern(regex.blockSpecialCharacters)]));
      this.commentForm.addControl('experience_months', new FormControl(experience?.experience_months, [Validators.pattern(regex.expMonths),
      Validators.pattern(regex.blockSpecialCharacters),]));
    } 
    this.isMandatory && this.commentForm.controls['comment']?.setValidators([Validators.required, Validators.maxLength(this.commentMaxLength)]);
    this.popUpContents?.isApproveJobPost && this.commentForm.addControl('owner', new FormControl(null));

    if (this.popUpContents?.isForwardResume) {
      this.commentForm.addControl('to', new FormControl(null));
      this.commentForm.addControl('cc', new FormControl(null));
    }
    if (["Rejected", "CV on Hold"].includes(this.popUpContents?.status)) {
      this.commentForm.addControl('notify_candidate', new FormControl(false));
      this.notifyRequired = true;
    }
    if (this.popUpContents?.isUpdateCandidateStatus){
      this.commentForm.addControl('update_candidate_source', new FormControl(false));
    }
  }
  onDateSelected(date: any) {
    if (date && date.joining_date != 'Invalid Date') {
      this.commentForm.controls['joining_date'].setValue(date.joining_date);
    }
    else {
      this.commentForm.controls['joining_date'].setValue(null);
    }
  }

  onCancel() {
    this.closePopup.emit();
  }

  //1168 starts

  onRecepientSelection(e: any, ref: NgSelectComponent, isTo: boolean) {
    if (isTo) {
        if (e?.email) {
          this.toList.push(e?.email);
          this.removeEmailFromList(e);
        }
    }
    else {
        if (e?.email) {
          this.ccList.push(e?.email);
          this.removeEmailFromList(e);
        }
    }
    ref.handleClearClick();
  }

  removeEmailFromList(e:any)
  {
    this.toUserList = this.toUserList.filter((item: any) => e?.email !== item?.email);
    this.ccUserList = this.ccUserList.filter((item: any) => e?.email !== item?.email);
  }

  canAddEmail(e: any) {
    let canAddEmail = false;
    if (e?.email && !this.toList.some((item: string) => item === e?.email) && !this.ccList.some((item: string) => item === e?.email)) {
      canAddEmail = true;
    }
    return canAddEmail;
  }

  onRemove(item: string, isTo: boolean) {
    const email = this.userList.find((e: any) => e.email === item)
    if (isTo) {
      this.toList = this.toList.filter((e: string) => e !== item)     
    }
    else {
      this.ccList = this.ccList.filter((e: string) => e !== item)
    }
    this.addEmailToList(email);
    this.sortEmailList();
  }
 
  addEmailToList(email:any)
  {
    this.ccUserList = [...this.ccUserList, email];
    this.toUserList = [...this.toUserList, email];
  }

  sortEmailList(){
    this.toUserList.sort((email_a: any, email_b: any) => email_a?.email.toLowerCase().localeCompare(email_b?.email.toLowerCase()));
    this.ccUserList.sort((email_a: any, email_b: any) => email_a?.email.toLowerCase().localeCompare(email_b?.email.toLowerCase()));
  }

  customSearchFn(term: string, item: any) {
    if (this.popUpContents?.isApproveJobPost) return this.utilService.customEmailAndUserNameSearch(term, item, 'hrName', 'email');
    return this.utilService.customEmailAndUserNameSearch(term, item)
  }

  recepientModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.userList);
  }


  getDropdownData() {
    this.subscriptions.push(
      this.appService.generalDropdowns.subscribe((data: any) => {
        const { HR_users = [], all_email_list=[] } = data;
        this.hrUsers = HR_users.map((hr: any) => { hr.hrName = `${hr.first_name} ${hr.last_name}`; return hr });
        if (this.popUpContents?.isForwardResume ){
          this.userList = all_email_list.map((user: any) => { user.username = (user?.first_name || user?.last_name) ? `${user.first_name} ${user.last_name}` : user.email; return user });
          this.toUserList = [...this.userList];
          this.ccUserList = [...this.userList];
          this.sortEmailList();
        }
      }, (error: any) => {
        console.log(error)
      }));

  }
  onModelChange(event: any, ref: NgSelectComponent, data: any) {
    if (event) {
      let filterValue = data.find((str: any) => str.id === event);
      ref.searchTerm = filterValue ? filterValue.name : '';
    } else {
      ref.searchTerm = '';
    }
  }

  ownerModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.hrUsers);
  }

  
  ngOnDestroy(): void {
    this.subscriptions.forEach((item: any) => {
      item.unsubscribe();
    });
  }
//1168 ends

  formatComment() {
    let comment = this.commentForm.controls['comment']?.value
    comment && this.commentForm.controls['comment']?.patchValue(comment?.trim())
  }

  onNotifyCandidate() {
    let notifyCandidate = this.commentForm.controls['notify_candidate'].value;
    this.commentForm.controls['notify_candidate'].setValue(!notifyCandidate);
  }

  onUpdateCandidateSource() {
    let updateSource = this.commentForm.controls['update_candidate_source'].value;
    this.commentForm.controls['update_candidate_source'].setValue(!updateSource);
  }

  onSave() {
    this.commentForm.markAllAsTouched();
    if (this.commentForm.invalid || (this.popUpContents?.isForwardResume && this.f.to.touched && !this.toList?.length)) {
      return;
    }
    let commentData: any = {};
    commentData['comment'] = this.commentForm.controls['comment'].value?.trim() || '';
    if (this.popUpContents?.id) { commentData['id'] = this.popUpContents?.id }
    if (this.popUpContents?.hasDateField) {
      commentData['joining_date'] = this.commentForm.controls['joining_date']?.value;
      commentData['experience_years'] = this.commentForm.controls['experience_years']?.value
      commentData['experience_months'] = this.commentForm.controls['experience_months']?.value
    }
    if (this.popUpContents?.isApproveJobPost) { commentData['owner'] = this.commentForm.controls['owner']?.value }
    if (this.popUpContents?.isForwardResume) { commentData['to'] = this.toList; commentData['cc'] = this.ccList }
    if (this.notifyRequired) { commentData['notify_candidate'] = this.commentForm.controls['notify_candidate']?.value }
    if (this.popUpContents?.isUpdateCandidateStatus && this.commentForm.controls['update_candidate_source']?.value) { commentData['update_candidate_source'] = this.commentForm.controls['update_candidate_source']?.value }
    this.saveComment.emit(commentData)
  }
}

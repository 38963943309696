import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { NavigationError, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStoreService } from './shared/_services/global-store.service';
import { StorageService } from './shared/_services/storage.service';
import { UtilService } from './shared/_services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private analyticsService: AngularFireAnalytics,
    private utilService: UtilService,
    private storageService: StorageService,
    private globalStore: GlobalStoreService,
    ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.utilService.setActiveUrl(event.url);
        if (event.navigationTrigger === 'popstate') {
          this.utilService.setActiveUrl(event.url);
          this.modalService.dismissAll();
        }
        this.utilService.scrollToTop();
      }
      if (event instanceof NavigationError) {
        this.router.navigate(['/dashboard']);
      }
    });
    this.analyticsService.logEvent('Initial');

    let user = JSON.parse(this.storageService.get('user') || '{}');
    if (user?.loggedIn !== false) {
      this.globalStore.setUser(user);
    }
  }
}

<ng-template #modal>
  <div class="confirmation-popup-div">
    <div class="modal-body">
      <div class="heading">{{modalConfig.modalTitle}}</div>
      <ng-content select="[mainDesc]"></ng-content>
      <div class="body text-center mb-4">{{modalConfig.description}}</div>
      <ng-content></ng-content>
      <div class="actions text-center mb-2">
        <button 
          class="btn cancel-btn" 
          (click)="dismiss()" 
          *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
          [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()"
        >
          {{modalConfig.dismissButtonLabel || 'NO'}}
        </button>
        <button class="btn save-btn ms-4" (click)="accept()">{{modalConfig.acceptButtonLabel || 'YES'}}</button>
      </div>
    </div>
  </div>
</ng-template>
<div class="label-tag-container dropdown" [ngClass]="{'outlined-label-tag' : outline}" 
[ngStyle]="getLabelContainerStyle()">
  <span *ngIf="outline && showDot" class="dot" [ngStyle]="{
  'background-color': bgColor}">
  </span>
  <span *ngIf="label" class="label-tag" [ngStyle]="getFontColour()"> {{label}} </span>
  <span *ngIf="editable" class="edit-section">
    <img src="./assets/images/edit-transparent.svg" alt="Edit" id="editDropdownButton" data-bs-toggle="dropdown"
      aria-expanded="false" />
    <ul class="dropdown-menu" aria-labelledby="editDropdownButton">
      <li *ngFor="let option of dropdownOptions" (click)="open(option)">
        <a class="dropdown-item">{{option.name}}</a>
      </li>
    </ul>
  </span>
</div>
<ng-template #updateStatusPopup let-modal>
  <app-update-status-popup [formContents]="statusUpdateForm" (closePopup)="cancel($event)"
    (updateData)="onUpdateSelectedStatus($event)"></app-update-status-popup>
</ng-template>
<ng-template #commentPopup let-modal>
  <app-comment-popup [commentMaxLength]="commentMaxLength" [popUpContents]="commentData" (closePopup)="cancel($event)"
    [commentLabel]="commentLabel" [isMandatory]="isMandatory" (saveComment)="save($event)"></app-comment-popup>
</ng-template>
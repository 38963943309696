<form class="row search-form" [formGroup]="filtersForm" (keydown)="searchBarkeyDown($event)">
  <div class="search-bar-wrapper">
    <div class="search-input">
      <img class="search-icon" src="./assets/images/search.svg" alt="search" />
      <div class="search-input-field">
        <input autofocus type="text" class="form-control" id="searchKeyword" [placeholder]="appliedTheme === 'classic-green' ? ' ' : searchInputPlaceholder"
        formControlName="search" (focusout)="handleSubmit($event, true)">
        <label *ngIf="appliedTheme === 'classic-green'" for="searchKeyword" class="floating-label">{{searchInputPlaceholder}}</label>
      </div>
      <div class="searched-tags" *ngIf="searchBarTags?.list?.length">
        <span *ngFor="let tags of searchBarTags.list; let i=index">
          <span class="skill">{{searchedLabels[tags[0]] || ''}}:</span>
          <span class="skill-item" *ngFor="let skill of tags[1]; let lastItem = last;">
            {{skill}}
            <span *ngIf="!lastItem">, </span>
          </span>
        </span>
        <div *ngIf="searchBarTags?.dropdown?.length" class="dropdown searched-tags-dropdown">
          <button class="btn dropdown-toggle searched-tags-button" type="button" id="searchBarDropdown"
            data-bs-toggle="dropdown" aria-expanded="false">
            +{{searchBarTags.more}} more
          </button>
          <ul class="dropdown-menu" aria-labelledby="searchBarDropdown">
            <li *ngFor="let item of searchBarTags.dropdown; let i = index">
              <div class="dropdown-item">
                <span class="skill">{{searchedLabels[item[0]] || ''}}:</span>
                <span class="skill-item" *ngFor="let skill of item[1]; let lastItem = last;">
                  {{skill}}
                  <span *ngIf="!lastItem">, </span>
                </span>
              </div>
            </li>
          </ul>
        </div>

        <span class="tags-applied"> tags applied</span>
      </div>
      <ng-container *ngIf="getAppliedFiltersPermissionCheck">
        <div *ngIf="showFiltersSelectedWithName.length > 1" class="show-filters">
          <div class="filters-applied">
            <div class="filters">Filters</div>
            <div *ngFor="let item of showFiltersSelectedWithName, let i =index">
              <div *ngIf="item.id || item.id == 0" class="filters-name">{{item.displayText ? item.displayText :(item.name ? item.name :
                item.id)}}
                <span (click)="addOrRemoveFilteItem(item.filterCategory, item.id, item.name, item.displayTag, item.type,item.id_2);
                  applyFilter();">
                  <img src="./assets/images/close-line-icon.svg" alt="remove" />
                </span>
              </div>
            </div>
            <span class="clear-filter red" (click)="clearFilter()">Clear All</span>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!isFilterHidden">
      <div *userRole="roles" clickOutside (clickOutside)="handleOutsideClick()" class="flter-wrap">
        <div class="filter-expand" (click)="toggleFilter()">
          <img class="theme-coloured-image" src="./assets/images/filter.svg" alt="filter" />
        </div>

        <div [hidden]="!isFilterOpen" class="filter-list">
          <div class="filter-heading">Filter by </div>
          <ul *ngIf="filterItems.length > 0">
            <ng-container *ngFor="let item of filterItems; let i =index">
              <li *userRole="item?.role">
                <!-- hiding multi select filters that doesn't have any value -->
                <div *ngIf="item?.type !=='single-select'&& (item?.type != 'multi-select' || item?.values?.length > 0)" class="filter-main"
                  (mouseenter)="handleDropdownPosition($event, item.id)">
                  {{ item && item.displayName}}
                  <span>{{ item && (filtersSelected[item.id]?.length > 0 ? ( '('+ filtersSelected[item.id]?.length + ')'
                    )
                    :
                    '')}}
                  </span>
                  <img src="./assets/images/right-angle.svg" alt="right-arrow" />
                </div>
                <ul *ngIf=" item && (item?.type ==='multi-select' || item?.type ==='single-select')"
                  [ngClass]="item?.type ==='multi-select'? 'sub-list':''" class="select-container">
                  <li *ngFor="let subItem of item?.values; let i=index" class="single-select cursor-pointer"
                    (click)="addOrRemoveFilteItem(item.id, subItem.id, subItem[item.dropdownKey], item.displayTag)"
                    [ngClass]="{'selected': filtersSelected[item.id]?.includes(subItem.id) ? true : false}">
                    <span>
                      <img
                        [src]="filtersSelected[item.id]?.includes(subItem.id) ? './assets/images/checked.svg' : './assets/images/checked-empty.svg'"
                        alt="checked" />
                    </span>
                    {{subItem[item.dropdownKey]}}
                  </li>
                </ul>

                <div *ngIf=" item && item?.type ==='dropdown-select'" class="sub-list dropdown">
                  <button class="dropdown-toggle custom-dropdown-btn" type="button" [id]="item.id"
                    (click)="item.expanded = !item?.expanded">
                    {{setDropDownSelectPlaceholder(item.dropdownName)}}
                  </button>
                  <ul class="custom-dropdown-menu" [ngClass]="item?.expanded ? 'show' : ''"
                    [attr.aria-labelledby]="item.id">
                    <li *ngFor="let subItem of item?.values; let i=index">
                      <a class="dropdown-item" [ngClass]="filtersSelected[item.id] == subItem.id ? 'active' : ''"
                        (click)="addOrRemoveFilteItem(item.id, subItem.id, subItem.name, item.displayTag, 'dropdown-select'); item.expanded = false">
                        {{subItem.name}}
                      </a>
                    </li>
                  </ul>
                </div>

                <ul *ngIf=" item && item.type === 'filter-multi-select'" class="sub-list overflow-visible type-search-multi-select multi-select-filter-dropdown">
                  <ng-select #searchSelect [items]="item.values" [bindLabel]="item.dropdownKey" bindValue="id" [clearOnBackspace]="false" (search)="onUpdateMultiSearchFilter(searchSelect)"
                    class="desg-multiselect" [isOpen]="true" placeholder="{{item.placeholder}}" [multiple]="multiple" (change)="onUpdateMultiSearchFilter(searchSelect)"
                    groupBy="department__name" (focus)="onFocus(searchSelect,undefined,false)"  [closeOnSelect]="false" [markFirst]="false">
                    <ng-template ng-option-tmp let-subItem="item" let-subItem$="item$" let-subItemIndex="index">
                      <div class="option-padding" (click)="addOrRemoveFilteItem(item.id, subItem.id, subItem[item.filterDisplayKey], item.displayTag)">
                        <span>
                          <img
                            [src]="filtersSelected[item.id]?.includes(subItem.id) ? './assets/images/checked.svg' : './assets/images/checked-empty.svg'"
                            alt="checked" /> {{subItem[item.dropdownKey]}}
                        </span>
                      </div>
                    </ng-template>
                  </ng-select>
                </ul>

                <div *ngIf="item && item.type === 'search-multi-select'" class="sub-list overflow-visible">
                  <ng-select #searchSelect [items]="item.values" [bindLabel]="item.dropdownKey" bindValue="id"
                    [isOpen]="true" placeholder="{{item.placeholder}}" [multiple]="multiple"
                    (focus)="onFocus(searchSelect, item.id)" (change)="onAdd(searchSelect)" [virtualScroll]="true"
                    [loading]="filterLoading" [typeahead]="input$" [closeOnSelect]="false">
                    <ng-template ng-option-tmp let-subItem="item" let-subItem$="item$" let-subItemIndex="index">
                      <div (click)="addOrRemoveFilteItem(item.id, subItem.id, subItem[item.filterDisplayKey], item.displayTag)">
                        <span>
                          <img
                            [src]="filtersSelected[item.id]?.includes(subItem.id) ? './assets/images/checked.svg' : './assets/images/checked-empty.svg'"
                            alt="checked" /> {{subItem[item.dropdownKey]}}
                        </span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>

                <div *ngIf="item && item.type === 'date'" class="sub-list">date</div>
                <div *ngIf="item && item.type === 'date-range'" class="sub-list sublist-date">
                  <app-daterange-picker-component #dateRangePicker [item]="item"
                    (dateFilterData)="onDateSelected($event)">
                  </app-daterange-picker-component>
                </div>
                <div *ngIf=" item && item.type==='multi-input'" class="multi-input-field sub-list">
                  <input *ngFor="let subItem of item.values; let i=index" class="form-control"
                    name="{{subItem.id+'_'+item.id}}" placeholder="{{subItem.name +' '+ item.id}}"
                    value="{{this.filtersSelected[subItem.id+'_'+item.id]}}"
                    (change)="addOrRemoveFilteItemOnChangeInput($event, 'multi-input')"
                    (keypress)="returnNumber($event)" autocomplete="off"/>
                </div>
                <div *ngIf="item && item.type === 'single-input'" class="sub-list">
                  <input *ngFor="let subItem of item.values; let i=index" class="form-control" name="{{item.id}}"
                    placeholder="{{item.displayName}}" value="{{this.filtersSelected[subItem.id+'_'+item.id]}}"
                    (change)="addOrRemoveFilteItemOnChangeInput($event, 'single-input')"
                    (keypress)="returnNumber($event)" />
                </div>
              </li>
            </ng-container>
          </ul>
          <div class="filter-buttons">
            <button class="apply-filter" (click)="applyFilter()">Apply filters</button>
            <button class="clear-filter" (click)="clearFilter()">Clear All</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isSortHidden">
      <div class="sortby-wrap" *userRole="roles">
        <div class="sortby-lablel">Sort by</div>
        <div class="dropdown">
          <button class="btn dropdown-toggle sort-button unit-opacity" [ngClass]="{'light': selectedSortOption.id == '', 'hide-caret': orderByItems.length <= 1}"
            type="button" id="sortDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" [disabled]="disableSort">
            {{ selectedSortOption.displayName }}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="sortDropdownMenuButton">
            <li class="dropdown-menu-item" *ngFor="let item of orderByItems; let i = index" (click)="changeSortOption(item)">
              <a class="dropdown-item">{{item.displayName}}</a>
            </li>
          </ul>
        </div>
        <div class="sort-icon">
          <button type="button" (click)="toggleOrderBy()" [disabled]="disableSort">
            <img class="search-icon theme-coloured-image"
              src="{{orderBy === 'asc' ? './assets/images/ascending-sort.svg' : './assets/images/descending-sort.svg'}}"
              alt="search" />
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</form>
<div class="interview-details-container">
    <div class="interview-details"> Interview Details</div>
    <div class="row separator-line"></div>
    <div class="mb-3">    
        <p class="section-header text-break">{{popUpContents[0].name}}</p> 
        <p class="content">
            {{popUpContents[0].date}} {{popUpContents[0].time}}
        </p>
    </div>
    <div>
        <div class="mt-1 meet-link-container" *ngIf="interviewDetails?.meet_link && !pastInterview">
            <a class="meet-link" target="_blank" 
            [href]="interviewDetails?.meet_link">Join with Google Meet</a>
        </div>
        <div class="row mt-2 d-flex align-items-center">
            <span class="col-sm-2 label-text me-2">Type:</span>
            <span *ngIf="!apiLoading" class="col-sm-8 content">{{interviewDetails?.mode? interviewDetails?.mode_name : '-'}}</span>
        </div>
        <div class="row mt-2 d-flex align-items-center">
            <span class="col-sm-2 label-text me-2">Location:</span>
            <span *ngIf="!apiLoading" class="col-sm-8 content">{{interviewDetails?.location?.name? interviewDetails?.location?.name:'-'}}</span>
        </div>
        <div class="row mt-2 d-flex align-items-baseline">
            <span class="col-sm-2 label-text me-2">Interviewers:</span>
            <span class="col-sm-8 content">
                <div>{{interviewDetails?.lead_interviewer?.username}}</div>
                <div *ngFor="let item of interviewDetails?.other_interviewers">
                    {{item.username}} <span class="optional-feild">(Optional)</span>
                </div>

            </span>
        </div>
    </div>
    <div class="row separator-line"></div>
    <div>
        <div class="row mt-2 d-flex align-items-center">
            <span class="col-sm-2 label-text me-2">Experience:</span>
            <span class="col-sm-8 content" *ngIf="interviewDetails?.candidate?.experience_years || interviewDetails?.candidate?.experience_months">
                {{interviewDetails?.candidate?.experience_years || 0 }} yrs {{interviewDetails?.candidate?.experience_months || 0 }} mnths
            </span>
            <span class="col-sm-8 content" *ngIf="!(interviewDetails?.candidate?.experience_years || interviewDetails?.candidate?.experience_months) && !apiLoading">
                -
            </span>
        </div>
        <div class="row mt-2 d-flex align-items-center">
            <span class="col-sm-2 label-text me-2">Stream:</span>
            <span *ngIf="!apiLoading" class="col-sm-8 content">{{interviewDetails?.candidate?.stream? interviewDetails?.candidate?.stream:'-'}}</span>
        </div>
    </div>
    <div>
        <button class="page-add-button mt-3 mb-3" (click)="viewCandidate()">{{'View Candidate Details' | uppercase}} </button>
    </div>
    <div class="row separator-line mt-0"></div>
    <div class="text-center">
        <button class="page-add-button save-btn cls-btn" (click)="onCancel()">CLOSE</button>
    </div>
        
</div>
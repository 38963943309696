<div class="history-container activity-history-container" *ngIf="activityLogs?.history?.length">
  <div class="header-section pe-2">
    <div [ngClass]="titleClass ? titleClass:'label-value'" class="title ">{{activityTitle}}</div>
  </div>
  <div>
    <app-pagination [start_count_parent]="activityLogs?.page_start_count"
      [end_count_parent]="activityLogs?.page_end_count" [total_count]="activityLogs?.totalItems || 0"
      [per_page_count]="activityLogs?.perpage" [is_next_active]="!!activityLogs?.nextPage || false"
      [is_prev_active]="!!activityLogs?.prevPage || false" (loadNext)="onNextClick($event)"
      (loadPrev)="onPrevClick($event)">
    </app-pagination>
  </div>
  <div class="task-history-list">
    <div *ngFor="let history of activityLogs?.history">
      <app-table-row>
        <div class="top-section">
          <div class="info w-100">
            <div class="data-row w-100">
              <div class="me-3 username" *ngIf="history?.name">
                <span class="content">{{history?.name | titlecase}}</span>
              </div>
              <div class="me-4" *ngIf="history?.task_type">
                <span class="label me-2"> Task:</span>
                <span class="content">{{history.task_type}}</span>
              </div>
              <div class="me-4" *ngIf="history?.status">
                <span class="label me-2"> Status:</span>
                <span class="content">{{history.status}}</span>
              </div>
              <div class="me-4" *ngIf=" history?.activity_type">
                <span class="label me-2"> Action Type:</span>
                <span class="content">{{history.activity_type}}</span>
              </div>
              <div class="ms-auto" *ngIf="history?.created_at">
                <span class="label">{{history.created_at | date: datetimeformat}}</span>
              </div>
            </div>
            <div class="data-row w-100">
              <div class="me-5" *ngIf="history?.details">
                <span class="label me-2"> Details:</span>
                <span class="content" [innerHTML]="history.details"></span>
              </div>
              <div class="ms-auto" *ngIf="history?.additional_info">
                <a><span class="link"
                    [ngClass]="{'disabled-icon':!history?.enable_additional_info_action }">{{history?.additional_info}}</span></a>
              </div>
            </div>
          </div>
        </div>
      </app-table-row>
    </div>
  </div>
  <div class="my-1">
    <app-pagination [start_count_parent]="activityLogs?.page_start_count"
      [end_count_parent]="activityLogs?.page_end_count" [total_count]="activityLogs?.totalItems || 0"
      [per_page_count]="activityLogs?.perpage" [is_next_active]="!!activityLogs?.nextPage || false"
      [is_prev_active]="!!activityLogs?.prevPage || false" (loadNext)="onNextClick($event)"
      (loadPrev)="onPrevClick($event)">
    </app-pagination>
  </div>
</div>
<div *ngIf="!activityLogs?.history?.length && !apiLoading" class="no-data-log-wrapper">No {{activityTitle | lowercase }} found</div>
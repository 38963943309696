import { Injectable } from '@angular/core';
import { PAGE } from 'src/app/shared/_models/page.enum';

@Injectable({
  providedIn: 'root'
})
export class DayRangeService {

  private completedInterviewateDateObj: any = {
    page: '', startDate: null, endDate: null, dateRangeId: 0
  }

  private hrPerformanceDateObj: any = {
    page: '', startDate: null, endDate: null, dateRangeId: 0
  }

  getCurrentPageDateDetails(page: string) {
    switch (page) {
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        return this.completedInterviewateDateObj;
        case PAGE.HRPERFORMANCEREPORT:
          return this.hrPerformanceDateObj;
      default:
        break;
    }
  }

  setCurrentPageDateDetails(page: string, dateObj: object) {
    switch (page) {
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        this.completedInterviewateDateObj = dateObj;
        break;
        case PAGE.HRPERFORMANCEREPORT:
          this.hrPerformanceDateObj = dateObj;
          break;
      default:
        break;
    }
  }

  removeCurrentPageDateDetails(page: string) {
    switch (page) {
      case PAGE.REPORTSCOMPLETEDINTERVIEWS:
        this.completedInterviewateDateObj = {
          page: '', startDate: null, endDate: null, dateRangeId: 0
        }
        break;
        case PAGE.HRPERFORMANCEREPORT:
          this.hrPerformanceDateObj = {
            page: '', startDate: null, endDate: null, dateRangeId: 0
          }
          break;
      default:
        break;
    }

  }
}

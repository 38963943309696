import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { customSideNav, } from '../../_constants/constants';
import { AuthService } from 'src/app/auth/auth.service';
import { Role } from '../../_models/role';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-custom-side-nav',
  templateUrl: './custom-side-nav.component.html',
  styleUrls: ['./custom-side-nav.component.scss']
})
export class CustomSideNavComponent implements OnInit, OnDestroy {
  @Input() menuName: string = '';
  @Input() routerVariables: any;
  customMenu: any = [];
  userRole: any;
  routerVars: any;
  existingQueryParams: any;
  customSideNav = JSON.parse(JSON.stringify(customSideNav));

  subscriptions: Subscription[] = [];

  @ViewChild('navList', { static: false }) navList!: ElementRef;
  
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    ) {
    this.userRole = this.authService.getUser()?.role;
    this.subscriptions.push(
      this.route.queryParams.subscribe((params: any) => {
        if (params) {
          this.existingQueryParams = params;
        }
      })
    )
  }
  ngOnInit(): void {
    this.setCustomMenu();
    this.changeRouterVariables();
    this.scrollActiveMenuItemIntoView();
    this.checkForRouteChange();
  }

  changeRouterVariables() {
    this.routerVars = { ...this.routerVariables };
    this.setRoutes();
  }

  setRoutes() {
    this.customMenu = this.customMenu.map((menu: any) => {
      Object.keys(this.routerVars).forEach((key: string) => {
        menu = { ...menu, routerLink: menu.routerLink.replace(key, this.routerVars[key]) };
      })
      return menu;
    });
  }

  setCustomMenu() {
    this.customMenu = this.getCustomMenu(this.menuName)
  }

  getCustomMenu(menuName: string) {
    const { role, mandate_offer_approval } = this.authService.getUser();
    let customNav = [...this.customSideNav[menuName]];
    customNav = customNav.filter((menuItem: any) => {
      if (!menuItem.role) {
        return menuItem
      } else if (menuItem?.page === 'workbook send offer letter' && role === Role.HR) {
        return !mandate_offer_approval;
      }
      else {
        return menuItem.role && menuItem.role.includes(role)
      }
    })
    return customNav;
  }

  filterCustomSideNavPages(pageList: any[]) {
    this.customMenu = [...this.customSideNav[this.menuName]].filter((navObj: any) => {
      let flag = false;
      pageList.forEach((page: any) => {
        if (navObj.page === page) {
          flag = true;
        }
      })
      return !flag;
    })
    this.scrollActiveMenuItemIntoView();
  }

  checkForRouteChange() {
    this.subscriptions.push(
      this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.scrollActiveMenuItemIntoView();
        }
      })
    )
  }

  scrollActiveMenuItemIntoView() {
    setTimeout(() => {
      this.navList?.nativeElement?.childNodes?.forEach((listItem: HTMLLIElement) => {
        if (listItem.className?.includes('active')) {
          listItem.scrollIntoView({block: "nearest"});
        }
      })
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe())
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() checked!: boolean;
  @Input() disabled!: boolean;
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  toggleCheckbox() {
    this.checked = !this.checked;
    this.toggle.emit(this.checked);
  }
}

import { Role } from "./role";
import { VirtualTeam } from "./space-user.model";
export class User {
  name!: string;
  email!: string;
  image!: string;
  employee_id!: string;
  role!: Role;
  is_vt_head!: boolean;
  is_core_leader!: boolean;
  has_interview!: boolean;
  id!: string;
  is_interviewer!: boolean;
  has_upcoming_interviews!: boolean;
  has_awaiting_ratings!: boolean;
  is_reports_enabled!: boolean;
  is_organization_admin!: boolean;
  mandate_offer_approval!: boolean; 
  mandate_candidate_photo?: boolean;
  allow_update_of_offer_email_after_approval!: boolean;
  hasOverFlow?: any
  is_enable_send_schedule_invite!: boolean;
  virtual_team!: VirtualTeam;
  enable_availability_menu?: boolean;
  is_recruitment_drives_enabled?: boolean;
}



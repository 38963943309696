import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-tab-section',
  templateUrl: './simple-tab-section.component.html',
  styleUrls: ['./simple-tab-section.component.scss']
})
export class SimpleTabSectionComponent {
  @Input() tabs: any = [];
  @Input() activeTab: number = 1;
  @Output() tabChanged: EventEmitter<number> = new EventEmitter();

  onTabChange(tab:number){
    this.tabChanged.emit(tab)
  }

}

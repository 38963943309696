export const regex = {
  username: "^[a-zA-Z0-9!@#$%^&*?()_+-=.// ]{5,80}$",
  emailOld:
    "[a-zA-Z0-9!#.$%&*-+/=?^_{|}~-]{1,}@[a-zA-Z0-9!#$%&*-+/=?^_{|}~-]{1,}([.]{1}[a-zA-Z0-9!#$%&*-+/=?^_{|}~-]{1,}){1,}",
  pswddRegex:
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z][A-Za-z0-9!@#$%^&*?()_+]{7,}$",
  urlRegex: /(https?:\/\/[^ ]*)/,
  text: "[A-Za-z0-9_ ]+",
  number: "^[0-9]*$",
  currency: "^[0-9,]*$",
  tel: "[0-9]{3,15}",
  indianMob: /^(\+?\d{0,3}[ ]*[-]?[ ]*)?\d{10}$/,
  twoDigitNumber: /^[0-9]{1,2}$/,
  oneToHundredNumber: /^([0]|[1-9][0-9]?$|^100)$/,
  months: "^(1[0-1]|[0]?[0-9])$",
  percentage: /^(?:100(?:\.00?)?|\d?\d(?:\.\d\d*)?)$/,
  hashtag: /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,140})(\b|\r)/g,
  address: "(^[\\p{L}0-9_ .()@#',*/-]*$)",
  area: "(^[\\p{L} .'-]*$)",
  postalCode: "(^[\\p{L}0-9_ -]*$)",
  decimalNumber: /^(0|([1-9][0-9]{0,1})){1}(\.[0-9]{1,6})?$/,
  anyDecimalNumber: /^\s*(?=.[0]|[1-9])\d*(?:\.\d{1,6})?\s*$/,
  anyDecimalNumberWithLimit: /^(0|([1-9][0-9]{0,8})){1}(\.[0-9]{1,6})?$/,
  blockSpecialCharacters: /^[^:>#*]+|([^:>#*][^>#*]+[^:>#*])$/,
  withoutLeadingZero: "^(0|[1-9][0-9]*)$",
  expMonths: "^(1[0-1]|[0]|[1-9])$",
  phoneNumber: "^[0-9][0-9][0-9]{3,13}$",
  percentageWithDecimal:/(^100([.]0{1,2})?)$|(^[1-9][0-9]{0,1}([.][0-9]{1,2})?)$/,
  htmlTagRegex: /<[^>]*>/g,
  nbspRegex: /&nbsp;/g,
  cgpa: "(^10([.]0{1,3})?)$|(^[0-9]([.][0-9]{1,3})?)$",
  oneToHundred: /^([1-9][0-9]?$|^100)$/,
  decimalWithThreePlace: /^(0|([1-9][0-9]{0,8})){1}(\.[0-9]{1,3})?$/,
  decimalWithTwoPlace : /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
  headingTagsExceptH4: /<(\/?)h[1-3|5-6]\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g,
  email: /(?!.*\.\.)^([a-zA-Z0-9!#.$%&*-+/"=?^_{|}~-]{1,}@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})))$/m,
  integerNumber: /^[1-9]\d*$/,
  integerNumberWithLimit:/^(0|([1-9][0-9]{0,8}))$/,
  // text_30: /^[A-Za-z0-9_ ]+{1,50}$/,
  // text_100: /^[A-Za-z0-9_ ]+{1,100}$/,
  // text_500: /^[A-Za-z0-9_ ]+{1,500}$/,
  empty: /.*\S.*/,
  username150: "^[a-zA-Z0-9!@#$%^&*?()_+-=.//]{5,150}$",
  daysOfTheMonth:"(3[01]|[12][0-9]|[1-9])$",
  daysOfTheWeek:"([1-7])$"
};
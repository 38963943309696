import { Component, NgZone, OnDestroy, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../shared/_models/user';
import { AppModel } from '../shared/_models/utils';
import { AuthService } from './auth.service';
import { StorageService } from '../shared/_services/storage.service';
import { LoaderService } from '../shared/_components/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { KeycloakService } from 'keycloak-angular';
import { GlobalStoreService } from '../shared/_services/global-store.service';

declare global {
  interface Window { gapi: any; authInstance: any, }
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnDestroy, OnInit {

  public gapiSetup: any; // marks if the gapi library has been loaded
  public authInstance: any;
  public error: string = '';
  public user: User = new User;
  initError: any = false;
  errorMsg: any = '';
  isLoggedIn: boolean = false;
  userProfile: any = {};
  appService: AppService;
  activatedRoute: ActivatedRoute;
  globalStore: GlobalStoreService;
  subscription: Subscription = new Subscription();
  public isDisabled: boolean = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private storageService: StorageService,
    private loader: LoaderService,
    private toaster: ToastrService,
    private injector: Injector,
    private readonly keycloak: KeycloakService
  ) {
    this.appService = this.injector.get<AppService>(AppService);
    this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.globalStore = this.injector.get<GlobalStoreService>(GlobalStoreService);

    this.appService.setTitle('QRMS V2 - Login');
  }

  ngOnInit(): void {
    this.loader.show();
    this.keycloak?.isLoggedIn().then((val: boolean) => {
      this.isLoggedIn = val;
      if (this.isLoggedIn) {
        this.getToken();
      } else {
        this.login();
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  login() {
    this.keycloak?.login().then();
  }

  logout() {
    this.keycloak?.logout();
  }

  getToken() {
    this.keycloak.loadUserProfile().then((val: any) => {
      this.userProfile = val;
    })
    
    this.keycloak.getToken().then((val: string) => {
      this.storageService.set('token', val);
      this.authenticate();
    }).catch((err: any) => {
      console.log(err)
    })
  }

  authenticate() {
    this.subscription = this.authService.login().subscribe(
      (res: AppModel<User>) => {
        if (res.status === 200) {
          this.storageService.set('user', JSON.stringify(res.data));
          this.globalStore.setUser(res.data);
          this.ngZone.run(() => {
            const redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'] || '/dashboard';
            return this.router.navigateByUrl(redirectUrl);
          }).then();
        } else if (res.status === 400) {
          localStorage.clear();
          this.keycloak.logout(window.origin + '/login');
        } else if (res.status === 401) {
          localStorage.clear();
          this.toaster.error(res.message);
          setTimeout(() => {
            this.keycloak.logout(window.origin + '/login');
          }, 5000);
        }
      },
      (error: any) => {
        this.loader.hide();
        if (error.status === 401) {
          this.toaster.error(`Invalid user. Please check your credentials.`);
        }
      },
    );
  }

  showLoader() {
    this.loader.show();
  }
}

import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})

export class ConfirmState {
  options!: ConfirmOptions;
  modal!: NgbModalRef;
}

export interface ConfirmOptions {
  title: string,
  message: string,
  acceptButtonText: string,
  rejectButtonText: string
}


<div class="email-popup email-flex-wrap" (window:resize)="onResize()">
   <form [formGroup]="emailForm">
      <div class="fluid-container" [ngClass]="isDesktop? 'p-3':'p-4'">
         <span class="action-icon" placement="bottom" ngbTooltip="Close">
         <img src="./assets/images/close.svg" alt="Close" class="mb-1" (click)="onCancel()"/>
         </span>
         <div class="content-wrapper">
            <div class="row" [ngClass]="isDesktop? 'mb-2':'mb-3'">
               <div class="col-sm-12 popup-header">{{popUpContents?.title}}
               </div>
            </div>
            <div class="d-flex popup-message" [ngClass]="isDesktop? 'mb-1':'mb-2'">
               <span>  <img src="./assets/images/info-icon.svg" alt="info" /></span>
               <span>{{popUpContents?.message ? popUpContents?.message + infoMessage : infoMessage}}
               </span>
            </div>
            <div class="col-sm-12 ">
               <div class="row form-group field-row">
                  <label for="from" class="col-sub-form-1 col-form-label field-label">From :</label>
                  <div class="col-sub-form-11">
                     <input name="from" type="text"  formControlName="from" class="form-control" readonly>
                  </div>
               </div>
            </div>
            <div *ngIf="f.from?.errors?.required && isSubmitted && f.from?.touched" class="alert alert-error invalid-msg">
               The field is mandatory.
            </div>
            <ng-container *ngIf="!hasMultipleCcAndBcc;else multipleCcAndBcc">
               <div class="col-sm-12">
                  <div class="row form-group field-row">
                     <label class="col-sub-form-1 col-form-label field-label">Cc :</label>
                     <div class="col-sub-form-11 cc">
                        <input name="cc" formControlName="cc" type="text" class="form-control" readonly>
                        <span (click)="toggleBccList()" class="bcc inline-bcc">Bcc</span>
                     </div>
                  </div>
                  <div *ngIf="showBccList" class="form-group row field-row">
                     <label class="col-sub-form-1 col-form-label field-label">Bcc :</label>
                     <div class="col-sub-form-11 ">
                        <input name="bcc" formControlName="bcc" type="text" class="form-control">
                     </div>
                  </div>
               </div>
               <div *ngIf="f.bcc?.errors?.pattern && f.bcc?.touched" class="col-sm-12 alert alert-error invalid-msg">
                  Invalid email
               </div>
            </ng-container>
            <ng-template #multipleCcAndBcc>
               <div class="col-sm-12">
                     <div class="row form-group field-row" [ngClass]="{'to-list-alignment':toAlignmentFlag }">
                        <label class="col-sub-form-1 col-form-label field-label">To :</label>
                        <div class="col-sub-form-10 to-list email-padding" [ngClass]="!isEditable ? 'no-action' : ''">
                           <ng-select #toSelectRef [items]="emailList" class="feild-value form-view toEmail" [(ngModel)]="toList"
                              (add)="scrollToBottom('to',$event)" [ngModelOptions]="{standalone: true}" [multiple]="true"
                              [searchFn]="customSearchFn.bind(this)" bindLabel="email" bindValue="email" [searchable]="isEditable"
                              (remove)="checkRemovedItem('to')" (close)="showEmailError = false" [clearable]="isToClearable" [hideSelected]="true"
                              [compareWith]="compareFn" (keyup)="selectFieldKeyupHandler($event, toSelectRef)"
                              [placeholder]="errorPlaceHolder" (focus)="toAlignmentFlag=true;" (blur)="toAlignmentFlag=false;">
                              <ng-template ng-option-tmp let-subItem="item">
                                 <span class="font-style"> {{subItem.username}} </span> &nbsp;[<span
                                    class="label-value-blue">{{subItem.email}}</span>]
                              </ng-template>
                              <ng-template ng-footer-tmp *ngIf="showEmailError">
                                 <span class="label-text email-error">Please select an email within our organization</span>
                              </ng-template>
                           </ng-select>
                        </div>
                     </div>
                  <div class="row form-group field-row" [ngClass]="{'cc-list-alignment':ccAlignmentFlag }">
                     <label class="col-sub-form-1 col-form-label field-label">Cc :</label>
                     <div class="col-sub-form-10 cc-list email-padding" [ngClass]="!isEditable ? 'no-action' : ''">
                        <ng-select #ccSelectRef id="ccSelect" [items]="emailList" class="feild-value form-view" [(ngModel)]="ccList"
                           (add)="scrollToBottom('cc',$event)" [ngModelOptions]="{standalone: true}" [multiple]="true"
                           [searchFn]="customSearchFn.bind(this)" bindLabel="email" bindValue="email" [searchable]="isEditable"
                           (remove)="checkRemovedItem('cc')" (close)="showEmailError = false" [clearable]="isCCClearable" [hideSelected]="true"
                           [compareWith]="compareFn" (keyup)="selectFieldKeyupHandler($event, ccSelectRef)" (focus)="ccAlignmentFlag=true;" (blur)="ccAlignmentFlag=false;">
                           <ng-template ng-option-tmp let-subItem="item">
                              <span class="font-style"> {{subItem.username}} </span>
                              &nbsp;[<span class="label-value-blue">{{subItem.email}}</span>]
                           </ng-template>
                           <ng-template ng-footer-tmp *ngIf="showEmailError">
                              <span class="label-text email-error">Please select an email within our organization</span>
                           </ng-template>
                        </ng-select>
                        <span *ngIf="!showBccList" (click)="toggleBccList()" class="bcc inline-bcc" [ngClass]="!isEditable ? 'disable-feature' : ''">Bcc</span>
                     </div>
                  </div>
                  <div  *ngIf="showBccList" class="form-group row field-row " [ngClass]="{'bcc-list-alignment':bccAlignmentFlag }">
                     <label class="col-sub-form-1 col-form-label field-label">Bcc :</label>
                     <div class="col-sub-form-10 bcc-list email-padding">
                        <ng-select #bccSelectRef (change)="scrollToBottom('bcc',$event)" [items]="emailList" class="feild-value form-view"
                           [(ngModel)]="bccList" [ngModelOptions]="{standalone: true}" [multiple]="true" [searchFn]="customSearchFn.bind(this)"
                           bindLabel="email" bindValue="email" [searchable]="true" (remove)="checkRemovedItem('bcc')"
                           [clearable]="isBCCClearable" [hideSelected]="true" [compareWith]="compareFn"
                           (keyup)="selectFieldKeyupHandler($event, bccSelectRef)" (close)="showEmailError = false"
                           (focus)="bccAlignmentFlag=true;" (blur)="bccAlignmentFlag=false;">
                           <ng-template ng-option-tmp let-subItem="item">
                              <span class="font-style"> {{subItem.username}} </span>
                              [<span class="label-value-blue">{{subItem.email}}</span>]
                           </ng-template>
                           <ng-template ng-footer-tmp *ngIf="showEmailError">
                              <span class="label-text email-error">Please select an email within our organization</span>
                           </ng-template>
                        </ng-select>
                     </div>
                  </div>
               </div>
            </ng-template>
            <div class="col-sm-12 mb-4">
               <div class="form-group row field-row">
                  <label class="col-sub-form-1 col-form-label field-label pe-0">Subject :</label>
                  <div class="col-sub-form-11">
                     <input name="subject" type="text" formControlName="subject" class="form-control subject" autocomplete="off" [readonly]="!this.isEditable">
                  </div>
               </div>
               <div *ngIf="f.subject?.touched && f.subject.errors?.required" class="alert alert-error invalid-msg">
                  The field is mandatory.
               </div>
            </div>
            <div>
               <angular-editor formControlName="mailBody"  [config]="config" (focus)="hideErroMessage()">
               </angular-editor>
               <div *ngIf="!isValidContent" class="alert alert-error invalid-msg">
                  The field is mandatory.
               </div>
            </div>
         <ng-container *ngIf="['send offer letter', 'approve offer', 'send for approval', 'approve & send offer letter'].includes(popUpContents?.title.toLowerCase())">
            <ng-container *ngFor="let file of apiGeneratedFiles;">
               <div class="col-sm-12" [ngClass]="isDesktop? 'mt-2':'mt-3'">
                  <div class="row  justify-content-center">
                     <div class="col">
                        <div class="file-name">{{file?.name}}<img class="ms-1 cursor-pointer" [ngClass]="!isEditable ? 'disable-feature' : ''" (click)="removeDefaultFile(file)"
                              src="./assets/images/close.svg" alt="clear" /></div>
                     </div>
                  </div>
               </div>
               <div>
               </div>
            </ng-container>
            <ng-container *ngFor="let file of userSelectedFiles; let i = index">
               <div class="col-sm-12" [ngClass]="isDesktop? 'mt-2':'mt-3'">
                  <div class="row  justify-content-center">
                     <div class="col">
                        <div class="file-name cursor-pointer"><span (click)="download(file?.link)">{{file?.name}}</span><img class="ms-1" (click)="removeUserFile(file)"
                              src="./assets/images/close.svg" alt="clear" /></div>
                     </div>
                  </div>
               </div>
               <div>
               </div>
            </ng-container>
            <div [ngClass]="[isDesktop? 'mt-2':'mt-3', !isEditable ? 'disable-feature' : '']">
                  <file-upload #resumeUpload btnName="documents" type="both" size="15" fileType="OFFR" [fetchPrevFile]="false"
                     [showHint]="false" [validationMessage]="resumeValidationMessage" [resetFile]="resetFile" [isUpperCase]="true"
                     [showUploadedFile]="false" (fileChanged)="fileHandler($event)" [mode]="mode" [uploadDisabled]="disableUpload()" [acceptType]="'.pdf'">
                  </file-upload>
            </div>
            <div *ngIf="fileError.length" class="alert alert-error invalid-msg">
               {{fileError}}
            </div>
         </ng-container>
            <div class="col-sm-12 mt-3">
               <div class="row  justify-content-center">
                  <div class="col">
                     <button (click)="onSend()" [ngClass]="emailBtnName?.length > 10 ? 'btn-sm-padding':'btn-md-padding'" class="btn save-btn">{{emailBtnName | uppercase}}</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
</div>
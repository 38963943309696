import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';

import { AppService } from 'src/app/app.service';
import { Department } from '../../_models/department';
import { Desigination } from '../../_models/desigination';
import { Location } from '../../_models/location';
import { PAGE } from '../../_models/page.enum';
import { InterviewLevel, InterviewType, JobCode, Stream } from '../../_models/utils';
import { CustomValidators } from '../../_services/custom-validators.service';
import { dateFormatForDatePipe } from '../../_services/format';
import { GlobalStoreService } from '../../_services/global-store.service';
import { UtilService } from '../../_services/util.service';
import { ScheduleInviteFormService } from './schedule-invite-form.service';
import { Role } from 'src/app/shared/_models/role';

@Component({
  selector: 'app-schedule-invite-form',
  templateUrl: './schedule-invite-form.component.html',
  styleUrls: ['./schedule-invite-form.component.scss']
})
export class ScheduleInviteFormComponent implements OnInit {

  scheduleInviteForm!: FormGroup;
  subscriptions: any = [];
  toaster: ToastrService;
  modalService: NgbModal;
  fb: FormBuilder;
  globalStore: GlobalStoreService;
  Role = Role;
  router: Router;
  jobCodes: Array<JobCode> = [];
  departments: Array<Department> = [];
  streams: Array<Stream> = [];
  streamBackup: Array<Stream> = [];
  designation: Array<Desigination> = [];
  designationBackup: Array<Desigination> = [];
  location: Array<Location> = [];
  interviewLevels: Array<InterviewLevel> = [];
  interviewDurations: Array<any> = [];
  interviewTypes: Array<InterviewType> = [];
  timepickerConfig: any = {};
  timeValue: string = '';
  resumeData: any = {};
  scheduleDisabled: boolean = false;
  localDateFormat: string = dateFormatForDatePipe;
  interviewersListBackup: any = []; // Interviewers list from API

  allInterviewers: any = []; // All interviewers array with group added (group: all)
  interviewersFiltered: any = []; // interviewers filtered based on user input (group: filtered)
  interviewersNonMatched: any = []; // Non matching interviewers array with group added (group: all)
  interviewersList: any = []; // interviewers which are passed to items array of interviewers dropdown (contains both arrays: interviewersFiltered and interviewersNonMatched)
  selectedInterviewersDetails: any = [] // selected interviewers array (entire interviewer object is present)
  selectedInterviewersIds: any = []; // selected interviewers array mapped with id

  otherInterviewersList: any = []; // other interviewers which are passed to items array of other interviewers dropdown
  selectedOtherInterviewersList: any = []; // selected other interviewers array mapped with id
  selectedOtherInterviewersDetails: any = []; // selected other interviewers array (entire interviewer object is present)

  availabilityData: any = {};

  warningPopupData: any = {};
  @ViewChild('confirmRequest', { static: true }) confirmRequest!: NgbModalRef; // Confirmation modal
  closeResult: any;
  confirmationMessage: string = '';
  confirmationTitle: string = '';

  @Input() resume: any = {};
  @Input() scheduledData: any = {};
  @Input() isEditMode: boolean = false;
  @Input() editFrom: string = '';

  candidateId!: number;

  @ViewChild('warningPopup', { static: true }) // Warning modal
  warningPopup!: NgbModalRef;

  @ViewChild('availabilityModal', { static: true })
  availabilityModal!: NgbModalRef; 

  constructor(
    private appService: AppService,
    private customValidatorService: CustomValidators,
    private scheduleInviteFormService: ScheduleInviteFormService,
    private datePipe: DatePipe,
    private utilService: UtilService,
    private injector: Injector,
  ) {
    this.toaster = this.injector.get<ToastrService>(ToastrService);
    this.modalService = this.injector.get<NgbModal>(NgbModal);
    this.fb = this.injector.get<FormBuilder>(FormBuilder);
    this.globalStore = this.injector.get<GlobalStoreService>(GlobalStoreService);
    this.router = this.injector.get<Router>(Router)
  }

  ngOnInit(): void {
    this.initializeScheduleInviteForm();
    this.getDropdownsData();
    this.setInterviewersListForDropdown();
    this.updateOtherInterviewers();
  }

  initializeScheduleInviteForm() {
    this.scheduleInviteForm = this.fb.group({
      job: [null],
      department: [null],
      stream: [null],
      designation: [null],
      mode: ['V', [Validators.required]],
      location: [null],
      level: ['T', [Validators.required]],
      duration: [3, [Validators.required]],
      interviewers: [[], [Validators.required]],
      other_interviewers: [[]],
      availability_from: [
        {
          id: 'availability_from_id',
          value: {
            startDate: dayjs().add(1, 'day'),
            endDate: dayjs().add(1, 'day'),
          },
          minDate: dayjs(),
          maxDate: dayjs.Dayjs,
        }, [Validators.required,
        this.customValidatorService.minimumDate(this.datePipe.transform(new Date(), this.localDateFormat)!)]
      ],
      display_availability_from: [dayjs().add(1, 'day').format('MMM DD, YYYY'), [Validators.required]],
      availability_to: [
        {
          id: 'availability_to_id',
          value: undefined,
          minDate: dayjs().add(1, 'day'),
          maxDate: dayjs().add(15, 'days'),
        }, [Validators.required,
        this.customValidatorService.minimumDate(this.datePipe.transform(new Date(), this.localDateFormat)!)]
      ],
      display_availability_to: [null, [Validators.required]],
      invite_expires: [
        {
          id: 'invite_expires_id',
          value: undefined,
          minDate: dayjs(),
          maxDate: dayjs().add(14, 'days'),
        }, [
          Validators.required,
          this.customValidatorService.minimumDate(this.datePipe.transform(new Date(), this.localDateFormat)!)
        ]
      ],
      display_invite_expires: [null, [Validators.required]],
      invite_expiry_time: [null, [Validators.required]],
      show_previous_interview: [false],
    })

    this.scheduleInviteForm.controls['invite_expires'].valueChanges
      .subscribe(expiryDate => {
        expiryDate = expiryDate?.value?.startDate ? this.datePipe.transform(expiryDate?.value?.startDate, this.localDateFormat)! : '';
        this.validateExpiryTime(expiryDate);
      });

    this.subscribeForUserInputChanges();
  }

  get f() {
    return this.scheduleInviteForm.controls;
  }

  populateForm() {
    this.candidateId = this.resume.id;
    const { job_code, stream, designation } = this.resume;
    const { department = '' } = this.resume?.stream ?? {};

    const jobCode = this.jobCodes.find(code => code.id === job_code?.id)
    this.f.job.setValue(jobCode?.id ?? null);

    if (stream) {
      this.f.stream.setValue(stream.id)
    }
    if (department) {
      const dept_id = department.id;
      this.setDesignation(dept_id);
      this.setStreams(dept_id);
      this.f.department.setValue(department.id)
    }
    if (designation) {
      this.f.designation.setValue(designation.id);
      this.setDesignationDropdown(designation);
      this.setDesignation(department.id);
    }
  }

  populateEditForm() {
    const {
      job, 
      stream, 
      department, 
      designation, 
      mode, 
      location, 
      level, 
      duration, 
      interviewers, 
      other_interviewers, 
      availability_from, 
      availability_to, 
      candidate, 
      expired_on,
      show_previous_interview,
    } = this.scheduledData;
    this.candidateId = candidate;
    const {localDate, localTime} = this.utilService.convertIsoToLocalDateAndTime(expired_on);

    this.scheduleInviteForm.patchValue({
      job,
      stream,
      department,
      designation,
      mode,
      location,
      level,
      duration,
      interviewers,
      other_interviewers,
      show_previous_interview,
      availability_from: {
        id: 'availability_from_id',
        value: {
          startDate: dayjs(availability_from),
          endDate: dayjs(availability_from),
        },
        minDate: dayjs(),
        maxDate: dayjs.Dayjs,
      },
      display_availability_from: dayjs(availability_from).format('MMM DD, YYYY'),
      availability_to: {
        id: 'availability_to_id',
        value: {
          startDate: dayjs(availability_to),
          endDate: dayjs(availability_to),
        },
        minDate: dayjs(availability_from),
        maxDate: dayjs(availability_from).add(14, 'day'),
      },
      display_availability_to: dayjs(availability_to).format('MMM DD, YYYY'),
      invite_expires: {
        id: 'invite_expires_id',
        value: {
          startDate: dayjs(localDate),
          endDate: dayjs(localDate),
        },
        minDate: dayjs(),
        maxDate: dayjs.Dayjs,
      },
      display_invite_expires: dayjs(localDate).format('MMM DD, YYYY'),
      invite_expiry_time: localTime
    })

    if (localTime) {
      this.timepickerConfig = { hour: parseInt(localTime.split(':')[0]), minute: parseInt(localTime.split(':')[1]) };
      this.timeValue = `${this.timepickerConfig.hour}:${this.timepickerConfig.minute}`
    }

    this.selectedInterviewersIds = [...interviewers];
    this.selectedInterviewersDetails = this.interviewersList.filter((user: any) => this.selectedInterviewersIds.includes(user?.id));
    if (interviewers.length) {
      setTimeout(() => {
        this.retainPlaceHolderForInterviewers();
      }, 0);
    }

    this.selectedOtherInterviewersList = [...other_interviewers];
    this.otherInterviewersList = this.interviewersListBackup.filter((item: any) => !this.selectedInterviewersIds.includes(item.id));
    this.selectedOtherInterviewersDetails = this.interviewersListBackup.filter((user: any) => this.selectedOtherInterviewersList.includes(user?.id));
    if (other_interviewers.length) {
      setTimeout(() => {
        this.retainPlaceHolderForOtherInterviewers();
      }, 0);
    }

    this.setDesignation(department);
    this.setStreams(department);
    // this.setDesignationDropdown(designation);
    this.setDesignation(department);

    //disabling schedule button until there is a change in the form in edit mode
    this.scheduleDisabled = true;
    this.detectChanges();

  }

  getDropdownsData() {
    this.subscriptions.push(
      this.appService.generalDropdowns.subscribe(
        (data: any) => {
          const {
            Job_codes,
            Department,
            Designation,
            Interview_Location,
            Interview_level_choice,
            Interview_mode_choice,
            interview_duration,
            Stream,
            Interviewers
          } = data;
          this.jobCodes = Job_codes;
          this.departments = Department;
          this.designationBackup = Designation;
          this.streams = Stream;
          this.streamBackup = Stream;
          this.location = Interview_Location;
          this.interviewLevels = Interview_level_choice;
          this.interviewDurations = interview_duration;
          this.interviewTypes = Interview_mode_choice;
          this.interviewersList = Interviewers;
          this.allInterviewers = Interviewers;
          this.interviewersListBackup = Interviewers;

          this.isEditMode ? this.populateEditForm() : this.populateForm();
        }
      ));
  }

  // Subscribe form input changes so as to filter interviewer list
  subscribeForUserInputChanges() {
    this.scheduleInviteForm.controls['level'].valueChanges
      .subscribe(() => {
        setTimeout(() => this.setInterviewersListForDropdown(), 0);
      });
    this.scheduleInviteForm.controls['stream'].valueChanges
      .subscribe(() => {
        setTimeout(() => this.setInterviewersListForDropdown(), 0);
      });
    this.scheduleInviteForm.controls['location'].valueChanges
      .subscribe(() => {
        setTimeout(() => this.setInterviewersListForDropdown(), 0);
      });

  }

  onJobCodeChange(e: any) {
    this.streams = [...this.streamBackup];
    this.setDesignation(e.dept);
    this.setStreams(e.dept);
    this.f.stream.setValue(e.stream)
    this.f.department.setValue(e.dept)
    this.f.designation.setValue(e.designation)
  }

  onDepartmentChange(dept_id: string) {
    this.scheduleInviteForm.controls['designation'].setValue(null);
    this.setDesignation(dept_id);
    if (!dept_id) {
      this.scheduleInviteForm.controls['stream'].setValue(null);
      this.streams = [...this.streamBackup];
    } else {
      this.scheduleInviteForm.controls['stream'].setValue(null);
      this.streams = [];
      if (dept_id) {
        this.streamBackup.forEach((s: any) => {
          if (dept_id == s.department_id) {
            this.streams = [...this.streams, s];
            //automatically set stream for non development departments
            if (dept_id != '2') {
              this.scheduleInviteForm.controls['stream'].setValue(s.id);
            }
          }
        });
      }
    }
  }

  onStreamChange(stream_id: string) {
    this.streams.forEach((stream: any) => {
      if (stream.id == stream_id) {
        let currentDeptId = this.scheduleInviteForm.controls['department'].value;
        if (currentDeptId && stream.department_id !== currentDeptId) {
          this.scheduleInviteForm.controls['designation'].setValue(null);
        }
        this.setDesignation(stream.department_id);
        this.scheduleInviteForm.controls['department'].setValue(
          stream.department_id
        );
      }
    });
  }

  setStreams(dept_id: string) {
    this.streams = this.streamBackup.filter((stream: any) => {
      return (dept_id === stream.department_id)
    });
  }


  setDesignation(dept_id: string) {
    this.designation = this.designationBackup.filter((item: any) => item.department_id === dept_id
      || this.f.designation?.value == item.id || item.is_temporary);
  }

  setDesignationDropdown(designation: any) {
    //remove the previously added temporary designation
    let last: any = this.designationBackup[this.designationBackup.length - 1];
    if (last.is_temporary) {
      this.designationBackup.pop();
    }
    //add current temporary designation to the dropdown if not already present
    let current: any = this.designationBackup.find(d => d?.id === designation?.id);
    if (!current) {
      let tempDesignation = { ...designation, is_temporary: true };
      this.designationBackup = [...this.designationBackup, tempDesignation];
    }
  }

  onDepartmentModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.departments)
  }

  onStreamModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.streams)
  }

  onDesignationModelChange(event: any, ref: NgSelectComponent) {
    this.onModelChange(event, ref, this.designation)
  }

  onModelChange(event: any, ref: NgSelectComponent, data: any) {
    if (event) {
      let filterValue = data.find((str: any) => str.id === event);
      ref.searchTerm = filterValue ? filterValue.name : '';
    } else {
      ref.searchTerm = '';
    }
  }

  onAvailabilityFromSelected(e: any, key: FormGroup) {
    key.controls['display_availability_from'].markAsTouched();

    if (e && e.availability_from_id != 'Invalid Date') {

      key.controls['availability_from'].setValue({
        ...key.controls['availability_from'].value,
        value: {
          startDate: dayjs(e.availability_from_id),
          endDate: dayjs(e.availability_from_id),
        }
      });

      key.controls['display_availability_from'].setValue(
        dayjs(e.availability_from_id).format('MMM DD, YYYY')
      );
      
      let to = key.controls['display_availability_to'].value ? dayjs(key.controls['display_availability_to'].value) : undefined;
      this.validateAvailabilityTo(key, e.availability_from_id, to);
      this.updateAvailabilityToRange(key, e.availability_from_id, to)

    } else {

      key.controls['availability_from'].setValue({
        id: 'availability_from_id',
        value: undefined,
        minDate: dayjs(),
        maxDate: dayjs.Dayjs,
      });
      key.controls['availability_to'].setValue({
        id: 'availability_to_id',
        value: {
          startDate: dayjs(),
          endDate: dayjs()
        },
        minDate: dayjs(),
        maxDate: dayjs.Dayjs,
      });
      key.controls['display_availability_from'].setValue(null);
      key.controls['display_availability_to'].setValue(null);
      this.validateAvailabilityTo(key, e.availability_from_id, key.controls['display_availability_to'].value)
    }
  }

  isDateInValidRange(date: any, from: any, to: any) {
    let isValid;
    if (date === "Invalid Date") {
      return true;

    } else {

      if (from && to) {
        isValid =
          dayjs(date).isSame(dayjs(from)) ||
          dayjs(date).isSame(dayjs(to)) ||
          (dayjs(date).isAfter(dayjs(from)) && dayjs(date).isBefore(dayjs(to)))
        return isValid;

      } else if (from && !to) {
        isValid = dayjs(date).isSame(dayjs(from)) || dayjs(date).isAfter(dayjs(from))
        return isValid;

      } else {
        return true;
      }
    }
  }

  validateToDate(valid: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      return valid ? null : { 'invalidDate': true }
    };
  }

  validateAvailabilityTo(key: FormGroup, from: any, to?: any) {
    let isToDateValid = true;

    if (to && !!from && from !== 'Invalid Date') {
      isToDateValid = to ? this.isDateInValidRange(to, from, dayjs(from).add(14, 'day')) : true;
    }

    if (isToDateValid) {
      key.controls['display_availability_to'].setValidators([Validators.required]);
    } else {
      key.controls['display_availability_to'].setValidators([this.validateToDate(isToDateValid)]);
    }
    key.controls['display_availability_to'].updateValueAndValidity();
  }

  updateAvailabilityToRange(key: FormGroup, from: any, to?: any) {
    const clearToDate = to === undefined;
    key.controls['availability_to'].setValue({
      id: 'availability_to_id',
      value: !clearToDate ? {
        startDate: dayjs(to),
        endDate: dayjs(to),
      } : undefined,
      minDate: dayjs(from),
      maxDate: dayjs(from).add(14, 'day'),
    });

    if (clearToDate) {
      key.controls['display_availability_to'].setValue(null);
    } else {
      key.controls['display_availability_to'].setValue(
        dayjs(to).format('MMM DD, YYYY')
      );
    }
  }

  onAvailabilityToSelected(e: any, key: FormGroup) {
    key.controls['display_availability_to'].markAsTouched();

    if (e && e.availability_to_id != 'Invalid Date') {

      let from = key.controls['display_availability_from'].value;
      let to = key.value.availability_from.maxDate
      to = to && this.datePipe.transform(to, this.localDateFormat)

      if (from && !this.isDateInValidRange( e.availability_to_id, from, to))
      {
        return;
      }

      key.controls['availability_to'].setValue({
        ...key.controls['availability_to'].value,
        value: {
          startDate: dayjs(e.availability_to_id),
          endDate: dayjs(e.availability_to_id),
        }
      });

      key.controls['display_availability_to'].setValue(
        dayjs(e.availability_to_id).format('MMM DD, YYYY')
      );

    } else {

      key.controls['availability_to'].setValue({
        ...key.controls['availability_to'].value,
        value: undefined
      });
      key.controls['display_availability_to'].setValue(null);
    }

    this.validateAvailabilityTo(key, key.controls['display_availability_from'].value, key.controls['display_availability_to'].value)
  }

  onInviteExpirySelected(e: any, key: FormGroup) {
    key.controls['display_invite_expires'].markAsTouched();
    if (e && e.invite_expires_id != 'Invalid Date') {

      key.controls['invite_expires'].setValue({
        ...key.controls['invite_expires'].value,
        value: {
          startDate: dayjs(e.invite_expires_id),
          endDate: dayjs(e.invite_expires_id),
        }
      });

      key.controls['display_invite_expires'].setValue(
        dayjs(e.invite_expires_id).format('MMM DD, YYYY')
      );
    } else {
      key.controls['invite_expires'].setValue({
        ...key.controls['invite_expires'].value,
        value: undefined,
      });

      key.controls['display_invite_expires'].setValue(null);
    }
  }

  onInviteExpiryTimeSelect(e: any) {
    this.f.invite_expiry_time.setValue(e);
    this.f.invite_expiry_time.markAsTouched();
    if (e) {
      this.timepickerConfig = { hour: parseInt(e.split(':')[0]), minute: parseInt(e.split(':')[1]) };
      const expiryDate = this.datePipe.transform(this.scheduleInviteForm.controls['invite_expires'].value?.value?.startDate, this.localDateFormat);
      this.validateExpiryTime(expiryDate ? expiryDate : '')
    }
  }

  // If interview mode is Video, interview location is not mandatory
  // Else interview location is mandatory
  onUpdateInterviewMode() {
    if (this.f.mode.value === 'V') {
      this.f.location.clearValidators();
    } else {
      this.f.location.setValidators([Validators.required]);
    }
    this.f.location.updateValueAndValidity();
  }

  customSearchFn(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item, 'full_name');
  }

  // check if selected date and time is not a past datetime
  isTimeInvalid() {
    const expiryDate = this.datePipe.transform(this.scheduleInviteForm.controls['invite_expires'].value?.value?.startDate, this.localDateFormat);
    let isValid = this.validateExpiryTime(expiryDate!)
    return !isValid
  }

  validateExpiryTime(expiryDate: string) {
    let isValid = true;
    const date = new Date();
    const today = this.datePipe.transform(date, this.localDateFormat)!;
    const timeNow = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    const timeControl = this.scheduleInviteForm.controls['invite_expiry_time'];

    if (expiryDate === today && timeControl.value <= timeNow) {
      timeControl.setValidators([Validators.required, this.customValidatorService.minimumTime(timeNow)])
      isValid = false;
    }
    else {
      timeControl.clearValidators();
      timeControl.setValidators([Validators.required]);
      isValid = true;
    }
    timeControl.updateValueAndValidity();
    return isValid;
  }

  isInterviewersCountValid() {
    this.validateInterviewersCount();
    return this.selectedInterviewersDetails.length <= 10 ? true : false
  }

  // construct request object for check availability API
  constructRequestObjectForAvailabilityCheck() {
    const formData = { ...this.scheduleInviteForm.value };

    const availabilityObj = {
      interviewers: this.selectedInterviewersDetails.map((item: any) => item.id),
      duration: formData.duration,
      available_from: this.datePipe.transform(formData.availability_from.value.startDate, this.localDateFormat),
      available_to: this.datePipe.transform(formData.availability_to.value.startDate, this.localDateFormat),
    }

    return { availability: availabilityObj };
  }

  // construct request object for schedule invite API
  constructRequestObjectForScheduleInvite() {
    const formData = { ...this.scheduleInviteForm.value };

    const expiryDate = this.datePipe.transform(formData.invite_expires.value.startDate, this.localDateFormat)!;
    const expiryDateTime = (expiryDate + ' ' + formData.invite_expiry_time).replace(/-/g, "/") // Replace fn is used as fix for Safari returning invalid date on new Date() conversion

    const requestObject = {
      ...formData,
      candidate: this.candidateId,
      availability_from: this.datePipe.transform(formData.availability_from.value.startDate, this.localDateFormat),
      availability_to: this.datePipe.transform(formData.availability_to.value.startDate, this.localDateFormat),
      expired_on: new Date(expiryDateTime).toISOString(),
      interviewers: this.selectedInterviewersDetails.map((item: any) => item.id)
    };

    ['display_availability_from',
      'display_availability_to',
      'display_invite_expires',
      'invite_expires',
      'invite_expiry_time'
    ].forEach(e => delete requestObject[e]);

    return { schedule: requestObject };
  }

  availabilityApi(getAvailabilityData: boolean = false) {
    const { availability }: any = this.constructRequestObjectForAvailabilityCheck();
    this.scheduleInviteFormService.checkAvailability(availability).subscribe(
      (response: any) => {
        if (response?.status === 200) {
          if (getAvailabilityData) {
            this.availabilityData = response.data;

            this.openAvailabiltyModal();
          } else {
            if (response?.Total_available_count >= 5) {
              this.handleEnoughAvailability();
            } else {
              this.handleLowAvailability();
            }
          }
        } else {
          this.toaster.error(response.message);
        }
      },
      () => {
        this.toaster.error(`Something went wrong`);
      }
    )
  }

  checkAvailability() {
    if (!this.scheduleInviteForm.valid || this.isTimeInvalid() || !this.isInterviewersCountValid()) {
      this.scheduleInviteForm.markAllAsTouched();
      return;
    }
    this.availabilityApi();
  }

  handleLowAvailability() {
    this.warningPopupData = {
      title: 'Low Availability',
      isWarning: true,
      warningDesc: `Total available slots for the selected timeframe is <b>less than 5</b></div>`,
      description: 'Please try with other interviewer(s) or try a different availability period',
      closeBtn: 'CLOSE'
    }
    this.openWarningModal();
  }

  handleEnoughAvailability() {
    this.confirmScheduleInvite();
  }

  // open confirmation modal
  confirmScheduleInvite() {
    this.confirmationTitle = 'Send Schedule Invite';
    this.confirmationMessage =
      'You are about to send the Schedule Invite. Are you sure you want to continue?';

    this.modalService.open(this.confirmRequest, { centered: true, backdrop: 'static', keyboard: false, }).result.then(
      (result) => {
        if (this.isEditMode) {
          this.updateScheduleInvite();
        } else {
          this.sendScheduleInvite()
        }
      },
      (reason) => {
        return;
      }
    );
  }

  sendScheduleInvite() {
    const { schedule }: any = this.constructRequestObjectForScheduleInvite();
    this.subscriptions.push(this.scheduleInviteFormService.scheduleInvite(schedule).subscribe(
      (res: any) => {
        if (res?.status === 200) {
          this.createSuccessHandler();
        } else {
          this.toaster.error(`Could not send Schedule Invite. Please try again`)
        }
        this.modalService.dismissAll();
      },
      () => {
        this.toaster.error(`Could not send Schedule Invite. Please try again`);
      }
    ))
  }

  updateScheduleInvite() {
    const { schedule }: any = this.constructRequestObjectForScheduleInvite();
    this.subscriptions.push(this.scheduleInviteFormService.updateScheduleInvite(this.scheduledData.id, schedule).subscribe(
      (res: any) => {
        if (res?.status === 200) {
          this.updateSuccessHandler();
        } else {
          this.toaster.error(`Could not update the Schedule Invite. Please try again`)
        }
        this.modalService.dismissAll();
      },
      () => {
        this.toaster.error(`Could not update the Schedule Invite. Please try again`);
      }
    ))
  }

  createSuccessHandler() {
    this.toaster.success(`Schedule Invite has been sent successfully`);
    this.globalStore.setCurrentPageDetails(PAGE.CANDIDATEDETAILS, { activeTab: 2 });
    this.router.navigate([`/candidates/${this.candidateId}/view`]);
  }

  updateSuccessHandler() {
    this.toaster.success(`Schedule Invite has been updated successfully`);
    if(this.editFrom === 'candidate-detail') {
      this.globalStore.setCurrentPageDetails(PAGE.CANDIDATEDETAILS, { activeTab: 2 });
      this.router.navigate([`/candidates/${this.candidateId}/view`]);
    } else {
      this.router.navigate([`/interviews/schedule-invites/list`]);
    }
  }

  onSave() {
    // check for availabilty first and if enough availability is there, invoke schedule API
    this.checkAvailability();
  }

  onCancel() {
    if (this.isEditMode) {
      if(this.editFrom === 'candidate-detail') {
        this.globalStore.setCurrentPageDetails(PAGE.CANDIDATEDETAILS, { activeTab: 2 });
        this.router.navigate([`/candidates/${this.candidateId}/view`]);
      } else {
        this.router.navigate([`/interviews/schedule-invites/list`]);
      }
    } else {
      this.router.navigate([`/candidates/${this.candidateId}/view`]);
    }
  }

  // creates interviewers array with both filtered set and all list
  setInterviewersListForDropdown() {
    const { stream, level, location } = this.scheduleInviteForm.value;
    this.allInterviewers = this.allInterviewers.map((item: any) => (
      {
        ...item,
        group: 'all'
      }
    ))
    if (stream || level) {
      this.interviewersFiltered = this.interviewersListBackup.filter(
        (interviewer: any) => {
          const interviewerStream = interviewer.stream.map((item: any) => item.id);
          const interviewerLevel = interviewer.interview_level_id;
          const interviewerLoc = interviewer.interview_location_id;

          return (stream ? interviewerStream.includes(stream) : true) &&
            (level ? level === interviewerLevel : true) &&
            (location ? location === interviewerLoc : true)
        }
      );
      this.interviewersFiltered = this.interviewersFiltered.map((item: any) => (
        {
          ...item,
          group: 'filtered'
        }
      ))
      this.interviewersNonMatched = this.allInterviewers.filter((item: any) => !this.interviewersFiltered.map((ele: any) => ele.id).includes(item.id))
      this.interviewersList = [...this.interviewersFiltered, ...this.interviewersNonMatched];
    } else {
      this.interviewersList = [...this.allInterviewers];
    }
  }

  groupByFn = (item: any) => item.group;

  groupValueFn = (_: string, children: any[]) => ({ group: children[0].group });

  onInterviewerSelect(event: any) {
    if (event) {
      this.setInterviewerSelection();
    }
  }

  onInterviewerUnSelect(event: any) {
    if (event?.value) {
      let itemId: any = event?.value?.id

      this.selectedInterviewersIds = this.selectedInterviewersIds.filter((item: any) => item !== itemId)
      this.setInterviewerSelection();
    }
  }

  onRemoveSelectedInterviewer(user: any) {
    if (user?.id) {
      let itemId: any = user?.id

      this.selectedInterviewersIds = this.selectedInterviewersIds.filter((item: any) => item !== itemId)
      this.setInterviewerSelection();
    }
  }

  setInterviewerSelection() {
    this.scheduleInviteForm.controls['interviewers'].markAsTouched();
    this.scheduleInviteForm.controls['interviewers'].setValue(this.selectedInterviewersIds);

    this.selectedInterviewersDetails = this.interviewersList.filter((user: any) => this.selectedInterviewersIds.includes(user?.id));

    this.updateOtherInterviewers();
    this.validateInterviewersCount();

    setTimeout(() => {
      this.retainPlaceHolderForInterviewers();
    }, 0);

  }

  onInterviewerDropdownOpen() {
    this.utilService.dropdownScrollToTop('.multi-select-interviewers-dropdown');
  }

  onInterviewersDropdownClose() {
    this.validateInterviewersCount();
  }

  interviewersValidator(valid: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == []) {
        return null;
      }

      return valid ? null : { 'invalidInterviewersCount': true }
    };
  }

  validateInterviewersCount() {
    const isInterviewersCountValid = this.selectedInterviewersDetails.length <= 10 ? true : false;
    if (isInterviewersCountValid) {
      this.scheduleInviteForm.controls['interviewers'].setValidators([Validators.required]);
    } else {
      this.scheduleInviteForm.controls['interviewers'].setValidators([Validators.required, this.interviewersValidator(isInterviewersCountValid)]);
    }
    this.scheduleInviteForm.controls['interviewers'].updateValueAndValidity();
  }

  updateOtherInterviewers() {
    this.otherInterviewersList = this.interviewersListBackup.filter((item: any) => !this.selectedInterviewersIds.includes(item.id));
    this.selectedOtherInterviewersList = this.selectedOtherInterviewersList.filter((item: any) => !this.selectedInterviewersIds.includes(item));
    this.selectedOtherInterviewersDetails = this.selectedOtherInterviewersDetails.filter((item: any) => !this.selectedInterviewersIds.includes(item.id));
    this.scheduleInviteForm.controls['other_interviewers'].setValue(this.selectedOtherInterviewersList);
  }


  retainPlaceHolderForInterviewers() {
    let multiSelectClass = '.multi-select-interviewers-dropdown .ng-select .ng-select-container';
    const multiSelect = document.querySelector(multiSelectClass) as any;
    multiSelect.classList.remove("ng-has-value");
  }

  retainPlaceHolderForOtherInterviewers() {
    let multiSelectClass = '.multi-select-dropdown .ng-select .ng-select-container';
    const multiSelect = document.querySelector(multiSelectClass) as any;
    multiSelect.classList.remove("ng-has-value");
  }

  onOtherInterviewerSelection(event: any) {
    if (event) {
      this.selectedOtherInterviewersDetails = this.interviewersListBackup.filter((user: any) => this.selectedOtherInterviewersList.includes(user?.id));
      this.scheduleInviteForm.controls['other_interviewers'].setValue(this.selectedOtherInterviewersList);
      setTimeout(() => {
        this.retainPlaceHolderForOtherInterviewers();
      }, 0);

    }
  }

  onRemoveOtherInterviewer(user: any) {
    this.selectedOtherInterviewersDetails = this.selectedOtherInterviewersDetails.filter((item: any) => item?.id !== user?.id);
    this.selectedOtherInterviewersList = this.selectedOtherInterviewersList.filter((item: any) => item !== user?.id);
    this.scheduleInviteForm.controls['other_interviewers'].setValue(this.selectedOtherInterviewersList);
  }

  onOtherInterviewerDropdownOpen() {
    this.utilService.dropdownScrollToTop('.multi-select-dropdown');
  }

  // open warning modal if low availability
  openWarningModal() {
    let modalConf: any = {
      size: 'md',
      centered: true,
      backdrop: 'static',
    };

    this.modalService.open(this.warningPopup, modalConf).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = reason;
      }
    );
  }

  closeWarningPopup() {
    this.modalService.dismissAll();
  }

  getAvailabilityData() {
    const { display_availability_from, display_availability_to, duration, interviewers } = this.scheduleInviteForm.value;
    if (!display_availability_from || !display_availability_to || !duration || !interviewers || this.selectedInterviewersDetails.length > 10) {
      return;
    }
    this.availabilityApi(true);
  }

  openAvailabiltyModal() {
    let modalConf: any = {
      size: 'lg',
      centered: true,
      backdrop : 'static',
      windowClass: 'availability-modal-width'
    };

    this.modalService.open(this.availabilityModal, modalConf).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = reason;
      }
    );
  }

  detectChanges() {
    setTimeout(() => {
      this.subscriptions.push(
        this.scheduleInviteForm.valueChanges.subscribe((data: any) => {
          this.scheduleDisabled = false;
        })
      )
    }, 100);
  }

  toggleCheckBox(formControlName: string){
    let value = this.scheduleInviteForm.controls[formControlName].value;
    this.scheduleInviteForm.controls[formControlName].setValue(!value);
  }

  onUpdateInterviewLevel(){
    if (this.f.level.value === 'M') {
      this.f.show_previous_interview.setValue(true);
    } else {
      this.f.show_previous_interview.setValue(false);
    }
  }
}

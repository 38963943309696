import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { regex } from 'src/app/shared/_services/regex';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppService } from 'src/app/app.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { environment } from 'src/environments/environment';
import { UtilService } from '../../_services/util.service';
import { Role } from '../../_models/role';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss'],
})
export class EmailPopupComponent implements OnInit, OnDestroy {
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() sendData: EventEmitter<any> = new EventEmitter();
  @Input() popUpContents: any;
  @Input() hasMultipleCcAndBcc: boolean = false;
  @Input() emailUserType: string = '';
  @Input() emailBtnName: string = 'SEND';
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;
  @Input() showBCCListByDefault: boolean = false;
  @Input() canRemoveCC: boolean = true;
  @Input() canRemoveBCC: boolean = true;
  @Input() canRemoveTo: boolean = true;
  @ViewChild('ccSelectRef') ccSelectRef !: any;
  @ViewChild('bccSelectRef') bccSelectRef !: any;
  @ViewChild('toSelectRef') toSelectRef !: any;
  

  config: AngularEditorConfig = {};
  showBccList: boolean = false;
  emailForm!: FormGroup;
  isValidContent: boolean = true;
  ccList: any[] = [];
  bccList: any[] = [];
  emailList: any[] = [];
  subscriptions: any = [];
  defaultValue: any = [];
  isDesktop: boolean = false;
  infoMessage: string = 'Applying formats to the email content may look different with varying email clients or devices. Please consider them when you update the content.'
  apiGeneratedFiles: any = [];
  userSelectedFiles: any = [];
  fileError: string = '';
  fileCount: number = 0;
  resetFile!: string | null;
  extraEmailList: any;
  showEmailError: boolean = false;
  toList: any[] = [];
  defaultCCList: any[] = [];
  defaultBCCList: any[] = [];
  defaultToList: any[] = [];
  isValidToUser: boolean = true;
  errorPlaceHolder: string = '';
  isToClearable: boolean = true;
  isCCClearable: boolean = true;
  isBCCClearable: boolean = true;
  ccAlignmentFlag: boolean = false;
  bccAlignmentFlag: boolean = false;
  toAlignmentFlag: boolean = false;
  mode!: string;
  isSubmitted: boolean = false;

  @Input() isEditable: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.setDefaultValue();
    this.checkScreenSize();
    environment.production && this.getDropDownValues();
    if(this.popUpContents?.contentType === "offer_letter"){
      this.mode = "offer";
    }
  }

  getDropDownValues() {
    this.subscriptions.push(this.appService.generalDropdowns.subscribe(
      (values: any) => {
        const { group_emails } = values;
        this.extraEmailList = group_emails?.map((e: any) => { return { ...e, username: '' } });
      }
    ));
  }

  onResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (window.screen.width < 1440) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
    this.setEditorConfig();
  }

  setEditorConfig() {
    this.config = {
      editable: this.isEditable,
      sanitize: false,
      spellcheck: true,
      height: this.isDesktop ? '12rem' : '17rem',
      minHeight: '10rem',
      maxHeight: this.isDesktop ? '12rem' : '17rem',
      placeholder: '',
      translate: 'no',
      toolbarPosition: 'bottom',
      showToolbar: this.isEditable,
      toolbarHiddenButtons: [
        [
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode',
          'fontSize',
        ],
        ['subscript', 'superscript', 'indent', 'outdent', 'fontName'],
      ],
    };
  }

  setDefaultValue() {
    this.emailForm = this.formBuilder.group({
      from: [this.popUpContents?.from, Validators.required],
      to: [this.popUpContents?.to],
      subject: [this.popUpContents?.subject, Validators.required],
      mailBody: [this.popUpContents?.mailContent],
    });

    if (this.showBCCListByDefault) {
      this.showBccList = true;
    }
    if (!this.hasMultipleCcAndBcc) {
      this.emailForm.addControl('cc', new FormControl(this.popUpContents?.cc));
      this.emailForm.addControl(
        'bcc',
        new FormControl('', [Validators.pattern(regex.email)])
      );
    } else if (this.emailUserType == 'spaceUsers') {
      this.defaultCCList = this.popUpContents?.ccList.map((e: string) => {
        return { email: e, username: e };
      });
      this.defaultBCCList = this.popUpContents?.bccList.map((e: string) => {
        return { email: e, username: e };
      });
      const toEmailList: any = Array.isArray(this.popUpContents?.to) ? this.popUpContents?.to : [this.popUpContents?.to];
      this.defaultToList = toEmailList.map((e: string) => {
        return { email: e, username: e };
      });
      this.ccList = [...[].concat.apply([], this.defaultCCList)];
      this.bccList = [...[].concat.apply([], this.defaultBCCList)];
      this.toList = [...[].concat.apply([], this.defaultToList)];
      this.defaultValue = [...this.defaultCCList, ...this.defaultBCCList, ...this.defaultToList];
      (!this.canRemoveCC || !this.canRemoveTo || !this.canRemoveBCC) && setTimeout(() => {
        this.hideCloseButton();
      }, 50);
      this.getSpaceUsersEmail();
    }

    if (this.popUpContents?.attachments?.length > 0) {
      this.apiGeneratedFiles = [...this.popUpContents?.attachments];
      this.apiGeneratedFiles = this.apiGeneratedFiles.map((file: any) => ({...file, name: this.truncateFileName(file.name)}));
    }
  }

  hideCloseButton() {
    if (!this.canRemoveCC) {
      this.isCCClearable = false;
      let ccListStyle =
        '.email-popup .cc-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left';
      let ccListElement = document.querySelectorAll<HTMLElement>(ccListStyle);
      let ccLength = this.popUpContents?.ccList?.length;
      for (let i = 0; i < ccLength; i++) {
        ccListElement[i].style.display = 'none';
      }
    }
    if (!this.canRemoveBCC) {
      this.isBCCClearable = false;
      let bccListStyle = '.email-popup .bcc-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left';
      let bccListElement = document.querySelectorAll<HTMLElement>(bccListStyle);
      let bccLength = this.popUpContents?.bccList?.length;
      for (let i = 0; i < bccLength; i++) {
        bccListElement[i].style.display = 'none';
      }
    }
    if (!this.canRemoveTo) {
      this.isToClearable = false;
      let toListStyle =
        '.email-popup .to-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left';
      let toListElement = document.querySelectorAll<HTMLElement>(toListStyle);
      let toLength = this.defaultToList.length;
      for (let i = 0; i < toLength; i++) {
        toListElement[i].style.display = 'none';
      }
    }

  }

  onSend() {
    this.isSubmitted = true;
    this.emailForm.markAllAsTouched();
    this.isValidContent = true;
    let subject = this.emailForm.value.subject.trim();
    this.emailForm.controls['subject'].patchValue(subject);

    if (!this.validateTextEditorContents(this.emailForm.value.mailBody))
      this.isValidContent = false;
    const toList: any = this.setEmailList(this.toList);
    this.isValidToUser = toList?.length > 0 ? true : false;
    this.errorPlaceHolder = this.isValidToUser ? '' : 'Please specify at least one recipient';

    let validDocuments = true;
    if (['approve & send offer letter', 'send offer letter', 'send for approval','approve offer'].includes(this.popUpContents?.title.toLowerCase())) {
      validDocuments = this.hasValidDocuments();
    }
    if (!this.emailForm.invalid && this.isValidContent && validDocuments && this.isValidToUser) {
      let result: any = {
        email_subject: this.emailForm.value.subject,
        email_body: this.emailForm.value.mailBody,
        from_email: this.emailForm.value.from,
      };
      result['to_email'] = toList;
      result['cc_email'] = this.hasMultipleCcAndBcc
        ? this.setEmailList(this.ccList) : [this.emailForm.value.cc];

      result['bcc_email'] = this.hasMultipleCcAndBcc
        ? this.setEmailList(this.bccList) : [this.emailForm.value.bcc];

      if (this.popUpContents?.candidateId) {
        result['candidateId'] = this.popUpContents?.candidateId;
      }
      result['contentType'] = this.popUpContents?.contentType || null;
      if (['send offer letter', 'approve offer', 'send for approval', 'approve & send offer letter'].includes(this.popUpContents?.title.toLowerCase())) {
        result['user_selected_files'] = this.userSelectedFiles;
        result['api_generated_files'] = this.apiGeneratedFiles;
      }
      this.sendData.emit(result);
    }
  }

  setEmailList(emailList: any) {
    let finalList: any = [];
    if (emailList?.length > 0) {
      if (emailList[0]?.email) {
        finalList = emailList.map((item: any) => item?.email)
      } else {
        finalList = [...emailList]
      }
    }
    return finalList;
  }


  hideErroMessage() {
    this.isValidContent = true;
  }

  validateTextEditorContents(htmlContent: string): boolean {
    //check for valid text content inside html tag
    let isValidContent = false;
    let parsedContent = htmlContent.replace(regex.htmlTagRegex, '');
    parsedContent = parsedContent.replace(regex.nbspRegex, '');
    if (parsedContent.trim().length) isValidContent = true;
    return isValidContent;
  }

  onCancel() {
    this.resetFile = null;
    this.closePopup.emit();
  }
  get f() {
    return this.emailForm.controls;
  }

  toggleBccList() {
    this.showBccList = !this.showBccList;
  }

  compareFn(a: any, b: any) {
    return a?.username === b?.username || a?.email === b?.email;
  }

  getSpaceUsersEmail() {
    this.subscriptions.push(
      this.appService.spaceUsers.subscribe(
        (response: any) => {
          if (response) {
            this.emailList = response;
            if (this.extraEmailList?.length > 0 && environment.production) {
              this.emailList.push(...this.extraEmailList);
            }
            if (this.popUpContents?.extented_list.length > 0) {
              let allList = [
                ...this.emailList,
                ...this.popUpContents?.extented_list,
              ];
              this.emailList = [...allList];
            }
            /* 
              Duplicate check can be removed and the set the ngModel value after setting the whole list to be 
              filtered from the select
            */
            this.ccList = [...this.ccList];
            this.bccList = [...this.bccList];
          }
        },
        (error) => {
          console.log(error)
        }
      )
    );
  }

  scrollToBottom(listName: string, event: any) {
    let classname: string = '';
    if (listName == 'cc') {
      this.isCCClearable = true;
      classname = '.email-popup .cc-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container';
    } else if (listName == 'to') {
      this.isToClearable = true;
      classname = '.email-popup .to-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container';
      this.isValidToUser = true;
    } else {
      this.isBCCClearable = true;
      classname = '.email-popup .bcc-list .ng-select.ng-select-multiple .ng-select-container .ng-value-container';
    }
    let ccListElement = document.querySelectorAll<HTMLElement>(classname);
    if (ccListElement.length > 0) {
      setTimeout(() => {
        ccListElement[0].scrollTop = ccListElement[0].scrollHeight;
        ccListElement[0].style.scrollBehavior = 'smooth';
      }, 100);
    }
  }

  customSearchFn(term: string, item: any) {
    return this.utilService.customEmailAndUserNameSearch(term, item);
  }

  addTagHandler(email: any) {
    if (!this.showEmailError) {
      return ({ email: email });
    }
    return false;
  }


  checkRemovedItem(field: string) {
    if (field === 'to') {
      this.updateToClearableFlag();
    } else if (field === 'cc') {
      this.updateCCClearableFlag();
    } else if (field === 'bcc') {
      this.updateBCCClearableFlag();
    }

  }
  updateToClearableFlag() {
    if (!this.canRemoveTo) {
      if (this.defaultToList.length == this.toList?.length) {
        this.isToClearable = false;
      } else {
        this.isToClearable = true;
      }
    } else {
      this.isToClearable = true;
      if (this.toList?.length == 0) {
        this.isValidToUser = false;
      } else {
        this.isValidToUser = true;
      }

      this.errorPlaceHolder = this.isValidToUser ? '' : 'Please specify at least one recipient';
    }
  }

  updateCCClearableFlag() {
    if (!this.canRemoveCC) {
      if (this.popUpContents?.ccList?.length == this.ccList?.length) {
        this.isCCClearable = false;
      } else {
        this.isCCClearable = true;
      }
    } else {
      this.isCCClearable = true;
    }
  }

  updateBCCClearableFlag() {
    if (!this.canRemoveBCC) {
      if (this.popUpContents?.bccList?.length == this.bccList?.length) {
        this.isBCCClearable = false;
      } else {
        this.isBCCClearable = true;
      }
    } else {
      this.isBCCClearable = true;
    }
  }

  //file handling for send offer letter documents 
  fileHandler(event: any) {
    this.fileError = '';
    if (event?.id) {
      const userFile = {
        id: event?.id,
        existing_qrms_file_key: event?.existing_qrms_file_key,
        name: this.truncateFileName(event?.name),
        link: event?.link
      }
      this.userSelectedFiles.push(userFile);
      this.resetFile = event;
      this.resetFileOnUpload();
    }
  }

  resetFileOnUpload() {
    if (this.fileUploadComponent.InputVar?.nativeElement) {
      this.fileUploadComponent.InputVar.nativeElement.value = '';
    }
    this.fileUploadComponent.file = undefined;
    this.fileUploadComponent.file_id = undefined;
    this.fileUploadComponent.fileName = '';
    this.fileUploadComponent.fileLink = '';
  }

  removeDefaultFile(item: any) {
    this.apiGeneratedFiles = this.apiGeneratedFiles.filter((element: any) => item.id !== element.id);
    this.hasFileCountError();
  }

  removeUserFile(item: any) {
    this.userSelectedFiles = this.userSelectedFiles.filter((element: any) => item.id !== element.id);
    this.fileUploadComponent.removeFile();
    this.hasFileCountError();
  }

  hasValidDocumentCount() {
    this.fileCount = this.apiGeneratedFiles.length + this.userSelectedFiles.length;
    return (this.fileCount <= Number(this.popUpContents?.attachmentCount) && this.fileCount > 0);
  }

  disableUpload() {
    this.fileCount = this.apiGeneratedFiles.length + this.userSelectedFiles.length;
    return (this.fileCount == Number(this.popUpContents?.attachmentCount));
  }

  download(fileLink: string) {
    window.open(fileLink, '_blank');
  }

  hasValidDocuments() {
    let isValid = true;
    if (this.fileUploadComponent.fileSizeError || this.fileUploadComponent.fileTypeError || !this.hasValidDocumentCount()) {
      isValid = false;
    }
    return isValid;
  }

  hasFileCountError() {
    if (!this.hasValidDocumentCount()) {
      this.fileUploadComponent.fileSizeError = false;
      this.fileUploadComponent.fileTypeError = false;
      this.fileError = 'Please attach the offer letter related document(s) to be sent to the candidate.';
      this.fileUploadComponent.file_id = undefined;
    }
  }

  truncateFileName(fileName: string) {
    if (fileName?.length > 76) {
      fileName =
        fileName.slice(0, 65) +
        '...' +
        fileName.slice(fileName.length - 8, fileName.length);
    }
    return fileName;
  }

  selectFieldKeyupHandler(event: any, selectRef: any) {
    if (selectRef.itemsList._filteredItems.length === 0) {
      selectRef.addTag = this.addTagHandler.bind(this);
      if (!this.validateEmail(event.target.value)) {
        selectRef.addTagText = '';
        this.showEmailError = true;
        return;
      } else {
        selectRef.addTagText = 'Add Email';
        this.showEmailError = false;
      }
    } else {
      this.showEmailError = false;
      selectRef.addTag = null;
    }
  }

  validateEmail(email: string, emailDomain: string = 'qburst.com') {
    const regularExpression = regex.email;
    if (regularExpression.test(String(email).toLowerCase())) {
      let splitTerm = email.split('@');
      return (splitTerm[1] === emailDomain)
    }
    return false;
  }


  isArray(arr: []) {
    return Array.isArray(arr);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((item: any) => {
      item.unsubscribe();
    });
  }
}

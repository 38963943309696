import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
  @Input() id!: number;
  private _rating: number = 0;
  @Input() readonly: boolean = false;
  @Output() rateChange: EventEmitter<any> = new EventEmitter;
  @Input() customClass: string = '';
  values: Array<object> = [
    {
      value: 0.5,
      label: 'Bad'
    },
    {
      value: 1,
      label: 'Bad'
    },
    {
      value: 1.5,
      label: 'Poor'
    },
    {
      value: 2,
      label: 'Poor'
    },
    {
      value: 2.5,
      label: 'Regular'
    },
    {
      value: 3,
      label: 'Regular'
    },
    {
      value: 3.5,
      label: 'Good'
    },
    {
      value: 4,
      label: 'Good'
    },
    {
      value: 4.5,
      label: 'Exceptional'
    },
    {
      value: 5,
      label: 'Exceptional'
    },
  ];

  @Input() set rating (r: number) {
    this._rating = (r === null) ? 0 : r;
  }
  get rating(): number {
    return this._rating;
  }

  handleClick(e: any) {
    const rating = e ?.target.value;
    this.rateChange.emit(rating);
  }

}


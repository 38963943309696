<ng-template #content let-modal>
  <div class="rating-modal-div" [ngClass]="readMode ? 'details-div' : ''">
    <div class="modal-header">
      <h4 *ngIf="!readMode" class="modal-title">Rating</h4>
      <h4 *ngIf="readMode" class="modal-title">Rating Details</h4>
    </div>
    <div class="modal-body">
      <form class="row" [formGroup]="ratingForm">
        <div class="d-flex rating-main-wrapper">
          <div class="rating-detail-wrapper">
            <div class="col-md-12 form-group">
              <div class="row" *ngIf="interviewLevel === 'T'">
                <div class="custom-col-label pe-0 mt-n-1"> <span class="label">Technical/Job Knowledge</span> </div>
                <div class="col-auto pe-0">
                  <app-star-rating [id]="interviewId + '1'" [rating]="ratingForm.value.technical"
                    (rateChange)="changeValue('technical', $event)" [readonly]="readMode"
                    [customClass]="'interview-rating-page'"> </app-star-rating>
                </div>
              </div>
              <div class="row mt-3" *ngIf="interviewLevel === 'T'">
                <div class="custom-col-label pe-0 mt-n-1"> <span class="label">Logical Reasoning</span> </div>
                <div class="col-auto pe-0">
                  <app-star-rating [id]="interviewId + '2'" [rating]="ratingForm.value.logical"
                    (rateChange)="changeValue('logical', $event)" [readonly]="readMode"
                    [customClass]="'interview-rating-page'"> </app-star-rating>
                </div>
              </div>
              <div class="row mt-3" *ngIf="['T', 'H'].includes(interviewLevel)">
                <div class="custom-col-label pe-0 mt-n-1"> <span class="label"
                    *ngIf="interviewLevel === 'T'">Communication</span> <span class="label"
                    *ngIf="interviewLevel === 'H'">Language Skills</span> </div>
                <div class="col-auto pe-0">
                  <app-star-rating [id]="interviewId + '3'" [rating]="ratingForm.value.communication"
                    (rateChange)="changeValue('communication', $event)" [readonly]="readMode"
                    [customClass]="'interview-rating-page'"></app-star-rating> <small
                    *ngIf="r.communication.touched && r.communication.errors"
                    class="alert alert-error ps-2">Required</small>
                </div>
              </div>
              <div class="row mt-3" *ngIf="interviewLevel === 'T'">
                <div class="custom-col-label pe-0 mt-n-1"> <span class="label">Attitude Rating</span> </div>
                <div class="col-auto pe-0">
                  <app-star-rating [id]="interviewId + '4'" [rating]="ratingForm.value.attitude"
                    (rateChange)="changeValue('attitude', $event)" [readonly]="readMode"
                    [customClass]="'interview-rating-page'"> </app-star-rating>
                </div>
              </div>
              <div class="row mt-3" *ngIf="['T', 'M'].includes(interviewLevel)">
                <div class="custom-col-label pe-0 mt-n-1"> <span class="label">Overall Rating</span> </div>
                <div class="col-auto pe-0">
                  <app-star-rating [id]="interviewId + '5'" [rating]="ratingForm.value.overall"
                    (rateChange)="changeValue('overall', $event)" [readonly]="readMode"
                    [customClass]="'interview-rating-page'"> </app-star-rating> <small
                    *ngIf="r.overall.touched && r.overall.errors" class="alert alert-error ps-2">Required</small>
                </div>
              </div>
              <div class="row mt-3" *ngIf="interviewLevel !== 'H'">
                <div class="custom-col-label pe-0 mb-2"> <span class="label"
                    *ngIf="interviewLevel === 'T'">Recommendation</span> <span class="label"
                    *ngIf="interviewLevel === 'M'">Hiring Decision</span> </div>
                <ng-container *ngIf="readMode">
                  <div class="col-sm-5 px-0 readmode-values"> <span
                      class="label-dark">{{recommendations[ratingForm.value.recommendation]}}</span> </div>
                </ng-container>
                <ng-container *ngIf="!readMode">
                  <div class="col-auto pe-0 ps-1 mt-1 ms-1"> <span *ngFor="let id of recommendationIds; let i = index">
                      <div class="col-auto recommendation-values"
                        [ngClass]="i === recommendationIds.length - 1 ? 'mb-0' : ''"> <input type="radio"
                          class="radio-small cursor-pointer" [value]="id" [id]="id" name="rec"
                          [checked]="id === r.recommendation.value" (change)="handleRadioSelect(id)" /> <span
                          class="radio-text cursor-pointer"
                          (click)="handleRadioSelect(id)">{{recommendations[id]}}</span> </div>
                    </span> <small *ngIf="r.recommendation.touched && r.recommendation.errors"
                      class="recommendation-values alert alert-error">Required</small> </div>
                </ng-container>
              </div>

              <div class="row mt-3">
                <div class="custom-col-label pe-0 mt-n-1 attachment-margin">
                  <span class="label">
                    Candidate Photo
                    <span class="optional-field" *ngIf="!readMode && (!mandateCandidatePhoto || interviewMode !== 'V')"> (Optional)</span>
                  </span>
                  <span class="label attachment-text" *ngIf="!readMode">Upload images of the candidate during the interview</span>
                </div>
                <div *ngIf="!readMode || attachmentData?.link" class="col-auto pe-0"
                  [ngClass]="readMode? 'readmode-values ps-0' : ''">
                  <file-upload #attachmentUpload btnName="" type="image" size="5" fileType="PH" [fetchPrevFile]="false"
                    (fileChanged)="attachmentHandler($event)" [isHintInRow]="false" [resumeData]="attachmentData" [tabIndex]="32"
                    [filePreviewOnly]="readMode ? true : false" [mode]="'interview'" [validationMessage]="photoValidationMessage">
                  </file-upload>
                </div>
                <div *ngIf="readMode && !attachmentData?.link" class="col-auto pe-0 readmode-values ps-0">-</div>
              </div>

            </div>
          </div>
          <div *ngIf="!readMode" class="feedback-wrapper">
             <div *ngIf="!readMode" class="partition-border" [ngClass]="interviewLevel === 'T' ? 'partition-technical':'partition-hr-mgmt'"></div>
            <div class="label  mb-2">Comments</div>
            <angular-editor formControlName="comment" [config]="config" (focus)="hideErroMessage()"
              [placeholder]="interviewLevel === 'T' && !readMode ? 'Minimum 200 Characters' : ''"> </angular-editor>
            <div><small class="char-count">Character Count: {{ ratingForm?.value.comment | editorContentLength }}</small></div>
            <div [ngClass]="!readMode && !isValidComment ? 'visible':'invisible'" class="alert alert-error"> <small
                class="alert alert-error"><span *ngIf="interviewLevel === 'T' ">Minimum 200 characters required</span>
                <span *ngIf="['M','H'].includes(interviewLevel)">Required</span></small> </div>
          </div>
        </div>
        <div *ngIf="!isSubmitAllowed" class="text-center"> <small class="alert alert-error">Minimum overall rating
            should be 3 for hiring/recommending for next round</small> </div>
      </form>
    </div>
    <div class="col-sm-12 text-center mb-4 resume-btns mt-0">
      <ng-container *ngIf="!readMode"> <button type="button" class="reset-btn btn btn-outline-secondary mx-3"
          aria-label="Close" (click)="modal.dismiss('Cross click'); resetForm()">CANCEL</button> <button type="button"
          class="save-btn btn btn-primary mx-3" (click)="saveRating()">SAVE</button> </ng-container>
      <ng-container *ngIf="readMode"> <button type="button" class="save-btn btn btn-primary mx-3" aria-label="Close"
          (click)="modal.dismiss('Cross click')">CLOSE</button> </ng-container>
    </div>
  </div>
</ng-template>
<button *ngIf="!readMode" (click)="provideRatingDisabled ? '' : open(content)"
  class="btn black-btn secondary-btn m-t me-1 pb-0"
  [ngClass]="{'provide-rating-secondary-design': useSecondaryButton}">PROVIDE RATING</button><button *ngIf="readMode"
  (click)="open(content)" class="btn black-btn">RATING DETAILS</button>
import { Directive, ElementRef, HostListener, Input} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[ngSelectHandler]'
})

export class NgSelectHandlerDirective {

  constructor(private _elementRef: ElementRef) {
  }
  selectedValue:any;
  selectedItem:any
  @Input('handler_input') handler_input!: NgSelectComponent
  @Input('isRequired') isRequired!: NgSelectComponent
  @Input('isEditable') isEditable!: NgSelectComponent
   isValueCleared =false;

  @HostListener('click', ['$event']) onClick(targetElement: any) {
    this.isValueCleared = true
    if (this.handler_input.isOpen) {
      this._elementRef.nativeElement.classList.add('ng-select-searchable');
    }
    if (this.handler_input.isOpen && this.handler_input.selectedItems?.length && !this.isEditable) {
      this.selectedValue = this.handler_input.selectedValues[0];
      this.selectedItem = this.handler_input.selectedItems[0];
      this.handler_input.selectedItems.splice(0, this.handler_input.selectedItems?.length);
      this.handler_input.selectedValues.splice(0, this.handler_input.selectedValues?.length);

    }
    if (!this.handler_input.isOpen) {
      if (!this.isRequired && !this.handler_input.selectedValues?.length && this.selectedValue && this.selectedItem) {
        this.handler_input.selectedValues.push(this.selectedValue);
        this.handler_input.selectedItems.push(this.selectedItem);
      }
      this.handler_input.blur();
    }
  }
  @HostListener('blur', ['$event']) onBlur(targetElement: any) {
    if (!this.handler_input.selectedValues?.length &&
      this.selectedValue && this.selectedItem && this.isRequired) {
      this.handler_input.selectedValues.push(this.selectedValue);
      this.handler_input.selectedItems.push(this.selectedItem);
    } else if (!this.handler_input.selectedValues?.length && !this.isRequired && this.selectedValue && this.selectedItem) {
      if (this.isValueCleared) {
        this.handler_input.selectedValues.push(this.selectedValue);
        this.handler_input.selectedItems.push(this.selectedItem);
        this.handler_input.handleClearClick();
      }
      this._elementRef.nativeElement.classList.remove('ng-select-focused');
      this._elementRef.nativeElement.classList.remove('ng-select-searchable');
    }
    this.isValueCleared = false
  }
}

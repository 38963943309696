import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss']
})
export class SectionLoaderComponent {

  isLoading: Observable<boolean> = this.loaderService.isLoading.pipe(delay(0)); //given delay to avoid infinite loading

  constructor(private loaderService: LoaderService) { }

}

<div class="confirmation-popup-div">
  <div class="modal-body">
    <div *ngIf="popupData?.isWarning" class="text-center mb-1 mt-2">
      <img src="./assets/images/warning.svg" alt="warning" />
    </div>
    <div class="heading" [ngClass]="popupData?.isWarning ? 'warning-title text-center mb-1' : ''">{{popupData.title}}</div>
    
    <div class="body text-center mb-4 " [ngClass]="popupData.isWarning ? 'warning-desc' : ''">
      <div *ngIf="popupData.warningDesc" class="body text-center" [ngClass]="popupData.isWarning ? 'warning-desc' : ''"
        [innerHTML]="popupData?.warningDesc">
      </div>
      {{popupData.description}}
    </div>
    <div class="actions text-center mb-2">
      <button class="btn save-btn ms-4" [ngClass]="popupData?.isWarning ? 'blue-btn' : ''" (click)="onCancel()">
        {{popupData.closeBtn || 'OK'}}
      </button>
    </div>
  </div>
</div>
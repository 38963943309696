import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent  {
  public start_count: number = 1;
  public end_count: number = 0;

  @Input() per_page_count: number = 25;
  @Input() total_count: number = 0;
  @Input() is_next_active: boolean = false;
  @Input() is_prev_active: boolean = false;
  @Input() is_total_count_reduced: boolean = false; // This will be true if total_count is not the exact count of items in the result.
  @Output() loadPrev: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadNext: EventEmitter<any> = new EventEmitter<any>();

  @Input() set start_count_parent(val: number) {
    if (val) {
      this.start_count = val;
    }
  }

  @Input() set end_count_parent(val: number) {
    if (val) {
      this.end_count = val;
    }
  }

  onNextClick() {
    if (this.is_next_active) {
      let start = this.start_count, end = this.end_count;

      if (end !== this.total_count) {
        start = end + 1;
      }
      if (end + this.per_page_count > this.total_count) {
        end = this.total_count;
      } else {
        end = end + this.per_page_count;
      }
      const emitData = {
        start: start,
        end: end
      }
      this.loadNext.emit(emitData);
    }
  }

  onPrevClick() {
    if (this.is_prev_active) {
      let start = this.start_count;
      let end = start - 1;
      start = start - this.per_page_count;
      const emitData = {
        start: start,
        end: end
      }
      this.loadPrev.emit(emitData);
    }
  }

}

<div [ngStyle]="{'display':(total_count <= per_page_count)?'none':'flex'}" class=" pagination">
  <span>Viewing {{start_count}}-{{end_count}} of
    <span *ngIf="!is_total_count_reduced else totalCountReduced">{{total_count}}
    </span>
    <ng-template #totalCountReduced>{{(total_count > 1000)? '1000+' : total_count}}</ng-template>
  </span>
  <img [ngStyle]="{'opacity':is_prev_active?1:.5, 'cursor': is_prev_active? 'pointer' : 'not-allowed'}"
    src="./assets/images/left-angle.svg" alt="prev" class="px-3 pagination-color" (click)="onPrevClick()">
  <img [ngStyle]="{'opacity':is_next_active?1:.5, 'cursor': is_next_active? 'pointer' : 'not-allowed'}"
    src="./assets/images/right-angle.svg" alt="next" class="px-3 pagination-color" (click)="onNextClick()">
</div>
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "src/app/core";
import { AvailableSlots } from "src/app/shared/_models/available-slots.model";
import { SpaceUserData } from "src/app/shared/_models/space-user.model";

export const routes = {
    virtualTeam: `/api/space-users`,
    repeatOptions: '/api/dropdown-items/repeat-options',
    getAddedSlot: '/api/available-slots',
    interviewers:'/api/interviewers',
    employees:'/api/dropdown-items/employees/',
    interviewer: `/api/interviewer/`,
    deleteSlot: `/api/delete-slot/`
}

@Injectable({
    providedIn: 'root',
})
export class InterviewerService {

    userSubject: BehaviorSubject<SpaceUserData | undefined> = new BehaviorSubject<SpaceUserData | undefined>(undefined);

    userDetailsObservable$ = this.userSubject.asObservable();
    shouldReload: boolean = false;

    constructor(
        private apiService: ApiService
    ) { }

    getSpaceUserVirtualTeam(id: any): Observable<any> {
        return this.apiService.get(`${routes.virtualTeam}/${id}/`);
    }

    setUser(user: SpaceUserData | undefined) {
        this.userSubject.next(user);
    }

    clearUserSubject() {
        this.userSubject.next(undefined);
    }

    getSlots(id: number | undefined, params: any): Observable<any> {
        return this.apiService.get(`${routes.interviewer}${id}/get-slots/`, params);
    }
    
    getInterviewersActivityLogs(id: any, page: any) {
        return this.apiService.get(`${routes.interviewer}${id}/activitylog/?page=${page}`);
    }

    getRepeatOptions(date: any) {
        return this.apiService.get(`${routes.repeatOptions}/?date=${date}`);
    }

    addAvailableSlot(id:any, params:AvailableSlots): Observable<any> {
        return this.apiService.post(`${routes.interviewer}${id}/create-slots/`, params);
    }

    editAvailableSlot(id:any, params:AvailableSlots): Observable<any> {
        return this.apiService.put(`${routes.getAddedSlot}/${id}/edit/`, params);
    }
    
    getAddedSlot(id:any): Observable<any> {
        return this.apiService.get(`${routes.getAddedSlot}/${id}/`);
    }
    addOrUpdateInterviewer(data: any): Observable<any> {
        return this.apiService.post(`${routes.interviewers}/assignee/`, data);
    }

    getEmployees(): Observable<any> {
        return this.apiService.get(`${routes.employees}`);
    }

    deleteSlot(slotId: number, delete_option?: number) {
        let url  = `${routes.deleteSlot}${slotId}/`;
        if (delete_option) {
            url = url+'?delete_option='+delete_option;
        }
        return this.apiService.delete(`${url}`);
    }

}
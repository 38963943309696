import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  ChartComponent,
  ApexChart,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
  ApexLegend,
  ApexDataLabels,
  ApexStates,
  ApexPlotOptions,
  ApexStroke,
  ApexFill} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: string[];
  tooltip: ApexTooltip;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  states: ApexStates;
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  fill: ApexFill;
}


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartsComponent implements OnInit {

  @ViewChild("chart")
  chart!: ChartComponent;
   public chartOptions!: Partial<ChartOptions>;
   _chartData:any;
  
  @Input() set chartData(value:any) {
    this._chartData = value;
    this.updateValue();
  }

  get chartData() {
    return this._chartData
  }
  constructor() { }

  ngOnInit(): void { 
  }

  updateValue() {
    this.chartOptions = {
      series:this.chartData.series,
      chart: {
        type: "donut",
        width:500
      },
      labels:this.chartData.labels,
      colors:this.chartData.colors,
      tooltip: {
        enabled:true,
         custom: function({series, seriesIndex, dataPointIndex, w}) 
         { let total = 0; for (let x of series) { total += x; }
            let selected = series[seriesIndex]
           return '<div class="arrow_box p-2 completed-report-chart">' +
            '<div class="cr-label text-center">' + w.config.labels[seriesIndex] + '</div>'+
            '<div  class="text-center">'  + (selected / total * 100).toFixed(2) + "%" + '</div>' +
            '</div>'
        },
      },
      legend:{
        show: true,
        fontSize: '11px',
        fontFamily: 'Lato',
        labels: { colors: 'black' },
       },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick:false,
          donut: {
            size: '50%'
          }
        }
      },
      stroke: {
        width: 0,  
      },
      states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.35,
            }
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 350
            },
            legend:{
             width:150
            },
          }
        }
      ],
    }
  }

}

import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ApiService } from '../core';
import { Role } from '../shared/_models/role';
import { GlobalStoreService } from '../shared/_services/global-store.service';
import { StorageService } from '../shared/_services/storage.service';

import { User } from './../shared/_models/user';

export const routes = {
  login: `/api/login/`,
  getUserFlags: `/api/user-flags/`,
  getTenantDetails: `/api/tenant-details/`,
  logout: `/api/logout/`
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private user: User = new User();
  logoutProgress: 'loggedIn' | 'loggingOut' | 'loggedOut' = 'loggedIn';
  constructor(private apiService: ApiService,
    private router: Router,
    private storageService: StorageService,
    private ngZone: NgZone,
    private globalStore: GlobalStoreService,
    private keycloak: KeycloakService,
    private injector: Injector
    ) { }

  isOA() {
    this.user = this.globalStore.getUser();
    return this.user?.is_organization_admin;
  }

  isVT() {
    this.user = this.globalStore.getUser();
    return this.user?.is_vt_head;
  }

  isCL() {
    this.user = this.globalStore.getUser();
    return this.user?.is_core_leader;
  }

  hasInterview() {
    this.user = this.globalStore.getUser();
    return this.user?.has_interview;
  }

  isInterviewer() {
    this.user = this.globalStore.getUser();
    return this.user?.is_interviewer;
  }

  isAuthorized() {
    this.user = this.globalStore.getUser();
    if (this.user?.id){
      this.logoutProgress = 'loggedIn';
    }
    return !!this.user?.id;
  }

  hasRole(role: Role) {
    this.user = this.globalStore.getUser();
    return this.isAuthorized() && this.user?.role === role;
  }

  getUser(): User {
    this.user = this.globalStore.getUser();
    return this.user;
  }

  login(): Observable<any> {
    this.logoutProgress = 'loggedIn';
    return this.apiService.post(routes.login, {});
  }

  logout() {
    if (this.logoutProgress !== 'loggedIn') return; // to avoid duplicate apis
    this.logoutProgress = 'loggingOut';
    let toaster = this.injector.get(ToastrService);
    this.apiService.post(routes.logout, {}).subscribe(
      (response: any) => {
        this.logoutProgress = 'loggedOut';
        if (response.status) {
          let user = JSON.parse(this.storageService.get('user'));
          this.storageService.set('user', JSON.stringify({...user, loggedIn: false}));
          this.globalStore.setUser(new User());
          this.globalStore.removeAllFilters();
          this.storageService.remove('tenant-config');
          this.keycloak?.logout(window.origin + '/login');
        } else {
          toaster.error('Something went wrong')
        }
      },
      (error: any) => {
        toaster.error('Something went wrong')
      })
  }

  getUserFlags() {
    return this.apiService.get(routes.getUserFlags);
  }

  hasReportsEnabled(){
    this.user = this.globalStore.getUser();
    return this.user.is_reports_enabled;
  }

  hasScheduleInvitesEnabled() {
    this.user = this.globalStore.getUser();
    return this.user.is_enable_send_schedule_invite;
  }

  hasRecruitmentDriveEnabled() {
    this.user = this.globalStore.getUser();
    return this.user.is_recruitment_drives_enabled;
  }

  getTenantDetails() {
    return this.apiService.get(routes.getTenantDetails);
  }
}

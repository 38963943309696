<nav id="main-header" class="header navbar navbar-expand-lg navbar-light transparent-header">
  <div class="top-section top-header-section" [ngClass]="isSideNavCollapsed ? 'left-spacing-collapsed' : 'left-spacing-expanded'">
    <div class="px-3 py-2 hide-tablet">
      <span (click)="toggleIcon()"><img src="./assets/images/hamburger.svg" alt="brand logo" /></span>
      <span class="navbar-brand mx-2" (click)="navigateToHome()">
        <img src="./assets/images/qb-logo.svg" alt="brand logo" />
      </span>
    </div>

    <div class="left-section ">
      <span *ngIf="showBackBtnInHeader" class="col-auto custom-link back-btn " (click)="handleBackButtonClick()">
        <img src="../assets/images/back-blue.png" class="me-1  theme-coloured-image blue-icon custom-link-icon" alt="Back" />
        <u>BACK</u></span>
    </div>

    <div class="right-section d-flex align-items-center">
      <div *userRole="[Role.HR, Role.HRM, Role.Director]" class="search-container position-relative" clickOutside (clickOutside)="handleOutsideClick()" >
        <div class="search-bar">
          <img src="./assets/images/small-search-icon.svg" alt="search">
          <input type="text" placeholder="Search candidates by name, email or contact number" [(ngModel)]="searchTerm" (keyup)="viewAll($event)"
            (input)="onCandidateSearch($event, 'myParam')" #myInput>
        </div>
        <div *ngIf="candidates?.length > 0" class="search-result-box">
          <div class="search-result-item py-2" *ngFor="let candidate of candidates">
            <a routerLink="/candidates/{{candidate?.id}}/view"
            [queryParams]='{from: "header"}' (click)="setSearchText();clearSearch()">
            <div class="label-value name-box">
              <span (mouseenter)="hideOrShowTooltip($event, candidate,'hasOverflow')"
                ngbTooltip="{{candidate?.hasOverflow ? candidate.full_name : ''}}"
                placement="bottom-left">{{candidate.full_name}}
              </span>
            </div>
            <div class="d-flex">
              <div class="mail-box">
                <span class="label-value-blue" (mouseenter)="hideOrShowTooltip($event, candidate,'hasOverflow')"
                  ngbTooltip="{{candidate?.hasOverflow ? candidate?.email : ''}}"
                  placement="bottom-left">{{candidate.email}}
                </span>
              </div>

              <div class="contact-box">
                <span class="label-value-black" (mouseenter)="hideOrShowTooltip($event, candidate,'hasOverflow')"
                  ngbTooltip="{{candidate?.hasOverflow ? candidate?.primary_contact_country_code+-+candidate.primary_contact_no: ''}}"
                  placement="bottom-left">{{candidate.primary_contact_no && ' |
                  '+candidate.primary_contact_country_code+-+candidate.primary_contact_no}}
                </span>
              </div>
            </div>
          </a>
          </div>
        </div>
        <div *ngIf="candidates?.length == 0 && !apiLoading" class="search-result-box">
          <div class="no-data-container">
            <span class="sy">Sorry!</span>
            <span>No matching candidates available</span>
          </div>
        </div>
      </div>

      <div (click)="toggleMenu()" class="nav-item dropdown user-dropdown">
        <a class="btn dropdown-toggle nav-link" id="profileMenu" data-bs-toggle="dropdown" aria-expanded="false">
          <img onerror="this.src='../assets/user_default.png'" class="user-avatar" src="{{imageUrl}}" alt="avatar" />
          <span class="username">{{ username }}</span>
        </a>
        <ul class="dropdown-menu" aria-labelledby="profileMenu">
          <li (click)="logout()">
            <a class="dropdown-item">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import * as dayjs from 'dayjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'date-picker-popup',
    templateUrl: './date-picker-popup.component.html',
    styleUrls: ['./date-picker-popup.component.scss'],
})
export class DatePickerPopUp implements OnInit {
    _value: string = '';
    startDate: any = dayjs().startOf('day');
    endDate: any = dayjs().startOf('day');
    @Input() item: any = {};
    @Input() border: boolean = false;
    @Input() disabled: boolean = false;
    @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();
    @Output() isCleared: EventEmitter<any> = new EventEmitter<any>();
    @Input() disabledVal: boolean = false;
    @Input() savedDate: string = '';
    @Input() placeholder: string = '';
    @Input() set value (val: string) {
      if (val) {
        this._value = dayjs(val).format('MMM DD, YYYY');
        this.placeholder = ''
      }
      else {
        this._value = '';
        this.placeholder = "Mmm DD, YYYY"
      }
    }
    get value(): string {
      return this._value;
    }
    popOverposition: string = 'bottom-left'


    @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective!: DaterangepickerDirective;
    @ViewChild('popOver') popover!: NgbPopover;


    inlineDate: any;
    inlineDateTime: any;
    picker!: DaterangepickerComponent;
    locale: any = {
        applyLabel: 'Apply', format: 'MMM DD, YYYY'
    };

    @Input() isInputVisible: boolean = true;

    ngOnInit() {
        if (this.value) {
            this.value = dayjs(this.value).format('MMM DD, YYYY');
            this.placeholder = ''
        }
        else {
            this.placeholder = "Mmm DD, YYYY"
        }
    }

    //Default function of Date range picker which triggers on input change.

    onDatechoosed(e: any, id: string) {
        let dateData: any = {};
        dateData[id] = dayjs(e.endDate).format("YYYY-MM-DD")
        this.selectedDate.emit(dateData);
        this.startDate = e.startDate;
        this.endDate = e.endDate;
        this.popover.close();
    }

    open() {
        this.pickerDirective.open();
        console.log(this.pickerDirective)
    }
    clear(e: any) {
        this.picker.clear();
        this.isCleared.emit(true);
    }

    onPopOverOpen(e: any) {
        this.handleDpPlacement(e);
        this.startDate = this.item?.value?.startDate || this.startDate || dayjs().startOf('day');
        this.endDate = this.item?.value?.endDate || this.endDate || dayjs().startOf('day');
    }

    // Get the position of input field
    // Infer the position of datepicker from the position of input field
    // Datepicker position is inferred because datepicker will not be in the DOM initially
    // Another way is get the position of datepicker after it is shown in the DOM, but it will
    // not provide a UI experience as position may switch after the calculation (causes flickering)
    handleDpPlacement(e: any) {
        let fieldPosition = e.target?.getBoundingClientRect();
        const { bottom = 0 } = fieldPosition ? fieldPosition : {};
        let dpPosition = { ...fieldPosition, top: bottom, bottom: bottom + 205 };

        const isInViewport = this.isInViewport(dpPosition);

        if (!isInViewport) {
            this.popOverposition = 'top-left';
        } else {
            this.popOverposition = 'bottom-left';
        }
    }

    isInViewport = (pos: any) => {
        let { top, bottom } = pos;
        return top >= 0 && bottom <= window.innerHeight - 70;
    };

}
<ng-template #popTemplate>
    <div class="datepicker-popover-main">
        <ngx-daterangepicker-material class="datepicker-calendar-icon form-control cursor-view"
            [singleDatePicker]="true" [locale]="locale" [minDate]="item.minDate" [maxDate]="item.maxDate"
            #pickerDirective [showDropdowns]="true" [showClearButton]="true" [startDate]="startDate" [endDate]="endDate"
            (datesUpdated)="onDatechoosed($event, item.id)">
        </ngx-daterangepicker-material>
    </div>
</ng-template>
<div #popOver="ngbPopover" placement="{{popOverposition}}" [autoClose]="'outside'" (click)="onPopOverOpen($event)"
    [ngbPopover]="popTemplate" popoverClass="datepicker-popover" class="input-section">
    <input placeholder="{{placeholder}}" readonly value="{{value}}" [disabled]="disabledVal"
        class="form-control feild-value form-view date-picker-input " />
    <img class="calendar-icon theme-coloured-image blue-icon" src="/assets/images/Calendar.svg">
</div>
<ng-template #popTemplate>
    <div class="timepicker-popover">
        <ngbd-timepicker-meridian [time]="time" (selectedTime)="_timeValue = $event">
        </ngbd-timepicker-meridian>
        <div class="buttn-class mt-3 d-flex justify-content-end">
            <button class="btn btn-default cancel-btn mt-4 me-1" (click)="clearTime()">Clear</button>
            <button class="btn apply-btn mt-4" (click)="applyTime()">Apply</button>
        </div>

    </div>
</ng-template>
<div class="input-section" [ngClass]="{'border-remove' : border===false}"
    [ngStyle]="{'outline':(focusElement && tabIndex != null && tabIndex>1 ) ? tabFocusBorder:'unset'}"
    (click)="onPopOverOpen($event)" #popOver="ngbPopover" [ngbPopover]="popTemplate" popoverClass="timepicker-popover"
    [autoClose]="'outside'" placement="{{popOverposition}}">
    <input placeholder="{{placeholder}}" readonly value="{{newTime}}" [disabled]="disabledVal"
        (hidden)="setPickerTime()" class="form-control feild-value form-view icon-class border-remove" [tabindex]="tabIndex"
        (blur)="removeFocus()" (keyup)="onFocusElement($event)" />
    <img class="clock-icon theme-coloured-image blue-icon" src="/assets/clock-blue_icon.svg">
</div>
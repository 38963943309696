<span (click)="open(content)">
  <img *ngIf="!editMode" src="../assets/images/schedule.svg" class="action-icon theme-coloured-image blue-icon" alt="Schedule"
   placement="bottom" ngbTooltip="Schedule interview"/>
  <img *ngIf="editMode" src="../assets/images/edit-bg-rounded.svg" alt="Edit" placement="bottom" ngbTooltip="Edit interview"
    class="edit-icon theme-coloured-image blue-icon" />
</span>
<ng-template #content let-modal>
  <div class="scheduleInterviewForm" [ngClass]="fromInterviewList?'':'movable-modal'" cdkDrag cdkDragRootElement=".modal-content" [cdkDragDisabled]="fromInterviewList" tabindex="0">
    <form [formGroup]="scheduleForm">
      <div class="fluid-container p-4">
        <div class="row mb-4">
          <div *ngIf="!editMode" class="col-sm-12 popup-header">Schedule Interview</div>
          <div *ngIf="editMode" class="col-sm-12 popup-header">Edit Interview </div>
        </div>
        <div class="col-sm-12">
          <div class="row mb-4">
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Lead Interviewer</label>
              <div class="col-sm-12 non-checkbox-select">
                <ng-select ngSelectHandler [editableSearchTerm]="true" [isEditable]="true"
                  [handler_input]="selectedInterviewersRef" #selectedInterviewersRef
                  class="feild-value remove-border" [items]="interviewers" [searchable]="true"
                  [clearable]="true" [searchFn]="customSearchFn.bind(this)" (change)="onInterviewerChange($event)" bindValue="id" bindLabel="full_name"
                  formControlName="lead_interviewer" placeholder="Search">
                  <ng-template ng-option-tmp let-subItem="item">
                    {{subItem.full_name}} &nbsp;[<span class="email">{{subItem.email}}</span>]
                  </ng-template>
                </ng-select>
                <div *ngIf="f.lead_interviewer.touched && f.lead_interviewer.invalid"
                  class="alert alert-error modal-error error-height">
                  <div *ngIf="f.lead_interviewer.errors?.required">Lead interviewer is required</div>
                </div>
                <div *ngIf="f.lead_interviewer.value && !slotsApiLoading && availableSlots.length === 0" class="error-height mt-1 no-slots-error">
                  No Marked Slots available
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <label class="col-form-label label-feild">Interview Level</label>
              <div class="col-sm-12">
                <ng-select class="feild-value remove-border form-view" [items]="interviewLevels" [searchable]="false"
                  [clearable]="false" bindValue="id" bindLabel="name" formControlName="level" placeholder="Select">
                </ng-select>
                <div *ngIf="f.level.touched && f.level.invalid" class="alert alert-error modal-error error-height">
                  <div *ngIf="f.level.errors?.required">Interview level is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <label class="col-form-label label-feild">Interview Type</label>
              <div class="col-sm-12">
                <ng-select class="feild-value remove-border form-view" [items]="interviewType" [searchable]="false"
                  [clearable]="false" bindValue="id" bindLabel="name" formControlName="mode" placeholder="Select">
                </ng-select>
                <div *ngIf="f.mode.touched && f.mode.invalid" class="alert alert-error modal-error error-height">
                  <div *ngIf="f.mode.errors?.required">Interview type is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4" *ngIf="availableSlots.length">
            <div class="slot-section">
              <div (click)="onPrev(slotDiv)" class="pagination-arrow" [ngClass]="currentSlotIndex <= 0 && 'disabled'">
                <img src="./assets/images/left-angle.svg" alt="previous" />
              </div>
              <div class="slots" id="slotDiv">
                <div class="slot-item" *ngFor="let slot of availableSlotsFiltered" (click)="onSlotSelect(slot)" [ngClass]="f.slot_id.value === slot.id && 'active'">
                  <div class="day">{{slot?.date | date :slotDayFormat}}</div>
                  <div class="date">{{slot?.date | date :slotDateFormat}}</div>
                  <div class="time">{{slot?.date+'T'+slot?.start_time | date: slotTimeFormatModal}}</div>
                </div>
              </div>
              <div (click)="onNext(slotDiv)" class="pagination-arrow right" [ngClass]="currentSlotIndex >= availableSlots.length - 5 && 'disabled'">
                <img src="./assets/images/left-angle.svg" alt="next" />
              </div>
            </div>
          </div>
          <div class="row mb-4">
                <div class="col-sm-6">
                  <label class="col-form-label label-feild">Date</label>
                  <div class="date-picker">
                    <app-single-date-picker-component [item]="datepickerConfig" (selectedDate)="onDateSelected($event);"
                      class="feild-value form-view" [border]="false" [datePickerId]="'ScheduleInterviewPicker'">
                    </app-single-date-picker-component>
                    <div *ngIf="f.scheduled_date.touched && f.scheduled_date.invalid"
                      class="alert alert-error modal-error error-height">
                      <div *ngIf="f.scheduled_date.errors?.required">Date is required</div>
                      <div *ngIf="f.scheduled_date.errors?.minimumDate">Chosen date is a past date</div>
                    </div>
                  </div>
                </div>
            <div class="col-sm-3">
              <label class="col-form-label label-feild">Time</label>
              <div class="date-picker">
                <ngbd-timepicker-meridian-popup class="feild-value remove-border form-view"
                  (selectedTime)="onTimeSelected($event);" [time]="timepickerConfig" [timeValue]="timeValue">
                </ngbd-timepicker-meridian-popup>
                <div *ngIf="f.scheduled_time.touched && f.scheduled_time.invalid"
                  class="alert alert-error modal-error error-height">
                  <div *ngIf="f.scheduled_time.errors?.required">Time is required</div>
                  <div *ngIf="f.scheduled_time.errors?.minimumTime">Chosen time is a past time</div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <label class="col-form-label label-feild">Duration</label>
              <div class="col-sm-12">
                <ng-select class="feild-value remove-border form-view" [items]="interview_duration" [searchable]="false"
                  [clearable]="false" bindValue="id" bindLabel="name" formControlName="duration" placeholder="Select"
                  (change)="onDurationChange()">
                </ng-select>
                <div *ngIf="f.duration.touched && f.duration.invalid" class="alert alert-error modal-error error-height">
                  <div *ngIf="f.duration.errors?.required">Interview duration is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Interview Location</label>
              <div class="col-sm-12">
                <ng-select class="feild-value remove-border form-view" [items]="interviewLocation" [searchable]="false"
                  [clearable]="false" bindValue="id" bindLabel="name" formControlName="location" placeholder="Search">
                </ng-select>
                <div *ngIf="f.location.touched && f.location.invalid"
                  class="alert alert-error modal-error error-height">
                  <div *ngIf="f.location.errors?.required">Interview location is required</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Other Interviewers</label><span class="optional-feild">
                (Optional)</span>
              <span class="other-info" (mouseover)="showOtherInfo = true" (mouseout)="showOtherInfo = false">
                <img src="./assets/images/info-message-icon.svg" alt="info">
                <span *ngIf="showOtherInfo" class="info-container">
                  The availability of optional interviewers will not be taken into consideration for scheduling an interview
                </span>
              </span>
              <div class="col-sm-12 multi-select-interviewer">
                <ng-select [items]="interviewersBackup" [multiple]="true" bindLabel="full_name" #selectedOtherInterviewersRef [closeOnSelect]="false"
                  bindValue="id" [(ngModel)]="otherInterviewersList" [ngModelOptions]="{standalone: true}"  [searchFn]="searchUser.bind(this)" [clearOnBackspace]="false"
                  (change)="onOtherInterviewerSelection($event)" class="placeholder" placeholder="Select or start typing" (open)="onDropdownOpen()">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
                    {{item.full_name}} &nbsp;[<span class="label-value-blue">{{item.email}}</span>]
                  </ng-template>
                </ng-select>
              </div>
              <div class="row min-height-tab">
                <div class="col-sm-12 d-flex  align-items-center flex-wrap mt-1">
                  <span class="skill-display d-flex  align-items-center"
                    *ngFor="let interviewer of otherInterviewersDetails">
                    {{interviewer.full_name}}
                    <img class="ms-2 cursor-pointer" (click)="onRemoveOtherInterviewer(interviewer)" src="./assets/images/remove.svg"
                      alt="close">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Job Code</label><span class="optional-feild"> (Optional)</span>
              <div class="col-sm-12 non-checkbox-select">
                <ng-select class="feild-value remove-border" [editableSearchTerm]="true" [items]="jobCodes" [searchable]="true"
                  [clearable]="true" bindValue="id" bindLabel="name" (change)="onJobDetailsChange($event, 'job')"
                  formControlName="job" placeholder="Select or start typing">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Department</label><span class="optional-feild">
                (Optional)</span>
              <div class="col-sm-12 non-checkbox-select">
                <ng-select #dept_dropdown class="feild-value remove-border" [editableSearchTerm]="true"
                  (change)="onJobDetailsChange($event, 'department')" [items]="departments" [searchable]="true"
                  [clearable]="true" bindValue="id" bindLabel="name" formControlName="department" placeholder="Select or start typing" (ngModelChange)="onDepartmentModelChange($event, dept_dropdown)">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Stream</label><span class="optional-feild"> (Optional)</span>
              <div class="col-sm-12 non-checkbox-select">
                <ng-select #stream_dropdown class="feild-value remove-border" [editableSearchTerm]="true" (change)="onJobDetailsChange($event, 'stream')"
                  [items]="streams" [searchable]="true" [clearable]="true" bindValue="id" bindLabel="name"
                  formControlName="stream" placeholder="Select or start typing" (ngModelChange)="onStreamModelChange($event, stream_dropdown)">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-form-label label-feild">Designation</label><span class="optional-feild">
                (Optional)</span>
              <div class="col-sm-12 non-checkbox-select">
                <ng-select #designation_dropdown class="feild-value remove-border" [editableSearchTerm]="true" [items]="designationsFiltered" [searchable]="true"
                  [clearable]="true" bindValue="id" bindLabel="name" formControlName="designation" placeholder="Select or start typing" (ngModelChange)="onDesignationModelChange($event, designation_dropdown)">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12">
              <label class="col-form-label label-feild">Comments</label><span class="optional-feild"> (Optional)</span>
              <textarea formControlName="comment" placeholder="Add comments"
                class="form-control feild-value remove-border textarea" id="interviewComments" rows="4"></textarea>
              <div *ngIf="f.comment.touched && f.comment.invalid" class="alert alert-error modal-error error-height">
                <div *ngIf="f.comment.errors?.maxlength">Comment should be less than 255 characters</div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="this.resume?.is_prompt_source_update">
            <div class="row mb-4">
              <div class="col-sm-12">
                <div class="d-flex source-prompt-checkbox px-2 py-2">
                  <app-checkbox [checked]="f.update_schedule_interview_source.value" (toggle)="onUpdateScheduleInterviewSource()" name="update_eazy_schedule_source">
                  </app-checkbox>
                  <div class="ms-2 d-flex info-msg">Update the source to HR since the candidate's last application was an year ago. 
                    <div (mouseover)="toggleInfoShow()" (mouseout)="toggleInfoHide()">
                      <div class="cursor-pointer tooltip-icon">
                        <img src="./assets/images/info-message-icon.svg" alt="info">
                      </div>
                      <div *ngIf="showInfo" class="info-container">
                        Applied on date will be set to today's date.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row mb-4">
            <div class="col-sm-12">
              <div class="d-flex align-items-center previous-interview-wrapper">
                <app-checkbox [disabled]="f.level.value === 'M' && f.show_previous_interview.value" [checked]="f.show_previous_interview.value"
                  (toggle)="toggleCheckBox('show_previous_interview')"></app-checkbox>
                <span class="ms-2">Interviewers can view the previous ratings</span>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12">
              <div class="d-flex align-items-center previous-interview-wrapper">
                <app-checkbox [checked]="f.allow_conflicting_interviews.value"
                  (toggle)="toggleCheckBox('allow_conflicting_interviews')"></app-checkbox>
                <span class="ms-2">Ignore conflicting interviews of the interviewer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-4">
          <div class="row">
            <div class="col-sm-3"></div>
            <button class="col-sm-3 m-1 btn btn-cancel" (click)="onCancelClick()">{{'cancel'|
              uppercase }}</button>
            <button class="col-sm-3 m-1 btn red-btn" (click)="scheduleInterview()"
              [disabled]="scheduleDisabled">{{'Schedule' |
              uppercase}}</button>
            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() checked!: boolean;
  @Input() disabled!: boolean;
  @Input() showStatus: boolean = true;
  @Output() switch: EventEmitter<any> = new EventEmitter();

  toggleSwitch(e: any) {
    this.switch.emit(e);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = '';
  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = 'https://' + window.location.host + '/backend';
    // this.baseUrl = 'https://development-qrms-v2.qburst.in/backend'; //server url for localhost - only uncomment while locally running;
  }

  public get(url: string, params?: any, baseUrl = this.baseUrl) {
    if (url && params && Object.keys(params)?.length) {
      url = this.updateQueryStringParameter(url, params)
    }
    return this.http.get([baseUrl, url].join(''))
  }

  public download(url: string, params?: any, baseUrl = this.baseUrl, post: boolean = false) {
    if (!post) {
      if (url && params && Object.keys(params)?.length) {
        url = this.updateQueryStringParameter(url, params)
      }
      return this.http.get<Blob>([baseUrl, url].join(''), { responseType: "blob" as "json" });
    } else {
      return this.http.post<Blob>([baseUrl, url].join(''), params, { responseType: "blob" as "json" });
    }
  }

  public post(url: string, data: any, options = {}, baseUrl = this.baseUrl) {
    return this.http.post([baseUrl, url].join(''), data, options);
  }

  public put(url: string, data: any, baseUrl = this.baseUrl) {
    return this.http.put([baseUrl, url].join(''), data)
  }

  public delete(url: string) {
    return this.http.delete([this.baseUrl, url].join(''));
  }

  public patch(url: string, data: any) {
    return this.http.patch([this.baseUrl, url].join(''), data)
  }

  private updateQueryStringParameter(uri: any, data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = encodeURIComponent(data[key]);
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        const separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
          uri = uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
          uri = uri + separator + key + '=' + value;
        }
      }
    }
    return uri;
  }

  public getwithOptions(url: string, options = {}, baseUrl = this.baseUrl) {
    return this.http.get([baseUrl, url].join(''), options)
  }

}

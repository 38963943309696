<div class="dashboard-widget-wrapper full-height-wrapper">
  <div class="card-title d-flex">
    <div class="card-main-title d-flex align-items-center"> {{firstTitle}} <div *ngIf="isReferral"
        (mouseover)="infoToggle = true" (mouseout)="infoToggle = false">
        <div class="d-flex ms-1 cursor-pointer"><img  src="./assets/images/info-message-icon.svg" alt="info"></div>
        <div *ngIf="infoToggle" class="info-container"> List of referrals done within the last one year </div>
      </div>
    </div>
    <ng-container *ngIf="!hasNoData || isReferral">
      <div class="cursor-pointer d-flex align-items-center">
        <button *ngIf="isReferral && !hasNoData" class="page-add-button refer-friend-btn me-2"
          (click)="referCandidate()"> {{'Refer a friend' | uppercase}}
        </button>
        <span (click)="viewAllData()" class="d-flex">
          <span class="card-sub-title">{{secondTitle}} ({{itemCount}})</span>
          <span class="d-flex align-items-center"><img class="blue-chev ms-1" src="./assets/images/blue-chevron.svg"
              alt="View all" /></span>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="card-content position-relative" [ngClass]="{'no-data':hasNoData}">
    <ng-content></ng-content>
  </div>
</div>
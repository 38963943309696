import { Component, EventEmitter, OnInit, Output, Input, OnDestroy, HostListener } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations'
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { secondaryMenus, customSideNav, newMenu } from '../../_constants/constants';
import { NavMenu } from '../../_models/navMenu.model';
import { PAGE } from '../../_models/page.enum';
import { Role } from '../../_models/role';
import { GlobalStoreService } from '../../_services/global-store.service';
import { UtilService } from '../../_services/util.service';
import { DayRangeService } from '../day-range-picker/day-range.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('400ms ease-out', 
                    style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: '*', opacity: 1 }),
            animate('250ms ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SideNavComponent implements OnInit, OnDestroy {

  username: string = '';
  headerMenu: any = [];
  secondaryMenu: any = [];
  isNavCollapsed: boolean = false;
  Page = PAGE;
  routerVars: any;
  id: any;
  userRole: any;
  secondaryNavSubscription: Subscription = new Subscription;
  routerVariablesSubscription: Subscription = new Subscription;
  hideMenuSubscription: Subscription = new Subscription;
  hasCustomSideNav: any = [];
  hoveredItemIndex: any;
  enableScheduleInvitesMenu: boolean = false;
  @Input()
  set isSideNavCollapsedInput(collapsed: boolean) {
    if (window.screen.width < 768) {
      // isNavCollapsed is handled from header in mobile devices
      this.isNavCollapsed = collapsed;
    }
  }

  @Output() sideNavCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private globalStore: GlobalStoreService,
    private utliService: UtilService,
    private dayRangeService: DayRangeService
  ) {
    this.userRole = this.authService.getUser()?.role;
    this.headerMenu = newMenu;
    this.utliService.activeUrl$.subscribe(
      (url) => {
        if (url) {
          this.showActiveMenu(this.headerMenu, url);
        }
      }
    );
    this.globalStore.getShowSchedulesInvites().subscribe(
      (val) => {
        this.enableScheduleInvitesMenu = val;
        this.headerMenu.filter((item: any) => item.title === 'Interviews')[0].subMenu = [...this.getSecondaryMenu('interviews')];
      }
    )
  }

  showActiveMenu(menuList: NavMenu[], url: string) {
    menuList.forEach((menu: any) => {
      if (url.includes(menu['routerLink'])) {
        menu['toggleSubmenu'] = true;
        if (menu['subMenu']?.length > 0) {
          this.showActiveMenu(menu['subMenu'], url)
        }
      } else {
        menu['toggleSubmenu'] = false;
      }
      const user = this.authService.getUser() as any;
      if (menu?.tenantBasedMenuFlag && user) { //has tenant based restriction
        const tenantFlag: string = menu.tenantBasedMenuFlag as string;
        menu['showMenuForTenant'] = user[tenantFlag];
      } else { //has no tenant based restriction, only check role based restriction in template
        menu['showMenuForTenant'] = true;
      }
    })
  }

  ngOnInit(): void {
    const { name } = this.authService.getUser();
    this.username = name;
    this.enableScheduleInvitesMenu = this.authService.hasScheduleInvitesEnabled();

    this.initMenu()
    this.checkActionItemsMenu()
    // this.changeSecondaryMenu();
    this.changeRouterVariables();
    this.hideActionItemsMenu();
    this.hasCustomMenu();
  }

  initMenu() {
    this.headerMenu.forEach((menu: any) => {
      if (Object.keys(menu).includes('subMenu')) {
        menu['subMenu'] = this.getSecondaryMenu(menu.mainMenuKey);
      }
    })
  }

  checkActionItemsMenu() {
    if (this.getSecondaryMenu('action items').length === 0) {
      this.headerMenu = this.headerMenu.filter((menu: any) => menu.title !== 'Action Items')
    }
  }

  hideActionItemsMenu() {
    this.hideMenuSubscription = this.globalStore.getHideMenu()
      .subscribe((setSecondaryMenu) => {
        this.checkActionItemsMenu();
        if (setSecondaryMenu) {
          this.headerMenu['action items'] = this.getSecondaryMenu('action items')
        }
      });
  }

  // select corresponding secondary menu as page changes
  // changeSecondaryMenu() {
  //   this.secondaryNavSubscription = this.globalStore.getSecondarynavMenu()
  //     .subscribe((menuName: string) => {
  //       this.secondaryMenu = this.getSecondaryMenu(menuName);
  //     });
  // }

  getSecondaryMenu(menuName: string) {
    const { role, has_interview, is_interviewer } = this.authService.getUser();
    let secondaryMenu = [...secondaryMenus[menuName]];
    secondaryMenu = secondaryMenu.filter((menuItem: any) => {
      if (!menuItem.role) {
        return menuItem
      } else if (menuItem.title == 'Awaiting Ratings') {
        return has_interview && menuItem
      } else if (menuItem.title == 'Schedules') {
        return (menuItem.role && menuItem.role.includes(role) || has_interview || is_interviewer) && menuItem
      } else if (menuItem.title == 'Schedule Invites') {
        return (menuItem.role && menuItem.role.includes(role) && this.enableScheduleInvitesMenu) && menuItem
      } else if (menuItem.title == 'Availability') {
        return (menuItem.role && menuItem.role.includes(role) || is_interviewer) && menuItem
      }
      else {
        return menuItem.role && menuItem.role.includes(role)
      }
    })
    return secondaryMenu
  }

  //listen to router variable change
  changeRouterVariables() {
    this.routerVariablesSubscription = this.globalStore.getRoutervariables()
      .subscribe((routerVars: any) => {
        this.routerVars = { ...routerVars };
        this.setRoutes();
      })
  }


  logout() {
    this.toggleMenu();
    this.authService.logout();
  }

  handleClick(e: any, menu: NavMenu) {
    if (menu.subMenu) {
      // clear the search query and search results of global search bar
      this.utliService.clearSearch();

      e.stopPropagation();
      e.preventDefault();
    }
    this.toggleMenu(menu);
  }


  toggleMenu(menu?: NavMenu) {
    this.headerMenu.forEach((item: any) => {
      if (Object.keys(item).includes('toggleSubmenu')) {
        if (item !== menu) {
          item['toggleSubmenu'] = false;
        }
      }
    })
    if (menu && menu.subMenu?.length) {
      menu.toggleSubmenu = !menu.toggleSubmenu;
      if (menu.page == PAGE.REPORTS) {
        this.globalStore.removeFilters(PAGE.REPORTSCOMPLETEDINTERVIEWS);
        this.globalStore.removePageDetails(PAGE.REPORTSCOMPLETEDINTERVIEWS);
        this.dayRangeService.removeCurrentPageDateDetails(PAGE.REPORTSCOMPLETEDINTERVIEWS);
      }
    } else {
      this.isRouteChanged(() => {
        if (menu?.page) {
          if (menu.page == PAGE.ACTIONITEMS) {
            //clear filters and page details of default sub-menu
            menu.page = [Role.HR, Role.HRM, Role.Director].includes(this.userRole) ? PAGE.ACTIONITEMSREVIEWRATING : PAGE.AWAITINGRATING;
          }
          this.globalStore.removeFilters(menu?.page);
          this.globalStore.removePageDetails(menu?.page);
        }

        if (menu?.page1) {
          this.globalStore.removeFilters(menu.page1);
          this.globalStore.removePageDetails(menu.page1)
        }
        if (window.screen.width < 768) {
          this.toggleIcon();
        }
      })
    }
  }

  isRouteChanged(callback: Function) {
    let s = this.router.events.subscribe((e: any) => {
      if (e instanceof RoutesRecognized) {
        if (this.router.url !== e.urlAfterRedirects) {
          callback();
        }
        s.unsubscribe();
      }
      if (e instanceof NavigationEnd) {
        s?.unsubscribe();
      }
    })
  }

  toggleIcon(event?: any) {
    // clear the search query and search results of global search bar
    this.utliService.clearSearch();
    
    event?.stopPropagation();
    this.isNavCollapsed = !this.isNavCollapsed;
    if (!this.isNavCollapsed) {
      this.showActiveMenu(this.headerMenu, this.router.url);
    }
    this.sideNavCollapsed.emit(this.isNavCollapsed);
  }

  //replace strings with actual value(like id) in routerLink
  setRoutes() {
    this.secondaryMenu = this.secondaryMenu.map((menu: any) => {
      Object.keys(this.routerVars).forEach((key: string) => {
        menu = { ...menu, routerLink: menu.routerLink.replace(key, this.routerVars[key]) }
      })
      return menu;
    });
  }

  hasCustomMenu() {
    this.headerMenu.forEach((menu: any) => {
      if (customSideNav[menu.page]?.length > 0) {
        this.hasCustomSideNav.push(menu.page);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  updateNavCollapseStatus() {
    const screenWidth = window.screen.width;
    if (screenWidth < 768) {
      this.isNavCollapsed = true;
    }
    else {
      this.isNavCollapsed = false;
    }
    this.sideNavCollapsed.emit(this.isNavCollapsed);
  }

  expand(e: any, menu: NavMenu) {
    if (menu.subMenu) { e.stopPropagation(); e.preventDefault() }
  }

  ngOnDestroy() {
    this.routerVariablesSubscription.unsubscribe();
    this.secondaryNavSubscription.unsubscribe();
    this.hideMenuSubscription.unsubscribe();
  }
}

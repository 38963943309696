import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class CustomValidators {
  dateFormat: string = 'YYYY-MM-DD';
  
  minimumDate(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      let controlValue = control.value?.value?.startDate ? dayjs(control.value?.value?.startDate).format("YYYY-MM-DD") : control.value;
      const controlDate = moment(controlValue, this.dateFormat);
      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date).subtract(1, 'day');

      return controlDate.isAfter(validationDate) ? null : {
          'minimumDate': validationDate.format(this.dateFormat),
          'actual': controlDate.format(this.dateFormat)
      };
    };
  }

  limitMaxmRows(maxmRowLimit:number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const newContent: string = control.value.trim();
      const lines = newContent.split("\n");
      const count = lines.length;
      return count > maxmRowLimit ? {'maxRows':maxmRowLimit ,'actualRow':count} : null;
    };
  }

  minimumTime(time: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      return control.value > time ? null : {
        'minimumTime': time,
        'actual': control.value
      }
    };
  }

  futureDateValidator(control: FormControl): { [s: string]: boolean } | null {
    if (control.value) {
      const date = moment(control.value);
      const today = moment();
      if (date.isAfter(today)) {
        return { 'invalidDate': true }
      }
    }
    return null;
  }
}
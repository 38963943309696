import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ApiService } from "../core/http/api.service";

export const routes = {
  getInterviews: '/api/interviews/',
  getInterviewById: '/api/interviews',
  getCandidateInterviews: '/api/candidates',
  getDropdowns: '/api/dropdown-items/',
  getScheduleInvites: '/api/schedule-invites',
  getInterviewDetails:`/api/availability/interviews`
};

@Injectable({
  providedIn: 'root'
})

export class InterviewService {

  isInterviewsLoadedSubject: Subject<boolean> = new Subject();
  reloadInterviews = new Subject<void>();
  constructor(private apiService: ApiService) {
    this.setInterviewLoaded(false);
  }

  isInterviewLoaded(): Observable<boolean> {
    return this.isInterviewsLoadedSubject.asObservable();
  }

  setInterviewLoaded(bool: boolean) {
    this.isInterviewsLoadedSubject.next(bool);
  }
  getInterviews(url: string, params?: any) {
    return this.apiService.get(url, params);
  }

  getCandidateInterviews(id: string, params?: any) {
    return this.apiService.get(`${routes.getCandidateInterviews}/${id}/interviews/`, params)
  }

  getInterviewById(id: string, params?: any) {
    return this.apiService.get(`${routes.getInterviewById}/${id}/`, params);
  }

  addInterviewRating(id: any, data: any) {
    return this.apiService.post(`${routes.getInterviews}${id}/rating/`, data);
  }

  requestInterviewRating(id: any) {
    return this.apiService.post(`${routes.getInterviewById}/${id}/request-rating/`, {});
  }

  sendInterviewCallLetter(id: any,data:any) {
    return this.apiService.post(`${routes.getInterviewById}/${id}/call-letter/`,data);
  }

  cancelInteriew(id: any) {
    return this.apiService.post(`${routes.getInterviewById}/${id}/cancel/`, {});
  }
    
  getInterviewCallLetter(id: any) {
    return this.apiService.get(`${routes.getInterviewById}/${id}/call-letter/`);
  }

  sendCallLetterEmail(id:any,data:any)
  {
   return this.apiService.post(`${routes.getInterviewById}/${id}/send-call-letter/`,data);
  }  
  updateInterviewNoShow(data: any, id: number) {
    return this.apiService.post(`${routes.getInterviewById}/${id}/no-show/`, data);
  }

  revokeScheduleInvite(id: number) {
    return this.apiService.post(`${routes.getScheduleInvites}/${id}/revoke/`, {});
  }

  getScheduleInviteById(id: string, params?: any) {
    return this.apiService.get(`${routes.getScheduleInvites}/${id}/`, params);
  }

  getInterviewDetailsById(id: string, params?: any) {
    return this.apiService.get(`${routes.getInterviewDetails}/${id}/`, params);
  }

}
